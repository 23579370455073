<template>
    <div class="vehicle-content" v-if="Vehicle.Tasks">
        <div class="notice" v-if="Vehicle.Tasks.length == 0">
            <span class="notice-title">📌 Er zijn nog geen taken</span>
            <span>Beheer eenvoudig taken per voertuig met onze takenmodule. Voeg een omschrijving toe, wijs een verantwoordelijke aan en stel een datum in om het overzicht te behouden. Zo blijft elk voertuig altijd up-to-date en klaar voor gebruik.</span>
        </div>
        <div class="tasks">
            <div class="task-item" v-for="(Task, i) in Vehicle.Tasks" :key="i" :class="{ completed: Task.Completed == 1 }">
                <div class="checkbox" @click="Task.Completed = !Task.Completed, CompleteTask(Task)">
                    <div class="checked" v-if="Task.Completed == 1"></div>
                </div>
                <span class="text">{{ Task.Text }}</span>
                <span class="actor" v-if="Task.Actor">{{ Task.ActorName[0] }}</span>
                <span class="date" v-if="Task.Date"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 6V12" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M16.24 16.24L12 12" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>{{ SetDate(Task.Date) }}</span>
                <svg @click="this.$root.$refs.Task.Init('Edit', Task.Identifier)" class="edit" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><g><g><rect width="4" height="4" x="10" y="3" rx="2"></rect><rect width="4" height="4" x="10" y="10" rx="2"></rect><rect width="4" height="4" x="10" y="17" rx="2"></rect> </g></g></svg>
            </div>
            <div class="task-create" @click="this.$root.$refs.Task.Init('Add', Vehicle.Identifier)">Aanmaken</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VehicleTasks',

    props: ['Vehicle'],

    methods: {
        CompleteTask (e) {
            this.$http.patch(`https://api.wheels.nl/tasks/${e.Identifier}`, {
                Text: e.Text,
                Completed: true
            }).then(() => {
                e.Completed = true
            })
        },

        SetDate (e) {
            if(e) {
                return this.$moment(e).format('D-MM-Y')
            }
        }
    }
}
</script>

<style scoped>
.vehicle-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    padding: 50px;
}

.vehicle-content .notice {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 15px 25px;
    width: 100%;
    padding: 25px;
    background: #ffffe0;
    border-radius: 5px;
}

.vehicle-content .notice .notice-title {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
}

.vehicle-content .tasks {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.vehicle-content .tasks .task-item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 80px;
    padding: 25px 50px 25px 25px;
    background: #fff;
    border-radius: 5px;
}

.vehicle-content .tasks .task-item.completed {
    opacity: 0.5;
}

.vehicle-content .tasks .task-item .checkbox {
    position: relative;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    border: solid 1px #ececec;
    border-radius: 5px;
    cursor: pointer;
}

.vehicle-content .tasks .task-item .checkbox .checked {
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: var(--primary);
    border-radius: 2.5px;
}

.vehicle-content .tasks .task-item .text {
    margin: 0 auto 0 0;
}

.vehicle-content .tasks .task-item .actor {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fde1bf;
    font-weight: 500;
    border-radius: 50%;
}

.vehicle-content .tasks .task-item .date {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 40px;
    padding: 0 15px;
    background: #f7f8fc;
    border-radius: 35px;
}

.vehicle-content .tasks .task-item .date svg {
    width: 20px;
    height: 20px;
}

.vehicle-content .tasks .task-item .edit {
    position: absolute;
    top: 23px;
    right: 15px;
    width: 30px;
    height: 30px;
    fill: #c3c3c3;
    transition: .25s;
    cursor: pointer;
}

.vehicle-content .tasks .task-item:hover .edit {
    fill: #333;
}

.vehicle-content .tasks .task-create {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    font-weight: 600;
    text-decoration: none;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}
</style>