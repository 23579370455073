<template>
    <div class="articles">
        <div class="filters" :class="{ loader: Loader }">
            <span class="title">Filter</span>

            <router-link to="/voertuigen/aanmaken" class="create">Aanmaken</router-link>

            <div class="group">
                <input v-model="Search" @change="GetArticles(true)" class="search" :class="{ active: Search }" type="text" placeholder="Zoek op merk, model of meldcode">
            </div>

            <span class="reset" v-if="Search.length > 0 || Filtered.length > 0 || Selected.length > 0" @click="ResetFilters">Reset</span>

            <div class="new-group">
                <span class="group-title">Merken en modellen</span>
                <input class="group-search" type="text" v-model="Models.Search">
                <div class="selected" v-if="Models.Checked.length > 0">
                    <span class="item" v-for="(Item, i) in Models.Checked" :key="i" @click="SetFilter(Item, Models, false)">{{ Item.Name }}</span>
                </div>
                <div class="divider" v-if="Models.Checked.length > 0"></div>
                <div class="items" :class="{ extended: Models.Search }">
                    <div class="item" v-for="(Item, Si) in Models.Items" :key="Si" :class="{ hidden: Item.filter(e => e.ModelName.toLowerCase().includes(Models.Search.toLowerCase())).length == 0 && !Item[0].BrandName.toLowerCase().startsWith(Models.Search.toLowerCase()) }">
                        <div class="brand">
                            <div class="checkbox" @click="SetItemFilter(Item, Models), Models.Search = ''">
                                <div v-if="Item.length == Models.Checked.filter(e => e.Brand == Item[0].BrandIdentifier).length" class="checked"></div>
                                <div v-if="Models.Checked.filter(e => e.Brand == Item[0].BrandIdentifier).length > 0 && Models.Checked.filter(e => e.Brand == Item[0].BrandIdentifier).length < Item.length" class="sub-checked"></div>
                            </div>
                            <span class="name">{{ Item[0].BrandName }}</span>
                            <svg class="more" @click="Item.Active = !Item.Active" :class="{ active: Item.Active }" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#000000"></path></g></svg>
                        </div>
                        <div class="list" v-if="Item.Active || Models.Search">
                            <div class="models">
                                <div class="model" v-for="(Model, ModelID) in Item.filter(e => e.ModelName.toLowerCase().includes(Models.Search.toLowerCase()))" :key="ModelID" @click="SetFilter(Model, Models, false)">
                                    <div class="checkbox">
                                        <div v-if="Models.Checked.filter(item => item.ModelIdentifier == Model.ModelIdentifier).length > 0" class="checked"></div>
                                    </div>
                                    <span class="name">{{ Model.ModelName }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="new-group" v-for="(Filter, i) in Filters" :key="i">
                <span class="group-title">{{ Filter.Title }}</span>
                <input class="group-search" v-if="Filter.Searchable" type="text" v-model="Filter.Search">
                <div class="selected" v-if="Filter.Checked.length > 0">
                    <span class="item" v-for="(Item, i) in Filter.Checked" :key="i" @click="SetDynamicFilter(Item, Filter, false)">{{ Item.Name }}</span>
                </div>
                <div class="divider" v-if="Filter.Checked.length > 0"></div>
                <div class="items" :class="{ 'max-extended': Filter.Items.filter(e => e.Active).length > 0 }">
                    <div class="item" v-for="(Item, i) in Filter.Items" :key="i" :class="{ hidden: Item.SubItems.filter(e => e.Name.toLowerCase().includes(Filter.Search.toLowerCase())).length == 0 && !Item.Name.toLowerCase().includes(Filter.Search.toLowerCase()) }">
                        <div class="brand">
                            <div class="checkbox" v-if="Filter.Checkable" @click="SetDynamicItemFilter(Filter, Item), Filter.Search = ''">
                                <div v-if="Item.SubItems.length == Filter.Checked.filter(e => e.Brand == Item.Identifier).length" class="checked"></div>
                                <div v-if="(Item.SubItems.length > Filter.Checked.filter(e => e.Brand == Item.Identifier).length) && Filter.Checked.filter(e => e.Brand == Item.Identifier).length > 0" class="sub-checked"></div>
                            </div>
                            <span class="name">{{ Item.Name }}</span>
                            <svg class="more" @click="Item.Active = !Item.Active" :class="{ active: Item.Active }" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#000000"></path></g></svg>
                        </div>
                        <div class="list" v-if="Item.Active || Filter.Search">
                            <div class="models" v-for="(SubItem, Si) in Item.SubItems.filter(e => e.Name.toLowerCase().includes(Filter.Search.toLowerCase()))" :key="Si">
                                <div class="model" @click="SetDynamicFilter(SubItem, Filter, Item)">
                                    <div class="checkbox">
                                        <div v-if="Filter.Checked.filter(item => item.Identifier == SubItem.Identifier).length > 0" class="checked"></div>
                                    </div>
                                    <span class="name">{{ SubItem.Name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="catalog">
            <span class="title" v-if="Vehicles.length > 1">{{ Vehicles.length }} voertuigen</span>
            <span class="title" v-else-if="Vehicles.length == 1">{{ Vehicles.length }} voertuig</span>
            <span class="title" v-else>Geen voertuigen gevonden</span>

            <div class="items">
                <div class="item" v-for="Vehicle in Vehicles" :key="Vehicle.Identifier">
                    <div class="image" v-if="Vehicle.MediaIdentifier" :style="{ background: 'url(https://api.wheels.nl/vault_vehicles/' + Vehicle.Identifier + '/Thumbnails/' + Vehicle.MediaIdentifier + '.png)' }"></div>
                    <div class="image" v-else :style="{ background: 'url(https://placehold.co/600x400/EEE/31343C)' }"></div>
                    <div class="information">
                        <span class="brand"><b>{{ Vehicle.Brand }} {{ Vehicle.Model }}</b>{{ Vehicle.Type }}</span>
                        <div class="specifications">
                            <span class="specifications-item" v-if="Vehicle.VIN">{{ Vehicle.VIN }}</span>
                            <span class="specifications-item license" v-if="Vehicle.License">{{ $root.SetLicense(Vehicle.License) }}</span>
                            <span class="specifications-item" v-if="Vehicle.Registration">{{ this.$moment(Vehicle.Registration).format('DD-MM-Y') }}</span>
                            <span class="specifications-item" v-if="Vehicle.Fuel">{{ SetFuel(Vehicle.Fuel) }}</span>
                            <span class="specifications-item" v-if="Vehicle.Gear">{{ SetGear(Vehicle.Gear) }}</span>
                        </div>
                    </div>
                    <div class="status">
                        <span class="date">{{ SetDate(Vehicle.Created) }}</span>
                        <div class="state">
                            <div class="state-item" v-for="Status in Vehicle.Status" :key="Status.Identifier" :style="{ background: SetStatusStyle(Status.Options.find(item => item.Identifier == Status.Value)).Background, color: SetStatusStyle(Status.Options.find(item => item.Identifier == Status.Value)).Color }" v-tooltip.top="{ value: Status.Name }">{{ Status.Code }}</div>
                            <div class="state-divider" v-if="Vehicle.Status"></div>
                            <div class="state-item" v-for="StatusItem in Status" :key="StatusItem.Identifier" :style="{ background: StatusStyle(StatusItem, Vehicle.VehicleStatus).Background, color: StatusStyle(StatusItem, Vehicle.VehicleStatus).Color }" v-tooltip.top="{ value: StatusItem.Name }">{{ StatusItem.Code }}</div>
                        </div>
                    </div>
                    <div class="options">
                        <router-link :to="'/voertuigen/' + Vehicle.Identifier" class="option">Bewerken</router-link>
                        <span class="option">Downloaden</span>
                        <span class="option">Dupliceren</span>
                        <span class="option" @click="DeleteVehicle(Vehicle)">Verwijderen</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticlesOverview',

    data () {
        return {
            View: 'Grid',
            Loader: false,
            Latest: false,
            Vehicles: [],
            Search: '',
            Filters: [
                {
                    Title: 'Statussen',
                    Search: '',
                    Searchable: false,
                    Checkable: true,
                    Checked: [],
                    Items: [
                        {
                            Identifier: 'c75b95b2-b982-4298-8eba-a7b3ccddca63',
                            Name: 'Transport',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: '18c42123-f953-4a4e-91fb-0e24cb8c690b',
                                    Name: 'Nog te plannen',
                                    Value: 1111
                                },
                                {
                                    Identifier: '1963d634-1be7-458e-88a0-6e6609acd46f',
                                    Name: 'Ingepland',
                                    Value: 2222
                                },
                                {
                                    Identifier: '31af88a1-3ad1-4d98-b85d-00eb11eafebf',
                                    Name: 'Onderweg',
                                    Value: 3333
                                },
                                {
                                    Identifier: 'fe882b4f-1ca6-4de7-9b92-927920a9ccd7',
                                    Name: 'Geleverd',
                                    Value: 4444
                                }
                            ]
                        },
                        {
                            Identifier: '22ba16a9-64ed-44b2-ad50-67c96d1a6731',
                            Name: 'Papieren',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: 'e3ace2ce-2d81-4efe-b6a0-5a7dc6d96e67',
                                    Name: 'Nog niet geüpload',
                                    Value: 1111
                                },
                                {
                                    Identifier: '4cff503f-32f8-435f-a518-72792d80480d',
                                    Name: 'Geüpload',
                                    Value: 3333
                                }
                            ]
                        },
                        {
                            Identifier: '53cd4368-1fc5-4cf7-8b4b-55b7b597fb9c',
                            Name: 'Opname',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: 'f2d4ddc6-b0f9-4b98-8b5d-edcd5aa51b35',
                                    Name: 'Nog niet geüpload',
                                    Value: 1111
                                },
                                {
                                    Identifier: 'c6a42879-3d13-4524-9238-566510deae98',
                                    Name: 'Geüpload',
                                    Value: 3333
                                }
                            ]
                        },
                        {
                            Identifier: '3d0c99cf-4271-4c3c-87ca-018ad68cee41',
                            Name: 'RDW',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: '48cd059a-5239-46c8-a459-c2a5a9ec2a6a',
                                    Name: 'Nog niet ingediend',
                                    Value: 1111
                                },
                                {
                                    Identifier: '394fb301-9db4-44ec-bd66-c03d29006a55',
                                    Name: 'Ingediend',
                                    Value: 3333
                                },
                                {
                                    Identifier: 'ddcd916a-5c67-4602-9ee2-8646d10b523a',
                                    Name: 'Verzoek om goede bestanden',
                                    Value: 3333
                                },
                                {
                                    Identifier: '3a74503e-a4aa-4be3-be69-fd390529bc58',
                                    Name: 'Fysieke afspraak',
                                    Value: 3333
                                },
                                {
                                    Identifier: '389c09d0-8c91-460d-9ea4-0a74ab78e6fc',
                                    Name: 'Afgerond',
                                    Value: 3333
                                }
                            ]
                        },
                        {
                            Identifier: '22b52974-fb03-47b2-818d-f49ed13c5c40',
                            Name: 'BPM',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: '3455302a-2c52-4cea-a629-8fcd323deac5',
                                    Name: 'Nog niet ingediend',
                                    Value: 1111
                                },
                                {
                                    Identifier: '825168ea-6f93-40ab-87e6-b590295716fe',
                                    Name: 'Goedkeuren',
                                    Value: 3333
                                },
                                {
                                    Identifier: 'ea8428a8-190c-494c-9fd1-057d8b5ba737',
                                    Name: 'Verstuurd',
                                    Value: 3333
                                },
                                {
                                    Identifier: '79e03bac-d2af-4bd5-8827-416e584d883c',
                                    Name: 'Betaalbericht',
                                    Value: 3333
                                },
                                {
                                    Identifier: 'b0e60629-4bc7-41aa-a6a4-451356504fc4',
                                    Name: 'Fiscaal akkoord',
                                    Value: 3333
                                },
                                {
                                    Identifier: '33f664d9-5c30-40f6-b218-dc46138bfa83',
                                    Name: 'Afgerond',
                                    Value: 3333
                                }
                            ]
                        }
                    ],
                },
                {
                    Title: 'Specificaties',
                    Search: '',
                    Searchable: false,
                    Checkable: true,
                    Checked: [],
                    Items: [
                        {
                            Identifier: '1b9379ab-4681-4be5-b19c-b097f65034df',
                            Name: 'Soort',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: 'f84a30f8-cf5d-4dc7-a340-d0a1ff7f1d27',
                                    Name: 'Personenauto',
                                    Value: 1111
                                },
                                {
                                    Identifier: 'ecfa8a57-a360-4fcb-90fe-5f15c3ad9ea5',
                                    Name: 'Bedrijfsauto',
                                    Value: 2222
                                },
                                {
                                    Identifier: 'cb803c90-976b-4a8a-a28e-40de884cfe30',
                                    Name: 'Motor',
                                    Value: 3333
                                },
                                {
                                    Identifier: '7a78c699-983e-41e7-84f4-735891a3f3a7',
                                    Name: 'Camper',
                                    Value: 4444
                                }
                            ]
                        },
                        {
                            Identifier: 'c18e217e-5ed1-4c2a-ba43-e23396ab7bdc',
                            Name: 'Brandstof',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: '908f3e55-99ce-4f76-88df-f7a2d1828c74',
                                    Name: 'Benzine',
                                    Value: 1111
                                },
                                {
                                    Identifier: 'fd041148-defc-4403-9c3f-05110dd9a866',
                                    Name: 'Diesel',
                                    Value: 2222
                                },
                                {
                                    Identifier: '69ab6ef7-5795-4355-9d50-3b5d627dcb16',
                                    Name: 'Elektrisch',
                                    Value: 3333
                                },
                                {
                                    Identifier: 'bc863a7b-14cb-4da3-95a5-fa3fc5019a4f',
                                    Name: 'Benzine (Plugin)',
                                    Value: 4444
                                },
                                {
                                    Identifier: 'da1b8049-23f8-4aa7-93f3-a59c199dcf17',
                                    Name: 'Diesel (Plugin)',
                                    Value: 4444
                                }
                            ]
                        },
                        {
                            Identifier: '2debebf2-0c64-4d1a-a10d-076d59c2f744',
                            Name: 'Transmissie',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: 'dd7aabfb-a0a0-476e-9d3f-f8a0dcc51a44',
                                    Name: 'Automaat',
                                    Value: 1111
                                },
                                {
                                    Identifier: '2a6b5dc9-04b1-414b-8def-f5551493dd3d',
                                    Name: 'Handgeschakeld',
                                    Value: 2222
                                }
                            ]
                        }
                    ],
                },
                {
                    Title: 'Diensten',
                    Search: '',
                    Searchable: false,
                    Checkable: true,
                    Checked: [],
                    Items: [
                        {
                            Identifier: '1b9379ab-4681-4be5-b19c-b097f65034df',
                            Name: 'Import',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: 'f84a30f8-cf5d-4dc7-a340-d0a1ff7f1d27',
                                    Name: 'RDW Inschrijving',
                                    Value: 1111
                                },
                                {
                                    Identifier: 'ecfa8a57-a360-4fcb-90fe-5f15c3ad9ea5',
                                    Name: 'BPM Berekening',
                                    Value: 2222
                                }
                            ]
                        },
                        {
                            Identifier: '1b9379ab-4681-4be5-b19c-b097f65034df',
                            Name: 'Waarde',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: 'f84a30f8-cf5d-4dc7-a340-d0a1ff7f1d27',
                                    Name: 'WEV Rapportage',
                                    Value: 1111
                                }
                            ]
                        }
                    ],
                }
            ],
            Filtered: [],
            Selected: [],
            Models: {
                Active: false,
                Search: '',
                Items: '',
                Checked: []
            },
            Count: 0,
            Page: 20,
            PageLimit: 25,
            Query: false,
            SearchQuery: false,
            Status: [
                {
                    Identifier: 'TRANSPORT',
                    Name: 'Transport',
                    Code: 'T',
                    Options: [
                        {
                            Identifier: 'TRANSPORT-1',
                            Text: 'Nog te plannen',
                            Value: 'TRANSPORT-1',
                            Background: '',
                            Color: ''
                        },
                        {
                            Identifier: 'TRANSPORT-2',
                            Text: 'Ingepland',
                            Value: 'TRANSPORT-2',
                            Background: '#fde1bf',
                            Color: '#a85c00'
                        },
                        {
                            Identifier: 'TRANSPORT-3',
                            Text: 'Onderweg',
                            Value: 'TRANSPORT-3',
                            Background: 'var(--primary)',
                            Color: '#0b5a56'
                        },
                        {
                            Identifier: 'TRANSPORT-4',
                            Text: 'Geleverd',
                            Value: 'TRANSPORT-4',
                            Background: '#b4e1b4',
                            Color: '#005901'
                        }
                    ]
                },
                {
                    Identifier: 'PAPERS',
                    Name: 'Papieren',
                    Code: 'P',
                    Options: [
                        {
                            Identifier: 'PAPERS-1',
                            Text: 'Nog niet geüpload',
                            Value: 'PAPERS-1',
                            Background: '',
                            Color: ''
                        },
                        {
                            Identifier: 'PAPERS-2',
                            Text: 'Geüpload',
                            Value: 'PAPERS-2',
                            Background: '#b4e1b4',
                            Color: '#005901'
                        }
                    ]
                },
                {
                    Identifier: 'PICTURES',
                    Name: 'Opname',
                    Code: 'O',
                    Options: [
                        {
                            Identifier: 'PICTURES-1',
                            Text: 'Nog niet geüpload',
                            Value: 'PICTURES-1',
                            Background: '',
                            Color: ''
                        },
                        {
                            Identifier: 'PICTURES-2',
                            Text: 'Geüpload',
                            Value: 'PICTURES-2',
                            Background: '#b4e1b4',
                            Color: '#005901'
                        }
                    ]
                },
                {
                    Identifier: 'RDW',
                    Name: 'RDW',
                    Code: 'R',
                    Options: [
                        {
                            Identifier: 'RDW-1',
                            Text: 'Nog niet ingediend',
                            Value: 'RDW-1',
                            Background: '',
                            Color: ''
                        },
                        {
                            Identifier: 'RDW-2',
                            Text: 'Ingediend',
                            Value: 'RDW-2',
                            Background: '#fde1bf',
                            Color: '#a85c00'
                        },
                        {
                            Identifier: 'RDW-3',
                            Text: 'Verzoek om goede bestanden',
                            Value: 'RDW-3',
                            Background: '#800080',
                            Color: '#ffffff'
                        },
                        {
                            Identifier: 'RDW-4',
                            Text: 'Fysieke afspraak',
                            Value: 'RDW-4',
                            Background: '#f693b4',
                            Color: '#982249'
                        },
                        {
                            Identifier: 'RDW-5',
                            Text: 'Afgerond',
                            Value: 'RDW-5',
                            Background: '#b4e1b4',
                            Color: '#005901'
                        }
                    ]
                },
                {
                    Identifier: 'BPM',
                    Name: 'BPM',
                    Code: 'B',
                    Options: [
                        {
                            Identifier: 'BPM-1',
                            Text: 'Nog niet ingediend',
                            Value: 'BPM-1',
                            Background: '',
                            Color: ''
                        },
                        {
                            Identifier: 'BPM-2',
                            Text: 'Goedkeuren',
                            Value: 'BPM-2',
                            Background: '#cae5ff',
                            Color: '#016dd1'
                        },
                        {
                            Identifier: 'BPM-3',
                            Text: 'Verstuurd',
                            Value: 'BPM-3',
                            Background: 'var(--primary)',
                            Color: '#0b5a56'
                        },
                        {
                            Identifier: 'BPM-4',
                            Text: 'Betaalbericht',
                            Value: 'BPM-4',
                            Background: '#1a41fe',
                            Color: '#ffffff'
                        },
                        {
                            Identifier: 'BPM-5',
                            Text: 'Informatieverzoek',
                            Value: 'BPM-5',
                            Background: '#800080',
                            Color: '#ffffff'
                        },
                        {
                            Identifier: 'BPM-6',
                            Text: 'Fiscaal akkoord',
                            Value: 'BPM-6',
                            Background: '#e5be01',
                            Color: '#333333'
                        },
                        {
                            Identifier: 'BPM-7',
                            Text: 'Afgerond',
                            Value: 'BPM-7',
                            Background: '#b4e1b4',
                            Color: '#005901'
                        }
                    ]
                }
            ]
        }
    },

    mounted () {
        this.GetVehicles()
        this.GetModels()
    },

    methods: {
        GetVehicles () {
            if(this.$route.query) {
                for (const [key, value] of Object.entries(this.$route.query)) {
                    if(key) {
                        var Selected = value.split('|')
                    }

                    this.Filters.forEach(FilterItem => {
                        FilterItem.Items.forEach(SubFilterItems => {
                            SubFilterItems.SubItems.forEach(SubFilterItem => {
                                if(Selected.includes(SubFilterItem.Identifier)) {
                                    FilterItem.Checked.push({
                                        Brand: SubFilterItems.Identifier,
                                        Identifier: SubFilterItem.Identifier,
                                        Name: SubFilterItem.Name
                                    })
                                }
                            })
                        })
                    })
                }
            }
            
            this.$http.get(`https://api.wheels.nl/vehicles?query=${JSON.stringify(this.$route.query)}`).then((response) => {
                if(response.data.vehicles) { this.Vehicles = response.data.vehicles }
            })
        },

        GetModels () {
            this.$http.get(`https://api.wheels.nl/vehicles/models?query=${JSON.stringify(this.$route.query)}`).then((response) => {                
                if(response.data.models) { this.Models.Items = response.data.models }
                if(response.data.checked) {this.Models.Checked = response.data.checked }
            })
        },

        DeleteVehicle (e) {
            if(confirm('Weet je zeker dat je dit voertuig wilt verwijderen?')) {
                this.$http.delete(`https://api.wheels.nl/vehicles/${e.Identifier}`).then((response) => {
                    if(response.data.error) {
                        this.$toast.open({
                            message: 'Voertuig kan niet verwijderd worden',
                            position: 'bottom-right',
                            type: 'error',
                            duration: 1500
                        })
                    } else {
                        this.Vehicles = this.Vehicles.filter(item => item.Identifier !== e.Identifier)

                        this.$toast.open({
                            message: 'Voertuig verwijderd',
                            position: 'bottom-right',
                            type: 'success',
                            duration: 1500
                        })
                    }
                })
            }
        },

        SetFuel (e) {
            if(e == 'B') {
                return 'Benzine'
            } else if(e == 'D') {
                return 'Diesel'
            } else if(e == 'E') {
                return 'Elektrisch'
            } else if(e == 'BH') {
                return 'Benzine (Plugin)'
            } else if(e == 'DH') {
                return 'Diesel (Plugin)'
            } else {
                return e
            }
        },

        SetGear (e) {
            if(e == 'A') {
                return 'Automaat'
            } else if(e == 'H') {
                return 'Handgeschakeld'
            } else {
                return e
            }
        },

        SetDate (e) {
            if(this.$moment().isSame(e, 'day')) {
                return this.$moment(e).format('HH:mm')
            } else {
                return this.$moment(e).format('DD-MM')
            }
        },

        StatusStyle (e, a) {
            if(a && a.filter(item => item.Identifier == e.Identifier).length > 0) {
                var Find = a.find(item => item.Identifier == e.Identifier)
                var Style = this.Status.find(item => item.Identifier == Find.Identifier).Options.find(subItem => subItem.Value == Find.Value)

                var Background
                var Color

                if(Style.Background) {
                    Background = Style.Background
                } else {
                    Background = ''
                }

                if(Style.Background) {
                    Color = Style.Color
                } else {
                    Color = ''
                }

                return {
                    "Background": Background,
                    "Color": Color
                }
            } else {
                return {
                    "Background": Background,
                    "Color": Color
                }
            }
        },

        SetStatusStyle (e) {
            if(e && e.Identifier && e.Value) {
                var Item = this.Status.find(item => item.Identifier == e.Identifier).Options.find(subItem => subItem.Value == e.Value)

                if(Item) {
                    return {
                        Background: Item.Background,
                        Color: Item.Color
                    }
                } else {
                    return {
                        Background: '',
                        Color: ''
                    }
                }
            } else if(e && e.Identifier && (e.Background || e.Color)) {
                return {
                    Background: e.Background,
                    Color: e.Color
                }
            } else {
                return {
                    Background: '',
                    Color: ''
                }
            }
        },

        ToggleStatus (e, event) {
            this.$refs[e][0].toggle(event)
        },

        SetFilter (e, a) {
            if(a.Checked.filter(item => item.ModelIdentifier == e.ModelIdentifier).length > 0) {
                a.Checked = a.Checked.filter(item => item.ModelIdentifier !== e.ModelIdentifier)
            } else {
                a.Checked.push({
                    ModelIdentifier: e.ModelIdentifier,
                    Brand: e.BrandIdentifier,
                    Name: e.ModelName
                })
            }

            this.SetQuery()
        },

        SetDynamicFilter (e, a, z) {
            if(a.Checked.filter(item => item.Identifier == e.Identifier).length > 0) {
                a.Checked = a.Checked.filter(item => item.Identifier !== e.Identifier)
            } else {
                a.Checked.push({
                    Identifier: e.Identifier,
                    Brand: z.Identifier,
                    Name: e.Name
                })
            }

            this.SetQuery()
        },

        SetItemFilter (e, a) {
            if(e.length == a.Checked.filter(z => z.Brand == e[0].BrandIdentifier).length) {
                a.Checked = a.Checked.filter(z => z.Brand !== e[0].BrandIdentifier)
            } else {
                e.forEach(z => {
                    if(a.Checked.filter(item => item.ModelIdentifier == z.ModelIdentifier).length == 0) {
                        a.Checked.push({
                            ModelIdentifier: z.ModelIdentifier,
                            Brand: z.BrandIdentifier,
                            Name: z.ModelName
                        })
                    }
                })
            }

            this.SetQuery()
        },

        SetDynamicItemFilter (e, a) {
            if(a.SubItems.length == e.Checked.filter(e => e.Brand == a.Identifier).length) {
                a.SubItems.forEach(z => {
                    e.Checked = e.Checked.filter(item => item.Identifier !== z.Identifier)
                })

                a.Active = false
            } else {
                a.SubItems.forEach(z => {
                    if(e.Checked.filter(item => item.Identifier == z.Identifier).length == 0) {
                        e.Checked.push({
                            Identifier: z.Identifier,
                            Brand: a.Identifier,
                            Name: a.Name + ' - ' + z.Name
                        })
                    }
                })

                a.Active = true
            }

            this.SetQuery()
        },

        SetSelection (e) {
            if(this.Selected.filter(item => item.Identifier == e.Identifier).length > 0) {
                this.Selected = this.Selected.filter(item => item.Identifier !== e.Identifier)
            } else {
                this.Selected.push(e)
            }

            localStorage.setItem('ArticleTime', Date.now())
            localStorage.setItem('ArticleSelection', JSON.stringify(this.Selected))
        },

        SetQuery () {
            const query = { ...this.$route.query }
            
            if(this.Models.Checked.length > 0) {
                query['model'] = this.Models.Checked.map(item => item.ModelIdentifier).join('|')
            } else {
                delete query['model']
            }
            
            this.Filters.forEach(e => {
                if(e.Checked.length > 0) {                    
                    query[e.Title] = e.Checked.map(item => item.Identifier).join('|')
                } else {
                    delete query[e.Title]
                }
            })

            this.$router.push({ query: query, params: { savePosition: true } })

            setTimeout(() => {
                this.GetVehicles()
            }, 50);
        },

        ResetFilters () {
            this.Page = 0
            this.Limit = 100

            localStorage.removeItem('ArticleTime')

            this.Search = ''
            localStorage.removeItem('ArticleSearch')

            this.Filtered = []
            localStorage.removeItem('ArticleFilter')

            this.Group = false
            localStorage.removeItem('ArticleGroup')

            this.Selected = []
            localStorage.removeItem('ArticleSelection')

            this.GetArticles()
        }
    }
}
</script>

<style scoped>
.articles {
    position: relative;
    display: flex;
    gap: 0 50px;
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 50px;
    background: #f7f8fc;
    overflow: hidden;
}

.articles .filters {
    position: relative;
    min-width: 350px;
    max-width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    padding: 50px 0;
    transition: .5s;
    overflow-y: auto;
}

.articles .filters.loader {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.articles .filters::-webkit-scrollbar {
    width: 0px;
}

.articles .filters .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.articles .filters .create {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    font-weight: 600;
    text-decoration: none;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.articles .filters .menu .list {
    width: 32px;
    height: 32px;
    fill: #000;
    cursor: pointer;
}

.articles .filters .menu .blocks {
    width: 24px;
    height: 24px;
    fill: #000;
    cursor: pointer;
}





.articles .filters .group .search {
    display: block;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.articles .filters .group .search.active {
    outline: solid 1px var(--primary);
    border: solid 1px var(--primary);
}





.articles .filters .radio {
    display: flex;
    width: 100%;
    min-height: 50px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
    overflow: hidden;
}

.articles .filters .radio .item {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.articles .filters .radio .item.active {
    color: #fff;
    font-weight: 600;
    background: var(--primary);
}





.articles .filters .reset {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    background: var(--primary);
    font-weight: 500;
    color: #fff;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}





.articles .filters .new-group {
    padding: 0 25px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.articles .filters .new-group.active {
    outline: solid 1px var(--primary);
    border: solid 1px var(--primary);
}

.articles .filters .new-group .group-title {
    display: block;
    font-weight: 700;
    margin: 20px 0 10px 0;
}

.articles .filters .new-group .group-search {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    margin: 0 0 15px 0;
    background: #f7f8fc;
    border: 0;
    border-radius: 5px;
    outline: 0;
}

.articles .filters .new-group .prices {
    display: flex;
    gap: 15px;
    margin: 0 0 25px 0;
}

.articles .filters .new-group .prices input {
    width: 100%;
    height: 50px;
    padding: 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.articles .filters .new-group .selected {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.articles .filters .new-group .selected .item {
    display: flex;
    align-items: center;
    padding: 2.5px 10px;
    font-size: 14px;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.articles .filters .new-group .divider {
    border-bottom: solid 1px #f7f8fc;
    padding: 15px 0 0 0;
    margin: 0 0 15px 0;
}

.articles .filters .new-group .search {
    width: 100%;
    background: #f7f8fc;
    padding: 2.5px 10px;
    margin: 0 0 10px 0;
    border: 0;
    border-radius: 5px;
    outline: none;
}

.articles .filters .new-group .items {
    display: flex;
    flex-direction: column;
    max-height: 250px;
    padding: 0 0 25px 0;
    overflow: auto;
    transition: .5s;
}

.articles .filters .new-group .items.extended {
    max-height: 500px;
}

.articles .filters .new-group .items.max-extended {
    max-height: unset;
}

.articles .filters .new-group .items::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.articles .filters .new-group .items .item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.articles .filters .new-group .items .item.hidden {
    display: none;
}

.articles .filters .new-group .items .item .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.articles .filters .new-group .items .item .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}

.articles .filters .new-group .items .item .checkbox .sub-checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
    opacity: 0.5;
}

.articles .filters .new-group .items .item .count {
    margin: 0 0 0 auto;
    font-size: 12px;
    opacity: 0.25;
}

.articles .filters .new-group .items .item .brand {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.articles .filters .new-group .items .item .brand .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.articles .filters .new-group .items .item .brand span {
    cursor: pointer;
}

.articles .filters .new-group .items .item .brand .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}

.articles .filters .new-group .items .item .brand .more {
    height: 40px;
    padding: 10px 0;
    margin: 0 0 0 auto;
    transition: .25s;
}

.articles .filters .new-group .items .item .brand .more.active {
    transform: rotate(180deg);
}

.articles .filters .new-group .items .item .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 0 10px 0;
}

.articles .filters .new-group .items .item .list .models {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 0 0 0 30px;
    cursor: pointer;
}

.articles .filters .new-group .items .item .list .models .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
}

.articles .filters .new-group .items .item .list .models .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}

.articles .filters .new-group .items .item .list .models .model {
    display: flex;
    gap: 10px;
}





.articles .catalog {
    position: relative;
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 50px 0;
    overflow: auto;
}

.articles .catalog .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.articles .catalog .items {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.articles .catalog .items .item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    padding: 15px;
    background: #fff;
    text-decoration: none;
    border: solid 1px #ededed;
    border-radius: 5px;
    transition: .25s;
}

.articles .catalog .items .item .image {
    width: 60px;
    height: 60px;
    background: #f7f8fc;
    background-position: 50% !important;
    background-size: cover !important;
    border-radius: 5px;
}

.articles .catalog .items .item .information {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.articles .catalog .items .item .information .brand {
    display: flex;
    gap: 5px;
}

.articles .catalog .items .item .information .brand b {
    font-weight: 600;
}

.articles .catalog .items .item .information .specifications {
    display: flex;
    align-items: center;
    gap: 10px;
}

.articles .catalog .items .item .information .specifications .specifications-item {
    width: fit-content;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f8fc;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 5px;
}

.articles .catalog .items .item .information .specifications .specifications-item.license {
    background: #e5be01;
    font-weight: 600;
    border-left: solid 10px #1a41fe;
    border-radius: 5px;
}

.articles .catalog .items .item .status {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0 0 0 auto;
}

.articles .catalog .items .item .status .state {
    display: flex;
    gap: 5px;
}

.articles .catalog .items .item .status .state .state-item {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f8fc;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}

.articles .catalog .items .item .status .state .state-item.orange {
    background: #fde1bf;
    color: #a85c00;
}

.articles .catalog .items .item .status .state .state-item.green {
    background: #b4e1b4;
    color: #005901;
}

.articles .catalog .items .item .status .state .state-divider {
    width: 8px;
    height: 8px;
    margin: auto 5px;
    background: #ededed;
    border-radius: 50%;
}

.articles .catalog .items .item .status .date {
    font-weight: 400;
    text-align: right;
}

.articles .catalog .items .item .status .state .license {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e5be01;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 600;
    border-left: solid 15px #1a41fe;
    border-radius: 5px;
}

.articles .catalog .items .item .options {
    width: 100%;
    height: 0;
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 0 0 75px;
    transition: .25s;
    opacity: 0;
}

.articles .catalog .items .item:hover .options {
    height: 20px;
    opacity: 1;
}

.articles .catalog .items .item .options .option {
    text-decoration: none;
    cursor: pointer;
}

.articles .catalog .items .item .options .option:hover {
    text-decoration: underline;
}





/* .articles .catalog {
    position: relative;
    width: calc(100% - 300px);
}

.articles .catalog .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.articles .catalog .selection {
    min-width: 250px;
    background: #fff;
}

.articles .catalog .list .header {
    display: flex;
    gap: 0 1px;
    border-bottom: solid 1px #ebebeb;
}

.articles .catalog .list .header .column {
    display: flex;
    align-items: center;
    min-width: 125px;
    height: 30px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 700;
    background: #fff;
    white-space: nowrap;
    overflow: hidden;
}

.articles .catalog .list .header .column.icon {
    min-width: 25px;
    max-width: 25px;
    padding: 0 5px;
}

.articles .catalog .grid {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 25px;
    height: 100%;
    padding: 49px 0 50px 1px;
    overflow: auto;
    transition: .5s;
}

.articles .catalog .grid.loader {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.articles .catalog .grid::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.articles .catalog .grid .header {
    width: 100%;
    display: flex;
    align-items: center;
}

.articles .catalog .grid .product {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc((100% / 7) - 22px);
    padding: 70px 25px 35px 25px;
    background: #fff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
    overflow: hidden;
}

.articles .catalog .grid .product.checked {
    outline: solid 1px var(--primary);
    border: solid 1px var(--primary);
}

.articles .catalog .grid .product .checkbox {
    position: absolute;
    top: 25px;
    left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.articles .catalog .grid .product.checked .checkbox .checked {
    width: 15px;
    height: 15px;
    background: var(--primary);
    border-radius: 2.5px;
}

.articles .catalog .grid .product .ean {
    position: absolute;
    top: 25px;
    right: 27.5px;
    height: 25px;
    display: flex;
    align-items: center;
    color: #b5b5b5;
    font-size: 12px;
    font-weight: 300;
}

.articles .catalog .grid .product .supplier-id {
    position: absolute;
    top: 45px;
    right: 27.5px;
    height: 25px;
    display: flex;
    align-items: center;
    color: #b5b5b5;
    font-size: 12px;
    font-weight: 300;
}

.articles .catalog .grid .product .completed {
    position: absolute;
    top: -22.5px;
    right: -22.5px;
    width: 45px;
    height: 45px;
    background: #8fbc8f;
    transform: rotate(45deg);
}

.articles .catalog .grid .product .image {
    width: 100%;
    aspect-ratio: 1.25 / 1;
    object-fit: contain;
    border-radius: 5px;
}

.articles .catalog .grid .product .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    margin: 0 0 10px 0;
}

.articles .catalog .grid .product .placeholder .loader {
    width: 60px;
    height: 60px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid var(--primary);
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.articles .catalog .grid .product .title {
    height: 60px;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    overflow: hidden;
}

.articles .catalog .grid .product .specifications {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.articles .catalog .grid .product .specifications .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 700;
    background: #f7f8fc;
    border-radius: 5px;
}

.articles .catalog .grid .product .specifications .item.count {
    color: #fff;
    background: var(--primary);
}

.articles .catalog .grid .product .price {
    display: flex;
    gap: 10px;
}

.articles .catalog .grid .product .price .from {
    text-decoration: line-through;
}

.articles .catalog .grid .product .stock {
    display: flex;
    flex-direction: column;
}

.articles .catalog .grid .product .stock .location {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.articles .catalog .grid .product .stock .location-title {
    font-size: 16px;
    font-weight: 500;
}

.articles .catalog .grid .product .stock .item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.articles .catalog .grid .product .stock .item .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: 700;
    background: #f7f8fc;
    border-radius: 5px;
}

.articles .catalog .grid .product .stock .item .counter.active {
    background: #00d78f;
}

.articles .catalog .list {
    height: calc(100% - 80px);
    overflow: scroll;
}

.articles .catalog .products::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.articles .catalog .list .product {
    display: flex;
    gap: 0 1px;
    border-bottom: solid 1px #ebebeb;
}

.articles .catalog .list .product .column {
    display: flex;
    align-items: center;
    min-width: 125px;
    height: 30px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 300;
    background: #fff;
    white-space: nowrap;
    overflow: hidden;
}

.articles .catalog .list .product .column.link {
    text-decoration: underline;
    cursor: pointer;
}

.articles .catalog .list .product .column.icon {
    min-width: 25px;
    max-width: 25px;
    padding: 0 5px;
}

.articles .catalog .pagination {
    position: absolute;
    bottom: 25px;
    left: calc(50% - 250px);
    width: 500px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 25px;
    margin: 15px 0 0 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 20px #00000025;
}

.articles .catalog .pagination select {
    border: 0;
}





.combine {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.combine .content {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px;
    background: #fff;
    border: solid 1px #ededed;
    border-radius: 10px;
    cursor: auto;
}

.combine .content .title {
    font-size: 24px;
    font-weight: 500;
}

.combine .content b {
    font-weight: 600;
}

.combine .content .close {
    position: absolute;
    top: 35px;
    right: 25px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.combine .content .button {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    background: var(--primary);
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    justify-content: center;
    cursor: pointer;
}

.combine .content.loader:deep(span) {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    pointer-events: none;
}

.combine .content .loader {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid var(--primary);
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}





@media only screen and (max-width: 2700px) {
    .articles .catalog .grid .product {
        width: calc((100% / 6) - 21px);
    }
}

@media only screen and (max-width: 2400px) {
    .articles .catalog .grid .product {
        width: calc((100% / 5) - 20px);
    }
}

@media only screen and (max-width: 2100px) {
    .articles .catalog .grid .product {
        width: calc((100% / 4) - 19px);
    }
}

@media only screen and (max-width: 1800px) {
    .articles .catalog .grid .product {
        width: calc((100% / 3) - 17.5px);
    }
}

@media only screen and (max-width: 1500px) {
    .articles .catalog .grid .product {
        width: calc((100% / 2) - 12.5px);
    }
}

@media only screen and (max-width: 1200px) {
    .articles .catalog .grid .product {
        width: 100%;
    }
} */
</style>