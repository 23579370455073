<template>
    <div class="vehicle-content" v-if="Vehicle.Technical">
        <div class="notice" v-if="!Vehicle.BPM">
            <span class="notice-title">📌 Er is nog geen BPM berekening</span>
            <span>Bereken gemakkelijk de Rest BPM van de {{ Vehicle.Brand }} {{ Vehicle.Model }}. Hiervoor dien je RDW datum, staat (nieuw of gebruikt) en de uitstoot (NEDC en/of WLTP) in te voeren. Hiermee berekend het systeem automatisch de Rest BPM op basis van de leeftijdskorting. Je krijgt de optie om de Rest BPM op te halen doormiddel van de Autotelex en Xray koerslijst.</span>
        </div>
        <div class="block half">
            <span class="block-title">Voertuig</span>
            <div class="block-group">
                <span class="group-title">Datum RDW keuring</span>
                <DatePicker v-model="VehicleData.InspectionDate" showButtonBar :todayButtonProps="{}" placeholder="DD-MM-JJJJ" hourFormat="24" fluid @update:modelValue="this.$parent.SetValue('InspectionDate', VehicleData.InspectionDate)" />
            </div>
            <div class="block-group">
                <span class="group-title">Land van herkomst</span>
                <Select v-model="VehicleData.Origin" :options="Countries" placeholder="Selecteer een land" @update:modelValue="this.$parent.SetValue('Origin', VehicleData.Origin)" />
            </div>
            <div class="block-group">
                <span class="group-title">Soort aangifte</span>
                <Select v-model="VehicleData.State" :options="[{ Label: 'Gebruikt', Value: 'u'}, { Label: 'Nieuw', Value: 'n'}]" optionLabel="Label" optionValue="Value" placeholder="Selecteer een status" @change="this.$parent.SetValue('State', VehicleData.State)" />
            </div>
            <div class="block-group">
                <span class="group-title">Tellerstand</span>
                <InputGroup>
                    <InputNumber v-model="VehicleData.Mileage" fluid @update:modelValue="this.$parent.SetValue('Mileage', VehicleData.Mileage)" />
                    <InputGroupAddon>
                        KM
                    </InputGroupAddon>
                </InputGroup>
            </div>
            <div class="block-group" :class="{ disabled: this.$moment(VehicleData.Registration).isAfter('2020-07-01') }">
                <span class="group-title">NEDC <span class="group-title-tip" v-if="this.$moment(VehicleData.Registration).isBefore('2020-07-01')">Indicatie: {{ Vehicle.Technical.emission }}</span></span>
                <InputGroup>
                    <InputNumber v-model="VehicleData.NEDC" fluid @update:modelValue="this.$parent.SetValue('NEDC', VehicleData.NEDC)" />
                    <InputGroupAddon>
                        gram
                    </InputGroupAddon>
                </InputGroup>
            </div>
            <div class="block-group" :class="{ disabled: this.$moment(VehicleData.Registration).isBefore('2018-01-01') }">
                <span class="group-title">WLTP <span class="group-title-tip" v-if="this.$moment(VehicleData.Registration).isAfter('2020-07-01')">Indicatie: {{ Vehicle.Technical.emission }}</span></span>
                <InputGroup>
                    <InputNumber v-model="VehicleData.WLTP" fluid @update:modelValue="this.$parent.SetValue('WLTP', VehicleData.WLTP)" />
                    <InputGroupAddon>
                        gram
                    </InputGroupAddon>
                </InputGroup>
            </div>
        </div>
        <div class="block half">
            <span class="block-title">Prijs</span>
            <div class="block-group price">
                <span class="group-title">Prijslijst</span>
                <span class="group-value">{{ $moment(Vehicle.Price.pricelistDate).format('DD-MM-YYYY') }}</span>
            </div>
            <div class="block-group price">
                <span class="group-title">Netto</span>
                <span class="group-value">€ {{ Number(Vehicle.Price.listprice).toLocaleString('nl-NL') }}</span>
            </div>
            <div class="block-group price">
                <span class="group-title">BTW</span>
                <span class="group-value">€ {{ Number(Vehicle.Price.vat).toLocaleString('nl-NL') }}</span>
            </div>
            <div class="block-group price" v-if="Vehicle.BPM">
                <span class="group-title">BPM</span>
                <span class="group-value">€ {{ Number(Vehicle.BPM.bruto_bpm.bruto_bpm).toLocaleString('nl-NL') }}</span>
            </div>
            <div class="block-group price" v-else>
                <span class="group-title">BPM</span>
                <span class="group-value">€ {{ Number(Vehicle.Price.bpm).toLocaleString('nl-NL') }}</span>
            </div>
            <div class="block-group price" v-if="Vehicle.BPM">
                <span class="group-title">Consumentenprijs</span>
                <span class="group-value">€ {{ Number(Vehicle.Price.listprice + Vehicle.Price.vat + Vehicle.BPM.bruto_bpm.bruto_bpm).toLocaleString('nl-NL') }}</span>
            </div>
            <div class="block-group price" v-else>
                <span class="group-title">Consumentenprijs</span>
                <span class="group-value">€ {{ Number(Vehicle.Price.consumerprice).toLocaleString('nl-NL') }}</span>
            </div>
            <div class="block-group price">
                <span class="group-title">Optiebedrag</span>
                <span class="group-value">€ {{ Number(this.$parent.SelectedOptions()).toLocaleString('nl-NL') }}</span>
            </div>
            <div class="block-group price" v-if="Vehicle.BPM">
                <span class="group-title bold">Consumentenprijs incl. opties</span>
                <span class="group-value bold">€ {{ Number(Vehicle.Price.listprice + Vehicle.Price.vat + Vehicle.BPM.bruto_bpm.bruto_bpm + this.$parent.SelectedOptions()).toLocaleString('nl-NL') }}</span>
            </div>
            <div class="block-group price" v-else>
                <span class="group-title bold">Consumentenprijs incl. opties</span>
                <span class="group-value bold">€ {{ Number(Vehicle.Price.listprice + Vehicle.Price.vat + Vehicle.Price.bpm + this.$parent.SelectedOptions()).toLocaleString('nl-NL') }}</span>
            </div>
        </div>
        <div class="block method" v-if="Vehicle.BPM">
            <span class="block-title">Afschrijvingstabel</span>
            <div class="block-group">
                <span class="group-title">Waarde</span>
                <span class="group-value">€ {{ Number((Vehicle.BPM.leeftijdskorting_percentage / 100) * Vehicle.Price.consumerprice).toLocaleString('nl-NL', { maximumFractionDigits: 0 }) }}</span>
            </div>
            <div class="block-group">
                <span class="group-title">Rest BPM</span>
                <span class="group-value">€ {{ Number(Vehicle.BPM.rest_bpm).toLocaleString('nl-NL') }}</span>
            </div>
            <div class="block-group">
                <span class="group-title">Afschrijving</span>
                <span class="group-value">{{ Vehicle.BPM.leeftijdskorting_percentage }} %</span>
            </div>
            <div class="block-group">
                <span class="group-download">Download aangifte</span>
            </div>
        </div>
        <div class="block method" v-if="Vehicle.BPM">
            <span class="block-title">Autotelex</span>
            <div class="block-group">
                <span class="group-title">Waarde</span>
                <span class="group-value" v-if="Vehicle.BPM.autotelex">€ 23.456</span>
                <span class="group-value" v-else>N.N.B.</span>
            </div>
            <div class="block-group">
                <span class="group-title">Rest BPM</span>
                <span class="group-value" v-if="Vehicle.BPM.autotelex">€ 1.234</span>
                <span class="group-value" v-else>N.N.B.</span>
            </div>
            <div class="block-group">
                <span class="group-title">Afschrijving</span>
                <span class="group-value">N.N.B.</span>
            </div>
            <div class="block-group">
                <span class="group-select" v-if="Vehicle.BPM.autotelex">Selecteren</span>
                <span class="group-select" v-else>Ophalen</span>
            </div>
        </div>
        <div class="block method" v-if="Vehicle.BPM">
            <span class="block-title">XRay</span>
            <div class="block-group">
                <span class="group-title">Waarde</span>
                <span class="group-value" v-if="Vehicle.BPM.xray">€ 23.456</span>
                <span class="group-value" v-else>N.N.B.</span>
            </div>
            <div class="block-group">
                <span class="group-title">Rest BPM</span>
                <span class="group-value" v-if="Vehicle.BPM.xray">€ 1.234</span>
                <span class="group-value" v-else>N.N.B.</span>
            </div>
            <div class="block-group">
                <span class="group-title">Afschrijving</span>
                <span class="group-value">N.N.B.</span>
            </div>
            <div class="block-group">
                <span class="group-select" v-if="Vehicle.BPM.xray">Selecteren</span>
                <span class="group-select" v-else @click="this.$parent.GetXrayValue()">Ophalen</span>
            </div>
        </div>
        <div class="block method" v-if="Vehicle.BPM">
            <span class="block-title">Tegenbewijs (To-Do)</span>
            <div class="block-group">
                <span class="group-title">Waarde</span>
                <span class="group-value">N.N.B.</span>
            </div>
            <div class="block-group">
                <span class="group-title">Rest BPM</span>
                <span class="group-value">N.N.B.</span>
            </div>
            <div class="block-group">
                <span class="group-title">Afschrijving</span>
                <span class="group-value">N.N.B.</span>
            </div>
            <div class="block-group">
                <span class="group-select">Selecteren</span>
            </div>
        </div>
    </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Select from 'primevue/select';
import DatePicker from 'primevue/datepicker';

export default {
    name: 'VehicleImport',

    components: {
        InputNumber,
        InputGroup,
        InputGroupAddon,
        Select,
        DatePicker
    },

    props: ['Vehicle'],

    data () {
        return {
            VehicleData: this.Vehicle,
            Countries: ['Belgie', 'Bulgarije', 'Cyprus', 'Denemarken', 'Duitsland', 'Estland', 'Finland', 'Frankrijk', 'Griekenland', 'Hongarije', 'Ierland', 'IJsland', 'Italie', 'Kroatie', 'Letland', 'Liechtenstein', 'Litouwen', 'Luxemburg', 'Malta', 'Nederland', 'Noorwegen', 'Oostenrijk', 'Polen', 'Portugal', 'Roemenie', 'Slovenie', 'Slowakije', 'Spanje', 'Tsjechie', 'Zweden', 'Overige']
        }
    }
}
</script>

<style scoped>
.vehicle-content {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding: 50px;
}

.vehicle-content .notice {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 15px 25px;
    width: 100%;
    padding: 25px;
    background: #ffffe0;
    border-radius: 5px;
}

.vehicle-content .notice .notice-title {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
}

.vehicle-content .block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 15px 25px;
    width: 100%;
    padding: 25px;
    background: #fff;
    border-radius: 5px;
}

.vehicle-content .block.half {
    width: calc(50% - 25px);
}

.vehicle-content .block.method {
    width: calc((100% / 4) - 25px);
    flex-direction: column;
    gap: 5px;
}

.vehicle-content .block .block-title {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
}

.vehicle-content .block.method .block-title {
    margin: 0 0 20px 0;
}

.vehicle-content .block .block-group {
    width: calc(50% - 12.5px);
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.vehicle-content .block .block-group.disabled {
    pointer-events: none;
    opacity: 0.25;
}

.vehicle-content .block .block-group.price {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.vehicle-content .block.method .block-group {
    width: 100%;
    flex-direction: unset;
    justify-content: space-between;
}

.vehicle-content .block .block-group .group-title {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
}

.vehicle-content .block .block-group .group-title .group-title-tip {
    height: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    background: #ededed;
    font-size: 12px;
    font-weight: 700;
    border-radius: 5px;
}

.vehicle-content .block .block-group .group-value {
    padding: 5px 0;
}

.vehicle-content .block .block-group .bold {
    font-weight: 600;
}

.vehicle-content .block .block-group.price .group-value {
    padding: 0;
}

.vehicle-content .block.method .block-group .group-value {
    padding: 0;
}

.vehicle-content .block .block-group .group-download {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    margin: 20px 0 0 0;
    padding: 0 10px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.vehicle-content .block .block-group .group-select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    margin: 20px 0 0 0;
    font-weight: 600;
    text-decoration: none;
    background: #ededed;
    color: #333;
    border-radius: 5px;
    cursor: pointer;
}
</style>