<template>
    <div class="filters">
        <div class="content">
            <div class="create">
                <span @click="Filter = [], Adjustments = [], Count = false"><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"><g><g id="Complete"> <g data-name="add" id="add-2"> <g> <line fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" x1="12" x2="12" y1="19" y2="5"></line> <line fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" x1="5" x2="19" y1="12" y2="12"></line> </g> </g> </g> </g></svg> Nieuw filter maken</span>
            </div>
            <div class="block half">
                <span class="title">Filters</span>
                <div class="block-content">
                    <div class="row" v-for="(FilterRow, i) in Filter" :key="i">
                        <div class="sub-row" v-for="(FilterSubRow, si) in FilterRow" :key="si">
                            <div class="group third">
                                <span class="subtitle">Kolom</span>
                                <select v-model="FilterSubRow.Column" @change="DBCount">
                                    <option v-for="(Column, i) in Columns" :key="i" :value="Column.Name">{{ Column.Name }}</option>
                                </select>
                            </div>
                            <div class="group third">
                                <select v-model="FilterSubRow.Operator" @change="DBCount">
                                    <option v-for="(Operator, i) in Operators" :key="i" :value="Operator.Operator">{{ Operator.Text }}</option>
                                </select>
                            </div>
                            <div class="group third">
                                <span class="subtitle">Waarde</span>
                                <input type="text" v-model="FilterSubRow.Value" @change="DBCount">
                            </div>
                            <svg class="delete" :class="{ disabled: si == 0 }" @click="RemoveSubRow(i, si)" viewBox="-3 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000"><g><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-259.000000, -203.000000)" fill="#000000"> <path d="M282,211 L262,211 C261.448,211 261,210.553 261,210 C261,209.448 261.448,209 262,209 L282,209 C282.552,209 283,209.448 283,210 C283,210.553 282.552,211 282,211 L282,211 Z M281,231 C281,232.104 280.104,233 279,233 L265,233 C263.896,233 263,232.104 263,231 L263,213 L281,213 L281,231 L281,231 Z M269,206 C269,205.447 269.448,205 270,205 L274,205 C274.552,205 275,205.447 275,206 L275,207 L269,207 L269,206 L269,206 Z M283,207 L277,207 L277,205 C277,203.896 276.104,203 275,203 L269,203 C267.896,203 267,203.896 267,205 L267,207 L261,207 C259.896,207 259,207.896 259,209 L259,211 C259,212.104 259.896,213 261,213 L261,231 C261,233.209 262.791,235 265,235 L279,235 C281.209,235 283,233.209 283,231 L283,213 C284.104,213 285,212.104 285,211 L285,209 C285,207.896 284.104,207 283,207 L283,207 Z M272,231 C272.552,231 273,230.553 273,230 L273,218 C273,217.448 272.552,217 272,217 C271.448,217 271,217.448 271,218 L271,230 C271,230.553 271.448,231 272,231 L272,231 Z M267,231 C267.552,231 268,230.553 268,230 L268,218 C268,217.448 267.552,217 267,217 C266.448,217 266,217.448 266,218 L266,230 C266,230.553 266.448,231 267,231 L267,231 Z M277,231 C277.552,231 278,230.553 278,230 L278,218 C278,217.448 277.552,217 277,217 C276.448,217 276,217.448 276,218 L276,230 C276,230.553 276.448,231 277,231 L277,231 Z" id="trash" sketch:type="MSShapeGroup"> </path> </g> </g> </g></svg>
                        </div>
                        <span class="add sub" @click="AddSubFilterRow(i)"><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"><g><g id="Complete"> <g data-name="add" id="add-2"> <g> <line fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line> <line fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line> </g> </g> </g> </g></svg> Voeg een subregel toe</span>
                        <span class="remove" v-if="i > 0" @click="Filter = Filter.filter(item => item !== FilterRow)"><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"><g><g id="Complete"> <g data-name="add" id="add-2"> <g> <line fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line> <line fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line> </g> </g> </g> </g></svg> Verwijder groep</span>
                    </div>
    
                    <span class="add" @click="AddFilterRow"><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"><g><g id="Complete"> <g data-name="add" id="add-2"> <g> <line fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line> <line fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line> </g> </g> </g> </g></svg> Voeg een regel toe</span>
                    
                    <div class="array">
                        <pre>{{ Filter }}</pre>
                    </div>
                </div>
            </div>

            <div class="block half">
                <span class="title">Correcties</span>
                <div class="block-content">
                    <div class="row" v-for="(AdjustmentRow, i) in Adjustments" :key="i">
                        <div class="sub-row">
                            <div class="group">
                                <span class="subtitle">Wijzig kolom</span>
                                <select v-model="AdjustmentRow.Column" @change="DBCount">
                                    <option v-for="(Column, i) in Columns" :key="i" :value="Column.Name">{{ Column.Name }}</option>
                                </select>
                            </div>
                            <div class="group">
                                <span class="subtitle">In de waarde</span>
                                <input type="text" v-model="AdjustmentRow.Value" @change="DBCount">
                            </div>
                            <svg class="delete" :class="{ disabled: i == 0 }" @click="Adjustments = Adjustments.filter(item => item !== AdjustmentRow)" viewBox="-3 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000"><g><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-259.000000, -203.000000)" fill="#000000"> <path d="M282,211 L262,211 C261.448,211 261,210.553 261,210 C261,209.448 261.448,209 262,209 L282,209 C282.552,209 283,209.448 283,210 C283,210.553 282.552,211 282,211 L282,211 Z M281,231 C281,232.104 280.104,233 279,233 L265,233 C263.896,233 263,232.104 263,231 L263,213 L281,213 L281,231 L281,231 Z M269,206 C269,205.447 269.448,205 270,205 L274,205 C274.552,205 275,205.447 275,206 L275,207 L269,207 L269,206 L269,206 Z M283,207 L277,207 L277,205 C277,203.896 276.104,203 275,203 L269,203 C267.896,203 267,203.896 267,205 L267,207 L261,207 C259.896,207 259,207.896 259,209 L259,211 C259,212.104 259.896,213 261,213 L261,231 C261,233.209 262.791,235 265,235 L279,235 C281.209,235 283,233.209 283,231 L283,213 C284.104,213 285,212.104 285,211 L285,209 C285,207.896 284.104,207 283,207 L283,207 Z M272,231 C272.552,231 273,230.553 273,230 L273,218 C273,217.448 272.552,217 272,217 C271.448,217 271,217.448 271,218 L271,230 C271,230.553 271.448,231 272,231 L272,231 Z M267,231 C267.552,231 268,230.553 268,230 L268,218 C268,217.448 267.552,217 267,217 C266.448,217 266,217.448 266,218 L266,230 C266,230.553 266.448,231 267,231 L267,231 Z M277,231 C277.552,231 278,230.553 278,230 L278,218 C278,217.448 277.552,217 277,217 C276.448,217 276,217.448 276,218 L276,230 C276,230.553 276.448,231 277,231 L277,231 Z" id="trash" sketch:type="MSShapeGroup"> </path> </g> </g> </g></svg>
                        </div>
                    </div>
        
                    <span class="add" @click="AddAdjustmentRow"><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g><g id="Complete"> <g data-name="add" id="add-2"> <g> <line fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line> <line fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line> </g> </g> </g> </g></svg> Voeg een regel toe</span>
        
                    <div class="array">
                        <pre>{{ Adjustments }}</pre>
                    </div>
    
                    
                    <div class="buttons">
                        <select v-if="Filter.length > 0 && 
                                    Adjustments.length > 0 &&
                                    Filter.filter(item => item.Column !== '').length == Filter.length && 
                                    Filter.filter(item => item.Operator !== '').length == Filter.length && 
                                    Filter.filter(item => item.Value !== '').length == Filter.length && 
                                    Adjustments.filter(item => item.Column !== '').length == Adjustments.length && 
                                    Adjustments.filter(item => item.Value !== '').length == Adjustments.length" class="category" v-model="Category" @change="CheckCategory">
                            <option value="" disabled selected>Kies een categorie...</option>
                            <option :value="Category" v-if="Category">{{ Category }}</option>
                            <option :value="Category" disabled v-if="Category">--- --- --- --- ---</option>
                            <option :value="Item" v-for="(Item, i) in [...new Set(Rules.map(e => e.Category))].sort()" :key="i">{{ Item }}</option>
                            <option value="NEW">Maak een nieuwe categorie...</option>
                        </select>

                        <span v-if="Filter.length > 0 && 
                                    Adjustments.length > 0 &&
                                    Filter.filter(item => item.Column !== '').length == Filter.length && 
                                    Filter.filter(item => item.Operator !== '').length == Filter.length && 
                                    Filter.filter(item => item.Value !== '').length == Filter.length && 
                                    Adjustments.filter(item => item.Column !== '').length == Adjustments.length && 
                                    Adjustments.filter(item => item.Value !== '').length == Adjustments.length" class="confirm" @click="DBAdjust"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> Wijzigen</span>
                        <span v-if="Filter.length > 0 && 
                                    Adjustments.length > 0 &&
                                    Filter.filter(item => item.Column !== '').length == Filter.length && 
                                    Filter.filter(item => item.Operator !== '').length == Filter.length && 
                                    Filter.filter(item => item.Value !== '').length == Filter.length && 
                                    Adjustments.filter(item => item.Column !== '').length == Adjustments.length && 
                                    Adjustments.filter(item => item.Value !== '').length == Adjustments.length && Category" class="save" @click="SaveAdjust"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" fill="#ffffff"></path> </g></svg> Opslaan</span>
                        
                        <span class="count" v-if="Count">{{ Count.toLocaleString('nl-NL') }} regels</span>
                    </div>
                </div>
            </div>

            <div class="block" v-if="Rules">
                <span class="title">Regels</span>
                <div class="categories">
                    <div class="category" v-for="(Category, i) in [...new Set(Rules.map(e => e.Category))].sort()" :key="i">
                        <div class="title" @click="Active[Category] = !Active[Category]">
                            {{ Category }}
                            <svg v-if="!Active[Category]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                            <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                        </div>
                        <div class="category-rows" v-if="Active[Category]">
                            <div class="category-row" v-for="(Rule, Si) in Rules.filter(e => e.Category == Category)" :key="Si">
                                <input type="text" v-model="Rule.Name" @change="ChangeRuleName(Rule)">
                                <span class="get" @click="Filter = Rule.Filters, Adjustments = Rule.Adjustments, this.Category = Rule.Category, DBCount()">Ophalen</span>
                                <span class="delete" @click="DeleteAdjust(Rule.Identifier)">Verwijderen</span>
                            </div>
                        </div>
                    </div>
                    <div class="category" v-if="Rules && Rules.filter(e => !e.Category).length > 0">
                        <div class="title" @click="Active['Others'] = !Active['Others']">
                            Overige
                            <svg v-if="!Active['Others']" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                            <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                        </div>
                        <div class="category-rows" v-if="Active['Others']">
                            <div class="category-row" v-for="(Rule, Si) in Rules.filter(e => !e.Category)" :key="Si">
                                <input type="text" v-model="Rule.Name" @change="ChangeRuleName(Rule)">
                                <span class="get" @click="Filter = Rule.Filters, Adjustments = Rule.Adjustments, this.Category = Rule.Category, DBCount()">Ophalen</span>
                                <span class="delete" @click="DeleteAdjust(Rule.Identifier)">Verwijderen</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeView',

    data () {
        return {
            Count: false,
            Columns: [],
            Rules: [],
            Filter: [],
            Category: false,
            Operators: [
                {
                    Operator: 'IS',
                    Text: 'is gelijk aan'
                },
                {
                    Operator: 'LIKE',
                    Text: 'bevat'
                },
                {
                    Operator: '<',
                    Text: 'is lager dan'
                },
                {
                    Operator: '<=',
                    Text: 'is gelijk aan of lager dan'
                },
                {
                    Operator: '>=',
                    Text: 'is gelijk aan of hoger dan'
                },
                {
                    Operator: '>',
                    Text: 'is hoger dan'
                }
            ],
            Adjustments: [],
            Active: []
        }
    },

    mounted () {
        this.DBColumns()
        this.GetAdjust()
    },

    methods: {
        DBColumns () {
            this.$http.post('https://api.wheels.nl/filters/columns').then((response) => {               
                if(response.data.columns) {
                    this.Columns = response.data.columns
                } else {
                    this.Columns = []
                }
            })
        },

        DBCount () {
            this.Count = false
            this.$http.post('https://api.wheels.nl/filters/count', {
                Filter: this.Filter
            }).then((response) => {
                if(response.data.count) {
                    this.Count = response.data.count
                } else {
                    this.Count = false
                }
            })
        },

        DBAdjust () {
            let Message = false
            if(this.Count) {
                Message = 'Weet je zeker dat je ' + this.Count + ' artikelen wilt aanpassen?';
            } else {
                Message = 'Weet je zeker dat je de artikelen wilt aanpassen?';
            }

            if (confirm(Message) == true) {
                this.$http.post('https://api.wheels.nl/filters/adjust', {
                    Filter: this.Filter,
                    Adjustments: this.Adjustments
                }).then(() => {
                    this.DBCount()
                })
            }
        },

        GetAdjust () {
            this.$http.get('https://api.wheels.nl/filters/rules').then((response) => {
                if(response.data.rules) {
                    this.Rules = response.data.rules
                } else {
                    this.Rules = []
                }
            })
        },

        SaveAdjust () {
            let Name = prompt("Geef een naam op voor de nieuwe regel");
            if (Name) {
                this.$http.post('https://api.wheels.nl/filters/rules', {
                    Name,
                    Category: this.Category,
                    Filters: this.Filter,
                    Adjustments: this.Adjustments
                }).then(() => {
                    this.GetAdjust()
                    this.$toast.open({
                        message: 'Filter ' + Name + ' aangemaakt',
                        position: 'top-right',
                        type: 'success'
                    })
                })
            }
        },

        DeleteAdjust (Identifier) {
            if (confirm('Weet je zeker dat je dit filter wilt verwijderen?') == true) {
                this.$http.delete(`https://api.wheels.nl/filters/rules/${Identifier}`, {
                    Identifier
                }).then(() => {
                    this.Rules = this.Rules.filter(item => item !== item.Identifier)
                })
            }
        },

        ChangeRuleName (e) {
            this.$http.post(`https://api.wheels.nl/filters/name`, {
                Filter: e
            }).then(() => {
                this.$toast.open({
                    title: e.Name,
                    message: 'Naam aangepast',
                    position: 'top-right',
                    type: 'success'
                })
            })
        },

        AddFilterRow () {
            this.Filter.push([{
                Column: '',
                Operator: 'IS',
                Value: ''
            }])
        },

        AddSubFilterRow (row) {
            this.Filter[row].push({
                Column: '',
                Operator: 'IS',
                Value: ''
            })
        },

        RemoveSubRow (row, subRow) {
            this.Filter[row] = this.Filter[row].filter(item => item !== this.Filter[row][subRow])
        },

        AddAdjustmentRow () {
            this.Adjustments.push({
                Column: '',
                Value: ''
            })
        },

        CheckCategory () {
            if(this.Category == 'NEW') {
                let Name = prompt("Geef een naam op voor de nieuwe categorie");
                if (Name) {
                    this.Category = Name
                }
            }
        }
    }
}
</script>

<style scoped>
.filters {
    width: 100%;
    height: calc(100% - 80px);
    padding: 50px;
    background: #f7f8fc;
    overflow: auto;
}

.filters .content {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}

.filters .content .create {
    width: 100%;
}

.filters .content .create span {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background: var(--primary);
    color: #fff;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
}

.filters .content .create span svg {
    width: 24px;
    height: 24px;
    margin: 0 15px 0 0;
}

.filters .content .block {
    width: 100%;
    background: #fff;
    border-radius: 5px;
}

.filters .content .block.half {
    width: calc(50% - 25px);
}

.filters .content .block .block-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px;
}

.filters .content .title {
    display: block;
    padding: 25px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: solid 1px #eaeaea;
}

.filters .content .row {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px;
    border: solid 1px #eaeaea;
    border-radius: 5px;
}

.filters .content .row .sub-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 0 15px;
}

.filters .content .row.adjust, .filters .content .row.rules {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0;
    border: 0;
}

.filters .content .row .group {
    display: flex;
    flex-direction: column;
    width: calc(calc(100% / 2) - 27px);
}

.filters .content .row .group.third {
    width: calc(calc(100% / 3) - 23px);
}

.filters .content .row.adjust .group, .filters .content .row.rules .group {
    width: 75%;
}

.filters .content .row .group .subtitle {
    margin: 0 0 5px 0;
}

.filters .content .row .group select, .filters .content .row .group input {
    padding: 10px;
    border: solid 1px #eaeaea;
    border-radius: 2.5px;
    outline: none;
}

.filters .content .row .group button {
    height: 37.5px;
    background: #dddddd;
    border: solid 1px #333333;
    cursor: pointer;
}

.filters .content .row .delete {
    min-width: 24px;
    height: 24px;
    margin: 0 0 12.5px 0;
    cursor: pointer;
}

.filters .content .row .delete.disabled {
    pointer-events: none;
    opacity: 0.25;
}

.filters .content .count {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 20px;
    font-weight: 600;
    border: solid 1px #dddddd;
    border-radius: 5px;
    cursor: pointer;
}

.filters .content .add {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.filters .content .add.sub {
    background: #00c4c2;
}

.filters .content .add svg {
    width: 24px;
    height: 24px;
    margin: 0 15px 0 0;
}

.filters .content .remove {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background: #cd5c5c;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.filters .content .remove svg {
    width: 24px;
    height: 24px;
    margin: 0 15px 0 0;
}

.filters .content .array {
    padding: 25px;
    background: #dddddd;
}

.filters .content .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.filters .content .buttons .category {
    width: 100%;
    padding: 15px 20px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.filters .content .confirm {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 20px;
    background: #333;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.filters .content .confirm svg {
    width: 20px;
    height: 20px;
}

.filters .content .save {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 20px;
    background: #20b2aa;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.filters .content .save svg {
    width: 20px;
    height: 20px;
}

.filters .block .rules-content {
    padding: 25px;
}

.filters .block .categories {
    display: flex;
    flex-direction: column;
}

.filters .block .categories .category {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.filters .block .categories .category .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 400;
    border-bottom: solid 1px #eaeaea;
    cursor: pointer;
}

.filters .block .categories .category .title svg {
    width: 24px;
    height: 24px;
}

.filters .block .categories .category .category-rows {
    padding: 0 0 15px 0;
    border-bottom: solid 1px #eaeaea;
}

.filters .block .categories .category .category-rows .category-row {
    display: flex;
    align-items: center;
    gap: 25px;
    padding: 10px 25px;
}

.filters .block .categories .category .category-rows .category-row input {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border-radius: 2.5px;
    border: solid 1px #eaeaea;
}

.filters .block .categories .category .category-rows .category-row .get {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    background: var(--primary);
    color: #fff;
    border-radius: 2.5px;
    cursor: pointer;
}

.filters .block .categories .category .category-rows .category-row .delete {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    background: #cd5c5c;
    color: #fff;
    border-radius: 2.5px;
    cursor: pointer;
}
</style>