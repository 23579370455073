<template>
    <div class="files" v-if="Vehicle.MediaArray">
        <!-- <div class="filter-items">
            <div class="filter-items-item" v-for="(Category, i) in [...new Set(Categories.map(e => e.Name))]" :key="i">
                <div class="filter-items-item-checkbox" @click="Filter[Category] = !Filter[Category]">
                    <div class="filter-items-item-checkbox-checked" v-if="!Filter[Category]"></div>
                </div>
                <span>{{ Category }}</span>
            </div>
        </div> -->

        <div class="files-list" :class="{ small: Preview }">
            <div class="files-list-category" v-for="(Category, i) in Categories" :key="i">
                <span class="files-list-category-name">{{ Category.Name }}</span>
                <div class="files-list-row" v-for="SubFile in Category.Items" :key="SubFile.ID" :class="{ active: Preview.ID && Preview.ID == SubFile.ID }" @click="SetPreview(SubFile)" :data-identifier="CheckImage(SubFile.ID).Identifier">
                    <img class="files-list-row-avatar" v-if="CheckImage(SubFile.ID)" :src="CheckImage(SubFile.ID).Thumbnail">
                    <div class="files-list-row-avatar" v-else></div>
                    <div class="files-list-row-information">
                        <span class="files-list-row-information-name">{{ SubFile.Name }}</span>
                    </div>
                    <span class="files-list-row-author">{{ CheckImage(SubFile.ID).AuthorName }}</span>
                    <span class="files-list-row-date" v-if="CheckImage(SubFile.ID).Created">{{ SetDate(CheckImage(SubFile.ID).Created).Short }}</span>
                    <svg class="files-list-row-verified" v-if="CheckImage(SubFile.ID).Created" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="var(--primary)"></path> </g></svg>
                    <svg class="files-list-row-edit" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><g><g><rect width="4" height="4" x="10" y="3" rx="2"></rect><rect width="4" height="4" x="10" y="10" rx="2"></rect><rect width="4" height="4" x="10" y="17" rx="2"></rect> </g></g></svg>
                </div>
            </div>
        </div>
        <div class="files-preview" v-if="Preview">
            <span class="files-preview-title">{{ Preview.Name }}</span>
            <span class="files-preview-category">{{ Preview.Category }}</span>
            <span class="files-preview-author" v-if="CheckImage(Preview.ID).Author && CheckImage(Preview.ID).Created">{{ CheckImage(Preview.ID).AuthorName }} {{ SetDate(CheckImage(Preview.ID).Created).Long }}</span>
            <img class="files-preview-image" v-if="CheckImage(Preview.ID)" :src="CheckImage(Preview.ID).Image" @load="Preview.Loader = false" :class="{ loader: Preview.Loader }">
            <div class="files-preview-notice" v-if="!CheckImage(Preview.ID) && Preview.Tip">
                <span class="files-preview-notice-title">📌 Tip voor de snelste afhandeling</span>
                <span>{{ Preview.Tip }}</span>
            </div>
            <div class="files-preview-upload" v-if="!CheckImage(Preview.ID)" @dragenter.prevent="Preview.Drop = true" @dragleave.prevent="Preview.Drop = false" @drop.prevent="this.Drop($event)" @dragover="DragOver" :class="{ drag: Preview.Drop, loader: Preview.Loader }">
                <svg v-if="Preview.Loader" class="files-preview-upload-loader" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="12" cy="20" r="2"></circle><circle cx="12" cy="4" r="2"></circle><circle cx="6.343" cy="17.657" r="2"></circle><circle cx="17.657" cy="6.343" r="2"></circle><circle cx="4" cy="12" r="2.001"></circle><circle cx="20" cy="12" r="2"></circle><circle cx="6.343" cy="6.344" r="2"></circle><circle cx="17.657" cy="17.658" r="2"></circle></g></svg>
                <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><rect width="24" height="24" fill="white"></rect> <path d="M5 12V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V12" stroke="#333" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 15L12 3M12 3L8 7M12 3L16 7" stroke="#333" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                <span class="files-preview-upload-text" v-if="Preview.Loader">{{ Preview.Name }} aan het uploaden...</span>
                <span class="files-preview-upload-text" v-else-if="Preview.Drop">Laat het bestand los om up te loaden</span>
                <span class="files-preview-upload-text" v-else>Drag & Drop hier je bestand of klik om een bestand te selecteren (PNG, JPEG of PDF)</span>
            </div>
            <div class="files-preview-buttons" v-if="CheckImage(Preview.ID)">
                <svg @click="Download" class="download" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.22 20.75H5.78C5.43322 20.7359 5.09262 20.6535 4.77771 20.5075C4.4628 20.3616 4.17975 20.155 3.94476 19.8996C3.70977 19.6442 3.52745 19.3449 3.40824 19.019C3.28903 18.693 3.23525 18.3468 3.25 18V15C3.25 14.8011 3.32902 14.6103 3.46967 14.4697C3.61033 14.329 3.80109 14.25 4 14.25C4.19892 14.25 4.38968 14.329 4.53033 14.4697C4.67099 14.6103 4.75 14.8011 4.75 15V18C4.72419 18.2969 4.81365 18.5924 4.99984 18.8251C5.18602 19.0579 5.45465 19.21 5.75 19.25H18.22C18.5154 19.21 18.784 19.0579 18.9702 18.8251C19.1564 18.5924 19.2458 18.2969 19.22 18V15C19.22 14.8011 19.299 14.6103 19.4397 14.4697C19.5803 14.329 19.7711 14.25 19.97 14.25C20.1689 14.25 20.3597 14.329 20.5003 14.4697C20.641 14.6103 20.72 14.8011 20.72 15V18C20.75 18.6954 20.5041 19.3744 20.0359 19.8894C19.5677 20.4045 18.9151 20.7137 18.22 20.75Z"></path><path d="M12 15.75C11.9015 15.7504 11.8038 15.7312 11.7128 15.6934C11.6218 15.6557 11.5392 15.6001 11.47 15.53L7.47 11.53C7.33752 11.3878 7.2654 11.1997 7.26882 11.0054C7.27225 10.8111 7.35096 10.6258 7.48838 10.4883C7.62579 10.3509 7.81118 10.2722 8.00548 10.2688C8.19978 10.2654 8.38782 10.3375 8.53 10.47L12 13.94L15.47 10.47C15.6122 10.3375 15.8002 10.2654 15.9945 10.2688C16.1888 10.2722 16.3742 10.3509 16.5116 10.4883C16.649 10.6258 16.7277 10.8111 16.7312 11.0054C16.7346 11.1997 16.6625 11.3878 16.53 11.53L12.53 15.53C12.4608 15.6001 12.3782 15.6557 12.2872 15.6934C12.1962 15.7312 12.0985 15.7504 12 15.75Z"></path><path d="M12 15.75C11.8019 15.7474 11.6126 15.6676 11.4725 15.5275C11.3324 15.3874 11.2526 15.1981 11.25 15V4C11.25 3.80109 11.329 3.61032 11.4697 3.46967C11.6103 3.32902 11.8011 3.25 12 3.25C12.1989 3.25 12.3897 3.32902 12.5303 3.46967C12.671 3.61032 12.75 3.80109 12.75 4V15C12.7474 15.1981 12.6676 15.3874 12.5275 15.5275C12.3874 15.6676 12.1981 15.7474 12 15.75Z"></path></svg>
                <svg @click="RotateImage(Media.find(item => item.Identifier == Active).Identifier, 90)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="fill: none;"><path d="M3 2v6h6"></path><path d="M3 13a9 9 0 103-7.7L3 8"></path></svg>
                <svg @click="RotateImage(Media.find(item => item.Identifier == Active).Identifier, -90)" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-rotate-cw" style="fill: none;"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                <svg @click="Close" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 496 496" xml:space="preserve"><g><g><g><path d="M248,0C111.033,0,0,111.033,0,248s111.033,248,248,248s248-111.033,248-248C495.841,111.099,384.901,0.159,248,0z M248,480C119.87,480,16,376.13,16,248S119.87,16,248,16s232,103.87,232,232C479.859,376.072,376.072,479.859,248,480z"></path><path d="M361.136,134.864c-3.124-3.123-8.188-3.123-11.312,0L248,236.688L146.176,134.864c-3.069-3.178-8.134-3.266-11.312-0.197c-3.178,3.069-3.266,8.134-0.197,11.312c0.064,0.067,0.13,0.132,0.197,0.197L236.688,248L134.864,349.824c-3.178,3.07-3.266,8.134-0.196,11.312c3.07,3.178,8.134,3.266,11.312,0.196c0.067-0.064,0.132-0.13,0.196-0.196L248,259.312l101.824,101.824c3.178,3.07,8.242,2.982,11.312-0.196c2.995-3.1,2.995-8.016,0-11.116L259.312,248l101.824-101.824C364.259,143.052,364.259,137.988,361.136,134.864z"></path></g></g></g></svg>
            </div>
            <svg @click="PreviousImage" class="files-preview-left" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><g><g><polyline fill="none" id="Left" points="15.5 5 8.5 12 15.5 19" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polyline></g></g></g></svg>
            <svg @click="NextImage" class="files-preview-right" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><g><g><polyline fill="none" id="Right" points="8.5 5 15.5 12 8.5 19" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polyline></g></g></g></svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VehicleMedia',

    props: ['Vehicle'],

    data () {
        return {
            Filter: {},
            Categories: [
                {
                    Name: 'Administratie',
                    Items: [
                        {
                            ID: 'ADMIN_CMR',
                            Name: 'CMR',
                            Category: 'Administratie'
                        },
                        {
                            ID: 'ADMIN_PURCHASE',
                            Name: 'Inkoopfactuur',
                            Category: 'Administratie'
                        }
                    ]
                },
                {
                    Name: 'Papieren',
                    Items: [
                    {
                            ID: 'PAPERS_1V',
                            Name: 'Deel 1 - Voorkant',
                            Category: 'Papieren'
                        },
                        {
                            ID: 'PAPERS_1A',
                            Name: 'Deel 1 - Achterkant',
                            Category: 'Papieren'
                        },
                        {
                            ID: 'PAPERS_2V',
                            Name: 'Deel 2 - Voorkant',
                            Category: 'Papieren'
                        },
                        {
                            ID: 'PAPERS_2A',
                            Name: 'Deel 2 - Achterkant',
                            Category: 'Papieren'
                        },
                        {
                            ID: 'PAPERS_CVOV',
                            Name: 'CVO - Voorkant',
                            Category: 'Papieren',
                            Tip: 'Zorg ervoor dat de gehele voorkant van het CVO op de foto of scan staat. Controleer of de afbeelding of scan in kleur is en duidelijk leesbaar.'
                        },
                        {
                            ID: 'PAPERS_CVOA',
                            Name: 'CVO - Achterkant',
                            Category: 'Papieren',
                            Tip: 'Zorg ervoor dat de gehele achterkant van het CVO op de foto of scan staat. Controleer of de afbeelding of scan in kleur is en duidelijk leesbaar.'
                        }
                    ]
                },
                {
                    Name: 'Voertuig',
                    Items: [
                        {
                            ID: 'CAR_LV',
                            Name: 'Linksvoor',
                            Category: 'Voertuig'
                        },
                        {
                            ID: 'CAR_RA',
                            Name: 'Rechtsachter',
                            Category: 'Voertuig'
                        },
                        {
                            ID: 'CAR_VIN',
                            Name: 'Chassisnummer',
                            Category: 'Voertuig'
                        },
                        {
                            ID: 'CAR_CP',
                            Name: 'Constructieplaat',
                            Category: 'Voertuig'
                        },
                        {
                            ID: 'CAR_KM',
                            Name: 'Tellerstand',
                            Category: 'Voertuig'
                        },
                        {
                            ID: 'CAR_INTERIOR',
                            Name: 'Interieur',
                            Category: 'Voertuig'
                        }
                    ]
                }
            ],
            Preview: false
        }
    },

    mounted () {
        var self = this

        document.addEventListener('keyup', function (evt) {
            if(evt.key === 'ArrowLeft') {
                self.PreviousImage()
            } else if(evt.key === 'ArrowRight') {
                self.NextImage()
            }
        })
    },

    methods: {
        DragOver (e) {
            e.preventDefault()
        },

        Drop (e) {
            this.Preview.Drop = false

            this.Upload(e, this.Preview)
        },

        Upload (e, a) {
            this.Preview.Loader = true

            this.$http.post(`https://api.wheels.nl/vehicles/upload/${this.$route.params.id}/${a.ID}`, {
                media: [...e.dataTransfer.files][0]
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }    
            }).then((response) => {
                this.Preview.Loader = false
                this.$parent.SetFile(response.data, a.ID)
            })
        },

        SetPreview (e) {
            this.Preview = e
            this.Preview.Identifier = this.CheckImage(e.ID).Identifier
        },

        CheckImage (e) {
            if(this.Vehicle.Media.filter(item => item.Category == e).length > 0) {
                return {
                    Identifier: this.Vehicle.Media.find(item => item.Category == e).Identifier,
                    Author: this.Vehicle.Media.find(item => item.Category == e).Author,
                    AuthorName: this.Vehicle.Media.find(item => item.Category == e).AuthorName,
                    Thumbnail: 'https://api.wheels.nl/vault_vehicles/' + this.Vehicle.Identifier + '/Thumbnails/' + this.Vehicle.Media.find(item => item.Category == e).Identifier + '.png',
                    Image: 'https://api.wheels.nl/vault_vehicles/' + this.Vehicle.Identifier + '/Images/' + this.Vehicle.Media.find(item => item.Category == e).Identifier + '.png',
                    Created: this.Vehicle.Media.find(item => item.Category == e).Created
                }
            } else {
                return false
            }
        },

        NextImage () {
            this.Preview.Loader = true

            document.querySelectorAll("[data-identifier]").forEach((e, i) => {
                if(e.dataset.identifier === this.Preview.Identifier) {
                    if(i == document.querySelectorAll("[data-identifier]").length - 1) {
                        document.querySelectorAll("[data-identifier]")[document.querySelectorAll("[data-identifier]").length - 1].click()
                    } else {
                        document.querySelectorAll("[data-identifier]")[i + 1].click()
                    }
                }
            })
        },

        PreviousImage () {
            this.Preview.Loader = true

            document.querySelectorAll("[data-identifier]").forEach((e, i) => {
                if(e.dataset.identifier == this.Preview.Identifier) {
                    if(i == 0) {
                        document.querySelectorAll("[data-identifier]")[document.querySelectorAll("[data-identifier]").length - 1].click()
                    } else {
                        document.querySelectorAll("[data-identifier]")[i - 1].click()
                    }
                }
            })
        },

        SetDate (e) {
            if(this.$moment().isSame(e, 'day')) {
                return {
                    Short: this.$moment(e).format('HH:mm'),
                    Long: 'om ' + this.$moment(e).format('HH:mm')
                }
            } else if(e) {
                return {
                    Short: this.$moment(e).format('DD-MM'),
                    Long: 'op ' + this.$moment(e).format('DD-MM')
                }
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>
.files {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 25px;
    padding: 50px;
}

.files .filter-items {
    width: 100%;
    display: flex;
    gap: 25px;
}

.files .filter-items-item {
    display: flex;
    gap: 10px;
}

.files .filter-items-item .filter-items-item-checkbox {
    position: relative;
    width: 24px;
    height: 24px;
    border: solid 1px #ececec;
    border-radius: 5px;
    cursor: pointer;
}

.files .filter-items-item .filter-items-item-checkbox .filter-items-item-checkbox-checked {
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: var(--primary);
    border-radius: 2.5px;
}

.files .files-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.files .files-list.small {
    width: 30%;
}

.files .files-list .files-list-category {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
}

.files .files-list .files-list-category .files-list-category-name {
    font-weight: 700;
}

.files .files-list .files-list-category .files-list-row {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 5px;
    outline: solid 2px transparent;
    border-radius: 5px;
    transition: .25s;
    cursor: pointer;
}

.files .files-list .files-list-category .files-list-row:hover {
    outline: solid 2px var(--primary);
}

.files .files-list .files-list-category .files-list-row.active {
    outline: solid 2px var(--primary);
}

.files .files-list .files-list-category .files-list-row .files-list-row-avatar {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    background: #ededed;
    border-radius: 5px;
}

.files .files-list .files-list-category .files-list-row .files-list-row-information {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.files .files-list .files-list-category .files-list-row .files-list-row-information .files-list-row-information-name {
    display: flex;
    align-items: center;
}

.files .files-list .files-list-category .files-list-row .files-list-row-information .files-list-row-information-category {
    display: flex;
    align-items: center;
    height: 18px;
    color: #a7a7a7;
    font-size: 14px;
}

.files .files-list .files-list-category .files-list-row .files-list-row-author {
    width: 100px;
}

.files .files-list.small .files-list-row .files-list-row-author {
    display: none;
}

.files .files-list .files-list-category .files-list-row .files-list-row-date {
    width: 250px;
    padding: 0 100px 0 0;
    text-align: right;
}

.files .files-list.small .files-list-row .files-list-row-date {
    display: none;
}

.files .files-list .files-list-category .files-list-row .files-list-row-verified {
    position: absolute;
    top: calc(50% - 12px);
    right: 30px;
    width: 24px;
    height: 24px;
    fill: #c3c3c3;
    transition: .25s;
    cursor: pointer;
}

.files .files-list .files-list-category .files-list-row .files-list-row-edit {
    position: absolute;
    top: calc(50% - 15px);
    right: 0;
    width: 30px;
    height: 30px;
    fill: #c3c3c3;
    transition: .25s;
    cursor: pointer;
}

.files .files-list .files-list-category .files-list-row .files-list-row-edit:hover {
    fill: #333;
}





.files .files-preview {
    position: sticky;
    top: 25px;
    width: calc(70% - 25px);
    display: flex;
    flex-direction: column;
    padding: 25px;
    background: #fff;
    border-radius: 5px;
}

.files .files-preview .files-preview-title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 600;
}

.files .files-preview .files-preview-category, .files .files-preview .files-preview-author {
    display: flex;
    align-items: center;
    height: 18px;
    color: #a7a7a7;
    font-size: 14px;
}

.files .files-preview .files-preview-image {
    width: 100%;
    margin: 25px 0;
    border-radius: 5px;
}

.files .files-preview .files-preview-image.loader {
    opacity: 0.5;
}

.files .files-preview .files-preview-upload {
    width: 100%;
    max-height: 350px;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    margin: 25px 0;
    border: dashed 2px #ededed;
    border-radius: 5px;
}

.files .files-preview .files-preview-upload.drag {
    border-color: var(--primary);
}

.files .files-preview .files-preview-upload.loader {
    pointer-events: none;
    opacity: 0.5;
}

.files .files-preview .files-preview-upload svg {
    width: 100px;
    pointer-events: none;
}

.files .files-preview .files-preview-upload .files-preview-upload-loader {
    animation: spin 2.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.files .files-preview .files-preview-upload .files-preview-upload-text {
    width: 90%;
    max-width: 500px;
    min-height: 50px;
    text-align: center;
    pointer-events: none;
}

.files .files-preview .files-preview-buttons {
    display: flex;
    justify-content: center;
    gap: 25px;
}

.files .files-preview .files-preview-buttons svg {
    width: 30px;
    height: 30px;
}

.files .files-preview .files-preview-left {
    position: absolute;
    top: calc(50% - 50px);
    left: 25px;
    width: 100px;
    height: 100px;
    cursor: pointer;
    opacity: 0.5;
}

.files .files-preview .files-preview-right {
    position: absolute;
    top: calc(50% - 50px);
    right: 25px;
    width: 100px;
    height: 100px;
    cursor: pointer;
    opacity: 0.5;
}

.files .files-preview .files-preview-left:hover, .files .files-preview .files-preview-right:hover {
    opacity: 1;
}

.files .files-preview .files-preview-notice {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 15px 25px;
    width: 100%;
    padding: 25px;
    margin: 25px 0 0 0;
    background: #ffffe0;
    border-radius: 5px;
}

.files .files-preview .files-preview-notice .files-preview-notice-title {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
}
</style>