<template>
    <div class="vehicle-content" v-if="Vehicle.Technical && Vehicle.Price">
        <div class="vehicle-column">
            <div class="block">
                <span class="title">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M22 11V17C22 21 21 22 17 22H7C3 22 2 21 2 17V7C2 3 3 2 7 2H8.5C10 2 10.33 2.44 10.9 3.2L12.4 5.2C12.78 5.7 13 6 14 6H17C21 6 22 7 22 11Z" stroke="#222" stroke-width="1.5" stroke-miterlimit="10"></path> <path opacity="0.4" d="M8 2H17C19 2 20 3 20 5V6.38" stroke="#222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    Algemeen
                </span>
                <div class="group">
                    <span class="group-title">Type voertuig</span>
                    <span class="group-value">{{ SetCategory(Vehicle.Category) }}</span>
                </div>
                <div class="group" v-if="Vehicle.License">
                    <span class="group-title">Kenteken</span>
                    <span class="group-value license">{{ $root.SetLicense(Vehicle.License) }}</span>
                </div>
                <div class="group" v-else>
                    <span class="group-title">{{ SetVin(this.$parent.Vehicle.VIN) }}</span>
                    <InputText type="text" v-model="this.$parent.Vehicle.VIN" :maxlength="17" @blur="this.$parent.SetValue('VIN', this.$parent.Vehicle.VIN)" @keyup="this.$parent.Vehicle.VIN = this.$parent.Vehicle.VIN.toUpperCase()" />
                </div>
                <div class="group">
                    <span class="group-title">Merk</span>
                    <span class="group-value">{{ Vehicle.Brand }}</span>
                </div>
                <div class="group">
                    <span class="group-title">Model</span>
                    <span class="group-value">{{ Vehicle.Model }}</span>
                </div>
                <div class="group">
                    <span class="group-title">Uitvoering</span>
                    <span class="group-value">{{ Vehicle.Technical.description }}</span>
                </div>
                <div class="group">
                    <span class="group-title">Brandstof</span>
                    <span class="group-value">{{ SetFuel(Vehicle.Technical.fuel.code) }}</span>
                </div>
                <div class="group">
                    <span class="group-title">Datum eerste tenaamstelling</span>
                    <span class="group-value">{{ this.$moment(Vehicle.Registration).format('DD-MM-YYYY') }}</span>
                </div>
                <div class="group">
                    <span class="group-title">Kilometerstand</span>
                    <InputGroup>
                        <InputNumber v-model="this.$parent.Vehicle.Mileage" fluid @update:modelValue="this.$parent.SetValue('Mileage', this.$parent.Vehicle.Mileage)" />
                        <InputGroupAddon>
                            KM
                        </InputGroupAddon>
                    </InputGroup>
                </div>
            </div>

            <div class="block">
                <span class="title">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M22 11V17C22 21 21 22 17 22H7C3 22 2 21 2 17V7C2 3 3 2 7 2H8.5C10 2 10.33 2.44 10.9 3.2L12.4 5.2C12.78 5.7 13 6 14 6H17C21 6 22 7 22 11Z" stroke="#222" stroke-width="1.5" stroke-miterlimit="10"></path> <path opacity="0.4" d="M8 2H17C19 2 20 3 20 5V6.38" stroke="#222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    Technisch
                </span>
                <div class="group">
                    <span class="group-title">Transmissie</span>
                    <span class="group-value" v-if="Vehicle.Technical.transmission.numberOfGears">{{ Vehicle.Technical.transmission.name }} ({{ Vehicle.Technical.transmission.numberOfGears }})</span>
                    <span class="group-value" v-else>{{ Vehicle.Technical.transmission.name }}</span>
                </div>
                <div class="group">
                    <span class="group-title">Aandrijving</span>
                    <span class="group-value">{{ Vehicle.Technical.drive.name }}</span>
                </div>
                <div class="group">
                    <span class="group-title">Gewicht</span>
                    <span class="group-value">{{ Number(Vehicle.Technical.weight).toLocaleString('nl-NL') }} KG</span>
                </div>
                <div class="group" v-if="Vehicle.Technical.numberOfCylinders">
                    <span class="group-title">Cilinders</span>
                    <span class="group-value">{{ Vehicle.Technical.numberOfCylinders }} ({{ Number(Vehicle.Technical.engineCapacity).toLocaleString('nl-NL') }} CC)</span>
                </div>
                <div class="group">
                    <span class="group-title">Vermogen</span>
                    <span class="group-value">{{ Vehicle.Technical.power }} KW</span>
                </div>
                <div class="group">
                    <span class="group-title">Koppel</span>
                    <span class="group-value">{{ Vehicle.Technical.torque }} NM</span>
                </div>
                <div class="group">
                    <span class="group-title">Carrosserie</span>
                    <span class="group-value">{{ Vehicle.Technical.bodywork.name }}</span>
                </div>
                <div class="group">
                    <span class="group-title">Topsnelheid</span>
                    <span class="group-value">{{ Vehicle.Technical.topSpeed }} KM</span>
                </div>
                <div class="group">
                    <span class="group-title">Acceleratie</span>
                    <span class="group-value">{{ Vehicle.Technical.acceleration / 10 }} sec.</span>
                </div>
                <div class="group">
                    <span class="group-title">Wielbasis</span>
                    <span class="group-value">{{ Number(Vehicle.Technical.wheelbase * 10).toLocaleString('nl-NL') }} MM</span>
                </div>
                <div class="group">
                    <span class="group-title">Deuren</span>
                    <span class="group-value">{{ Vehicle.Technical.numberOfDoors }}</span>
                </div>
                <div class="group">
                    <span class="group-title">Stoelen</span>
                    <span class="group-value">{{ Vehicle.Technical.numberOfSeats }}</span>
                </div>
                <div class="group" v-if="Vehicle.Technical.energyLabel">
                    <span class="group-title">Energielabel</span>
                    <span class="group-energy">
                        <span class="group-energy" :class="{ g: Vehicle.Technical.energyLabel == 'G' }">{{ Vehicle.Technical.energyLabel }}</span>
                    </span>
                </div>
                <div class="group" v-if="Vehicle.Technical.emissionCode">
                    <span class="group-title">Emissieklasse</span>
                    <span class="group-value">{{ Vehicle.Technical.emissionCode }}</span>
                </div>
            </div>
        </div>
        <div class="vehicle-column">
            <div class="block" v-if="COC">
                <span class="title">Direct CVO bestellen?</span>
                <span class="group-title">Wil je een CVO (Certificaat van Overeenstemming) bestellen voor de {{ Vehicle.Brand }} {{ Vehicle.Model }} met VIN {{ Vehicle.VIN }}? Maak hieronder je keuze. Het document wordt automatisch toegevoegd aan 'Bestanden' en hierover krijg je een melding.</span>
                <div class="order">
                    <div class="order-row" v-for="(Item, i) in COC" :key="i">
                        <span class="days">{{ Item.Time }} werkdagen levertijd</span>
                        <span class="price">€ {{ Number(Item.Price).toLocaleString('nl-NL') }}</span>
                        <span class="deal" @click="this.$parent.OrderCOC(Item.Identifier)">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M21 5L19 12H7.37671M20 16H8L6 3H3M16 5.5H13.5M13.5 5.5H11M13.5 5.5V8M13.5 5.5V3M9 20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                            Bestellen
                        </span>
                    </div>
                </div>
            </div>

            <div class="block">
                <span class="title">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M22 11V17C22 21 21 22 17 22H7C3 22 2 21 2 17V7C2 3 3 2 7 2H8.5C10 2 10.33 2.44 10.9 3.2L12.4 5.2C12.78 5.7 13 6 14 6H17C21 6 22 7 22 11Z" stroke="#222" stroke-width="1.5" stroke-miterlimit="10"></path> <path opacity="0.4" d="M8 2H17C19 2 20 3 20 5V6.38" stroke="#222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    Afbeeldingen
                </span>

                <img class="vehicle-image" v-if="Vehicle.Media.filter(e => e.Category == 'CAR_LV').length > 0" :src="'https://api.wheels.nl/vault_vehicles/' + Vehicle.Identifier + '/Thumbnails/' + Vehicle.Media.find(e => e.Category == 'CAR_LV').Identifier + '.png'" alt="Voertuig voorkant">
                <img class="vehicle-image" v-if="Vehicle.Media.filter(e => e.Category == 'CAR_RA').length > 0" :src="'https://api.wheels.nl/vault_vehicles/' + Vehicle.Identifier + '/Thumbnails/' + Vehicle.Media.find(e => e.Category == 'CAR_RA').Identifier + '.png'" alt="Voertuig achterkant">
                <img class="vehicle-image" v-if="Vehicle.Media.filter(e => e.Category == 'CAR_Interior').length > 0" :src="'https://api.wheels.nl/vault_vehicles/' + Vehicle.Identifier + '/Thumbnails/' + Vehicle.Media.find(e => e.Category == 'CAR_Interior').Identifier + '.png'" alt="Voertuig binnenkant">
            </div>

            <div class="block">
                <span class="title">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M22 11V17C22 21 21 22 17 22H7C3 22 2 21 2 17V7C2 3 3 2 7 2H8.5C10 2 10.33 2.44 10.9 3.2L12.4 5.2C12.78 5.7 13 6 14 6H17C21 6 22 7 22 11Z" stroke="#222" stroke-width="1.5" stroke-miterlimit="10"></path> <path opacity="0.4" d="M8 2H17C19 2 20 3 20 5V6.38" stroke="#222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    Prijs
                </span>
                <div class="group price">
                    <span class="group-title">Prijslijst</span>
                    <span class="group-value">{{ $moment(Vehicle.Price.pricelistDate).format('DD-MM-YYYY') }}</span>
                </div>
                <div class="group price">
                    <span class="group-title">Netto</span>
                    <span class="group-value">€ {{ Number(Vehicle.Price.listprice).toLocaleString('nl-NL') }}</span>
                </div>
                <div class="group price">
                    <span class="group-title">BTW</span>
                    <span class="group-value">€ {{ Number(Vehicle.Price.vat).toLocaleString('nl-NL') }}</span>
                </div>
                <div class="group price">
                    <span class="group-title">BPM</span>
                    <span class="group-value">€ {{ Number(Vehicle.Price.bpm).toLocaleString('nl-NL') }}</span>
                </div>
                <div class="group price" v-if="Vehicle.Price.calculatedListpriceGross">
                    <span class="group-title">Fiscale waarde</span>
                    <span class="group-value">€ {{ Number(Vehicle.Price.calculatedListpriceGross).toLocaleString('nl-NL') }}</span>
                </div>
                <div class="group price" v-else>
                    <span class="group-title">Consumentenprijs</span>
                    <span class="group-value">€ {{ Number(Vehicle.Price.consumerprice).toLocaleString('nl-NL') }}</span>
                </div>
            </div>

            <div class="block" v-if="Vehicle.Custom">
                <span class="title">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M22 11V17C22 21 21 22 17 22H7C3 22 2 21 2 17V7C2 3 3 2 7 2H8.5C10 2 10.33 2.44 10.9 3.2L12.4 5.2C12.78 5.7 13 6 14 6H17C21 6 22 7 22 11Z" stroke="#222" stroke-width="1.5" stroke-miterlimit="10"></path> <path opacity="0.4" d="M8 2H17C19 2 20 3 20 5V6.38" stroke="#222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    Eigen velden
                </span>
                <div class="group" v-for="(Field, i) in Vehicle.Custom" :key="i" :class="{ full: Field.Type == 'Textarea' }">
                    <span class="group-title">{{ Field.Name }}</span>
                    <InputText v-if="Field.Type == 'Text'" type="text" @change="this.$parent.SetCustomValue($event.target.value, Field.Identifier)" :modelValue="Field.Value"></InputText>
                    <Select v-if="Field.Type == 'Select'" :options="Field.Options" placeholder="Maak een keuze" showClear @change="this.$parent.SetCustomValue($event.value, Field.Identifier)" :modelValue="Field.Value" />
                    <textarea v-if="Field.Type == 'Textarea'" @change="this.$parent.SetCustomValue($event.target.value, Field.Identifier)" :value="Field.Value"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="vehicle-content-empty" v-else>
        <div class="notice">
            <span class="notice-title">📌 Er is nog geen uitvoering geselecteerd</span>
            <span>Beheer eenvoudig taken per voertuig met onze takenmodule. Voeg een omschrijving toe, wijs een verantwoordelijke aan en stel een datum in om het overzicht te behouden. Zo blijft elk voertuig altijd up-to-date en klaar voor gebruik.</span>
        </div>
        <div class="create-versions" v-if="Create.Versions.Items.length > 0">
            <div class="create-versions-list">
                <div class="create-versions-list-header">
                    <div class="create-versions-list-header-item name">
                        <span class="create-versions-list-header-item-title">Uitvoering</span>
                        <InputText v-model="Create.Versions.Filters.Name" />
                    </div>
                    <div class="create-versions-list-header-item cc">
                        <span class="create-versions-list-header-item-title">CC</span>
                        <Select v-model="Create.Versions.Filters.CC" :options="[...new Set(Create.Versions.Items.map(e => e.cc))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item fuel">
                        <span class="create-versions-list-header-item-title">Benzine</span>
                        <Select v-model="Create.Versions.Filters.Fuel" :options="[...new Set(Create.Versions.Items.map(e => e.brandstof))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item gear">
                        <span class="create-versions-list-header-item-title">Transmissie</span>
                        <Select v-model="Create.Versions.Filters.Gear" :options="[...new Set(Create.Versions.Items.map(e => e.transmissie))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item power">
                        <span class="create-versions-list-header-item-title">Vermogen</span>
                        <Select v-model="Create.Versions.Filters.KW" :options="[...new Set(Create.Versions.Items.map(e => e.kw))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item emission">
                        <span class="create-versions-list-header-item-title">Co2</span>
                        <Select v-model="Create.Versions.Filters.Uitstoot" :options="[...new Set(Create.Versions.Items.map(e => e.co2_uitstoot))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item duration">
                        <span class="create-versions-list-header-item-title">Looptijd</span>
                        <Select v-model="Create.Versions.Filters.Looptijd" :options="[...new Set(Create.Versions.Items.map(e => e.looptijd))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item price">
                        <span class="create-versions-list-header-item-title">Prijs</span>
                        <Select v-model="Create.Versions.Filters.Price" :options="[...new Set(Create.Versions.Items.map(e => e.catalogus_prijs))].sort(function(a, b) { return a - b })" />
                    </div>
                </div>
                <div class="create-versions-list-select">
                    <div class="checkbox" @click="Create.Versions.Outdated = !Create.Versions.Outdated">
                        <div v-if="Create.Versions.Outdated" class="checked"></div>
                    </div>
                    Toon modellen buiten de looptijd
                </div>
                <div class="create-versions-list-items">
                    <div class="create-versions-list-items-row" v-for="Item in FilteredVersions" :key="Item.uitvoering_nummer" :class="{ outdated: CheckVersionDate(Item.looptijd), hidden: CheckVersionDate(Item.looptijd) && !Create.Versions.Outdated }">
                        <div class="create-versions-list-items-row-items">
                            <svg class="create-versions-list-items-row-item open" v-if="Item.OptionsActive" @click="Item.OptionsActive = false" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path d="M7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H7Z" fill="#e5e5e5"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 2C3.11929 2 2 3.11929 2 4.5V19.5C2 20.8807 3.11929 22 4.5 22H19.5C20.8807 22 22 20.8807 22 19.5V4.5C22 3.11929 20.8807 2 19.5 2H4.5ZM4 4.5C4 4.22386 4.22386 4 4.5 4H19.5C19.7761 4 20 4.22386 20 4.5V19.5C20 19.7761 19.7761 20 19.5 20H4.5C4.22386 20 4 19.7761 4 19.5V4.5Z" fill="#e5e5e5"></path> </g></svg>
                            <svg class="create-versions-list-items-row-item open" v-else @click="GetOptions(Item)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M12 6C12.5523 6 13 6.44772 13 7V11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H11V7C11 6.44772 11.4477 6 12 6Z" fill="#e5e5e5"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4.5C2 3.11929 3.11929 2 4.5 2H19.5C20.8807 2 22 3.11929 22 4.5V19.5C22 20.8807 20.8807 22 19.5 22H4.5C3.11929 22 2 20.8807 2 19.5V4.5ZM4.5 4C4.22386 4 4 4.22386 4 4.5V19.5C4 19.7761 4.22386 20 4.5 20H19.5C19.7761 20 20 19.7761 20 19.5V4.5C20 4.22386 19.7761 4 19.5 4H4.5Z" fill="#e5e5e5"></path></g></svg>
                            <span class="create-versions-list-items-row-item name" @click="ConnectVehicle(Item)">{{ Item.Typenaam }}</span>
                            <span class="create-versions-list-items-row-item cc">{{ Item.cc }}</span>
                            <span class="create-versions-list-items-row-item fuel">{{ Item.brandstof }}</span>
                            <span class="create-versions-list-items-row-item gear">{{ Item.transmissie }}</span>
                            <span class="create-versions-list-items-row-item power">{{ Item.kw }}</span>
                            <span class="create-versions-list-items-row-item emission">{{ Item.co2_uitstoot }}</span>
                            <span class="create-versions-list-items-row-item duration">{{ Item.looptijd }}</span>
                            <span class="create-versions-list-items-row-item price">€ {{ Number(Item.catalogus_prijs).toLocaleString('nl-NL') }}</span>
                        </div>
                        <div class="create-versions-list-items-row-options" v-if="Item.OptionsActive && Item.Options">
                            <span class="create-versions-list-items-row-options-option" v-for="(Option, i) in Item.Options" :key="i">{{ Option.naam }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Select from 'primevue/select';

export default {
    name: 'Summery',

    components: {
        InputText,
        InputNumber,
        InputGroup,
        InputGroupAddon,
        Select
    },

    props: ['Vehicle', 'COC'],
    
    data () {
        return {
            VehicleData: false,
            Fuels: [{ label: 'Benzine', value: 'B' }, { label: 'Diesel', value: 'D' }, { label: 'Hybride Benzine (Plug-In / OVC)', value: 'HB' }, { label: 'Hybride Diesel (Plug-In / OVC)', value: 'HD' }, { label: 'Elektriciteit', value: 'E' }, { label: 'Aardgas (CNG)', value: 'A' }, { label: 'Waterstof', value: 'W' }, { label: 'Ethanol', value: 'ETH' }, { label: 'LNG', value: 'LNG' }, { label: 'LPG', value: 'LPG' }],
            Countries: ['Belgie', 'Bulgarije', 'Cyprus', 'Denemarken', 'Duitsland', 'Estland', 'Finland', 'Frankrijk', 'Griekenland', 'Hongarije', 'Ierland', 'IJsland', 'Italie', 'Kroatie', 'Letland', 'Liechtenstein', 'Litouwen', 'Luxemburg', 'Malta', 'Nederland', 'Noorwegen', 'Oostenrijk', 'Polen', 'Portugal', 'Roemenie', 'Slovenie', 'Slowakije', 'Spanje', 'Tsjechie', 'Zweden', 'Overige'],
            States: [{label: 'Goed', value: '2'}, {label: 'Redelijk', value: '1'}, {label: 'Slecht', value: '0'}],
            Vehicles: [{ label: 'M1 - Personenauto', value: 'P' }, { label: 'N1 - Bedrijfsauto', value: 'B' }, { label: 'M1 - Camper', value: 'C' }, { label: 'L3E - Motorfiets', value: 'M' }], 
            Gears: [{ label: 'Handgeschakeld', value: 'H' }, { label: 'Automaat', value: 'A' }],
            Labels: [{label: 'A', value: 'a'}, {label: 'B', value: 'b'}, {label: 'C', value: 'c'}, {label: 'D', value: 'd'}, {label: 'E', value: 'e'}, {label: 'F', value: 'f'}, {label: 'G', value: 'g'}],
            Collapse: {
                Factory: false,
                Packages: false,
                List: false,
                Other: false
            },
            Create: {
                Versions: {
                    Search: '',
                    Outdated: false,
                    Tip: '',
                    Items: [],
                    Filters: {
                        Name: '',
                        Fuel: '',
                        Gear: '',
                        CC: '',
                        KW: '',
                        Carrosserie: '',
                        Milieulabel: '',
                        Emissiecode: '',
                        Uitstoot: '',
                        Price: '',
                        Looptijd: ''
                    },
                    Selected: []
                }
            }
        }
    },

    mounted () {
        if(!this.Vehicle.Technical) {
            setTimeout(() => {
                this.GetVersions()
            }, 250)
        }

        // setTimeout(() => {
        //     this.VehicleData = this.Vehicle
        // }, 50)
    },

    methods: {
        GetVersions () {
            this.$http.get(`https://api.wheels.nl/vehicles/versions/create?model=${this.Vehicle.ModelID}&type=personenauto&datum_eerste_toelating=${this.$moment(this.Vehicle.Registration).format('DD-MM-YYYY')}`).then((response) => {               
                if(response.data.versions) { this.Create.Versions.Items = response.data.versions }
            })
        },

        GetOptions (e) {
            this.$http.get(`https://api.wheels.nl/vehicles/options/${e.auto_hash}`).then((response) => {
                if(response.data.options) {
                    e.Options = response.data.options
                    e.OptionsActive = true
                }
            })
        },

        ConnectVehicle (e) {
            this.$http.post(`https://api.wheels.nl/vehicles`, {
                Identifier: this.Vehicle.Identifier,
                Registration: this.Vehicle.Registration,
                Version: e.uitvoering_nummer,
                Emissions: e.co2_uitstoot
            }).then(() => {
                this.$parent.GetVehicle()
            })
        },

        CheckVersionDate (e) {
            var Registration = this.$moment(this.Vehicle.Registration)
            var From = this.$moment((e).split('-')[0].trim(), 'MM-YY')
            var Till = this.$moment((e).split('-')[1].trim(), 'MM-YY')

            if(Registration.isBetween(From, Till)) {
                return false
            } else {
                return true
            }
        },

        SetCategory (e) {
            if(e == 'M1') {
                return 'M1 - Personenauto'
            } else if(e == 'N1') {
                return 'N1 - Bedrijfswagen'
            } else if(e == 'M1C') {
                return 'M1C - Camper'
            } else if(e == 'L3E') {
                return 'L3E - Motorfiets'
            } else {
                return e
            }
        },

        SetVin (e) {
            if(e && e.length == 4) {
                return 'Meldcode (' + e.length + ')'
            } else if(e && e.length > 0) {
                return 'Chassisnummer (' + e.length + ')'
            } else {
                return 'Chassisnummer'
            }
        },

        SetFuel (e) {
            if(e == 'B') {
                return 'Benzine'
            } else if(e == 'D') {
                return 'Diesel'
            } else if(e == 'E') {
                return 'Elektrisch'
            } else if(e == 'F') {
                return 'Benzine (Hybride)'
            } else if(e == 'G') {
                return 'Diesel (Hybride)'
            } else if(e == 'C') {
                return 'Aardgas'
            } else if(e == 'H') {
                return 'Waterstof'
            } else {
                return e
            }
        }
    },

    computed: {
        FilteredVersions () {
            var Versions = this.Create.Versions.Items

            if(this.Create.Versions.Filters.Search) {
                Versions = Versions.filter(e => e.Typenaam.toLowerCase().includes(this.Create.Versions.Filters.Search.toLowerCase()))
            }

            if(this.Create.Versions.Filters.Name) {
                Versions = Versions.filter(e => e.Typenaam.toLowerCase().includes(this.Create.Versions.Filters.Name.toLowerCase()))
            }

            if(this.Create.Versions.Filters.Fuel) {
                Versions = Versions.filter(e => e.brandstof == this.Create.Versions.Filters.Fuel)
            }

            if(this.Create.Versions.Filters.Gear) {
                Versions = Versions.filter(e => e.transmissie == this.Create.Versions.Filters.Gear)
            }

            if(this.Create.Versions.Filters.CC) {
                Versions = Versions.filter(e => e.cc == this.Create.Versions.Filters.CC)
            }

            if(this.Create.Versions.Filters.KW) {
                Versions = Versions.filter(e => e.kw == this.Create.Versions.Filters.KW)
            }

            if(this.Create.Versions.Filters.Carrosserie) {
                Versions = Versions.filter(e => e.carrosserie == this.Create.Versions.Filters.Carrosserie)
            }

            if(this.Create.Versions.Filters.Milieulabel) {
                Versions = Versions.filter(e => e.milieulabel == this.Create.Versions.Filters.Milieulabel)
            }

            if(this.Create.Versions.Filters.Emissiecode) {
                Versions = Versions.filter(e => e.emissiecode == this.Create.Versions.Filters.Emissiecode)
            }

            if(this.Create.Versions.Filters.Uitstoot) {
                Versions = Versions.filter(e => e.co2_uitstootitstoot == this.Create.Versions.Filters.Uitstoot)
            }

            if(this.Create.Versions.Filters.Price) {
                Versions = Versions.filter(e => e.catalogus_prijs.includes(this.Create.Versions.Filters.Price))
            }

            if(this.Create.Versions.Filters.Looptijd) {
                Versions = Versions.filter(e => e.looptijd == this.Create.Versions.Filters.Looptijd)
            }

            return Versions.sort(function(a, b){ return Number(a.catalogus_prijs) - Number(b.catalogus_prijs) })
        }
    }
}
</script>

<style scoped>
.vehicle-content {
    display: flex;
    align-items: flex-start;
    gap: 25px;
    padding: 50px;
}

.vehicle-content .vehicle-column {
    width: calc(50% - 12.5px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 25px;
}

.vehicle-content .vehicle-column .block {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 25px;
    width: 100%;
    padding: 25px;
    background: #fff;
    border-radius: 5px;
}

.vehicle-content .vehicle-column .block.half {
    width: calc(50% - 12.5px);
}

.vehicle-content .vehicle-column .block .title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 600;
}

.vehicle-content .vehicle-column .block .title svg {
    width: 24px;
}

.vehicle-content .vehicle-column .block .group {
    width: calc(50% - 12.5px);
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.vehicle-content .vehicle-column .block .group.price {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.vehicle-content .vehicle-column .block .group.full {
    width: 100%;
}

.vehicle-content .vehicle-column .block .group .group-title {
    font-weight: 600;
}

.vehicle-content .vehicle-column .block .group .group-value {
    padding: 5px 0;
}

.vehicle-content .vehicle-column .block .group.price .group-value {
    padding: 0;
}

.vehicle-content .vehicle-column .block .group .group-value.license {
    width: fit-content;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e5be01;
    padding: 0 15px;
    font-weight: 600;
    border-left: solid 15px #1a41fe;
    border-radius: 5px;
}

.vehicle-content .vehicle-column .block .group .group-energy {
    position: relative;
    width: 155px;
    margin: 0 0 25px 0;
}

.vehicle-content .vehicle-column .block .group .group-energy span {
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    color: #fff;
    font-weight: 700;
}

.vehicle-content .vehicle-column .block .group .group-energy span:before {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 15px solid var(--primary);
    border-bottom: 15px solid transparent;
}

.vehicle-content .vehicle-column .block .group .group-energy span.a {
    background: #3b7634;
}
.vehicle-content .vehicle-column .block .group .group-energy span.a:before {
    border-left-color: #3b7634;
}

.vehicle-content .vehicle-column .block .group .group-energy span.b {
    background: #5da436;
}
.vehicle-content .vehicle-column .block .group .group-energy span.b:before {
    border-left-color: #5da436;
}

.vehicle-content .vehicle-column .block .group .group-energy span.c {
    background: #a3cf2a;
}
.vehicle-content .vehicle-column .block .group .group-energy span.c:before {
    border-left-color: #a3cf2a;
}

.vehicle-content .vehicle-column .block .group .group-energy span.d {
    background: #f6df1b;
}
.vehicle-content .vehicle-column .block .group .group-energy span.d:before {
    border-left-color: #f6df1b;
}

.vehicle-content .vehicle-column .block .group .group-energy span.e {
    background: #f29020;
}
.vehicle-content .vehicle-column .block .group .group-energy span.e:before {
    border-left-color: #f29020;
}

.vehicle-content .vehicle-column .block .group .group-energy span.f {
    background: #eb422c;
}
.vehicle-content .vehicle-column .block .group .group-energy span.f:before {
    border-left-color: #eb422c;
}

.vehicle-content .vehicle-column .block .group .group-energy span.g {
    background: #ea2039;
}
.vehicle-content .vehicle-column .block .group .group-energy span.g:before {
    border-left-color: #ea2039;
}

.vehicle-content .vehicle-column .block .group textarea {
    min-height: 150px;
    padding: 15px;
    border: solid 1px #cbd5e1;
    border-radius: 5px;
    outline: none;
    resize: none;
}

.vehicle-content .vehicle-column .block .group .radio {
    width: fit-content;
    height: 48px;
    border: solid 1px #ececec;
    border-radius: 5px;
    outline: none;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.vehicle-content .vehicle-column .block .group .radio .item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 25px;
    color: #333;
    background: transparent;
    font-weight: 500;
    cursor: pointer;
}

.vehicle-content .vehicle-column .block .group .radio .item.active {
    color: #fff;
    background: var(--primary);
}

.vehicle-content .vehicle-column .block .vehicle-image {
    width: 120px;
    object-fit: cover;
    border-radius: 5px;
}



.vehicle-content-empty {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 50px;
}

.vehicle-content-empty .notice {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 15px 25px;
    width: 100%;
    padding: 25px;
    background: #ffffe0;
    border-radius: 5px;
}

.vehicle-content-empty .notice .notice-title {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
}

.vehicle-content-empty .create-versions {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.vehicle-content-empty .create-versions .create-versions-title {
    font-weight: 700;
}

.vehicle-content-empty .create-versions .create-versions-list {
    display: flex;
    flex-direction: column;
    padding: 25px;
    background: #fff;
    border: solid 2px #ededed;
    border-radius: 5px;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-header {
    display: flex;
    gap: 15px;
    padding: 15px 25px;
    background: #f7f8fc;
    border-radius: 5px;
    overflow: auto;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item .create-versions-list-header-item-title {
    font-weight: 700;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.name {
    min-width: 300px;
    max-width: 300px;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.cc,
.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.power,
.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.emission,
.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.duration,
.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.price {
    min-width: 100px;
    max-width: 100px;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.fuel,
.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.gear {
    min-width: 200px;
    max-width: 200px;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-select {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-select .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-select .checkbox .checked {
    width: 18px;
    height: 18px;
    background: var(--primary);
    border-radius: 2.5px;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px 0 0 0;
    overflow: auto;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row.outdated {
    opacity: .5;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row.hidden {
    display: none;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items {
    display: flex;
    align-items: center;
    gap: 15px;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.open {
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    cursor: pointer;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.name {
    min-width: 300px;
    max-width: 300px;
    font-weight: 700;
    cursor: pointer;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.name:hover {
    color: var(--primary);
    text-decoration: underline;
    text-underline-offset: 5px;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.cc,
.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.power,
.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.emission,
.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.duration,
.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.price {
    min-width: 100px;
    max-width: 100px;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.fuel,
.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.gear {
    min-width: 200px;
    max-width: 200px;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-options {
    column-count: 4;
    column-gap: 40px;
    padding: 0 0 0 30px;
    opacity: 0.5;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-options .create-versions-list-items-row-options-option {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.vehicle-content-empty .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-options .create-versions-list-items-row-options-option::first-letter {
    text-transform: capitalize;
}
</style>