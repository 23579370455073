<template>
    <div class="shortcuts">
        Shortcuts
    </div>
</template>

<script>
export default {
    name: 'DashboardShortcuts'
}
</script>

<style scoped>
.shortcuts {
    display: none;
    width: 100%;
    height: 100px;
    background: #fff;
}
</style>