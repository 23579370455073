<template>
    <div class="vehicle-content" v-if="Vehicle.Options">
        <div class="vehicle-columns" v-if="Vehicle.Options.Factory">
            <div class="vehicle-column">
                <span class="vehicle-column-title">Standaardopties</span>
                <div class="vehicle-column-list" :class="{ active: Collapse.Factory }">
                    <div class="vehicle-column-list-summery" @click="Collapse.Factory = !Collapse.Factory">
                        <span>{{ Vehicle.Options.Factory.length }} opties</span>
                        <svg v-if="!Collapse.Factory" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                        <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    </div>
                    <div class="vehicle-column-list-item" v-for="(Item, i) in Sort(Vehicle.Options.Factory, 'Name')" :key="i">
                        <span class="description full">{{ Item.Name }}</span>
                    </div>
                </div>
            </div>
            <div class="vehicle-column" v-if="Vehicle.Options.Packages">
                <span class="vehicle-column-title">Optiepakketten</span>
                <div class="vehicle-column-list" :class="{ active: Collapse.Packages }">
                    <div class="vehicle-column-list-summery" @click="Collapse.Packages = !Collapse.Packages">
                        <span>{{ Vehicle.Options.Packages.filter(item => item.Selected).length }} van {{ Vehicle.Options.Packages.length }} geselecteerd</span>
                        <svg v-if="!Collapse.Packages" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                        <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    </div>
                    <div class="vehicle-column-list-item" v-for="(Packages, i) in Sort(Vehicle.Options.Packages, 'Name')" :key="i">
                        <div class="checkbox" @click="Packages.Selected = !Packages.Selected, this.$parent.SetValue('Options')">
                            <div class="checked" v-if="Packages.Selected"></div>
                        </div>
                        <span class="description">{{ Packages.Name }}</span>
                        <span class="price">€ {{ Number(Packages.Price).toLocaleString('nl-NL') }}</span>
                        <ul>
                            <li class="subitem" v-for="(PackageItem, Pi) in Packages.Content" :key="Pi">{{ PackageItem.naam }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="vehicle-columns">
            <div class="vehicle-column" v-if="Vehicle.Options.Extra">
                <span class="vehicle-column-title">Opties</span>
                <div class="vehicle-column-list" :class="{ active: Collapse.Options }">
                    <div class="vehicle-column-list-summery" @click="Collapse.Options = !Collapse.Options">
                        <span>{{ Vehicle.Options.Extra.filter(item => item.Selected).length }} van {{ Vehicle.Options.Extra.length }} geselecteerd</span>
                        <svg v-if="!Collapse.List" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                        <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    </div>
                    <div class="vehicle-column-list-item" v-for="(List, i) in Sort(Vehicle.Options.Extra, 'Name')" :key="i" :class="{ disabled: PackagesID.includes(List.ID) }">
                        <div class="checkbox" @click="List.Selected = !List.Selected, this.$parent.SetValue('Options')">
                            <div class="checked" v-if="List.Selected || PackagesID.includes(List.ID)"></div>
                        </div>
                        <span class="description">{{ List.Name }}</span>
                        <span class="price">€ {{ Number(List.Price).toLocaleString('nl-NL') }}</span>
                    </div>
                </div>
            </div>
            <div class="vehicle-column">
                <span class="vehicle-column-title">Extra opties</span>
                <div class="vehicle-column-list" :class="{ active: Collapse.Other }">
                    <div class="vehicle-column-list-summery" @click="Collapse.Other = !Collapse.Other">
                        <span>{{ Vehicle.Options.Other.filter(item => item.Selected).length }} van {{ Vehicle.Options.Other.length }} geselecteerd</span>
                        <svg v-if="!Collapse.List" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                        <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    </div>
                    <div class="vehicle-column-list-item" v-for="(Other, i) in Vehicle.Options.Other" :key="i">
                        <div class="checkbox" @click="Other.Selected = !Other.Selected, this.$parent.SetValue('Options')">
                            <div class="checked" v-if="Other.Selected"></div>
                        </div>
                        <span class="description">{{ Other.Name }}</span>
                        <span class="price">
                            <span class="price">€ {{ Number(Other.Price).toLocaleString('nl-NL') }}</span>
                        </span>
                    </div>
                    <div class="vehicle-column-list-item add">
                        <InputText type="text" class="add-name" placeholder="Naam van de optie" v-model="Other.Name" />
                        <InputGroup class="add-price">
                            <InputGroupAddon>
                                €
                            </InputGroupAddon>
                            <InputNumber placeholder="Prijs" v-model="Other.Price" fluid />
                        </InputGroup>
                        <span class="add-button" @click="AddCustomOption" :class="{ active: Other.Name && Other.Price }">Toevoegen</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';

export default {
    name: 'Summery',

    components: {
        InputText,
        InputNumber,
        InputGroup,
        InputGroupAddon
    },

    props: ['Vehicle'],

    data () {
        return {
            Collapse: {
                Factory: false,
                Packages: true,
                Options: true,
                Other: false
            },
            Other: {
                Name: '',
                Price: ''
            }
        }
    },

    methods: {
        AddCustomOption () {
            if(this.Other.Name && this.Other.Price) {
                this.$parent.AddCustomOption(this.Other.Name, this.Other.Price)
                
                this.Other.Name = ''
                this.Other.Price = ''

                setTimeout(() => {
                    this.$parent.SetValue('Options')
                }, 250)
            }
        },

        Sort (e, z) {
            return e.sort(function(a, b) { return a[z] - b[z] || a[z].localeCompare(b[z]) })
        }
    },

    computed: {
        PackagesID () {
            var Selected = []
            if(this.Vehicle.Options.Packages) {
                this.Vehicle.Options.Packages.forEach(e => {
                    if(e.Selected) {                        
                        e.Content.forEach(a => {
                            this.Vehicle.Options.Extra.forEach(extra => {
                                if(extra.ID == a.id) {
                                    extra.Selected = false
                                }
                            })

                            Selected.push(a.id)
                        })
                    }
                })

                return Selected
            } else {
                return Selected
            }
        }
    }
}
</script>

<style scoped>
.vehicle-content {
    display: flex;
    align-items: flex-start;
    gap: 25px;
    padding: 50px;
}

.vehicle-content .vehicle-columns {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.vehicle-content .vehicle-columns .vehicle-column {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    width: 100%;
    padding: 25px;
    background: #fff;
    border-radius: 5px;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-title {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list {
    position: relative;
    width: 100%;
    max-height: 55px;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list.active {
    max-height: unset;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list-summery {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 0;
    border-bottom: dashed 1px #e3e3e3;
    cursor: pointer;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list-summery:last-of-type {
    border: 0;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list-summery svg {
    position: absolute;
    top: 15px;
    right: 0px;
    width: 24px;
    height: 24px;
    pointer-events: none;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 10px 0;
    border-bottom: solid 1px #ececec;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item.add {
    gap: 15px;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item:last-of-type {
    border: 0;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item.disabled {
    pointer-events: none;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item.disabled > * {
    opacity: 0.5;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item .checkbox {
    position: relative;
    width: 24px;
    height: 24px;
    margin: 0 10px 0 0;
    border: solid 1px #ececec;
    border-radius: 5px;
    cursor: pointer;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item .checkbox .checked {
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: var(--primary);
    border-radius: 2.5px;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item .description {
    width: calc(100% - 125px);
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item .description::first-letter {
    text-transform: capitalize;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item .description.full {
    width: 100%;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item .price {
    width: 75px;
    text-align: right;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item ul {
    width: calc(100% - 80px);
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item ul .subitem {
    margin: 0 0 0 55px;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item ul .subitem {
    width: 100%;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item ul .subitem::first-letter {
    text-transform: capitalize;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item ul .subitem.full {
    width: 100%;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item .add-name {
    width: calc(75% - 7.5px);
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item .add-price {
    width: calc(25% - 7.5px);
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item .add-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    font-weight: 600;
    text-decoration: none;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.25;
}

.vehicle-content .vehicle-columns .vehicle-column .vehicle-column-list .vehicle-column-list-item .add-button.active {
    pointer-events: all;
    opacity: 1;
}
</style>