<template>
    <div class="create" :class="{ full: Create.Versions.Items.length > 0 }">
        <router-link to="/voertuigen" class="back">
            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g><path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"></path><path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"></path></g></svg>
            Terug naar voertuigen
        </router-link>
        <div class="groups">
            <div class="group">
                <span class="group-title">1. Kies het soort voertuig</span>
                <div class="method">
                    <span class="method-item" @click="Create.Type = 'M1'" :class="{ active: Create.Type == 'M1', disabled: Create.Type && Create.Type !== 'M1' }"><span class="bubble">M1</span> Personenauto</span>
                    <span class="method-item" @click="Create.Type = 'N1'" :class="{ active: Create.Type == 'N1', disabled: Create.Type && Create.Type !== 'N1' }"><span class="bubble">N1</span> Bedrijfsauto</span>
                    <span class="method-item" @click="Create.Type = 'L3E'" :class="{ active: Create.Type == 'L3E', disabled: Create.Type && Create.Type !== 'L3E' }"><span class="bubble">L3e</span> Motoren</span>
                    <span class="method-item" @click="Create.Type = 'M1C'" :class="{ active: Create.Type == 'M1C', disabled: Create.Type && Create.Type !== 'M1C' }"><span class="bubble">M1c</span> Camper</span>
                </div>
            </div>
        </div>
        <div class="groups" v-if="Create.Type">
            <div class="group">
                <span class="group-title">2. Via welke methode wil je het voertuig samenstellen?</span>
                <div class="method">
                    <div class="method-item" @click="Create.Method = 'VIN'" :class="{ active: Create.Method == 'VIN', disabled: Create.Method && Create.Method !== 'VIN' }">
                        <span class="method-item-title">Chassisnummer</span>
                        <div class="group">
                            <span class="group-title">Datum eerste tenaamstelling</span>
                            <InputMask v-model="Create.Registration" mask="99-99-9999" placeholder="DD-MM-YYYY" />
                        </div>
                        <div class="group" v-if="Create.Method == 'VIN' && /^[0-9]{2}[-|\/]{1}[0-9]{2}[-|\/]{1}[0-9]{4}$/.test(Create.Registration)">
                            <span class="group-title">{{ SetVin(Create.VIN) }}</span>
                            <input type="text" v-model="Create.VIN" class="uppercase" :maxlength="17">
                        </div>
                        <div class="method-item-loader" v-if="Create.Method == 'VIN' && Create.MethodLoader">
                            <svg class="method-item-loader-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="12" cy="20" r="2"></circle><circle cx="12" cy="4" r="2"></circle><circle cx="6.343" cy="17.657" r="2"></circle><circle cx="17.657" cy="6.343" r="2"></circle><circle cx="4" cy="12" r="2.001"></circle><circle cx="20" cy="12" r="2"></circle><circle cx="6.343" cy="6.344" r="2"></circle><circle cx="17.657" cy="17.658" r="2"></circle></g></svg>
                        </div>
                    </div>
                    <div class="method-item" @click="Create.Method = 'Free'" :class="{ active: Create.Method == 'Free', disabled: Create.Method && Create.Method !== 'Free' }">
                        <span class="method-item-title">Merk en model</span>
                        <div class="group">
                            <span class="group-title">Datum eerste tenaamstelling</span>
                            <InputMask v-model="Create.Registration" mask="99-99-9999" placeholder="DD-MM-YYYY" @blur="GetModels()" />
                        </div>
                        <div class="group" v-if="Create.Method == 'Free' && /^[0-9]{2}[-|\/]{1}[0-9]{2}[-|\/]{1}[0-9]{4}$/.test(Create.Registration)">
                            <span class="group-title">Selecteer een model</span>
                            <input type="text" placeholder="Zoeken..." v-model="Create.Models.Search" :class="{ active: Create.Models.Search }" @focus="Create.Models.Active = true" @blur="CloseModels()">
                            <div class="models" v-if="Create.Models.Active && Create.Models.Search && Create.Models.Items">
                                <div class="model" v-for="(Model, i) in Create.Models.Items.filter(e => e.ModelName.toLowerCase().includes(Create.Models.Search.toLowerCase()))" :key="i" @click="Create.Model = Model.ModelIdentifier, Create.Models.Search = Model.BrandName + ' ' + Model.ModelName, Create.Models.Items = false, Create.ModelID = Model.ModelID, GetVersions()">
                                    <div class="model-item">{{ Model.BrandName }} <b>{{ Model.ModelName }}</b></div>
                                </div>
                            </div>
                        </div>
                        <div class="method-item-loader" v-if="Create.Method == 'Free' && Create.MethodLoader">
                            <svg class="method-item-loader-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="12" cy="20" r="2"></circle><circle cx="12" cy="4" r="2"></circle><circle cx="6.343" cy="17.657" r="2"></circle><circle cx="17.657" cy="6.343" r="2"></circle><circle cx="4" cy="12" r="2.001"></circle><circle cx="20" cy="12" r="2"></circle><circle cx="6.343" cy="6.344" r="2"></circle><circle cx="17.657" cy="17.658" r="2"></circle></g></svg>
                        </div>
                    </div>
                    <div class="method-item" @click="Create.Method = 'License'" :class="{ active: Create.Method == 'License', disabled: Create.Method && Create.Method !== 'License' }">
                        <span class="method-item-title">Kenteken</span>
                        <div class="group">
                            <span class="group-title">Kenteken</span>
                            <InputText type="text" v-model="Create.License.Search" v-keyfilter.alphanum placeholder="XXX-XXX-XXX" class="uppercase" />
                        </div>
                        <div class="group" v-if="Create.Method == 'License' && Create.License.Search.length == 6">
                            <span class="group-title">Kilometerstand</span>
                            <InputNumber class="group-input" v-model="Create.Mileage" unstyled fluid />
                        </div>
                        <div class="method-item-loader" v-if="Create.Method == 'License' && Create.MethodLoader">
                            <svg class="method-item-loader-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="12" cy="20" r="2"></circle><circle cx="12" cy="4" r="2"></circle><circle cx="6.343" cy="17.657" r="2"></circle><circle cx="17.657" cy="6.343" r="2"></circle><circle cx="4" cy="12" r="2.001"></circle><circle cx="20" cy="12" r="2"></circle><circle cx="6.343" cy="6.344" r="2"></circle><circle cx="17.657" cy="17.658" r="2"></circle></g></svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="groups" v-if="Create.Method == 'VIN'">
            <div class="group" v-if="Create.VIN.length == 17">
                <span class="group-title">&nbsp;</span>
                <button v-if="Create.Versions.Items.length == 0" class="group-button" @click="GetVIN()" :class="{ loader: Create.Loader }">Aanmaken</button>
            </div>
            <div class="group full" v-if="Create.Versions.Items.length > 0">
                <span class="group-title">Selecteer een uitvoering</span>
                <span class="group-tip" v-if="Create.Versions.Tip">{{ Create.Versions.Tip }}</span>

                <DataTable :value="FilteredVersions" selectionMode="single" sortField="kw" sortMode="single" resizableColumns columnResizeMode="fit" filterDisplay="row" :sortOrder="1" showGridlines dataKey="uitvoering_nummer" @rowSelect="CreateVIN($event)" :class="{ loader: Create.Loader }">
                    <template #header>
                        <input type="text" v-model="Create.Versions.Filters.Search" placeholder="Zoek een uitvoering" class="group-search">
                    </template>
                    <Column style="width: 50px">
                        <template #body>
                            <input type="checkbox" name="" id="">
                        </template>
                    </Column>
                    <Column header="Uitvoering" filterField="Typenaam" dataType="Typenaam" :showFilterMenu="false" sortable>
                        <template #body="{ data }">
                            {{ data.Typenaam }}
                        </template>
                        <template #filter>
                            <input type="text" v-model="Create.Versions.Filters.Name">
                        </template>
                    </Column>
                    <Column header="Brandstof" filterField="brandstof" dataType="brandstof" :showFilterMenu="false" sortable>
                        <template #body="{ data }">
                            {{ data.brandstof }}
                        </template>
                        <template #filter>
                            <select v-model="Create.Versions.Filters.Fuel">
                                <option value="" disabled>Brandstof</option>
                                <option value="" disabled>- - - - -</option>
                                <option v-for="(Fuel, i) in [...new Set(Create.Versions.Items.map(e => e.brandstof))].sort(function(a, b) { return a - b })" :key="i" :value="Fuel">{{ Fuel }}</option>
                            </select>
                        </template>
                    </Column>
                    <Column header="Transmissie" filterField="transmissie" dataType="transmissie" :showFilterMenu="false" sortable style="min-width: 185px;">
                        <template #body="{ data }">
                            {{ data.transmissie }}
                        </template>
                        <template #filter>
                            <select v-model="Create.Versions.Filters.Gear">
                                <option value="" disabled>Transmissie</option>
                                <option value="" disabled>- - - - -</option>
                                <option v-for="(Gear, i) in [...new Set(Create.Versions.Items.map(e => e.transmissie))].sort(function(a, b) { return a - b })" :key="i" :value="Gear">{{ Gear }}</option>
                            </select>
                        </template>
                    </Column>
                    <Column v-if="Create.Versions.Items.filter(e => e.cc).length > 0" header="CC" filterField="cc" dataType="cc" :showFilterMenu="false" sortable style="min-width: 115px;">
                        <template #body="{ data }">
                            {{ data.cc }}
                        </template>
                        <template #filter>
                            <select v-model="Create.Versions.Filters.CC">
                                <option value="" disabled>CC</option>
                                <option value="" disabled>- - - - -</option>
                                <option v-for="(CC, i) in [...new Set(Create.Versions.Items.map(e => e.cc))].sort(function(a, b) { return a - b })" :key="i" :value="CC">{{ CC }}</option>
                            </select>
                        </template>
                    </Column>
                    <Column header="KW" filterField="kw" dataType="kw" :showFilterMenu="false" sortable style="min-width: 115px;">
                        <template #body="{ data }">
                            {{ data.kw }}
                        </template>
                        <template #filter>
                            <select v-model="Create.Versions.Filters.KW">
                                <option value="" disabled>KW</option>
                                <option value="" disabled>- - - - -</option>
                                <option v-for="(KW, i) in [...new Set(Create.Versions.Items.map(e => e.kw))].sort(function(a, b) { return a - b })" :key="i" :value="KW">{{ KW }}</option>
                            </select>
                        </template>
                    </Column>
                    <Column header="Carrosserie" filterField="carrosserie" dataType="carrosserie" :showFilterMenu="false" sortable style="min-width: 180px;">
                        <template #body="{ data }">
                            {{ data.carrosserie }}
                        </template>
                        <template #filter>
                            <select v-model="Create.Versions.Filters.Carrosserie">
                                <option value="" disabled>Carrosserie</option>
                                <option value="" disabled>- - - - -</option>
                                <option v-for="(Carrosserie, i) in [...new Set(Create.Versions.Items.map(e => e.carrosserie))].sort(function(a, b) { return a - b })" :key="i" :value="Carrosserie">{{ Carrosserie }}</option>
                            </select>
                        </template>
                    </Column>
                    <Column header="Milieulabel" filterField="milieulabel" dataType="milieulabel" :showFilterMenu="false" sortable style="min-width: 175px;">
                        <template #body="{ data }">
                            {{ data.milieulabel }}
                        </template>
                        <template #filter>
                            <select v-model="Create.Versions.Filters.Milieulabel">
                                <option value="" disabled>Milieulabel</option>
                                <option value="" disabled>- - - - -</option>
                                <option v-for="(Milieulabel, i) in [...new Set(Create.Versions.Items.map(e => e.milieulabel))].sort(function(a, b) { return a - b })" :key="i" :value="Milieulabel">{{ Milieulabel }}</option>
                            </select>
                        </template>
                    </Column>
                    <Column v-if="Create.Versions.Items.filter(e => e.emissiecode).length > 0" header="Emissiecode" filterField="emissiecode" dataType="emissiecode" :showFilterMenu="false" sortable style="min-width: 190px;">
                        <template #body="{ data }">
                            {{ data.emissiecode }}
                        </template>
                        <template #filter>
                            <select v-model="Create.Versions.Filters.Emissiecode">
                                <option value="" disabled>Emissiecode</option>
                                <option value="" disabled>- - - - -</option>
                                <option v-for="(Emissiecode, i) in [...new Set(Create.Versions.Items.map(e => e.emissiecode))].sort(function(a, b) { return a - b })" :key="i" :value="Emissiecode">{{ Emissiecode }}</option>
                            </select>
                        </template>
                    </Column>
                    <Column v-if="Create.Versions.Items.filter(e => e.co2_uitstoot).length > 0" header="Co2" filterField="co2_uitstoot" dataType="co2_uitstoot" :showFilterMenu="false" sortable style="min-width: 120px;">
                        <template #body="{ data }">
                            {{ data.co2_uitstoot }}
                        </template>
                        <template #filter>
                            <select v-model="Create.Versions.Filters.Uitstoot">
                                <option value="" disabled>Co2</option>
                                <option value="" disabled>- - - - -</option>
                                <option v-for="(Uitstoot, i) in [...new Set(Create.Versions.Items.map(e => e.co2_uitstoot))].sort(function(a, b) { return a - b })" :key="i" :value="Uitstoot">{{ Uitstoot }}</option>
                            </select>
                        </template>
                    </Column>
                    <Column header="Prijs" filterField="catalogus_prijs" dataType="catalogus_prijs" :showFilterMenu="false" sortable>
                        <template #body="{ data }">
                            € {{ Number(data.catalogus_prijs).toLocaleString('nl-NL') }}
                        </template>
                        <template #filter>
                            <input type="text" v-model="Create.Versions.Filters.Price">
                        </template>
                    </Column>
                    <Column header="Looptijd" filterField="looptijd" dataType="looptijd" :showFilterMenu="false" sortable  style="min-width: 150px;">
                        <template #body="{ data }">
                            {{ data.looptijd }}
                        </template>
                        <template #filter>
                            <select v-model="Create.Versions.Filters.Looptijd">
                                <option value="" disabled>Looptijd</option>
                                <option value="" disabled>- - - - -</option>
                                <option v-for="(Looptijd, i) in [...new Set(Create.Versions.Items.map(e => e.looptijd))].sort(function(a, b) { return a - b })" :key="i" :value="Looptijd">{{ Looptijd }}</option>
                            </select>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <div class="groups" v-if="Create.Method == 'License'">
            <div class="group" v-if="Create.Mileage">
                <span class="group-title">&nbsp;</span>
                <button class="group-button" @click="GetLicense()" :class="{ loader: Create.Loader }">Ophalen</button>
            </div>
            <div class="group full" v-if="Create.License.Item">
                <div class="group-license">
                    <div class="group-license-item">
                        <span class="group-license-item-title">Voertuigsoort</span>
                        <span>{{ Create.License.Item.voertuigsoort }}</span>
                    </div>
                    <div class="group-license-item">
                        <span class="group-license-item-title">Model</span>
                        <span>{{ Create.License.Item.handelsbenaming }}</span>
                    </div>
                    <div class="group-license-item">
                        <span class="group-license-item-title">Datum eerste toelating</span>
                        <span>{{ this.$moment(Create.License.Item.datum_eerste_toelating).format('DD-MM-YYYY') }}</span>
                    </div>
                    <div class="group-license-item" v-if="Create.License.Item.catalogusprijs">
                        <span class="group-license-item-title">Cataloguswaarde</span>
                        <span>€ {{ Number(Create.License.Item.catalogusprijs).toLocaleString('nl-NL') }}</span>
                    </div>
                    <div class="group-license-item">
                        <button class="group-license-item-button" @click="CreateLicense()" :class="{ loader: Create.Loader }">Aanmaken</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="create-versions" v-if="Create.Versions.Items.length > 0">
            <span class="create-versions-title">3. Selecteer de juiste uitvoering</span>
            <div class="create-versions-list">
                <div class="create-versions-list-header">
                    <div class="create-versions-list-header-item name">
                        <span class="create-versions-list-header-item-title">Uitvoering</span>
                        <InputText v-model="Create.Versions.Filters.Name" />
                    </div>
                    <div class="create-versions-list-header-item cc">
                        <span class="create-versions-list-header-item-title">CC</span>
                        <Select v-model="Create.Versions.Filters.CC" :options="[...new Set(Create.Versions.Items.map(e => e.cc))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item fuel">
                        <span class="create-versions-list-header-item-title">Benzine</span>
                        <Select v-model="Create.Versions.Filters.Fuel" :options="[...new Set(Create.Versions.Items.map(e => e.brandstof))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item gear">
                        <span class="create-versions-list-header-item-title">Transmissie</span>
                        <Select v-model="Create.Versions.Filters.Gear" :options="[...new Set(Create.Versions.Items.map(e => e.transmissie))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item power">
                        <span class="create-versions-list-header-item-title">Vermogen</span>
                        <Select v-model="Create.Versions.Filters.KW" :options="[...new Set(Create.Versions.Items.map(e => e.kw))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item emission">
                        <span class="create-versions-list-header-item-title">Co2</span>
                        <Select v-model="Create.Versions.Filters.Uitstoot" :options="[...new Set(Create.Versions.Items.map(e => e.co2_uitstoot))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item duration">
                        <span class="create-versions-list-header-item-title">Looptijd</span>
                        <Select v-model="Create.Versions.Filters.Looptijd" :options="[...new Set(Create.Versions.Items.map(e => e.looptijd))].sort(function(a, b) { return a - b })" />
                    </div>
                    <div class="create-versions-list-header-item price">
                        <span class="create-versions-list-header-item-title">Prijs</span>
                        <Select v-model="Create.Versions.Filters.Price" :options="[...new Set(Create.Versions.Items.map(e => e.catalogus_prijs))].sort(function(a, b) { return a - b })" />
                    </div>
                </div>
                <div class="create-versions-list-select">
                    <div class="checkbox" @click="Create.Versions.Outdated = !Create.Versions.Outdated">
                        <div v-if="Create.Versions.Outdated" class="checked"></div>
                    </div>
                    Toon modellen buiten de looptijd
                </div>
                <div class="create-versions-list-items">
                    <div class="create-versions-list-items-row" v-for="Item in FilteredVersions" :key="Item.uitvoering_nummer" :class="{ outdated: CheckVersionDate(Item.looptijd), hidden: CheckVersionDate(Item.looptijd) && !Create.Versions.Outdated }">
                        <div class="create-versions-list-items-row-items">
                            <svg class="create-versions-list-items-row-item open" v-if="Item.OptionsActive" @click="Item.OptionsActive = false" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path d="M7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H7Z" fill="#e5e5e5"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 2C3.11929 2 2 3.11929 2 4.5V19.5C2 20.8807 3.11929 22 4.5 22H19.5C20.8807 22 22 20.8807 22 19.5V4.5C22 3.11929 20.8807 2 19.5 2H4.5ZM4 4.5C4 4.22386 4.22386 4 4.5 4H19.5C19.7761 4 20 4.22386 20 4.5V19.5C20 19.7761 19.7761 20 19.5 20H4.5C4.22386 20 4 19.7761 4 19.5V4.5Z" fill="#e5e5e5"></path> </g></svg>
                            <svg class="create-versions-list-items-row-item open" v-else @click="GetOptions(Item)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M12 6C12.5523 6 13 6.44772 13 7V11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H11V7C11 6.44772 11.4477 6 12 6Z" fill="#e5e5e5"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4.5C2 3.11929 3.11929 2 4.5 2H19.5C20.8807 2 22 3.11929 22 4.5V19.5C22 20.8807 20.8807 22 19.5 22H4.5C3.11929 22 2 20.8807 2 19.5V4.5ZM4.5 4C4.22386 4 4 4.22386 4 4.5V19.5C4 19.7761 4.22386 20 4.5 20H19.5C19.7761 20 20 19.7761 20 19.5V4.5C20 4.22386 19.7761 4 19.5 4H4.5Z" fill="#e5e5e5"></path></g></svg>
                            <span class="create-versions-list-items-row-item name" @click="CreateVehicle(Item)">{{ Item.Typenaam }}</span>
                            <span class="create-versions-list-items-row-item cc">{{ Item.cc }}</span>
                            <span class="create-versions-list-items-row-item fuel">{{ Item.brandstof }}</span>
                            <span class="create-versions-list-items-row-item gear">{{ Item.transmissie }}</span>
                            <span class="create-versions-list-items-row-item power">{{ Item.kw }}</span>
                            <span class="create-versions-list-items-row-item emission">{{ Item.co2_uitstoot }}</span>
                            <span class="create-versions-list-items-row-item duration">{{ Item.looptijd }}</span>
                            <span class="create-versions-list-items-row-item price">€ {{ Number(Item.catalogus_prijs).toLocaleString('nl-NL') }}</span>
                        </div>
                        <div class="create-versions-list-items-row-options" v-if="Item.OptionsActive && Item.Options">
                            <span class="create-versions-list-items-row-options-option" v-for="(Option, i) in Item.Options" :key="i">{{ Option.naam }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputMask from 'primevue/inputmask';
import Select from 'primevue/select';

export default {
    name: 'VehiclesCreate',

    components: {
        DataTable,
        Column,
        InputMask,
        InputText,
        InputNumber,
        Select
    },

    data () {
        return {
            Loader: false,
            Create: {
                Identifier: '',
                Type: '',
                Method: '',
                MethodLoader: false,
                VIN: '',
                Registration: '',
                Mileage: '',
                License: {
                    Search: '',
                    Item: false
                },
                Model: false,
                ModelID: false,
                ModelName: false,
                Models: {
                    Active: false,
                    Search: '',
                    Items: []
                },
                Version: false,
                Versions: {
                    Search: '',
                    Outdated: false,
                    Tip: '',
                    Items: [],
                    Filters: {
                        Name: '',
                        Fuel: '',
                        Gear: '',
                        CC: '',
                        KW: '',
                        Carrosserie: '',
                        Milieulabel: '',
                        Emissiecode: '',
                        Uitstoot: '',
                        Price: '',
                        Looptijd: ''
                    },
                    Selected: []
                }
            }
        }
    },

    mounted () {
        if(this.$route.query.type) {
            this.Create.Type = this.$route.query.type
        }
        
        if(this.$route.query.method) {
            this.Create.Method = this.$route.query.method
        }

        if(this.$route.query.registration) {
            this.Create.Registration = this.$route.query.registration

            this.GetModels()
        }
    },

    methods: {
        GetModels () {
            if(this.Create.Registration.length == 10) {
                this.Create.MethodLoader = true
    
                this.$http.get(`https://api.wheels.nl/vehicles/models/create?type=${this.Create.Type}&registration=${this.Create.Registration}`).then((response) => {
                    if(response.data.models) { this.Create.Models.Items = response.data.models }
    
                    this.Create.MethodLoader = false
                })
            }
        },

        GetVersions () {
            this.Create.MethodLoader = true
            this.$http.get(`https://api.wheels.nl/vehicles/versions/create?model=${this.Create.ModelID}&type=personenauto&datum_eerste_toelating=${this.Create.Registration}`).then((response) => {
                if(response.data.versions) { this.Create.Versions.Items = response.data.versions }

                this.Create.MethodLoader = false
            })
        },

        GetOptions (e) {
            this.$http.get(`https://api.wheels.nl/vehicles/options/${e.auto_hash}`).then((response) => {
                if(response.data.options) {
                    e.Options = response.data.options
                    e.OptionsActive = true
                }
            })
        },

        CreateVehicle (e) {
            this.Create.Loader = true
            this.$http.post(`https://api.wheels.nl/vehicles`, {
                Registration: this.Create.Registration,
                Version: e.uitvoering_nummer,
                Type: this.Create.Type,
                Emissions: e.co2_uitstoot,
                Hash: e.auto_hash
            }).then((response) => {
                this.Create.Loader = false
                this.$router.push('/voertuigen/' + response.data.link)
            })
        },

        GetVIN () {
            this.Create.Loader = true
            this.$http.get(`https://api.wheels.nl/vehicles/vin/check?vin=${this.Create.VIN}&registration=${this.Create.Registration}`).then((response) => {
                this.Create.Loader = false
                if(response.data.link) { 
                    location.href = 'http://localhost:8080/voertuigen/' + response.data.link
                } else if(response.data.versions) {
                    if(response.data.versions) {
                        this.Create.Identifier = response.data.identifier
                        this.Create.Versions.Tip = response.data.tip
                        this.Create.Versions.Items = response.data.versions
                    }
                }
            })
        },

        CreateVIN (e) {
            this.Create.Loader = true
            this.$http.get(`https://api.wheels.nl/vehicles/vin/create?identifier=${this.Create.Identifier}&id=${e.data.uitvoering_nummer}&hash=${e.data.auto_hash}&registration=${this.Create.Registration}`).then((response) => {
                this.Create.Loader = false

                location.href = 'http://localhost:8080/voertuigen/' + response.data.link
            })
        },

        GetLicense () {
            this.Create.Loader = true
            this.$http.get(`https://api.wheels.nl/vehicles/license/check?license=${this.Create.License.Search}`).then((response) => {
                this.Create.Loader = false
                if(response.data.license) { this.Create.License.Item = response.data.license }
            })
        },

        CreateLicense () {
            this.Create.Loader = true
            this.$http.get(`https://api.wheels.nl/vehicles/license/create?license=${this.Create.License.Search}&mileage=${this.Create.Mileage}&type=${this.Create.License.Item.europese_voertuigcategorie}`).then((response) => {
                this.Create.Loader = false
                if(response.data.link) { 
                    location.href = 'http://localhost:8080/voertuigen/' + response.data.link
                }
            })
        },

        SetVin (e) {
            if(e && e.length == 4) {
                return 'Meldcode (' + e.length + ')'
            } else if(e && e.length > 0) {
                return 'Chassisnummer (' + e.length + ')'
            } else {
                return 'Chassisnummer'
            }
        },

        CloseModels () {
            setTimeout(() => {
                this.Create.Models.Active = false
            }, 100)
        },

        CheckVersionDate (e) {
            var Registration = this.$moment(this.Create.Registration, 'DD-MM-YYYY')
            var From = this.$moment((e).split('-')[0].trim(), 'MM-YY')
            var Till = this.$moment((e).split('-')[1].trim(), 'MM-YY')

            if(Registration.isBetween(From, Till)) {
                return false
            } else {
                return true
            }
        }
    },

    computed: {
        FilteredVersions () {
            var Versions = this.Create.Versions.Items

            if(this.Create.Versions.Filters.Search) {
                Versions = Versions.filter(e => e.Typenaam.toLowerCase().includes(this.Create.Versions.Filters.Search.toLowerCase()))
            }

            if(this.Create.Versions.Filters.Name) {
                Versions = Versions.filter(e => e.Typenaam.toLowerCase().includes(this.Create.Versions.Filters.Name.toLowerCase()))
            }

            if(this.Create.Versions.Filters.Fuel) {
                Versions = Versions.filter(e => e.brandstof == this.Create.Versions.Filters.Fuel)
            }

            if(this.Create.Versions.Filters.Gear) {
                Versions = Versions.filter(e => e.transmissie == this.Create.Versions.Filters.Gear)
            }

            if(this.Create.Versions.Filters.CC) {
                Versions = Versions.filter(e => e.cc == this.Create.Versions.Filters.CC)
            }

            if(this.Create.Versions.Filters.KW) {
                Versions = Versions.filter(e => e.kw == this.Create.Versions.Filters.KW)
            }

            if(this.Create.Versions.Filters.Carrosserie) {
                Versions = Versions.filter(e => e.carrosserie == this.Create.Versions.Filters.Carrosserie)
            }

            if(this.Create.Versions.Filters.Milieulabel) {
                Versions = Versions.filter(e => e.milieulabel == this.Create.Versions.Filters.Milieulabel)
            }

            if(this.Create.Versions.Filters.Emissiecode) {
                Versions = Versions.filter(e => e.emissiecode == this.Create.Versions.Filters.Emissiecode)
            }

            if(this.Create.Versions.Filters.Uitstoot) {
                Versions = Versions.filter(e => e.co2_uitstootitstoot == this.Create.Versions.Filters.Uitstoot)
            }

            if(this.Create.Versions.Filters.Price) {
                Versions = Versions.filter(e => e.catalogus_prijs.includes(this.Create.Versions.Filters.Price))
            }

            if(this.Create.Versions.Filters.Looptijd) {
                Versions = Versions.filter(e => e.looptijd == this.Create.Versions.Filters.Looptijd)
            }

            return Versions.sort(function(a, b){ return Number(a.catalogus_prijs) - Number(b.catalogus_prijs) })
        }
    }
}
</script>

<style scoped>
.create {
    max-width: 1400px;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 50px;
    margin: auto;
    transition: .5s;
    overflow: auto;
}

.create.full {
    max-width: 100%;
}

.create .back {
    display: flex;
    align-items: center;
    gap: 10px;
}

.create .back svg {
    width: 24px;
    height: 24px;
}

.create .groups {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.create .groups .group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.create .groups .group.full {
    width: 100%;
}

.create .groups .group .group-title {
    font-weight: 700;
    margin: 0 0 10px 0;
}

.create .groups .group .group-tip {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-weight: 600;
    background: #ffa500;
    color: #fff;
    border-radius: 5px;
}

.create .groups .group .group-search {
    padding: 0 !important;
    border: 0 !important;
}

.create .groups .group .group-button {
    height: 50px;
    font-weight: 600;
    background: var(--primary);
    color: #fff;
    border: solid 1px #f4f6f9;
    border-radius: 5px;
    cursor: pointer;
}

.create .groups .group .group-button.loader {
    pointer-events: none;
    opacity: 0.5;
}

.create .groups .group input, .create .groups .group select, .create .groups .group .group-input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.create .groups .group input.active {
    border-radius: 5px 5px 0 0;
}

.create .groups .group input.uppercase {
    text-transform: uppercase;
}

.create .groups .group .group-input:deep(input) {
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
}

.create .groups .group .method {
    display: flex;
    align-items: flex-start;
    gap: 15px;
}

.create .groups .group .method .method-item {
    position: relative;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px;
    background: #fff;
    border: solid 2px #ededed;
    border-radius: 5px;
    cursor: pointer;
}

.create .groups .group .method .method-item.active {
    border: solid 2px var(--primary);
}

.create .groups .group .method .method-item.disabled {
    opacity: 0.5;
}

.create .groups .group .method .method-item .bubble {
    width: fit-content;
    padding: 5px 15px;
    margin: 0 0 -15px 0;
    background: #f7f8fc;
    color: #333;
    letter-spacing: 5px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 5px;
}

.create .groups .group .method .method-item .method-item-title {
    width: fit-content;
    padding: 5px 15px;
    background: #f7f8fc;
    color: #333;
    font-weight: 700;
    font-size: 14px;
    border-radius: 5px;
}

.create .groups .group .method .method-item .method-item-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffffbf;
}

.create .groups .group .method .method-item .method-item-loader .method-item-loader-svg {
    width: 100px;
    animation: spin 2.5s linear infinite;
}

.create .groups .group .models {
    width: 100%;
    max-height: 250px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #ffffff;
    padding: 15px;
    margin: -6px 0 0 0;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-top: solid 1px #ededed;
    border-radius: 0 0 5px 5px;
    overflow: auto;
}

.create .groups .group .models .model-item {
    cursor: pointer;
}

.create .groups .group .models .model-item b {
    font-weight: 700;
}

.create .groups .group .group-license {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.create .groups .group .group-license .group-license-item {
    display: flex;
    flex-direction: column;
}

.create .groups .group .group-license .group-license-item .group-license-item-title {
    font-weight: 600;
}

.create .groups .group .group-license .group-license-item .group-license-item-button {
    width: fit-content;
    height: 50px;
    font-weight: 600;
    padding: 0 25px;
    background: #ffa500;
    color: #fff;
    border: solid 1px #f4f6f9;
    border-radius: 5px;
    cursor: pointer;
}

.create .groups .group .group-license .group-license-item .group-license-item-button.loader {
    pointer-events: none;
    opacity: 0.5;
}





.create .groups .group .versions-list {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    background: #fff;
    border: solid 1px #ededed;
    border-radius: 5px;
    overflow: auto;
}

.create .groups .group .versions-list:deep(.p-datatable-header) {
    border-radius: 5px 5px 0 0;
}

.create .groups .group .versions-list .versions-list-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
}

.create .groups .group .versions-list .versions-list-item .radio {
    min-width: 20px;
    min-height: 20px;
    border: solid 1px #e3e3e3;
    border-radius: 50%;
    transition: 0s;
}

.create .groups .group .versions-list .versions-list-item:hover .radio {
    background: #e3e3e3;
}

.create .groups .group .versions-list .versions-list-item.active .radio {
    background: var(--primary);
    border-color: var(--primary);
}

.create .groups .group .versions-list .versions-list-item .description {
    min-width: 300px;
}

.create .groups .group .versions-list .versions-list-item.active .description {
    font-weight: 700;
}

.create .groups .group .versions-list .versions-list-item .fuel {
    min-width: 150px;
    padding: 0 0 0 15px;
}

.create .groups .group .versions-list .versions-list-item .power {
    min-width: 100px;
    padding: 0 0 0 15px;
}

.create .groups .group .versions-list .versions-list-item .engine {
    min-width: 100px;
    padding: 0 0 0 15px;
}

.create .groups .group .versions-list .versions-list-item .transmission {
    min-width: 100px;
    padding: 0 0 0 15px;
}

.create .groups .group .versions-list .versions-list-item .drive {
    min-width: 100px;
    padding: 0 0 0 15px;
}

.create .groups .group .versions-list .versions-list-item .carrosserie {
    min-width: 100px;
    padding: 0 0 0 15px;
}

.create .groups .group .versions-list .versions-list-item .emissioncode {
    min-width: 100px;
    padding: 0 0 0 15px;
}

.create .groups .group .versions-list .versions-list-item .emission {
    min-width: 100px;
    padding: 0 0 0 15px;
}

.create .groups .group .versions-list .versions-list-item .price {
    min-width: 100px;
}

.create .groups .group .versions-list .versions-list-item .from {
    min-width: 100px;
    padding: 0 0 0 15px;
}





.create .create-versions {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.create .create-versions .create-versions-title {
    font-weight: 700;
}

.create .create-versions .create-versions-list {
    display: flex;
    flex-direction: column;
    padding: 25px;
    background: #fff;
    border: solid 2px #ededed;
    border-radius: 5px;
}

.create .create-versions .create-versions-list .create-versions-list-header {
    display: flex;
    gap: 15px;
    padding: 15px 25px;
    background: #f7f8fc;
    border-radius: 5px;
    overflow: auto;
}

.create .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.create .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item .create-versions-list-header-item-title {
    font-weight: 700;
}

.create .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.name {
    min-width: 300px;
    max-width: 300px;
}

.create .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.cc,
.create .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.power,
.create .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.emission,
.create .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.duration,
.create .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.price {
    min-width: 100px;
    max-width: 100px;
}

.create .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.fuel,
.create .create-versions .create-versions-list .create-versions-list-header .create-versions-list-header-item.gear {
    min-width: 200px;
    max-width: 200px;
}

.create .create-versions .create-versions-list .create-versions-list-select {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
}

.create .create-versions .create-versions-list .create-versions-list-select .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.create .create-versions .create-versions-list .create-versions-list-select .checkbox .checked {
    width: 18px;
    height: 18px;
    background: var(--primary);
    border-radius: 2.5px;
}

.create .create-versions .create-versions-list .create-versions-list-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px 0 0 0;
    overflow: auto;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row.outdated {
    opacity: .5;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row.hidden {
    display: none;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items {
    display: flex;
    align-items: center;
    gap: 15px;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.open {
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    cursor: pointer;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.name {
    min-width: 300px;
    max-width: 300px;
    font-weight: 700;
    cursor: pointer;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.name:hover {
    color: var(--primary);
    text-decoration: underline;
    text-underline-offset: 5px;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.cc,
.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.power,
.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.emission,
.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.duration,
.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.price {
    min-width: 100px;
    max-width: 100px;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.fuel,
.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-items .create-versions-list-items-row-item.gear {
    min-width: 200px;
    max-width: 200px;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-options {
    column-count: 4;
    column-gap: 40px;
    padding: 0 0 0 30px;
    opacity: 0.5;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-options .create-versions-list-items-row-options-option {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.create .create-versions .create-versions-list .create-versions-list-items .create-versions-list-items-row .create-versions-list-items-row-options .create-versions-list-items-row-options-option::first-letter {
    text-transform: capitalize;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>