<template>
    <div class="website">
        <div class="website-menu">
            <span class="item" :class="{ active: Menu == 'Primary' }" @click="Menu = 'Primary', GetMenus()">Hoofdmenu</span>
            <span class="item" :class="{ active: Menu == 'SubPrimary' }" @click="Menu = 'SubPrimary', GetMenus()">Secundair</span>
            <span class="item" :class="{ active: Menu == 'Top' }" @click="Menu = 'Top', GetMenus()">Top</span>
            <span class="item" :class="{ active: Menu == 'Message' }" @click="Menu = 'Message', GetMenus()">Aankondiging</span>
            <span class="item" :class="{ active: Menu == 'Footer' }" @click="Menu = 'Footer', GetMenus()">Footer</span>
            <span class="item" :class="{ active: Menu == 'SubFooter' }" @click="Menu = 'SubFooter', GetMenus()">Sub-Footer</span>
            <span class="save" @click="UpdateMenus">Opslaan</span>
        </div>
        <div class="website-block" v-if="Menu == 'Primary'">
            <div class="items">
                <div class="item" v-for="(Item, i) in Primary.Items" :key="i">
                    <span v-if="Item.Title" class="title">{{ Item.Title }}</span>
                    <span v-else class="title">Titel</span>
                    <div class="item-shortcuts">
                        <svg class="left" :class="{ disabled: i == 0 }" @click="OrderMenuItem(i, i - 1, 'Primary')" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        <svg class="right" :class="{ disabled: i == Primary.Items.length - 1 }" @click="OrderMenuItem(i, i + 1, 'Primary')" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                    </div>
                    <div class="input">
                        <span>Titel</span>
                        <input type="text" v-model="Item.Title" placeholder="Bijv. Fietsen">
                    </div>
                    <div class="input">
                        <span>Pagina</span>
                        <input type="text" v-model="Item.Link" placeholder="Bijv. /stadfietsen">
                    </div>
                    <div class="input">
                        <span>Kleur</span>
                        <input type="text" v-model="Item.Color" placeholder="Bijv. #e0e0e0">
                    </div>
                    <div class="item-menu">
                        <span class="more" @click="SelectMenuItem(false, 'Primary')" v-if="Primary.ActiveSub == Item">Inklappen</span>
                        <span class="more" @click="SelectMenuItem(Item, 'Primary')" v-else>Uitklappen</span>
                        <span class="delete" @click="DeleteMenuItem(Item, 'Primary')">Verwijderen</span>
                    </div>
                </div>
                <div class="create" @click="CreateMenuItem('Primary')">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#333333"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    <span>Nieuw menu item</span>
                </div>
            </div>
            <div class="columns" v-if="Primary.ActiveSub">
                <div class="column">
                    <div class="item" v-for="(SubItem, i) in Primary.ActiveSub.SubItems.First" :key="i">
                        <span v-if="SubItem.Title" class="title">{{ SubItem.Title }}</span>
                        <span v-else class="title">Titel</span>
                        <div class="item-shortcuts">
                            <svg class="left" :class="{ disabled: i == 0 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.First, i, i - 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                            <svg class="right" :class="{ disabled: i == Primary.ActiveSub.SubItems.First.length - 1 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.First, i, i + 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        </div>
                        <div class="input">
                            <span>Soort</span>
                            <select v-model="SubItem.Type">
                                <option value="Link">Link</option>
                                <option value="Text">Text</option>
                                <option value="Empty">Leeg</option>
                            </select>
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Titel</span>
                            <input type="text" v-model="SubItem.Title">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Text' || SubItem.Type == 'Empty' }">
                            <span>Link</span>
                            <input type="text" v-model="SubItem.Link">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Kleur</span>
                            <input type="text" v-model="SubItem.Color">
                        </div>
                        <div class="checkbox">
                            <div class="checked" :class="{ active: SubItem.Tip }" @click="SubItem.Tip = !SubItem.Tip"></div>
                            <span>Tip</span>
                        </div>
                        <span class="delete" @click="DeleteSubMenuItem(Primary.ActiveSub.SubItems.First, i)">Verwijderen</span>
                    </div>
                    <div class="create" v-if="Primary.ActiveSub.SubItems.First.length <= 20" @click="CreateSubMenuItem(Primary.ActiveSub.SubItems.First)">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="var(--primary)"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    </div>
                </div>
                <div class="column">
                    <div class="item" v-for="(SubItem, i) in Primary.ActiveSub.SubItems.Second" :key="i">
                        <span v-if="SubItem.Title" class="title">{{ SubItem.Title }}</span>
                        <span v-else class="title">Titel</span>
                        <div class="item-shortcuts">
                            <svg class="left" :class="{ disabled: i == 0 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Second, i, i - 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                            <svg class="right" :class="{ disabled: i == Primary.ActiveSub.SubItems.Second.length - 1 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Second, i, i + 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        </div>
                        <div class="input">
                            <span>Soort</span>
                            <select v-model="SubItem.Type">
                                <option value="Link">Link</option>
                                <option value="Text">Text</option>
                                <option value="Empty">Leeg</option>
                            </select>
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Titel</span>
                            <input type="text" v-model="SubItem.Title">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Text' || SubItem.Type == 'Empty' }">
                            <span>Link</span>
                            <input type="text" v-model="SubItem.Link">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Kleur</span>
                            <input type="text" v-model="SubItem.Color">
                        </div>
                        <div class="checkbox">
                            <div class="checked" :class="{ active: SubItem.Tip }" @click="SubItem.Tip = !SubItem.Tip"></div>
                            <span>Tip</span>
                        </div>
                        <span class="delete" @click="DeleteSubMenuItem(Primary.ActiveSub.SubItems.Second, i)">Verwijderen</span>
                    </div>
                    <div class="create" v-if="Primary.ActiveSub.SubItems.Second.length <= 20" @click="CreateSubMenuItem(Primary.ActiveSub.SubItems.Second)">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="var(--primary)"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    </div>
                </div>
                <div class="column">
                    <div class="item" v-for="(SubItem, i) in Primary.ActiveSub.SubItems.Third" :key="i">
                        <span v-if="SubItem.Title" class="title">{{ SubItem.Title }}</span>
                        <span v-else class="title">Titel</span>
                        <div class="item-shortcuts">
                            <svg class="left" :class="{ disabled: i == 0 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Third, i, i - 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                            <svg class="right" :class="{ disabled: i == Primary.ActiveSub.SubItems.Third.length - 1 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Third, i, i + 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        </div>
                        <div class="input">
                            <span>Soort</span>
                            <select v-model="SubItem.Type">
                                <option value="Link">Link</option>
                                <option value="Text">Text</option>
                                <option value="Empty">Leeg</option>
                            </select>
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Titel</span>
                            <input type="text" v-model="SubItem.Title">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Text' || SubItem.Type == 'Empty' }">
                            <span>Link</span>
                            <input type="text" v-model="SubItem.Link">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Kleur</span>
                            <input type="text" v-model="SubItem.Color">
                        </div>
                        <div class="checkbox">
                            <div class="checked" :class="{ active: SubItem.Tip }" @click="SubItem.Tip = !SubItem.Tip"></div>
                            <span>Tip</span>
                        </div>
                        <span class="delete" @click="DeleteSubMenuItem(Primary.ActiveSub.SubItems.Third, i)">Verwijderen</span>
                    </div>
                    <div class="create" v-if="Primary.ActiveSub.SubItems.Third.length <= 20" @click="CreateSubMenuItem(Primary.ActiveSub.SubItems.Third)">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="var(--primary)"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    </div>
                </div>
                <div class="column">
                    <div class="item" v-for="(SubItem, i) in Primary.ActiveSub.SubItems.Fourth" :key="i">
                        <span v-if="SubItem.Title" class="title">{{ SubItem.Title }}</span>
                        <span v-else class="title">Titel</span>
                        <div class="item-shortcuts">
                            <svg class="left" :class="{ disabled: i == 0 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Fourth, i, i - 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                            <svg class="right" :class="{ disabled: i == Primary.ActiveSub.SubItems.Fourth.length - 1 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Fourth, i, i + 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        </div>
                        <div class="input">
                            <span>Soort</span>
                            <select v-model="SubItem.Type">
                                <option value="Link">Link</option>
                                <option value="Text">Text</option>
                                <option value="Empty">Leeg</option>
                            </select>
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Titel</span>
                            <input type="text" v-model="SubItem.Title">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Text' || SubItem.Type == 'Empty' }">
                            <span>Link</span>
                            <input type="text" v-model="SubItem.Link">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Kleur</span>
                            <input type="text" v-model="SubItem.Color">
                        </div>
                        <div class="checkbox">
                            <div class="checked" :class="{ active: SubItem.Tip }" @click="SubItem.Tip = !SubItem.Tip"></div>
                            <span>Tip</span>
                        </div>
                        <span class="delete" @click="DeleteSubMenuItem(Primary.ActiveSub.SubItems.Fourth, i)">Verwijderen</span>
                    </div>
                    <div class="create" v-if="Primary.ActiveSub.SubItems.Fourth.length <= 20" @click="CreateSubMenuItem(Primary.ActiveSub.SubItems.Fourth)">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="var(--primary)"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="website-block" v-if="Menu == 'SubPrimary'">
            <div class="items">
                <div class="item">
                    <span class="title">Button 1</span>
                    <div class="input">
                        <span class="subtitle">Type</span>
                        <select v-model="SubPrimary.FirstButtonType">
                            <option value="Text">Tekst</option>
                            <option value="Opening">Openingstijden</option>
                        </select>
                    </div>
                    <div class="input">
                        <span class="subtitle">Tekst</span>
                        <input type="text" v-model="SubPrimary.FirstButtonText">
                    </div>
                    <div class="input">
                        <span class="subtitle">Link</span>
                        <input type="text" v-model="SubPrimary.FirstButtonLink">
                    </div>
                    <div class="hours" v-if="SubPrimary.FirstButtonType == 'Opening'">
                        <div class="day">
                            <span class="subtitle">Maandag</span>
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Monday.From">
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Monday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Dinsdag</span>
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Tuesday.From">
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Tuesday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Woensday</span>
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Wednesday.From">
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Wednesday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Donderdag</span>
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Thursday.From">
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Thursday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Vrijdag</span>
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Friday.From">
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Friday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Zaterdag</span>
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Saturday.From">
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Saturday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Zondag</span>
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Sunday.From">
                            <input type="text" v-model="SubPrimary.FirstButtonHours.Sunday.Till">
                        </div>
                    </div>
                </div>
                <div class="item">
                    <span class="title">Button 2</span>
                    <div class="input">
                        <span class="subtitle">Type</span>
                        <select v-model="SubPrimary.SecondButtonType">
                            <option value="Text">Tekst</option>
                            <option value="Opening">Openingstijden</option>
                        </select>
                    </div>
                    <div class="input">
                        <span class="subtitle">Tekst</span>
                        <input type="text" v-model="SubPrimary.SecondButtonText">
                    </div>
                    <div class="input">
                        <span class="subtitle">Link</span>
                        <input type="text" v-model="SubPrimary.SecondButtonLink">
                    </div>
                    <div class="hours" v-if="SubPrimary.SecondButtonType == 'Opening'">
                        <div class="day">
                            <span class="subtitle">Maandag</span>
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Monday.From">
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Monday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Dinsdag</span>
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Tuesday.From">
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Tuesday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Woensday</span>
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Wednesday.From">
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Wednesday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Donderdag</span>
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Thursday.From">
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Thursday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Vrijdag</span>
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Friday.From">
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Friday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Zaterdag</span>
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Saturday.From">
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Saturday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Zondag</span>
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Sunday.From">
                            <input type="text" v-model="SubPrimary.SecondButtonHours.Sunday.Till">
                        </div>
                    </div>
                </div>
                <div class="item">
                    <span class="title">Button 3</span>
                    <div class="input">
                        <span class="subtitle">Type</span>
                        <select v-model="SubPrimary.ThirdButtonType">
                            <option value="Text">Tekst</option>
                            <option value="Opening">Openingstijden</option>
                        </select>
                    </div>
                    <div class="input">
                        <span class="subtitle">Tekst</span>
                        <input type="text" v-model="SubPrimary.ThirdButtonText">
                    </div>
                    <div class="input">
                        <span class="subtitle">Link</span>
                        <input type="text" v-model="SubPrimary.ThirdButtonLink">
                    </div>
                    <div class="hours" v-if="SubPrimary.ThirdButtonType == 'Opening'">
                        <div class="day">
                            <span class="subtitle">Maandag</span>
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Monday.From">
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Monday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Dinsdag</span>
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Tuesday.From">
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Tuesday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Woensday</span>
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Wednesday.From">
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Wednesday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Donderdag</span>
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Thursday.From">
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Thursday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Vrijdag</span>
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Friday.From">
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Friday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Zaterdag</span>
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Saturday.From">
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Saturday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Zondag</span>
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Sunday.From">
                            <input type="text" v-model="SubPrimary.ThirdButtonHours.Sunday.Till">
                        </div>
                    </div>
                </div>
                <div class="item">
                    <span class="title">Button 4</span>
                    <div class="input">
                        <span class="subtitle">Type</span>
                        <select v-model="SubPrimary.FourthButtonType">
                            <option value="Text">Tekst</option>
                            <option value="Opening">Openingstijden</option>
                        </select>
                    </div>
                    <div class="input">
                        <span class="subtitle">Tekst</span>
                        <input type="text" v-model="SubPrimary.FourthButtonText">
                    </div>
                    <div class="input">
                        <span class="subtitle">Link</span>
                        <input type="text" v-model="SubPrimary.FourthButtonLink">
                    </div>
                    <div class="hours" v-if="SubPrimary.FourthButtonType == 'Opening'">
                        <div class="day">
                            <span class="subtitle">Maandag</span>
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Monday.From">
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Monday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Dinsdag</span>
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Tuesday.From">
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Tuesday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Woensday</span>
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Wednesday.From">
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Wednesday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Donderdag</span>
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Thursday.From">
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Thursday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Vrijdag</span>
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Friday.From">
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Friday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Zaterdag</span>
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Saturday.From">
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Saturday.Till">
                        </div>
                        <div class="day">
                            <span class="subtitle">Zondag</span>
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Sunday.From">
                            <input type="text" v-model="SubPrimary.FourthButtonHours.Sunday.Till">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="website-block" v-if="Menu == 'Top'">
            <div class="top-item">
                <span class="title">Regels</span>
                <div class="top-item-row" v-for="(Item, i) in Top.Menu" :key="i">
                    <input type="text" v-model="Item.Text">
                    <button @click="RemoveTopMenu(Item)" class="remove">Verwijderen</button>
                </div>
                <div class="top-item-row">
                    <input type="text" v-model="TopMenuAdd">
                    <button @click="AddTopMenu" class="add">Toevoegen</button>
                </div>
            </div>
            <div class="top-item">
                <span class="title">Achtergrond</span>
                <div class="top-item-row">
                    <input type="text" v-model="Top.Background">
                </div>
            </div>
            <div class="top-item">
                <span class="title">Kleur</span>
                <div class="top-item-row">
                    <input type="text" v-model="Top.Color">
                </div>
            </div>
        </div>
        <div class="website-block" v-if="Menu == 'SubFooter'">
            <div class="items">
                <div class="item">
                    <span class="title">Opmaak</span>
                    <div class="input">
                        <span class="subtitle">ID</span>
                        <input type="text" v-model="SubFooter.Picture">
                    </div>
                    <div class="input">
                        <span class="subtitle">BG</span>
                        <input type="text" v-model="SubFooter.Background">
                    </div>
                    <div class="input">
                        <span class="subtitle">Kleur</span>
                        <input type="text" v-model="SubFooter.Color">
                    </div>
                </div>
                <div class="item">
                    <span class="title">Button 1</span>
                    <div class="input">
                        <span class="subtitle">SVG</span>
                        <input type="text" v-model="SubFooter.FirstButtonSVG">
                    </div>
                    <div class="input">
                        <span class="subtitle">Tekst</span>
                        <input type="text" v-model="SubFooter.FirstButtonText">
                    </div>
                    <div class="input">
                        <span class="subtitle">Link</span>
                        <input type="text" v-model="SubFooter.FirstButtonLink">
                    </div>
                </div>
                <div class="item">
                    <span class="title">Button 2</span>
                    <div class="input">
                        <span class="subtitle">SVG</span>
                        <input type="text" v-model="SubFooter.SecondButtonSVG">
                    </div>
                    <div class="input">
                        <span class="subtitle">Tekst</span>
                        <input type="text" v-model="SubFooter.SecondButtonText">
                    </div>
                    <div class="input">
                        <span class="subtitle">Link</span>
                        <input type="text" v-model="SubFooter.SecondButtonLink">
                    </div>
                </div>
                <div class="item">
                    <span class="title">Button 3</span>
                    <div class="input">
                        <span class="subtitle">SVG</span>
                        <input type="text" v-model="SubFooter.ThirdButtonSVG">
                    </div>
                    <div class="input">
                        <span class="subtitle">Tekst</span>
                        <input type="text" v-model="SubFooter.ThirdButtonText">
                    </div>
                    <div class="input">
                        <span class="subtitle">Link</span>
                        <input type="text" v-model="SubFooter.ThirdButtonLink">
                    </div>
                </div>
            </div>
        </div>
        <div class="website-block" v-if="Menu == 'Footer'">
            <div class="items">
                <div class="item" v-for="(Item, i) in Footer.Items" :key="i">
                    <span v-if="Item.Title" class="title">{{ Item.Title }}</span>
                    <span v-else class="title">Titel</span>
                    <div class="item-shortcuts">
                        <svg class="left" :class="{ disabled: i == 0 }" @click="OrderMenuItem(i, i - 1, 'Footer')" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        <svg class="right" :class="{ disabled: i == Footer.Items.length - 1 }" @click="OrderMenuItem(i, i + 1, 'Footer')" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                    </div>
                    <div class="input">
                        <span>Titel</span>
                        <input type="text" v-model="Item.Title" placeholder="Bijv. Fietsen">
                    </div>
                    <div class="input">
                        <span>Pagina</span>
                        <input type="text" v-model="Item.Link" placeholder="Bijv. /stadfietsen">
                    </div>
                    <div class="input">
                        <span>Kleur</span>
                        <input type="text" v-model="Item.Color" placeholder="Bijv. #e0e0e0">
                    </div>
                    <div class="item-menu">
                        <span class="more" @click="SelectMenuItem(false, 'Footer')" v-if="Footer.ActiveSub == Item">Inklappen</span>
                        <span class="more" @click="SelectMenuItem(Item, 'Footer')" v-else>Uitklappen</span>
                        <span class="delete" @click="DeleteMenuItem(Item, 'Footer')">Verwijderen</span>
                    </div>
                </div>
                <div class="create" @click="CreateMenuItem('Footer')">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#333333"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    <span>Nieuw menu item</span>
                </div>
            </div>
            <div class="columns" v-if="Footer.ActiveSub">
                <div class="column full">
                    <div class="item" v-for="(SubItem, i) in Footer.ActiveSub.SubItems.Fourth" :key="i">
                        <span v-if="SubItem.Title" class="title">{{ SubItem.Title }}</span>
                        <span v-else class="title">Titel</span>
                        <div class="item-shortcuts">
                            <svg class="left" :class="{ disabled: i == 0 }" @click="OrderSubMenuItem(Footer.ActiveSub.SubItems.Fourth, i, i - 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                            <svg class="right" :class="{ disabled: i == Footer.ActiveSub.SubItems.Fourth.length - 1 }" @click="OrderSubMenuItem(Footer.ActiveSub.SubItems.Fourth, i, i + 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        </div>
                        <div class="input">
                            <span>Soort</span>
                            <select v-model="SubItem.Type">
                                <option value="Link">Link</option>
                                <option value="Text">Text</option>
                                <option value="Stars">Sterren</option>
                                <option value="Empty">Leeg</option>
                            </select>
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' || SubItem.Type == 'Stars' }">
                            <span>Titel</span>
                            <input type="text" v-model="SubItem.Title">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Text' || SubItem.Type == 'Empty' || SubItem.Type == 'Stars' }">
                            <span>Link</span>
                            <input type="text" v-model="SubItem.Link">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' || SubItem.Type == 'Stars' }">
                            <span>Kleur</span>
                            <input type="text" v-model="SubItem.Color">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' || SubItem.Type == 'Stars' }">
                            <span>Bold</span>
                            <select v-model="SubItem.Bold">
                                <option value="0">Uit</option>
                                <option value="1">Aan</option>
                            </select>
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Text' || SubItem.Type == 'Empty' }">
                            <span>Aantal</span>
                            <select v-model="SubItem.Stars">
                                <option value="1">1</option>
                                <option value="1.5">1.5</option>
                                <option value="2">2</option>
                                <option value="2.5">2.5</option>
                                <option value="3">3</option>
                                <option value="3.5">3.5</option>
                                <option value="4">4</option>
                                <option value="4.5">4.5</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                        <div class="checkbox" :class="{ disabled: SubItem.Type == 'Empty' || SubItem.Type == 'Stars' }">
                            <div class="checked" :class="{ active: SubItem.Tip }" @click="SubItem.Tip = !SubItem.Tip"></div>
                            <span>Tip</span>
                        </div>
                        <span class="delete" @click="DeleteSubMenuItem(Footer.ActiveSub.SubItems.Fourth, i)">Verwijderen</span>
                    </div>
                    <div class="create" v-if="Footer.ActiveSub.SubItems.Fourth.length <= 20" @click="CreateSubMenuItem(Footer.ActiveSub.SubItems.Fourth)">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="var(--primary)"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WebsiteOverview',

    data () {
        return {
            Loader: false,
            Menu: 'Primary',
            Primary: {
                Active: 'Primary',
                ActiveSub: false,
                Items: []
            },
            SubPrimary: {
                FirstButtonType: 'Text',
                FirstButtonText: '',
                FirstButtonLink: '',
                FirstButtonHours: {
                    Monday: {
                        From: '',
                        Till: ''
                    },
                    Tuesday: {
                        From: '',
                        Till: ''
                    },
                    Wednesday: {
                        From: '',
                        Till: ''
                    },
                    Thursday: {
                        From: '',
                        Till: ''
                    },
                    Friday: {
                        From: '',
                        Till: ''
                    },
                    Saturday: {
                        From: '',
                        Till: ''
                    },
                    Sunday: {
                        From: '',
                        Till: ''
                    }
                },
                SecondButtonType: 'Text',
                SecondButtonText: '',
                SecondButtonLink: '',
                SecondButtonHours: {
                    Monday: {
                        From: '',
                        Till: ''
                    },
                    Tuesday: {
                        From: '',
                        Till: ''
                    },
                    Wednesday: {
                        From: '',
                        Till: ''
                    },
                    Thursday: {
                        From: '',
                        Till: ''
                    },
                    Friday: {
                        From: '',
                        Till: ''
                    },
                    Saturday: {
                        From: '',
                        Till: ''
                    },
                    Sunday: {
                        From: '',
                        Till: ''
                    }
                },
                ThirdButtonType: 'Text',
                ThirdButtonText: '',
                ThirdButtonLink: '',
                ThirdButtonHours: {
                    Monday: {
                        From: '',
                        Till: ''
                    },
                    Tuesday: {
                        From: '',
                        Till: ''
                    },
                    Wednesday: {
                        From: '',
                        Till: ''
                    },
                    Thursday: {
                        From: '',
                        Till: ''
                    },
                    Friday: {
                        From: '',
                        Till: ''
                    },
                    Saturday: {
                        From: '',
                        Till: ''
                    },
                    Sunday: {
                        From: '',
                        Till: ''
                    }
                },
                FourthButtonType: 'Text',
                FourthButtonText: '',
                FourthButtonLink: '',
                FourthButtonHours: {
                    Monday: {
                        From: '',
                        Till: ''
                    },
                    Tuesday: {
                        From: '',
                        Till: ''
                    },
                    Wednesday: {
                        From: '',
                        Till: ''
                    },
                    Thursday: {
                        From: '',
                        Till: ''
                    },
                    Friday: {
                        From: '',
                        Till: ''
                    },
                    Saturday: {
                        From: '',
                        Till: ''
                    },
                    Sunday: {
                        From: '',
                        Till: ''
                    }
                }
            },
            Top: {
                Menu: [],
                Background: '',
                Color: ''
            },
            Footer: {
                Active: 'Footer',
                ActiveSub: false,
                Items: []
            },
            SubFooter: {
                Picture: '',
                Background: '',
                Color: '',
                FirstButtonSVG: '',
                FirstButtonText: '',
                FirstButtonLink: '',
                SecondButtonSVG: '',
                SecondButtonText: '',
                SecondButtonLink: '',
                ThirdButtonSVG: '',
                ThirdButtonText: '',
                ThirdButtonLink: ''
            }
        }
    },

    mounted () {
        this.GetMenus()
    },

    methods: {
        GetMenus () {
            this.$http.get(`https://api.wheels.nl/pages/menu/${this.Menu}`).then((response) => {
                if(response.data.primary) { this.Primary.Items = response.data.primary }
                if(response.data.subprimary) { this.SubPrimary = response.data.subprimary }
                if(response.data.footer) { this.Footer.Items = response.data.footer }
                if(response.data.subfooter) { this.SubFooter = response.data.subfooter }
                if(response.data.top) {
                    this.Top.Menu = response.data.top.Menu
                    this.Top.Background = response.data.top.Background
                    this.Top.Color = response.data.top.Color
                }
            })
        },

        CreateMenuItem (e) {
            this[e].Items.push({
                Title: '',
                Link: '',
                Color: '',
                SubItems: {
                    First: [],
                    Second: [],
                    Third: [],
                    Fourth: []
                }
            })
        },

        OrderMenuItem (e, a, z) {
            var tmp = this[z].Items[e];
            this[z].Items[e] = this[z].Items[a];
            this[z].Items[a] = tmp;
        },

        SelectMenuItem (e, a) {
            if(e) {
                this[a].ActiveSub = e
            } else {
                this[a].ActiveSub = false
            }
        },

        DeleteMenuItem (e, a) {
            this[a].Items = this[a].Items.filter(item => item !== e)
        },

        CreateSubMenuItem (e) {
            e.push({
                Type: 'Link',
                Title: '',
                Link: '',
                Color: '',
                Tip: false
            })
        },

        OrderSubMenuItem (e, a, z) {
            var tmp = e[a];
            e[a] = e[z];
            e[z] = tmp;
        },

        DeleteSubMenuItem (e, a) {            
            e.splice(a, 1)
        },

        AddTopMenu () {
            this.Top.Menu.push({
                Text: this.TopMenuAdd
            })

            this.TopMenuAdd = ''
        },

        RemoveTopMenu (e) {
            this.Top.Menu = this.Top.Menu.filter(item => item !== e)
        },

        UpdateMenus () {
            if(this.Menu == 'Primary') {
                this.$http.patch('https://api.wheels.nl/pages/menu', {
                    Active: 'Primary',
                    Items: this.Primary.Items
                }).then(() => {
                    this.$toast.open({
                        message: 'Menu opgeslagen',
                        position: 'top-right',
                        type: 'success'
                    })
                })
            } else if(this.Menu == 'Top') {
                this.$http.patch('https://api.wheels.nl/pages/menu', {
                    Active: 'Top',
                    Menu: this.Top.Menu,
                    Background: this.Top.Background,
                    Color: this.Top.Color
                }).then(() => {
                    this.$toast.open({
                        message: 'Top Menu opgeslagen',
                        position: 'top-right',
                        type: 'success'
                    })
                })
            } else if(this.Menu == 'Footer') {
                this.$http.patch('https://api.wheels.nl/pages/menu', {
                    Active: 'Footer',
                    Items: this.Footer.Items
                }).then(() => {
                    this.$toast.open({
                        message: 'Footer opgeslagen',
                        position: 'top-right',
                        type: 'success'
                    })
                })
            } else if(this.Menu == 'SubFooter') {
                this.$http.patch('https://api.wheels.nl/pages/menu', {
                    Active: 'SubFooter',
                    Items: this.SubFooter
                }).then(() => {
                    this.$toast.open({
                        message: 'SubFooter opgeslagen',
                        position: 'top-right',
                        type: 'success'
                    })
                })
            } else if(this.Menu == 'SubPrimary') {
                this.$http.patch('https://api.wheels.nl/pages/menu', {
                    Active: 'SubPrimary',
                    Items: this.SubPrimary
                }).then(() => {
                    this.$toast.open({
                        message: 'SubPrimary opgeslagen',
                        position: 'top-right',
                        type: 'success'
                    })
                })
            }
        }
    }
}
</script>

<style scoped>
.website {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    height: calc(100% - 80px);
    padding: 50px;
    background: #f7f8fc;
    overflow: auto;
}

.website .website-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.website .website-menu .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 25px;
    border-bottom: solid 2px #ededed;
    cursor: pointer;
}

.website .website-menu .item.active {
    color: var(--primary);
    font-weight: 700;
    border-bottom: solid 2px var(--primary);
}

.website .website-menu .save {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    min-width: 250px;
    padding: 0 15px;
    margin: 0 0 0 25px;
    background: var(--primary);
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
}

.website .website-block {
    display: flex;
    flex-direction: column;
    gap: 50px 15px;
    padding: 25px;
    background: #fff;
    border-radius: 10px;
}

.website .website-block .top-item {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.website .website-block .top-item .title {
    font-size: 20px;
    font-weight: 700;
}

.website .website-block .top-item .top-item-row {
    display: flex;
    gap: 15px;
}

.website .website-block .top-item .top-item-row input {
    width: 100%;
    padding: 5px;
    border: solid 1px #f7f8fc;
    border-radius: 5px;
}

.website .website-block .top-item .top-item-row button {
    width: 120px;
    padding: 5px;
    background: transparent;
    color: #fff;
    border: solid 1px #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.website .website-block .top-item .top-item-row button.remove {
    background: #cd5c5c;
}

.website .website-block .top-item .top-item-row button.add {
    background: var(--primary);
}

.website .website-block .top-item .top-item-row input {
    width: 100%;
    padding: 5px;
    border: solid 1px #f7f8fc;
    border-radius: 5px;
}

.website .website-block .activate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 40px;
    padding: 0 15px;
    background: #f7f8fc;
    color: #333;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
}

.website .website-block .activate.active {
    background: #8fbc8f;
    color: #fff;
}

.website .website-block .divider {
    margin: 15px 0;
    border-bottom: solid 1px #f7f8fc;
}

.website .website-block .items {
    display: flex;
    gap: 15px;
    padding: 0 0 5px 0;
    overflow-x: auto;
}

.website .website-block .items::-webkit-scrollbar {
    height: 5px;
    background: transparent;
    border-radius: 5px;
}

.website .website-block .items::-webkit-scrollbar-thumb {
    background: #ededed;
    border-radius: 5px;
    cursor: pointer;
}

.website .website-block .items::-webkit-scrollbar-thumb:hover {
    background: #333;
}

.website .website-block .items .item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 350px;
    padding: 25px;
    background: #f7f8fc;
    border-radius: 10px;
}

.website .website-block .items .item .title {
    margin: 0 0 25px 0;
    font-size: 20px;
    font-weight: 700;
}

.website .website-block .items .item .item-shortcuts {
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    gap: 10px;
}

.website .website-block .items .item .item-shortcuts .left {
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.website .website-block .items .item .item-shortcuts .right {
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    transform: rotate(180deg);
    cursor: pointer;
}

.website .website-block .items .item .item-shortcuts .left.disabled, .website .website-block .items .item .item-shortcuts .right.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.website .website-block .items .item .input {
    display: flex;
    align-items: center;
}

.website .website-block .items .item .input span {
    width: 75px;
}

.website .website-block .items .item .input input, .website .website-block .items .item .input select {
    width: 250px;
    padding: 10px;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.website .website-block .items .item .hours {
    width: 325px;
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    margin: 25px 0 0 0;
    padding: 15px;
    background: #e0e0e0;
    border-radius: 5px;
}

.website .website-block .items .item .hours .day {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 20px;
}

.website .website-block .items .item .hours .day .subtitle {
    width: 100%;
}

.website .website-block .items .item .hours .day input {
    width: calc(50% - 10px);
    padding: 10px;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.website .website-block .items .item .item-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 25px 0 0 0;
}

.website .website-block .items .item .item-menu .more {
    color: var(--primary);
    font-weight: 500;
    cursor: pointer;
}

.website .website-block .items .item .item-menu .delete {
    color: #cd5c5c;
    cursor: pointer;
}

.website .website-block .items .create {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    min-width: 300px;
    min-height: 350px;
    padding: 25px;
    background: #f7f8fc;
    border-radius: 10px;
    cursor: pointer;
}

.website .website-block .items .create svg {
    width: 50px;
    height: 50px;
}

.website .website-block .columns {
    display: flex;
    gap: 25px;
    padding: 25px;
    background: #f7f8fc;
    border-radius: 10px;
}

.website .website-block .columns .column {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 25%;
    padding: 25px;
    background: #fff;
    border-radius: 10px;
}

.website .website-block .columns .column.full {
    width: 100%;
}

.website .website-block .columns .column .item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px;
    background: #f7f8fc;
    border-radius: 10px;
}

.website .website-block .columns .column .item .title {
    font-size: 20px;
    font-weight: 500;
}

.website .website-block .columns .column .item .item-shortcuts {
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    gap: 10px;
}

.website .website-block .columns .column .item .item-shortcuts .left {
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    transform: rotate(90deg);
    cursor: pointer;
}

.website .website-block .columns .column .item .item-shortcuts .right {
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    transform: rotate(270deg);
    cursor: pointer;
}

.website .website-block .columns .column .item .item-shortcuts .left.disabled, .website .website-block .columns .column .item .item-shortcuts .right.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.website .website-block .columns .column .item .input {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.website .website-block .columns .column .item .input.disabled, .website .website-block .columns .column .item .checkbox.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.website .website-block .columns .column .item .input input, .website .website-block .columns .column .item .input select {
    height: 50px;
    padding: 10px;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.website .website-block .columns .column .item .checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
}

.website .website-block .columns .column .item .checkbox .checked {
    width: 30px;
    height: 30px;
    background: #fff;
    border: solid 1px #ededed;
    border-radius: 5px;
    cursor: pointer;
}

.website .website-block .columns .column .item .checkbox .checked.active {
    background: #009896;
    outline: solid 2px #ffffff;
}

.website .website-block .columns .column .item .checkbox span {
    height: 20px;
    display: flex;
    width: fit-content;
    align-items: center;
    padding: 0 10px;
    font-weight: 700;
    font-size: 12px;
    background: #009896;
    color: #fff;
    border-radius: 5px;
}

.website .website-block .columns .column .item .delete {
    margin: 0 0 0 auto;
    color: #cd5c5c;
    cursor: pointer;
}

.website .website-block .columns .column .create {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border: dashed 1px #333333;
    border-radius: 10px;
    cursor: pointer;
}

.website .website-block .columns .column .create svg {
    width: 50px;
    height: 50px;
    stroke: var(--primary);
}
</style>