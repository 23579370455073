<template>
    <div class="dashboard">
        <div class="left">
            <Counters :Counters="Counters" />
            <Tasks :Tasks="Tasks" v-if="Tasks.length > 0" />
            <News v-if="Tasks.length == 0" />
        </div>
        <div class="right">
            <Message v-if="Notification.Status" :Notification="Notification" />
            <Create />
            <Latest :Latest="Latest" />
            <News v-if="Tasks.length > 0" />
            <Shortcuts />
        </div>
    </div>
</template>

<script>
import Counters from './Dashboard/Counters.vue';
import Message from './Dashboard/Message.vue';
import Create from './Dashboard/Create.vue';
import Tasks from './Dashboard/Tasks.vue';
import Latest from './Dashboard/Latest.vue';
import News from './Dashboard/News.vue';
import Shortcuts from './Dashboard/Shortcuts.vue';

export default {
    name: 'VehicleOverview',

    components: {
        Counters,
        Message,
        Create,
        News,
        Tasks,
        Latest,
        Shortcuts
    },

    data () {
        return {
            Notification: {
                Status: true,
                Title: 'Update: Transport status per voertuig toegevoegd',
                Text: 'Vanaf vandaag is het mogelijk per dossier de transport status bij te houden. Deze status zie je ook in het dossier overzicht. Zo zie je in één opslag welke voertuigen nog gepland, of al onderweg zijn. En welke al voor de deur staan, dus waar je mee aan de slag kunt. Binnenkort zie je deze functie ook terug in onze mobiele app. Ook ideëen aandragen? Dan kan! Mail ons op info@importeer.nu.'
            },
            Counters: {
                Approve: 10,
                All: 287,
                Papers: 12,
                Pictures: 18,
                RDW: 2,
                RDWFiles: 0,
                RDWCheck: 1
            },
            Latest: [],
            Tasks: []
        }
    },

    mounted () {
        this.GetVehicles()
        this.GetTasks()
    },

    methods: {
        GetVehicles () {
            this.$http.get('https://api.wheels.nl/vehicles?limit=5').then((response) => {
                if(response.data.vehicles) { this.Latest = response.data.vehicles }
            })
        },

        GetTasks () {
            this.$http.get('https://api.wheels.nl/tasks?dashboard=true').then((response) => {                
                if(response.data.tasks) { this.Tasks = response.data.tasks }
            })
        },

        CompleteTask (e) {
            this.$http.patch(`https://api.wheels.nl/tasks/${e.Identifier}`, {
                Text: e.Text,
                Completed: true
            }).then(() => {
                e.Completed = true
            })
        }
    }
}
</script>

<style scoped>
.dashboard {
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    margin: auto;
    padding: 50px;
    overflow: auto;
}

.dashboard .left {
    width: calc(50% - 25px);
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.dashboard .right {
    width: calc(50% - 25px);
    display: flex;
    flex-direction: column;
    gap: 50px;
}
</style>