import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'



import axios from 'axios'
import VueAxios from 'vue-axios'



// Create App
let app = createApp(App)



// Pinia
const pinia = createPinia()
pinia.use(({ store }) => {
    store.$http = app.config.globalProperties.$http;
})
app.use(pinia)



// MITT
import mitt from 'mitt'
app.config.globalProperties.$mitt = mitt()



// Select
import vSelect from 'vue-select'
app.component('v-select', vSelect)
import 'vue-select/dist/wheels-select.css';



// VueDiff
import VueDiff from 'vue-diff';
import 'vue-diff/dist/index.css';
app.use(VueDiff);



// PAGINATION
import VueAwesomePaginate from "vue-awesome-paginate"
import "vue-awesome-paginate/dist/style.css"
app.use(VueAwesomePaginate)



// Axios
app.use(VueAxios, axios)
app.config.globalProperties.$http = axios
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true

axios.interceptors.request.use(async (config) => {
    config.headers['X-Wheels-Auth-Token'] = localStorage.getItem('WAT')
    config.headers['X-Wheels-Device-Token'] = localStorage.getItem('WDT')
    
    return config;
})

axios.interceptors.response.use(function (response) {
    if(response.data.newToken) {        
        localStorage.setItem('WAT', response.data.newToken)
    }

    if(response.data.logout && (router.currentRoute.value.name !== 'Login' && router.currentRoute.value.name !== 'NarrowcastingScreen')) {
        localStorage.clear()
        location.href = '/login?redirectUrl=' + location.href
    }

    return response;
}, function (error) {
    return Promise.reject(error);
})



// MOMENT
import moment from 'moment'
app.config.globalProperties.$moment = moment



// TOAST
import {useToast} from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
app.config.globalProperties.$toast = useToast()



// PRIMEVUE
import PrimeVue from 'primevue/config'
import Aura from '@primevue/themes/aura'
import Tooltip from 'primevue/tooltip'
import KeyFilter from 'primevue/keyfilter'
app.use(PrimeVue, {
    // Default theme configuration
    theme: {
        preset: Aura,
        options: {
            prefix: 'p',
            darkModeSelector: '.my-app-dark',
            cssLayer: false
        }
    },
    locale: {
        today: 'Vandaag',
        clear: 'Wissen',
        dateFormat: 'dd-mm-yy',
        dayNames: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
        dayNamesShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
        dayNamesMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
        monthNames: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
    }
})
app.directive('tooltip', Tooltip)
app.directive('keyfilter', KeyFilter)



// Mount App
app.use(router).mount('#app')