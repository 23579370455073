<template>
    <div class="articles">
        <div class="filters" :class="{ loader: Loader || Combine.Active }">
            <span class="title">Filter</span>

            <div class="group">
                <input v-model="Search" @change="GetArticles(true)" class="search" :class="{ active: Search }" type="text" placeholder="Zoek op EAN / Merk / Model">
            </div>

            <span class="reset" v-if="Search.length > 0 || Filtered.length > 0 || Selected.length > 0" @click="ResetFilters">Reset</span>

            <div class="radio">
                <span class="item" @click="SetGroup(false)" :class="{ active: Group == 'false' || !Group }">Per item</span>
                <span class="item" @click="SetGroup(true)" :class="{ active: Group == 'true' || Group == true }">Per groep</span>
            </div>

            <div class="new-group" v-if="Filters.custom" :class="{ active: Filtered.filter(item => item.Category == 'Categories' || item.Category == 'SubCategories').length > 0 }">
                <span class="group-title">Categorieën</span>
                <div class="selected" v-if="Filtered.filter(item => item.Category == 'Categories' || item.Category == 'SubCategories').length > 0">
                    <span class="item" v-for="(Item, i) in Filtered.filter(item => item.Category == 'Categories' || item.Category == 'SubCategories')" :key="i" @click="SetFilter(Item.Code, Item.Name, Item.Value, Item.Category), GetArticles(true)">{{ Item.Name }}</span>
                </div>
                <div class="divider" v-if="Filtered.filter(item => item.Category == 'Categories' || item.Category == 'SubCategories').length > 0"></div>
                <div class="items">
                    <div class="item" v-for="(Item, i) in SetFilters(Filters.custom.categories, 'Name')" :key="i">
                        <div class="row" @click="SetFilter(Item.options[0].Code, Item.label, Item.options[0].Value, 'Categories'), GetArticles(true)">
                            <div class="checkbox">
                                <div v-if="Filtered.filter(item => item.Code == Item.options[0].Code).length > 0" class="checked"></div>
                            </div>
                            <span class="name">{{ Item.label }}</span>
                        </div>
                        <div class="subitem" v-for="(SubItem, si) in Item.options.filter(item => item.SubCode)" :key="si" @click="SetFilter(SubItem.SubCode, SubItem.SubName, SubItem.SubCode, 'SubCategories'), GetArticles(true)">
                            <div class="checkbox">
                                <div v-if="Filtered.filter(item => item.Code == SubItem.SubCode).length > 0" class="checked"></div>
                            </div>
                            <span class="name">{{ SubItem.SubName }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="new-group">
                <span class="group-title">Adviesprijs</span>
                <div class="prices">
                    <input type="text" v-model="Price.From" @change="GetArticles(true)">
                    <input type="text" v-model="Price.Till" @change="GetArticles(true)">
                </div>
            </div>

            <div class="new-group" v-if="Filters.custom" :class="{ active: Filtered.filter(item => item.Category == 'Labels').length > 0 }">
                <span class="group-title">Labels</span>
                <div class="selected" v-if="Filtered.filter(item => item.Category == 'Labels').length > 0">
                    <span class="item" v-for="(Item, i) in Filtered.filter(item => item.Category == 'Labels')" :key="i" @click="SetFilter(Item.Code, Item.Name, Item.Value, Item.Category), GetArticles(true)">{{ Item.Name }}</span>
                </div>
                <div class="divider" v-if="Filtered.filter(item => item.Category == 'Labels').length > 0"></div>
                <div class="items">
                    <div class="item" v-for="(Item, i) in Filters.custom.labels" :key="i">
                        <div class="row" @click="SetFilter(Item.Identifier, Item.Name, Item.Identifier, 'Labels'), GetArticles(true)">
                            <div class="checkbox">
                                <div v-if="Filtered.filter(item => item.Code == Item.Identifier).length > 0" class="checked"></div>
                            </div>
                            <span class="name">{{ Item.Name }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="new-group" v-for="(Filter, i) in Filters.default" :key="i" :class="{ active: Filtered.filter(item => item.Category == Filter.Name).length > 0 }">
                <span class="group-title">{{ Filter.Name }}</span>
                <div class="selected" v-if="Filtered.filter(item => item.Category == Filter.Name).length > 0">
                    <span class="item" v-for="(Item, i) in Filtered.filter(item => item.Category == Filter.Name)" :key="i" @click="SetFilter(Item.Code, Item.Name, Item.Name, Item.Category), GetArticles(true)">{{ Item.Name }}</span>
                </div>
                <div class="divider" v-if="Filtered.filter(item => item.Category == Filter.Name).length > 0"></div>
                <input type="text" class="search" v-model="Filter.Search">
                <div class="items">
                    <div class="item" v-for="(Item, i) in Filter.Items" :key="i" @click="SetFilter(Item.Name, Item.Name, Item.Name, Filter.Name, Filter.Column), GetArticles(true)">
                        <div class="checkbox">
                            <div v-if="Filtered.filter(item => item.Code == Item.Name && item.Category == Filter.Name).length > 0" class="checked"></div>
                        </div>
                        <span class="name" v-if="Item.Name">{{ Item.Name }}</span>
                        <span class="name unknown" v-else>Onbekend</span>
                        <span class="count" v-if="Item.Count">({{ Item.Count }})</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="catalog">
            <div class="grid" :class="{ loader: Loader || Combine.Active }">
                <div class="header">
                    <span class="title" v-if="Loader">Aan het laden...</span>
                    <span class="title" v-else-if="Latest">Laatste {{ Articles.length }} artikelen</span>
                    <span class="title" v-else-if="!Latest && Count > 1">{{ Count }} artikelen</span>
                    <span class="title" v-else-if="!Latest && Count == 1">{{ Count }} artikel</span>
                    <span class="title" v-else-if="!Latest && Count == 0">{{ Count }} artikelen</span>
                    <v-select v-if="Selected.length > 0" v-model="SelectionMenuOption" class="selection" :clearable="false" :options="SelectionMenu" label="Label" placeholder="Selecteer een optie..." @option:selected="OpenSelectionPopup"></v-select>
                </div>
                <div class="product" v-for="Article in Articles" :key="Article.Article" :class="{ checked: Selected.filter(item => item.Identifier == Article.Identifier).length > 0 }">
                    <div class="checkbox" @click="SetSelection(Article)">
                        <div class="checked"></div>
                    </div>
                    <span class="ean">{{ Article.EAN }}</span>
                    <span class="supplier-id">{{ Article.SupplierID }}</span>
                    <div v-if="Article.Checked" class="completed"></div>
                    <router-link :to="'/tweewielers/' + Article.Identifier">
                        <img v-if="Article.Thumbnail" :src="'https://api.wheels.nl/vault/' + Article.Identifier + '/' + Article.Thumbnail + '.jpeg'" alt="Thumbnail" class="image">
                        <img v-else :src="'https://api.wheels.nl/vault/' + this.$root.Client + '/' + this.$root.Placeholder + '.png'" alt="Thumbnail" class="image">
                    </router-link>
                    <router-link class="title" :to="'/tweewielers/' + Article.Identifier">{{ Article.Brand }} {{ Article.Model }}</router-link>
                    <div class="specifications">
                        <span class="item" v-if="Article.Size && Article.Size !== 0">{{ Article.Size }}</span>
                        <span class="item" v-if="Article.Colour && Article.Colour !== 0">{{ Article.Colour }}</span>
                        <span class="item" v-if="Article.Year && Article.Year !== 0">{{ Article.Year }}</span>
                        <span class="item" v-if="Article.LabelName" :style="{ color: Article.LabelColor, background: Article.LabelBackground }">{{ Article.LabelName }}</span>
                        <span class="item count" v-if=Article.GroupCount>{{ Article.GroupCount }}x</span>
                    </div>
                    <div class="price">
                        <span class="from" v-if="Number(Article.RetailPrice) > Number(Article.Price)">€ {{ SetPrice(Article.RetailPrice) }}</span>
                        <span class="to" v-if="Article.Price">€ {{ SetPrice(Article.Price) }}</span>
                    </div>
                    <div class="stock" v-if="Article.Stock">
                        <div class="location" v-for="(Location, i) in Article.Stock.map(JSON.stringify).reverse().filter(function(item, index, arr){ return arr.indexOf(item, index + 1) === -1; }).reverse().map(JSON.parse)" :key="i">
                            <span class="location-title">{{ Location.City }}</span>
                            <div class="item">
                                <span class="counter" :class="{ active: Location.Quantity > 0 }">{{ Location.Quantity }}</span>
                                Voorraad
                            </div>
                            <div class="item">
                                <span class="counter" :class="{ active: Location.QuantityDemo > 0 }">{{ Location.QuantityDemo }}</span>
                                Demo
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span class="pagination" v-if="Count > 0 && !Latest && !Loader">
                <span>{{ Count }} Artikelen</span>
                <select v-model="Page" @change="GetArticles(false)" v-if="Math.floor(Count / PageLimit) > 0">
                    <option v-for="(PageNr, i) in (Math.floor(Count / PageLimit))" :value="PageNr - 1" :key="i">{{ i + 1 }}</option>
                </select>
                <select v-model="PageLimit" @change="GetArticles(false)">
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                </select>
            </span>
        </div>
        <div class="combine" v-if="Combine.Active" @click.self="Combine.Active = false">
            <div class="content" :class="{ loader: Combine.Loader }">
                <span class="title">Artikelen samenvoegen</span>
                <svg class="close" @click="CloseCombine" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g><path fill="#333" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"></path></g></svg>
                <span>Je staat op het punt om <b>{{ this.Selected.length }} artikelen</b> samen te voegen.</span>
                <span class="button" @click="SetCombine">Samenvoegen</span>
                <div class="loader" v-if="Combine.Loader"></div>
            </div>
        </div>
        <div class="bike-loader" v-if="Loader">
            <svg class="bike" viewBox="0 0 48 30" width="48px" height="30px">
                <g fill="none" stroke="var(--primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1">
                    <g transform="translate(9.5,19)">
                        <circle class="bike__tire" r="9" stroke-dasharray="56.549 56.549" />
                        <g class="bike__spokes-spin" stroke-dasharray="31.416 31.416" stroke-dashoffset="-23.562">
                            <circle class="bike__spokes" r="5" />
                            <circle class="bike__spokes" r="5" transform="rotate(180,0,0)" />
                        </g>
                    </g>
                    <g transform="translate(24,19)">
                        <g class="bike__pedals-spin" stroke-dasharray="25.133 25.133" stroke-dashoffset="-21.991" transform="rotate(67.5,0,0)">
                            <circle class="bike__pedals" r="4" />
                            <circle class="bike__pedals" r="4" transform="rotate(180,0,0)" />
                        </g>
                    </g>
                    <g transform="translate(38.5,19)">
                        <circle class="bike__tire" r="9" stroke-dasharray="56.549 56.549" />
                        <g class="bike__spokes-spin" stroke-dasharray="31.416 31.416" stroke-dashoffset="-23.562">
                            <circle class="bike__spokes" r="5" />
                            <circle class="bike__spokes" r="5" transform="rotate(180,0,0)" />
                        </g>
                    </g>
                    <polyline class="bike__seat" points="14 3,18 3" stroke-dasharray="5 5" />
                    <polyline class="bike__body" points="16 3,24 19,9.5 19,18 8,34 7,24 19" stroke-dasharray="79 79" />
                    <path class="bike__handlebars" d="m30,2h6s1,0,1,1-1,1-1,1" stroke-dasharray="10 10" />
                    <polyline class="bike__front" points="32.5 2,38.5 19" stroke-dasharray="19 19" />
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticlesOverview',

    data () {
        return {
            Loader: true,
            Latest: false,
            Group: false,
            Articles: [],
            Article: {
                Active: false,
                Data: [],
                Tab: 'Article'
            },
            Search: '',
            Filters: [],
            Filtered: [],
            Selected: [],
            SelectionMenu: [
                {
                    Label: 'Samenvoegen',
                    Value: 'S',
                    Active: false
                },
                {
                    Label: 'Accu toevoegen',
                    Value: 'A',
                    Active: true
                }
            ],
            SelectionMenuOption: '',
            Price: {
                From: 0,
                Till: 999999
            },
            Count: 0,
            Page: 20,
            PageLimit: 25,
            Query: false,
            SearchQuery: false,
            Combine: {
                Loader: false,
                Active: false
            }
        }
    },

    mounted () {
        this.GetFilters()
    },

    methods: {
        GetFilters () {
            this.$http.get('https://api.wheels.nl/stock/filters').then((response) => {
                if(response.data.filters) {
                    this.Filters = response.data.filters
                } else {
                    this.Filters = []
                }

                if(localStorage.getItem('ArticleTime')) {
                    if(Date.now() < (Number(localStorage.getItem('ArticleTime')) + (60 * 60 * 60 * 60))) {
                        localStorage.setItem('ArticleTime', Date.now())

                        if(localStorage.getItem('ArticleSearch')) {
                            this.Search = localStorage.getItem('ArticleSearch')
                        }

                        if(localStorage.getItem('ArticleGroup')) {
                            this.Group = localStorage.getItem('ArticleGroup')
                        }

                        if(localStorage.getItem('ArticleFilter')) {
                            this.Filtered = JSON.parse(localStorage.getItem('ArticleFilter'))
                        }
        
                        if(localStorage.getItem('ArticleSelection')) {
                            this.Selected = JSON.parse(localStorage.getItem('ArticleSelection'))
                        }
                    } else {
                        localStorage.removeItem('ArticleSearch')
                        localStorage.removeItem('ArticleGroup')
                        localStorage.removeItem('ArticleTime')
                        localStorage.removeItem('ArticleFilter')
                        localStorage.removeItem('ArticleSelection')
                    }
                }

                this.GetArticles(true)
            })
        },

        GetArticles (reset) {
            this.Loader = true

            if(reset) { this.Page = 0 }

            if(this.Search) {
                localStorage.setItem('ArticleSearch', this.Search)
            }

            localStorage.setItem('ArticleGroup', this.Group)

            this.$http.get('https://api.wheels.nl/stock', {
                params: {
                    search: this.Search,
                    filters: this.Filtered,
                    priceFrom: this.Price.From,
                    priceTill: this.Price.Till,
                    stock: true,
                    group: this.Group,
                    page: this.Page,
                    limit: this.PageLimit
                }
            }).then((response) => {
                if(response.data.articles) {
                    this.Latest = response.data.latest
                    this.Articles = response.data.articles
                    this.Count = response.data.count
                    this.Query = response.data.query
                    this.SearchQuery = response.data.searchQuery

                    if(response.data.models) {
                        if(this.Filters.default.filter(item => item.Name == 'Modellen').length > 0) {
                            this.Filters.default = this.Filters.default.filter(item => item.Name !== 'Modellen')
                            this.Filters.default.splice(1, 0, response.data.models)
                        } else {
                            this.Filters.default.splice(1, 0, response.data.models)
                        }
                    } else {
                        this.Filters.default = this.Filters.default.filter(item => item.Name !== 'Modellen')
                    }
                } else {
                    this.Articles = []
                    this.Count = 0
                }

                this.Loader = false
            })
        },

        SetGroup (e) {
            this.Group = e

            this.Selected = []
            localStorage.removeItem('ArticleSelection')

            this.GetArticles(true)
        },

        GetArticle (id) {
            this.Article.View = true
            this.$http.get('https://api.wheels.nl/stock/' + id.EAN).then((response) => {
                if(response.data.article) { this.Article.Data = response.data.article } else { this.Article.Data = [] }
            })
        },

        CloseArticle () {
            this.Article.View = false
            this.Article.Data = []
            this.Article.Tab = 'Article'
        },

        SetPrice (e) {
            if(Number.isInteger(e)) {
                return Number(e) + ',-'
            } else if(e % 1 == 0) {
                return Number(e).toLocaleString(undefined, {minimumFractionDigits: 0}) + ',-'
            } else {
                return Number(e).toLocaleString(undefined, {minimumFractionDigits: 2})
            }
        },

        SetDate (date) {
            return date
        },

        SetFilters (e, a) {
            const Array = e.filter(item => item.Name).reduce((m,{[a]:label, ...rest}) => {
                const group = m.get(label) || {label, options:[]};
                group.options.push({...rest})
                return m.set(label, group)
            },new Map)

            return [...Array.values()]
        },

        SetFilter (e, a, x, y, z) {
            if(this.Filtered.filter(item => item.Code == e).length > 0) {
                this.Filtered = this.Filtered.filter(item => item.Code !== e)
            } else {
                this.Filtered.push({
                    Code: e,
                    Name: a,
                    Value: x,
                    Category: y,
                    Column: z
                })
            }

            localStorage.setItem('ArticleTime', Date.now())
            localStorage.setItem('ArticleFilter', JSON.stringify(this.Filtered))
        },

        SetSelection (e) {
            if(this.Selected.filter(item => item.Identifier == e.Identifier).length > 0) {
                this.Selected = this.Selected.filter(item => item.Identifier !== e.Identifier)
            } else {
                this.Selected.push(e)
            }

            localStorage.setItem('ArticleTime', Date.now())
            localStorage.setItem('ArticleSelection', JSON.stringify(this.Selected))
        },

        ResetFilters () {
            this.Page = 0
            this.Limit = 100

            localStorage.removeItem('ArticleTime')

            this.Search = ''
            localStorage.removeItem('ArticleSearch')

            this.Filtered = []
            localStorage.removeItem('ArticleFilter')

            this.Group = false
            localStorage.removeItem('ArticleGroup')

            this.Selected = []
            localStorage.removeItem('ArticleSelection')

            this.GetArticles()
        },

        OpenSelectionPopup (e) {
            this.SelectionMenuOption = ''

            if(e.Value == 'S') {
                this.Combine.Active = true
            }
        },

        SetCombine () {
            this.Combine.Loader = true

            this.$http.post('https://api.wheels.nl/stock/combine', {
                Items: this.Selected,
                Group: this.Group
            }).then(() => {
                this.CloseCombine()
                this.GetArticles()
                this.Selected = []
            })
        },

        CloseCombine () {
            this.Combine.Loader = false
            this.Combine.Active = false
        }
    }
}
</script>

<style scoped>
.articles {
    position: relative;
    display: flex;
    gap: 0 50px;
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 50px;
    background: #f7f8fc;
    overflow: hidden;
}

.articles .filters {
    position: relative;
    min-width: 350px;
    max-width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    padding: 50px 1px;
    transition: .5s;
    overflow-y: auto;
}

.articles .filters.loader {
    /* filter: blur(5px);
    -webkit-filter: blur(5px); */
    pointer-events: none;
    opacity: 0.5;
}

.articles .filters::-webkit-scrollbar {
    width: 0px;
}

.articles .filters .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.articles .filters .menu .list {
    width: 32px;
    height: 32px;
    fill: #000;
    cursor: pointer;
}

.articles .filters .menu .blocks {
    width: 24px;
    height: 24px;
    fill: #000;
    cursor: pointer;
}





.articles .filters .group .search {
    display: block;
    width: 100%;
    height: 50px;
    padding: 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.articles .filters .group .search.active {
    outline: solid 1px var(--primary);
    border: solid 1px var(--primary);
}





.articles .filters .radio {
    display: flex;
    width: 100%;
    min-height: 50px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
    overflow: hidden;
}

.articles .filters .radio .item {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.articles .filters .radio .item.active {
    color: #fff;
    font-weight: 600;
    background: var(--primary);
}





.articles .filters .reset {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    background: var(--primary);
    font-weight: 500;
    color: #fff;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}





.articles .filters .new-group {
    padding: 0 25px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.articles .filters .new-group.active {
    outline: solid 1px var(--primary);
    border: solid 1px var(--primary);
}

.articles .filters .new-group .group-title {
    display: block;
    font-weight: 700;
    margin: 20px 0 10px 0;
}

.articles .filters .new-group .prices {
    display: flex;
    gap: 15px;
    margin: 0 0 25px 0;
}

.articles .filters .new-group .prices input {
    width: 100%;
    height: 50px;
    padding: 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.articles .filters .new-group .selected {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.articles .filters .new-group .selected .item {
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 10px;
    font-size: 14px;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.articles .filters .new-group .divider {
    border-bottom: solid 1px #f7f8fc;
    padding: 15px 0 0 0;
    margin: 0 0 15px 0;
}

.articles .filters .new-group .search {
    width: 100%;
    background: #f7f8fc;
    padding: 2.5px 10px;
    margin: 0 0 10px 0;
    border: 0;
    border-radius: 5px;
    outline: none;
}

.articles .filters .new-group .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 250px;
    padding: 0 0 25px 0;
    overflow: auto;
}

.articles .filters .new-group .items::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.articles .filters .new-group .items .item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.articles .filters .new-group .items .item .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.articles .filters .new-group .items .item .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}

.articles .filters .new-group .items .item .count {
    margin: 0 0 0 auto;
    font-size: 12px;
    opacity: 0.25;
}

.articles .filters .new-group .items .item .row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.articles .filters .new-group .items .item .row .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.articles .filters .new-group .items .item .row .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}

.articles .filters .new-group .items .item .subitem {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 0 0 35px;
    cursor: pointer;
}

.articles .filters .new-group .items .item .subitem .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
}

.articles .filters .new-group .items .item .subitem .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}





.articles .catalog {
    position: relative;
    width: calc(100% - 350px);
}

.articles .catalog .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.articles .catalog .selection {
    min-width: 250px;
    background: #fff;
}

.articles .catalog .grid {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 25px;
    height: 100%;
    padding: 49px 0 50px 1px;
    overflow: auto;
    transition: .5s;
}

.articles .catalog .grid.loader {
    /* filter: blur(5px);
    -webkit-filter: blur(5px); */
    pointer-events: none;
    opacity: 0.5;
}

.articles .catalog .grid::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.articles .catalog .grid .header {
    width: 100%;
    display: flex;
    align-items: center;
}

.articles .catalog .grid .product {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc((100% / 7) - 22px);
    padding: 70px 25px 35px 25px;
    background: #fff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
    overflow: hidden;
}

.articles .catalog .grid .product.checked {
    outline: solid 1px var(--primary);
    border: solid 1px var(--primary);
}

.articles .catalog .grid .product .checkbox {
    position: absolute;
    top: 25px;
    left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.articles .catalog .grid .product.checked .checkbox .checked {
    width: 15px;
    height: 15px;
    background: var(--primary);
    border-radius: 2.5px;
}

.articles .catalog .grid .product .ean {
    position: absolute;
    top: 25px;
    right: 27.5px;
    height: 25px;
    display: flex;
    align-items: center;
    color: #b5b5b5;
    font-size: 12px;
    font-weight: 300;
}

.articles .catalog .grid .product .supplier-id {
    position: absolute;
    top: 45px;
    right: 27.5px;
    height: 25px;
    display: flex;
    align-items: center;
    color: #b5b5b5;
    font-size: 12px;
    font-weight: 300;
}

.articles .catalog .grid .product .completed {
    position: absolute;
    top: -22.5px;
    right: -22.5px;
    width: 45px;
    height: 45px;
    background: #8fbc8f;
    transform: rotate(45deg);
}

.articles .catalog .grid .product .image {
    width: 100%;
    aspect-ratio: 1.25 / 1;
    object-fit: contain;
    border-radius: 5px;
}

.articles .catalog .grid .product .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    margin: 0 0 10px 0;
}

.articles .catalog .grid .product .placeholder .loader {
    width: 60px;
    height: 60px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid var(--primary);
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.articles .catalog .grid .product .title {
    height: 60px;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    overflow: hidden;
}

.articles .catalog .grid .product .specifications {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.articles .catalog .grid .product .specifications .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 700;
    background: #f7f8fc;
    border-radius: 5px;
}

.articles .catalog .grid .product .specifications .item.count {
    color: #fff;
    background: var(--primary);
}

.articles .catalog .grid .product .price {
    display: flex;
    gap: 10px;
}

.articles .catalog .grid .product .price .from {
    text-decoration: line-through;
}

.articles .catalog .grid .product .stock {
    display: flex;
    flex-direction: column;
}

.articles .catalog .grid .product .stock .location {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.articles .catalog .grid .product .stock .location-title {
    font-size: 16px;
    font-weight: 500;
}

.articles .catalog .grid .product .stock .item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.articles .catalog .grid .product .stock .item .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: 700;
    background: #f7f8fc;
    border-radius: 5px;
}

.articles .catalog .grid .product .stock .item .counter.active {
    background: #00d78f;
}

.articles .catalog .products::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.articles .catalog .pagination {
    position: absolute;
    bottom: 25px;
    left: calc(50% - 250px);
    width: 500px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 25px;
    margin: 15px 0 0 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 20px #00000025;
}

.articles .catalog .pagination select {
    border: 0;
}





.combine {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.combine .content {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px;
    background: #fff;
    border: solid 1px #ededed;
    border-radius: 10px;
    cursor: auto;
}

.combine .content .title {
    font-size: 24px;
    font-weight: 500;
}

.combine .content b {
    font-weight: 600;
}

.combine .content .close {
    position: absolute;
    top: 35px;
    right: 25px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.combine .content .button {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    background: var(--primary);
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    justify-content: center;
    cursor: pointer;
}

.combine .content.loader:deep(span) {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    pointer-events: none;
}

.combine .content .loader {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid var(--primary);
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}





@media only screen and (max-width: 2700px) {
    .articles .catalog .grid .product {
        width: calc((100% / 6) - 21px);
    }
}

@media only screen and (max-width: 2400px) {
    .articles .catalog .grid .product {
        width: calc((100% / 5) - 20px);
    }
}

@media only screen and (max-width: 2100px) {
    .articles .catalog .grid .product {
        width: calc((100% / 4) - 19px);
    }
}

@media only screen and (max-width: 1800px) {
    .articles .catalog .grid .product {
        width: calc((100% / 3) - 17.5px);
    }
}

@media only screen and (max-width: 1500px) {
    .articles .catalog .grid .product {
        width: calc((100% / 2) - 12.5px);
    }
}

@media only screen and (max-width: 1200px) {
    .articles .catalog .grid .product {
        width: 100%;
    }
}





.bike-loader {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.bike-loader .bike {
	display: block;
	margin: auto;
	width: 16em;
	height: auto;
}

.bike-loader .bike__body,
.bike-loader .bike__front,
.bike-loader .bike__handlebars,
.bike-loader .bike__pedals,
.bike-loader .bike__pedals-spin,
.bike-loader .bike__seat,
.bike-loader .bike__spokes,
.bike-loader .bike__spokes-spin,
.bike-loader .bike__tire {
	animation: bikeBody 3s ease-in-out infinite;
	stroke: var(--primary);
	transition: stroke var(--trans-dur);
}

.bike-loader .bike__front {
	animation-name: bikeFront;
}

.bike-loader .bike__handlebars {
	animation-name: bikeHandlebars;
}

.bike-loader .bike__pedals {
	animation-name: bikePedals;
}

.bike-loader .bike__pedals-spin {
	animation-name: bikePedalsSpin;
}

.bike-loader .bike__seat {
	animation-name: bikeSeat;
}

.bike-loader .bike__spokes,
.bike-loader .bike__tire {
	stroke: currentColor;
}

.bike-loader .bike__spokes {
	animation-name: bikeSpokes;
}

.bike-loader .bike__spokes-spin {
	animation-name: bikeSpokesSpin;
}

.bike-loader .bike__tire {
	animation-name: bikeTire;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue),90%,10%);
		--fg: hsl(var(--hue),90%,90%);
	}
}

/* Animations */
@keyframes bikeBody {
	from { stroke-dashoffset: 79; }
	33%,
	67% { stroke-dashoffset: 0; }
	to { stroke-dashoffset: -79; }
}
@keyframes bikeFront {
	from { stroke-dashoffset: 19; }
	33%,
	67% { stroke-dashoffset: 0; }
	to { stroke-dashoffset: -19; }
}
@keyframes bikeHandlebars {
	from { stroke-dashoffset: 10; }
	33%,
	67% { stroke-dashoffset: 0; }
	to { stroke-dashoffset: -10; }
}
@keyframes bikePedals {
	from {
		animation-timing-function: ease-in;
		stroke-dashoffset: -25.133;
	}
	33%,
	67% {
		animation-timing-function: ease-out;
		stroke-dashoffset: -21.991;
	}
	to {
		stroke-dashoffset: -25.133;
	}
}
@keyframes bikePedalsSpin {
	from { transform: rotate(0.1875turn); }
	to { transform: rotate(3.1875turn); }
}
@keyframes bikeSeat {
	from { stroke-dashoffset: 5; }
	33%,
	67% { stroke-dashoffset: 0; }
	to { stroke-dashoffset: -5; }
}
@keyframes bikeSpokes {
	from {
		animation-timing-function: ease-in;
		stroke-dashoffset: -31.416;
	}
	33%,
	67% {
		animation-timing-function: ease-out;
		stroke-dashoffset: -23.562;
	}
	to {
		stroke-dashoffset: -31.416;
	}
}
@keyframes bikeSpokesSpin {
	from { transform: rotate(0); }
	to { transform: rotate(3turn); }
}
@keyframes bikeTire {
	from {
		animation-timing-function: ease-in;
		stroke-dashoffset: 56.549;
		transform: rotate(0);
	}
	33% {
		stroke-dashoffset: 0;
		transform: rotate(0.33turn);
	}
	67% {
		animation-timing-function: ease-out;
		stroke-dashoffset: 0;
		transform: rotate(0.67turn);
	}
	to {
		stroke-dashoffset: -56.549;
		transform: rotate(1turn);
	}
}
</style>