<template>
    <div class="item">
        <div class="item-block full notice" v-if="Invoice.Status && Invoice.Status !== 'O'">
            <span class="item-block-title">📌 Facturen kunnen na verzending niet worden gewijzigd</span>
            <span>Zodra een factuur is verstuurd, kan deze niet meer worden aangepast of verwijderd. Dit is conform de wettelijke richtlijnen voor facturatie en boekhouding. Indien correcties nodig zijn, kan er een creditfactuur worden aangemaakt om de oorspronkelijke factuur (gedeeltelijk of volledig) te verrekenen. Dit zorgt voor een correcte en transparante administratie.</span>
        </div>
        <div class="item-block buttons">
            <div class="item-block-button blue" @click="Patch">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" fill="#ffffff"></path></g></svg>
                Opslaan
            </div>
            <div class="item-block-spacer"></div>
            <div class="item-block-button blue" v-if="Invoice.Status == 'O'">
                <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#fff" fill="none"><path d="M38.61,54.93,27.94,35.57,9.08,25.38a1,1,0,0,1,.2-1.8L54.08,8.64a1,1,0,0,1,1.27,1.27L40.41,54.73A1,1,0,0,1,38.61,54.93Z"></path><line x1="55.13" y1="8.91" x2="27.94" y2="35.57" stroke-linecap="round"></line></svg>
                Factuur versturen
            </div>
            <div class="item-block-button blue" v-else>
                <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#fff" fill="none"><path d="M38.61,54.93,27.94,35.57,9.08,25.38a1,1,0,0,1,.2-1.8L54.08,8.64a1,1,0,0,1,1.27,1.27L40.41,54.73A1,1,0,0,1,38.61,54.93Z"></path><line x1="55.13" y1="8.91" x2="27.94" y2="35.57" stroke-linecap="round"></line></svg>
                Factuur nogmaals versturen
            </div>
            <div class="item-block-button blue" @click="Download">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#fff"><path d="M24 12a5 5 0 0 1-5 5h-2v-1h2a3.99 3.99 0 0 0 .623-7.934l-.79-.124-.052-.798a5.293 5.293 0 0 0-10.214-1.57L8.17 6.59l-.977-.483A2.277 2.277 0 0 0 6.19 5.87a2.18 2.18 0 0 0-1.167.339 2.206 2.206 0 0 0-.98 1.395l-.113.505-.476.2A4 4 0 0 0 5 16h3v1H5a5 5 0 0 1-1.934-9.611 3.21 3.21 0 0 1 1.422-2.025 3.17 3.17 0 0 1 1.702-.493 3.268 3.268 0 0 1 1.446.34 6.293 6.293 0 0 1 12.143 1.867A4.988 4.988 0 0 1 24 12zm-11-1h-1v10.292l-2.646-2.646-.707.707 3.854 3.854 3.853-3.852-.707-.707L13 21.294z"></path><path fill="none" d="M0 0h24v24H0z"></path></svg>
                Download factuur
            </div>
            <div class="item-block-button green" v-if="Invoice.Status !== 'P'" @click="Payd">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                Zet op betaald
            </div>
            <div class="item-block-button red" v-if="Invoice.Status == 'O'" @click="Delete">
                <svg fill="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"></path></g></svg>
                Verwijderen
            </div>
        </div>
        <div class="item-block">
            <span class="item-block-title">Factuur</span>
            <div class="item-block-group">
                <span class="item-block-group-subtitle">Kenmerk</span>
                <input v-model="Invoice.Title" type="text" class="item-block-group-input" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
            </div>
            <div class="item-block-group">
                <span class="item-block-group-subtitle">Nummer</span>
                <input v-model="Invoice.Number" type="text" class="item-block-group-input" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
            </div>
            <div class="item-block-group">
                <span class="item-block-group-subtitle">Datum</span>
                <DatePicker v-model="Invoice.Date" class="item-block-group-input date" dateFormat="dd-mm-yy" :class="{ 'input-disabled': Invoice.Status !== 'O' }" />
            </div>
            <div class="item-block-group">
                <span class="item-block-group-subtitle">Status</span>
                <select v-model="Invoice.Status" class="item-block-group-input" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
                    <option value="O">Open</option>
                    <option value="S">Verstuurd</option>
                    <option value="P">Betaald</option>
                </select>
            </div>
            <div class="item-block-group">
                <span class="item-block-group-subtitle">Aangemaakt</span>
                <input :value="this.$moment(Invoice.Created).format('DD-MM-YYYY HH:mm')" type="text" class="item-block-group-input" disabled>
            </div>
            <div class="item-block-group">
                <span class="item-block-group-subtitle">Gewijzigd</span>
                <input v-if="Invoice.Changed" :value="this.$moment(Invoice.Changed).format('DD-MM-YYYY HH:mm')" type="text" class="item-block-group-input" disabled>
                <input v-else type="text" class="item-block-group-input" disabled>
            </div>
        </div>
        <div class="item-block">
            <span class="item-block-title">Klant</span>
            <div class="item-block-group full">
                <SelectButton v-model="Invoice.CustomerMethod" :options="[{ Label: 'Klantenbestand', Value: 'Database' }, { Label: 'Vrije velden', Value: 'Free' }]" optionLabel="Label" optionValue="Value" :allowEmpty="false" :disabled="Invoice.Status !== 'O'" />
            </div>
            <div class="item-block-group full" v-if="Invoice.CustomerMethod == 'Database'">
                <span class="item-block-group-subtitle">Selecteer een klant</span>
                <Select v-model="Invoice.Customer" :options="Clients" optionLabel="FullName" optionValue="Identifier" :disabled="Invoice.Status !== 'O'" />
            </div>
            <div class="item-block-group full" v-if="Invoice.CustomerMethod == 'Database' && Invoice.Customer">
                <router-link :to="'/klanten/' + Clients.find(e => e.Identifier == Invoice.Customer).Identifier" class="item-block-group-name">{{ Clients.find(e => e.Identifier == Invoice.Customer).FullName }}</router-link>
                <span class="item-block-group-subtitle">{{ Clients.find(e => e.Identifier == Invoice.Customer).Street }} {{ Clients.find(e => e.Identifier == Invoice.Customer).Number }}</span>
                <span class="item-block-group-subtitle">{{ Clients.find(e => e.Identifier == Invoice.Customer).Postcode }} {{ Clients.find(e => e.Identifier == Invoice.Customer).City }}</span>
                <span class="item-block-group-subtitle">{{ Clients.find(e => e.Identifier == Invoice.Customer).Country }}</span>
            </div>
            <div class="item-block-group" v-if="Invoice.CustomerMethod == 'Free'">
                <span class="item-block-group-subtitle">Naam</span>
                <input v-model="Invoice.CustomerArray.Name" type="text" class="item-block-group-input" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
            </div>
            <div class="item-block-group" v-if="Invoice.CustomerMethod == 'Free'">
                <span class="item-block-group-subtitle">Straat</span>
                <input v-model="Invoice.CustomerArray.Street" type="text" class="item-block-group-input" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
            </div>
            <div class="item-block-group" v-if="Invoice.CustomerMethod == 'Free'">
                <span class="item-block-group-subtitle">Nummer</span>
                <input v-model="Invoice.CustomerArray.Number" type="text" class="item-block-group-input" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
            </div>
            <div class="item-block-group" v-if="Invoice.CustomerMethod == 'Free'">
                <span class="item-block-group-subtitle">Postcode</span>
                <input v-model="Invoice.CustomerArray.Postcode" type="text" class="item-block-group-input" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
            </div>
            <div class="item-block-group" v-if="Invoice.CustomerMethod == 'Free'">
                <span class="item-block-group-subtitle">Plaats</span>
                <input v-model="Invoice.CustomerArray.City" type="text" class="item-block-group-input" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
            </div>
            <div class="item-block-group" v-if="Invoice.CustomerMethod == 'Free'">
                <span class="item-block-group-subtitle">Land</span>
                <input v-model="Invoice.CustomerArray.Country" type="text" class="item-block-group-input" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
            </div>
            <div class="item-block-group" v-if="Invoice.CustomerMethod == 'Free'">
                <span class="item-block-group-subtitle">E-mail</span>
                <input v-model="Invoice.CustomerArray.Email" type="text" class="item-block-group-input" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
            </div>
            <div class="item-block-group" v-if="Invoice.CustomerMethod == 'Free'">
                <span class="item-block-group-subtitle">Telefoon</span>
                <input v-model="Invoice.CustomerArray.Phone" type="text" class="item-block-group-input" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
            </div>
        </div>
        <div class="item-block full" v-if="Invoice.Items">
            <span class="item-block-title">Regels</span>
            <div class="item-block-header" v-if="Invoice.Items.length > 0">
                <span class="item-block-header-item article">Artikel</span>
                <span class="item-block-header-item quantity">Aantal</span>
                <span class="item-block-header-item btw">BTW</span>
                <span class="item-block-header-item price">Prijs</span>
                <span class="item-block-header-item more"></span>
            </div>
            <div class="item-block-row" v-for="(Item, i) in Invoice.Items" :key="i">                
                <input v-if="Item.Type == 'Plus'" type="text" class="item-block-row-item article" v-model="Item.Article" :class="{ 'input-disabled': Invoice.Status !== 'O' }">

                <input v-if="Item.Type == 'BPM'" type="text" class="item-block-row-item bpm" disabled v-model="Item.Article" :class="{ 'input-disabled': Invoice.Status !== 'O' }">

                <InputGroup v-if="Item.Type == 'Minus'" class="item-block-row-item article min">
                    <InputGroupAddon>
                        -
                    </InputGroupAddon>
                    <InputText fluid v-model="Item.Article" :class="{ 'input-disabled': Invoice.Status !== 'O' }" />
                </InputGroup>

                <input v-if="Item.Type == 'Plus' || Item.Type == 'Minus'" type="text" class="item-block-row-item quantity" v-model="Item.Quantity" :class="{ 'input-disabled': Invoice.Status !== 'O' }">

                <select v-if="Item.Type == 'Plus' || Item.Type == 'Minus'" class="item-block-row-item btw" v-model="Item.BTW" :class="{ 'input-disabled': Invoice.Status !== 'O' }">
                    <option value="0">Marge</option>
                    <option value="9">9%</option>
                    <option value="21">21%</option>
                </select>
                
                <InputGroup v-if="Item.Type == 'Plus' || Item.Type == 'Minus'" class="item-block-row-item price">
                    <InputGroupAddon>
                        €
                    </InputGroupAddon>
                    <InputNumber fluid v-model="Item.Price" :maxFractionDigits="2" :class="{ 'input-disabled': Invoice.Status !== 'O' }" />
                </InputGroup>

                <InputGroup v-if="Item.Type == 'BPM'" class="item-block-row-item price">
                    <InputGroupAddon>
                        €
                    </InputGroupAddon>
                    <InputNumber fluid v-model="Item.BPM" :class="{ 'input-disabled': Invoice.Status !== 'O' }" />
                </InputGroup>

                <textarea v-if="Item.Type == 'Comment'" v-model="Item.Text" :class="{ 'input-disabled': Invoice.Status !== 'O' }"></textarea>

                <svg @click="Item.More = !Item.More" class="item-block-row-more" :class="{ 'element-disabled': Invoice.Status !== 'O' }" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><g><g><path d="M11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18Z" stroke="#d4d4d4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#d4d4d4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11 6C11 6.55228 11.4477 7 12 7C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6Z" stroke="#d4d4d4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></g></g></svg>

                <div class="item-block-row-shortcuts" v-if="Item.More">
                    <svg @click="Up(i)" class="item-block-row-shortcuts-shortcut" :class="{ disabled: i == 0 }" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289L19.7071 14.2929C20.0976 14.6834 20.0976 15.3166 19.7071 15.7071C19.3166 16.0976 18.6834 16.0976 18.2929 15.7071L12 9.41421L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7Z" fill="#333"></path></g></svg>
                    <svg @click="Down(i)" class="item-block-row-shortcuts-shortcut" :class="{ disabled: i == Invoice.Items.length - 1 }" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z" fill="#333"></path> </g></svg>
                    <svg @click="Remove(i)" class="item-block-row-shortcuts-shortcut" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M10 12V17" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 12V17" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                </div>
            </div>
            <div class="item-block-add" :class="{ margin: Invoice.Items.length > 0 }" v-if="Invoice.Status == 'O'">
                <span class="item-block-add-item" @click="AddRow('Plus')">Artikel toevoegen</span>
                <span class="item-block-add-item" @click="AddRow('Minus')">Min-artikel toevoegen</span>
                <span class="item-block-add-item" @click="AddRow('BPM')">Rest BPM toevoegen</span>
                <span class="item-block-add-item" @click="AddRow('Comment')">Opmerking toevoegen</span>
            </div>
        </div>
        <div class="item-block full summery">
            <div class="item-block-summery">
                <div class="item-block-summery-group">
                    <span class="item-block-summery-group-subtitle">Subtotaal</span>
                    <InputGroup class="item-block-summery-group-input disabled">
                        <InputGroupAddon>
                            €
                        </InputGroupAddon>
                        <InputNumber fluid v-model="Summery().Sub" />
                    </InputGroup>
                </div>
                <div class="item-block-summery-group" v-if="Summery().BTW">
                    <span class="item-block-summery-group-subtitle">BTW</span>
                    <InputGroup class="item-block-summery-group-input disabled">
                        <InputGroupAddon>
                            €
                        </InputGroupAddon>
                        <InputNumber fluid v-model="Summery().BTW" />
                    </InputGroup>
                </div>
                <div class="item-block-summery-group" v-if="Summery().BPM">
                    <span class="item-block-summery-group-subtitle">Rest BPM</span>
                    <InputGroup class="item-block-summery-group-input disabled">
                        <InputGroupAddon>
                            €
                        </InputGroupAddon>
                        <InputNumber fluid v-model="Summery().BPM" />
                    </InputGroup>
                </div>
                <div class="item-block-summery-group">
                    <span class="item-block-summery-group-subtitle">Totaal</span>
                    <InputGroup class="item-block-summery-group-input disabled">
                        <InputGroupAddon>
                            €
                        </InputGroupAddon>
                        <InputNumber fluid v-model="Summery().Total" />
                    </InputGroup>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Select from 'primevue/select';
import SelectButton from 'primevue/selectbutton';
import DatePicker from 'primevue/datepicker';

export default {
    name: 'BillingItem',

    components: {
        InputText,
        InputNumber,
        InputGroup,
        InputGroupAddon,
        Select,
        SelectButton,
        DatePicker
    },

    data () {
        return {
            Invoice: [],
            InvoiceStart: [],
            Clients: [],
            Status: 'O'
        }
    },

    mounted () {
        this.GetInvoice()
    },

    methods: {
        GetInvoice () {
            this.$http.get(`https://api.wheels.nl/invoices/${this.$route.params.id}`).then((response) => {
                if(response.data.invoice) {
                    this.Invoice = response.data.invoice
                    this.InvoiceStart = JSON.stringify(response.data.invoice)

                    if(response.data.clients) {
                        this.Clients = response.data.clients
                    }
                } else {
                    this.$router.push('/facturatie')
                    this.$toast.open({
                        message: 'Factuur niet gevonden',
                        position: 'bottom-right',
                        type: 'error',
                        duration: 1500
                    })
                }
            })
        },

        Patch () {
            this.$http.patch(`https://api.wheels.nl/invoices/${this.$route.params.id}`, {
                Invoice: this.Invoice
            }).then(() => {
                this.InvoiceStart = JSON.stringify(this.Invoice)

                this.$toast.open({
                    message: 'Factuur opgeslagen',
                    position: 'bottom-right',
                    type: 'success',
                    duration: 1500
                })
            })
        },

        Payd () {
            this.Invoice.Status = 'P'

            setTimeout(() => {
                this.Patch()
            }, 250)
        },

        Download () {
            window.open('https://api.wheels.nl/invoices/' + this.$route.params.id + '/file?M=View&WAT=' + this.$root.WAT + '&WDT=' + this.$root.WDT)
        },

        Delete () {
            if(confirm('Weet je zeker dat je deze factuur wilt verwijderen?')) {
                this.$http.delete(`https://api.wheels.nl/invoices/${this.$route.params.id}`).then((response) => {
                    if(response.data.error) {
                        this.$toast.open({
                            message: 'Factuur kan niet verwijderd worden',
                            position: 'bottom-right',
                            type: 'error',
                            duration: 1500
                        })
                    } else {
                        this.$router.push('/facturatie')
                        this.$toast.open({
                            message: 'Factuur verwijderd',
                            position: 'bottom-right',
                            type: 'success',
                            duration: 1500
                        })
                    }
                })
            }
        },

        AddRow (e) {
            if(e == 'Plus') {
                this.Invoice.Items.push({
                    Type: 'Plus',
                    Quantity: 1,
                    BTW: 0
                })
            } else if(e == 'Minus') {
                this.Invoice.Items.push({
                    Type: 'Minus',
                    Quantity: 1,
                    BTW: 0
                })
            } else if(e == 'BPM') {
                this.Invoice.Items.push({
                    Article: 'Rest BPM',
                    Type: 'BPM',
                    BPM: 0
                })
            } else if(e == 'Comment') {
                this.Invoice.Items.push({
                    Type: 'Comment',
                    Text: ''
                })
            }
        },

        Summery () {
            if(this.Invoice.Items) {
                var Sub = 0
                var BTW = 0
                var BPM = 0
                var Total = 0

                this.Invoice.Items.forEach(e => {
                    var ItemSub = 0
                    var ItemBTW = 0

                    if(e.Type == 'Plus' && e.Quantity && e.Price) {
                        ItemSub = Number(e.Quantity) * Number(e.Price)
                        ItemBTW = (Number(e.Quantity) * Number(e.Price)) * (e.BTW / 100)

                        Sub = Sub + ItemSub
                        BTW = BTW + ItemBTW
                    } else if(e.Type == 'Minus' && e.Quantity && e.Price) {
                        ItemSub = Number(e.Quantity) * Number(e.Price)
                        ItemBTW = (Number(e.Quantity) * Number(e.Price)) * (e.BTW / 100)

                        Sub = Sub - ItemSub
                        BTW = BTW - ItemBTW
                    } else if(e.Type == 'BPM' && e.BPM) {
                        BPM = BPM + Number(e.BPM)
                    }
                })

                if(BPM) {
                    Total = Sub + BTW + BPM
                } else {
                    Total = Sub + BTW
                }

                return {
                    Sub: Math.round(Sub),
                    BTW: Math.round(BTW),
                    BPM: Math.round(BPM),
                    Total: Math.round(Total)
                }
            } else {
                return {
                    Sub: 0,
                    BTW: 0,
                    Total: 0
                }
            }
        },

        Up (i) {
            var Temp = this.Invoice.Items[i]
            this.Invoice.Items.splice(i - 1, 0, Temp)
            this.Invoice.Items.splice(i + 1, 1)
        },

        Down (i) {
            var Temp = this.Invoice.Items[i]
            this.Invoice.Items.splice(i, 1)
            this.Invoice.Items.splice(i + 1, 0, Temp)
        },

        Remove (i) {
            this.Invoice.Items.splice(i, 1)
        }
    },

    beforeRouteLeave (to, from , next) {
        if(JSON.stringify(this.Invoice) !== this.InvoiceStart) {
            const answer = window.confirm('Weet je zeker dat je de factuur wilt sluiten? Wijzigingen worden niet opgeslagen.')
            if (answer) {
                next()
            } else {
                next(false)
            }
        } else {
            next()
        }
    }
}
</script>

<style scoped>
.item {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 25px;
    max-width: 1200px;
    margin: auto;
    padding: 50px;
    overflow: auto;
}

.item .item-block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 15px 25px;
    width: calc(50% - 12.5px);
    padding: 25px;
    background: #fff;
    border-radius: 5px;
}

.item .item-block.full {
    width: 100%;
}

.item .item-block.notice {
    background: #ffffe0;
}

.item .item-block.summery {
    background: unset;
    padding: 0 25px;
}

.item .item-block.buttons {
    width: 100%;
    gap: 15px;
}

.item .item-block .item-block-title {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
}

.item .item-block .item-block-group {
    width: calc(50% - 12.5px);
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.item .item-block .item-block-group.full {
    width: 100%;
}

.item .item-block .item-block-group .item-block-group-name {
    font-weight: 700;
}

.item .item-block .item-block-group .item-block-group-input {
    height: 40px;
    padding: 0 10px;
    outline: 0;
    border: solid 1px #cbd5e1;
    border-radius: 5px;
}

.item .item-block .item-block-group .item-block-group-input:disabled {
    opacity: 0.5;
}

.item .item-block .item-block-group .item-block-group-input.date {
    padding: 0;
}

.item .item-block .item-block-group .item-block-group-input.date:deep(.p-inputtext) {
    background: unset;
    border: unset;
}

.item .item-block .item-block-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    background: #333333;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.item .item-block .item-block-button.blue {
    background: var(--primary);
}

.item .item-block .item-block-button.green {
    background: #8fbc8f;
}

.item .item-block .item-block-button.red {
    background: #cd5c5c;
}

.item .item-block .item-block-button svg {
    width: 18px;
    height: 18px;
}

.item .item-block .item-block-spacer {
    width: 8px;
    height: 8px;
    margin: auto 8px;
    background: var(--primary);
    border-radius: 50%;
}

.item .item-block .item-block-header {
    width: 100%;
    display: flex;
    gap: 15px;
}

.item .item-block .item-block-header-item.article {
    width: calc(60% - 90px);
}

.item .item-block .item-block-header-item.quantity {
    width: 10%;
}

.item .item-block .item-block-header-item.btw {
    width: 10%;
}

.item .item-block .item-block-header-item.price {
    min-width: 20%;
    max-width: 20%;
}

.item .item-block .item-block-header-item.more {
    min-width: 30px;
    max-width: 30px;
}

.item .item-block .item-block-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.item .item-block .item-block-row .item-block-row-item.article {
    width: calc(60% - 90px);
}

.item .item-block .item-block-row .item-block-row-item.article.min {
    padding: 0;
    border: 0;
}

.item .item-block .item-block-row .item-block-row-item.bpm {
    width: calc(80% - 60px);
}

.item .item-block .item-block-row .item-block-row-item.quantity {
    width: 10%;
}

.item .item-block .item-block-row .item-block-row-item.btw {
    width: 10%;
}

.item .item-block .item-block-row .item-block-row-item.price {
    min-width: 20%;
    max-width: 20%;
    padding: 0;
    border: 0;
}

.item .item-block .item-block-row .item-block-row-item {
    width: 60%;
    height: 40px;
    padding: 0 10px;
    outline: 0;
    border: solid 1px #cbd5e1;
    border-radius: 6px;
}

.item .item-block .item-block-row .item-block-row-more {
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    margin: auto;
    cursor: pointer;
}

.item .item-block .item-block-row .item-block-row-shortcuts {
    display: flex;
    gap: 5px;
    margin: 0 45px 0 auto;
}

.item .item-block .item-block-row .item-block-row-shortcuts-shortcut {
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    padding: 5px;
    border: solid 1px #cbd5e1;
    border-radius: 5px;
    cursor: pointer;
}

.item .item-block .item-block-row .item-block-row-shortcuts-shortcut.disabled {
    pointer-events: none;
    opacity: 0.25;
}

.item .item-block .item-block-row textarea {
    width: calc(100% - 45px);
    min-height: 120px;
    padding: 10px;
    outline: 0;
    border: solid 1px #cbd5e1;
    border-radius: 5px;
    resize: none;
}

.item .item-block .item-block-add {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.item .item-block .item-block-add.margin {
    margin: 25px 0 0 0;
}

.item .item-block .item-block-add .item-block-add-item {
    display: flex;
    padding: 10px 15px;
    background: #f7f8fc;
    cursor: pointer;
    border-radius: 5px;
}

.item .item-block .item-block-summery {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 0 0 auto;
}

.item .item-block .item-block-summery .item-block-summery-group {
    display: flex;
    align-items: center;
}

.item .item-block .item-block-summery .item-block-summery-group .item-block-summery-group-subtitle {
    width: 100px;
}

.item .item-block .item-block-summery .item-block-summery-group .item-block-summery-group-input {
    width: 200px;
}

.item .item-block .item-block-summery .item-block-summery-group .item-block-summery-group-input.disabled {
    pointer-events: none;
}





/* PAYD */
.input-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.element-disabled {
    pointer-events: none;
    opacity: 0.5;
}
</style>