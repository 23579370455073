<template>
    <div class="overview">
        <span class="create" @click="CreateNarrowcasting()">Maak nieuw scherm aan</span>
        <div class="block" v-for="(Screen, i) in Screens" :key="Screen.Identifier">
            <span class="subtitle">Code: <b>{{ Screen.Code }}</b></span>

            <draggable v-model="Screen.Items" class="media" item-key="id" ghost-class="ghost" chosen-class="chosen" drag-class="drag">
                <template #item="{element, index}">
                    <div class="media-item" :style="{ background: '#fff url(' + element.ImageURL + ')' }">
                        <svg v-if="element.Type == 'webm'" class="video" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M16 10L18.5768 8.45392C19.3699 7.97803 19.7665 7.74009 20.0928 7.77051C20.3773 7.79703 20.6369 7.944 20.806 8.17433C21 8.43848 21 8.90095 21 9.8259V14.1741C21 15.099 21 15.5615 20.806 15.8257C20.6369 16.056 20.3773 16.203 20.0928 16.2295C19.7665 16.2599 19.3699 16.022 18.5768 15.5461L16 14M6.2 18H12.8C13.9201 18 14.4802 18 14.908 17.782C15.2843 17.5903 15.5903 17.2843 15.782 16.908C16 16.4802 16 15.9201 16 14.8V9.2C16 8.0799 16 7.51984 15.782 7.09202C15.5903 6.71569 15.2843 6.40973 14.908 6.21799C14.4802 6 13.9201 6 12.8 6H6.2C5.0799 6 4.51984 6 4.09202 6.21799C3.71569 6.40973 3.40973 6.71569 3.21799 7.09202C3 7.51984 3 8.07989 3 9.2V14.8C3 15.9201 3 16.4802 3.21799 16.908C3.40973 17.2843 3.71569 17.5903 4.09202 17.782C4.51984 18 5.07989 18 6.2 18Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                        <svg v-if="!element.Identifier" class="add" @click="this.$root.OpenMedia(element, 'Image')" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g><g><g data-name="add" id="add-2"> <g> <line fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line> <line fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line> </g> </g> </g> </g></svg>
                        <svg v-if="element.Identifier" class="delete" @click="RemoveItem(i, index)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M10 12V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 12V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    </div>
                </template>
            </draggable>

            <span class="subtitle">Opties</span>
            <div class="group">
                <span>Nieuws</span>
                <select v-model="Screen.News">
                    <option :value="1">Ja</option>
                    <option :value="0">Nee</option>
                </select>
            </div>
            <div class="group">
                <span>Tijd van afbeeldingen</span>
                <select v-model="Screen.Length" @change="SetLength(Screen.Items, $event)">
                    <option :value="5">5 seconden</option>
                    <option :value="10">10 seconden</option>
                    <option :value="15">15 seconden</option>
                    <option :value="20">20 seconden</option>
                    <option :value="25">25 seconden</option>
                    <option :value="30">30 seconden</option>
                </select>
            </div>

            <div class="buttons">
                <span class="save" @click="SaveNarrowcasting(Screen.Code, Screen)">Updaten</span>
                <span class="delete" @click="DeleteNarrowcasting(Screen.Code)">Verwijderen</span>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: 'NarrowcastingOverview',

    components: {
        draggable
    },

    data () {
        return {
            Screens: []
        }
    },

    mounted () {
        this.GetStore()

        var self = this
        this.$mitt.on('ImageSelected', (e) => { self.ChangeMedia(e); })
    },
    
    methods: {
        GetStore () {
            this.$http.get(`https://api.wheels.nl/narrowcasting`).then((response) => {
                if(response.data.screens) { this.Screens = response.data.screens }
            })
        },

        CreateNarrowcasting () {
            this.$http.get(`https://api.wheels.nl/narrowcasting/create`).then(() => {
                location.reload()
            })
        },

        SaveNarrowcasting (e, a) {
            this.$http.patch(`https://api.wheels.nl/narrowcasting/${e}`, {
                Items: a.Items.filter(item => item.Image || item.Video),
                News: a.News,
                Length: a.Length
            }).then(() => {
                this.$toast.open({
                    message: 'Scherm geüpdate',
                    position: 'bottom-right',
                    type: 'success',
                    duration: 2500
                })
            })
        },

        DeleteNarrowcasting (e) {
            let DeleteNarrowcastingPrompt = prompt('Voer de code ' + e + ' in om het scherm te verwijderen.');
            switch(DeleteNarrowcastingPrompt) {
                case e:
                    this.$http.delete(`https://api.wheels.nl/narrowcasting/${e}`).then(() => {
                        location.reload()
                    })
                    break;
            }
        },

        ChangeMedia (e) {
            this.Screens.forEach((a) => {
                a.Items.forEach((z) => {
                    if(z.Active == e.Focus) {
                        z.Identifier = e.Selected

                        this.SetMedia(z, e.Selected, a)
                    }
                })
            })
        },

        SetMedia (e, a, z) {
            this.$http.get(`https://api.wheels.nl/pages/media/${a}`).then((response) => {
                e.Image = a,
                e.ImageURL = response.data.Image
                e.Type = response.data.Type
                e.Alt = response.data.Alt

                if(response.data.Video) {
                    e.Video = response.data.Video
                    e.Duration = response.data.Duration
                } else if(z.Length) {
                    e.Duration = z.Length
                } else {
                    e.Duration = 15
                }
            })

            z.Items.push({
                Active: false,
                Identifier: false
            })
        },

        SetLength (e, z) {
            e.forEach((a) => {
                if(!a.Video) {
                    a.Duration = z.target.value
                }
            })
        },

        RemoveItem (e, a) {
            this.Screens[e].Items.splice(a, 1)
        }
    }
}
</script>

<style scoped>
.overview {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    padding: 50px;
    background: #f7f8fc;
    overflow: auto;
}

.overview .create {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-height: 50px;
    padding: 0 25px;
    background: var(--primary);
    color: #fff;
    font-weight: 600;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
    cursor: pointer;
}

.overview .block {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px;
    background: #fff;
    border: solid 1px #ededed;
    border-radius: 10px;
}

.overview .block .subtitle {
    font-size: 20px;
    font-weight: 500;
}

.overview .block .subtitle b {
    font-size: 20px;
    font-weight: 700;
}

.overview .block .group {
    position: relative;
    display: flex;
    align-items: center;
    gap: 25px;
}

.overview .block .group span {
    width: 25%;
}

.overview .block .group select {
    width: 75%;
    height: 40px;
    padding: 0 35px 0 10px;
    background: #fff;
    border: solid 1px #ededed;
    border-radius: 5px;
    outline: 0;
}

.overview .block .media {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin: 0 0 35px 0;
}

.overview .block .media-item {
    position: relative;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 50% !important;
    background-size: cover !important;
    border: dashed 1px #797979;
    border-radius: 5px;
    transition: .25s;
    cursor: pointer;
}

.overview .block .media-item:hover {
    outline: dashed 2px var(--primary);
}

.overview .block .media-item.ghost {
    outline: dashed 2px var(--primary);
    opacity: 0.5;
}

.overview .block .media-item.drag {
    transform: rotate(-2.5deg) scale(1.025) !important;
    box-shadow: 0 0 rgba(0, 0, 0, 0.250);
}

.overview .block .media-item.chosen {
    outline: dashed 2px var(--primary);
    transform: rotate(-2.5deg) scale(1.025) !important;
    box-shadow: 0 0 rgba(0, 0, 0, 0.250);
}

.overview .block .media .media-item .add {
    width: 96px;
    height: 96px;
    padding: 24px;
    cursor: pointer;
}

.overview .block .media .media-item .video {
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 30px;
    height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
}

.overview .block .media .media-item .delete {
    display: none;
    position: absolute;
    left: 15px;
    bottom: 15px;
    width: 30px;
    height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.overview .block .media .media-item:hover .delete {
    display: block;
}

.overview .block .buttons {
    display: flex;
    gap: 25px;
    margin: 25px 0 0 0;
}

.overview .block .buttons .save {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    width: 150px;
    height: 50px;
    background: var(--primary);
    color: #fff;
    font-weight: 600;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
    cursor: pointer;
}

.overview .block .buttons .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    width: 150px;
    height: 50px;
    background: #cd5c5c;
    color: #fff;
    font-weight: 600;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
    cursor: pointer;
}
</style>