<template>
    <div class="bar" v-if="this.$root.Expert">
        <router-link to="/" class="logo">
            <img :src="this.$root.Expert.header.logo" alt="Header Wheels">
        </router-link>
        <div class="search">
            <input type="text" class="search-input" :placeholder="this.$root.Expert.header.placeholder" :style="{ background: SetSearchBackground(this.$root.Expert.header.background), color: this.$root.Expert.header.color }">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><g><path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" :stroke="{ color: this.$root.Expert.header.color }"></path></g></svg>
        </div>
        <div class="shortcuts">
            Shortcuts
        </div>
    </div>
</template>

<script>
import { colord } from "colord"

export default {
    name: 'Menubar',

    methods: {
        SetSearchBackground (e) {
            return colord(e).lighten(0.05).toHex()
        }
    }
}
</script>

<style scoped>
.bar {
    display: flex;
    align-items: center;
    padding: 0 50px;
}

.bar .logo {
    width: 250px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto 0 0;
}

.bar .logo img {
    max-height: 80px;
    max-width: 200px;
}

.search {
    position: relative;
    width: calc(100% - 500px);
    max-width: 500px;
    display: flex;
    justify-content: center;
}

.search .search-input {
    width: 100%;
    height: 38px;
    padding: 0 25px;
    background: #4f5567;
    background: #333;
    color: #fff;
    text-align: center;
    outline: 0;
    border: 0;
    border-radius: 5px;
}

.search svg {
    position: absolute;
    top: 7.5px;
    right: 15px;
    width: 24px;
    height: 24px;
}

.search .search-input:focus {
    border: solid 1.5px #6e7280;
}

.search .search-input:focus ~ svg {
    stroke: #fff;
    transition: .25s;
}

.search .search-input::placeholder {
    color: #fff;
    transition: .25s;
}

.search .search-input:focus::placeholder {
    color: transparent;
}

.shortcuts {
    width: 250px;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    margin: 0 0 0 auto;
}





/* SMALL MENU */
.bar.small {
    padding: 0 25px;
}
</style>