<template>
    <div class="create">
        <span class="create-title">Snel een dossier aanmaken</span>
        <div class="create-group">
            <span class="create-group-title">Soort voertuig</span>
            <Select v-model="Create.Type" :options="[{ Label: 'M1 - Personenauto', Value: 'M1' }, { Label: 'N1 - Bedrijfsauto', Value: 'N1' }, { Label: 'L3E - Motoren', Value: 'L3E' }, { Label: 'M1C - Camper', Value: 'M1C' }]" optionLabel="Label" optionValue="Value" />
        </div>
        <div class="create-group" v-if="Create.Type">
            <span class="create-group-title">Kies een methode</span>
            <Select v-model="Create.Method" :options="[{ Label: 'Chassisnummer', Value: 'VIN' }, { Label: 'Kenteken', Value: 'License' }, { Label: 'Merk en model', Value: 'Free' }]" optionLabel="Label" optionValue="Value" />
        </div>
        <div class="create-group half" v-if="Create.Method == 'VIN' || Create.Method == 'Free'">
            <span class="create-group-title">Datum eerste tenaamstelling</span>
            <InputMask v-model="Create.Registration" mask="99-99-9999" placeholder="DD-MM-YYYY" @blur="GetModels()" />
        </div>
        <div class="create-group half" v-if="Create.Method == 'License'">
            <span class="create-group-title">Kenteken</span>
            <InputText type="text" v-model="Create.License" v-keyfilter.alphanum placeholder="XXX-XXX-XXX" :maxlength="6" class="create-group-input" @keyup="Create.License = Create.License.toUpperCase()" />
        </div>
        <div class="create-group half" v-if="Create.Method == 'VIN' && /^[0-9]{2}[-|\/]{1}[0-9]{2}[-|\/]{1}[0-9]{4}$/.test(Create.Registration)">
            <span class="create-group-title">{{ SetVin(Create.VIN) }}</span>
            <InputText v-model="Create.VIN" placeholder="Minimaal 4 tekens" :maxlength="17" class="create-group-input" @keyup="Create.VIN = Create.VIN.toUpperCase()" />
        </div>
        <div class="create-group half" v-if="Create.Method == 'License' && Create.License.length == 6">
            <span class="create-group-title">Kilometerstand</span>
            <InputNumber class="group-input" v-model="Create.Mileage" fluid />
        </div>
        <div class="create-group half" v-if="Create.Method == 'Free' && /^[0-9]{2}[-|\/]{1}[0-9]{2}[-|\/]{1}[0-9]{4}$/.test(Create.Registration)">
            <span class="create-group-title">Selecteer een model</span>
            <InputText v-model="Create.Models.Search" placeholder="Zoeken..." @focus="Create.Models.Active = true" @blur="CloseModels()" :onKeydown="SetFirstModel" />
            <div class="create-group-models" v-if="Create.Models.Active && Create.Models.Search && Create.Models.Items.filter(e => e.ModelName.toLowerCase().includes(Create.Models.Search.toLowerCase())).length > 0">
                <div class="create-group-models-model" v-for="(Model, i) in Create.Models.Items.filter(e => e.ModelName.toLowerCase().includes(Create.Models.Search.toLowerCase()))" :key="i" @click="SetModel(Model)">
                    <div class="create-group-models-model-item">{{ Model.BrandName }} <b>{{ Model.ModelName }}</b></div>
                </div>
            </div>
        </div>
        <div class="create-group" v-if="(Create.Method == 'VIN' && /^[0-9]{2}[-|\/]{1}[0-9]{2}[-|\/]{1}[0-9]{4}$/.test(Create.Registration) && Create.VIN.length >= 4) || (Create.Method == 'License' && Create.License.length == 6 && Create.Mileage) || (Create.Method == 'Free' && /^[0-9]{2}[-|\/]{1}[0-9]{2}[-|\/]{1}[0-9]{4}$/.test(Create.Registration) && Create.Model)">
            <button class="create-group-button" @click="CreateVehicle()">Aanmaken</button>
        </div>
        <div class="create-group-loader" v-if="Create.Loader">
            <svg class="create-group-loader-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="12" cy="20" r="2"></circle><circle cx="12" cy="4" r="2"></circle><circle cx="6.343" cy="17.657" r="2"></circle><circle cx="17.657" cy="6.343" r="2"></circle><circle cx="4" cy="12" r="2.001"></circle><circle cx="20" cy="12" r="2"></circle><circle cx="6.343" cy="6.344" r="2"></circle><circle cx="17.657" cy="17.658" r="2"></circle></g></svg>
        </div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputMask from 'primevue/inputmask';
import Select from 'primevue/select';

export default {
    name: 'DashboardCreate',

    components: {
        InputMask,
        InputText,
        InputNumber,
        Select
    },
    
    data () {
        return {
            Create: {
                Loader: false,
                Type: '',
                Method: '',
                VIN: '',
                License: '',
                Registration: '',
                Model: '',
                Models: {
                    Active: false,
                    Search: '',
                    Items: [],
                }
            }
        }
    },

    methods: {
        GetModels () {
            this.$http.get(`https://api.wheels.nl/vehicles/models/create?type=${this.Create.Type}&registration=${this.Create.Registration}`).then((response) => {
                if(response.data.models) { this.Create.Models.Items = response.data.models }
            })
        },

        CreateVehicle () {
            this.Create.Loader = true
            this.$http.post(`https://api.wheels.nl/vehicles`, {
                Registration: this.Create.Registration,
                Model: this.Create.Model,
                Type: this.Create.Type
            }).then((response) => {
                this.Create.Loader = false
                this.$router.push('/voertuigen/' + response.data.link)
            })
        },

        SetModel (e) {
            this.Create.Model = e.ModelIdentifier
            this.Create.Models.Search = e.BrandName + ' ' + e.ModelName
        },

        SetFirstModel (e) {
            var List = this.Create.Models.Items.filter(e => e.ModelName.toLowerCase().includes(this.Create.Models.Search.toLowerCase()))

            if(e.keyCode == 13 && List.length > 0) {
                this.Create.Model = List[0].ModelIdentifier
                this.Create.Models.Search = List[0].BrandName + ' ' + List[0].ModelName
            }
        },


        SetVin (e) {
            if(e && e.length == 4) {
                return 'Meldcode (' + e.length + ')'
            } else if(e && e.length > 0) {
                return 'Chassisnummer (' + e.length + ')'
            } else {
                return 'Chassisnummer'
            }
        },

        CloseModels () {
            setTimeout(() => {
                this.Create.Models.Active = false
            }, 100)
        }
    }
}
</script>

<style scoped>
.create {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding: 25px;
    background: #fff;
    border: 0;
    border-radius: 10px;
}

.create .create-title {
    font-size: 20px;
    font-weight: 600;
}

.create .create-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.create .create-group.half {
    width: calc(50% - 12.5px);
}

.create .create-group .create-group-title {
    font-weight: 700;
}

.create .create-group .create-group-button {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0 15px;
    color: #fff;
    font-weight: 600;
    background: var(--primary);
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}

.create .create-group .create-group-method {
    width: 100%;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    overflow: hidden;
}

.create .create-group .create-group-method .create-group-method-item {
    position: relative;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 15px;
    background: #fff;
    border: solid 2px #ededed;
    border-radius: 5px;
    cursor: pointer;
}

.create .create-group .create-group-method .create-group-method-item.active {
    border: solid 2px var(--primary);
}

.create .create-group .create-group-method .create-group-method-item.disabled {
    opacity: 0.5;
}

.create .create-group .create-group-method .create-group-method-item .create-group-method-item-bubble {
    width: fit-content;
    padding: 2.5px 10px;
    margin: 0 0 -20px 0;
    background: #f7f8fc;
    color: #333;
    letter-spacing: 5px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 5px;
}

.create .create-group .create-group-method .create-group-method-item.active .create-group-method-item-bubble {
    background: #fff;
    color: #222222;
}

.create .create-group .create-group-models {
    width: 100%;
    max-height: 250px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #ffffff;
    padding: 15px;
    margin: -12px 0 0 0;
    outline: solid 1px transparent;
    border: solid 1px #10b981;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    overflow: auto;
}

.create .create-group .create-group-models .create-group-models-model {
    cursor: pointer;
}

.create .create-group .create-group-models .create-group-models-model b {
    font-weight: 700;
}

.create .create-group-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffffbf;
}

.create .create-group-loader .create-group-loader-svg {
    width: 100px;
    animation: spin 2.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}




@media screen and (max-width: 1350px) {
    .create .create-group {
        width: 100% !important;
    }
}
</style>