import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '../views/Dashboard/Overview.vue'

import Login from '../views/Authenticate/Login.vue'

import ClientOverview from '../views/Clients/Overview.vue'
import ClientItem from '../views/Clients/Item.vue'

import VehiclesDashboard from '../views/Vehicles/Dashboard.vue'
import VehiclesList from '../views/Vehicles/List.vue'
import VehiclesItem from '../views/Vehicles/Item.vue'
import VehiclesCreate from '../views/Vehicles/Create.vue'

import ArticlesOverview from '../views/Articles/Overview.vue'
import ArticleItem from '../views/Articles/Item.vue'

import Filters from '../views/Filters/Overview.vue'

import Stores from '../views/Stores/Overview.vue'
import StoresItem from '../views/Stores/Item.vue'

import WebsitePages from '../views/Website/Pages.vue'
import WebsiteStock from '../views/Website/Stock.vue'
import WebsiteBlogs from '../views/Website/Blogs.vue'
import WebsitePage from '../views/Website/Page.vue'
import WebsiteMenu from '../views/Website/Menu.vue'

import BillingOverview from '../views/Billing/Overview.vue'
import BillingItem from '../views/Billing/Item.vue'

import LeadsOverview from '../views/Inbox/Overview.vue'

import NarrowcastingOverview from '../views/Narrowcasting/Overview.vue'
import NarrowcastingScreen from '../views/Narrowcasting/Screen.vue'

import SettingsOverview from '../views/Settings/Overview.vue'

import ErrorOverview from '../views/Layout/404.vue'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        meta: {
            Auth: true,
            Title: 'Dashboard',
            Layout: 'small'
        },
        component: Dashboard
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            Title: 'Inloggen',
            Layout: 'full'
        },
        component: Login
    },
    {
        path: '/klanten/:id',
        name: 'ClientItem',
        meta: {
            Auth: true,
            Title: 'Klanten',
            Module: 'Clients',
            Layout: 'wide'
        },
        component: ClientItem
    },
    {
        path: '/klanten',
        name: 'ClientOverview',
        meta: {
            Auth: true,
            Title: 'Klanten',
            Module: 'Clients'
        },
        component: ClientOverview
    },
    {
        path: '/voertuigen/aanmaken',
        name: 'VehiclesCreate',
        meta: {
            Auth: true,
            Title: 'Voertuig aanmaken',
            Menu: 'Voertuigen',
            Module: 'Vehicles',
            Layout: 'wide'
        },
        component: VehiclesCreate
    },
    {
        path: '/voertuigen/:id',
        name: 'VehiclesItem',
        meta: {
            Auth: true,
            Title: 'Voertuig',
            Menu: 'Voertuigen',
            Module: 'Vehicles',
            Layout: 'wide'
        },
        component: VehiclesItem
    },
    {
        path: '/voertuigen/lijst',
        name: 'VehiclesList',
        meta: {
            Auth: true,
            Title: 'Voertuigen',
            Menu: 'Voertuigen',
            Module: 'Vehicles',
            Layout: 'wide'
        },
        component: VehiclesList
    },
    {
        path: '/voertuigen',
        name: 'VehiclesDashboard',
        meta: {
            Auth: true,
            Title: 'Voertuigen',
            Menu: 'Voertuigen',
            Module: 'Vehicles',
            Layout: 'wide'
        },
        component: VehiclesDashboard
    },
    {
        path: '/tweewielers/:id',
        name: 'ArticleItem',
        meta: {
            Auth: true,
            Title: 'Artikelen',
            Module: 'Articles',
            Layout: 'wide'
        },
        component: ArticleItem
    },
    {
        path: '/tweewielers',
        name: 'ArticlesOverview',
        meta: {
            Auth: true,
            Title: 'Artikelen',
            Module: 'Articles',
            Layout: 'wide'
        },
        component: ArticlesOverview
    },
    {
        path: '/filters',
        name: 'Filters',
        meta: {
            Auth: true,
            Title: 'Filters',
            Module: 'Filters',
            Layout: 'wide'
        },
        component: Filters
    },
    {
        path: '/winkels/:id',
        name: 'StoresItem',
        meta: {
            Auth: true,
            Title: 'Winkels',
            Module: 'Stores',
            Layout: 'wide'
        },
        component: StoresItem
    },
    {
        path: '/winkels',
        name: 'Stores',
        meta: {
            Auth: true,
            Title: 'Winkels',
            Module: 'Stores',
            Layout: 'wide'
        },
        component: Stores
    },
    {
        path: '/website/:page/:id',
        name: 'WebsitePage',
        meta: {
            Auth: true,
            Title: 'Pagina',
            Menu: 'Website',
            Module: 'Website',
            Layout: 'wide'
        },
        component: WebsitePage
    },
    {
        path: '/website/pagina',
        name: 'WebsitePages',
        meta: {
            Auth: true,
            Title: 'Paginas',
            Menu: 'Website',
            Module: 'Website',
            Layout: 'small'
        },
        component: WebsitePages
    },
    {
        path: '/website/menu',
        name: 'WebsiteMenu',
        meta: {
            Auth: true,
            Title: 'Menu',
            Menu: 'Website',
            Module: 'Website',
            Layout: 'small'
        },
        component: WebsiteMenu
    },
    {
        path: '/website/voorraad',
        name: 'WebsiteStock',
        meta: {
            Auth: true,
            Title: 'Voorraadpagina',
            Menu: 'Website',
            Module: 'Website',
            Layout: 'small'
        },
        component: WebsiteStock
    },
    {
        path: '/website/blog',
        name: 'WebsiteBlogs',
        meta: {
            Auth: true,
            Title: 'Blogs',
            Menu: 'Website',
            Module: 'Website',
            Layout: 'small'
        },
        component: WebsiteBlogs
    },
    {
        path: '/facturatie/:id',
        name: 'BillingItem',
        meta: {
            Auth: true,
            Title: 'Facturatie',
            Menu: 'Billing',
            Module: 'Billing',
            Layout: 'wide'
        },
        component: BillingItem
    },
    {
        path: '/facturatie',
        name: 'Billing',
        meta: {
            Auth: true,
            Title: 'Facturatie',
            Menu: 'Billing',
            Module: 'Billing',
            Layout: 'wide'
        },
        component: BillingOverview
    },
    {
        path: '/inbox/:id?',
        name: 'LeadsOverview',
        meta: {
            Auth: true,
            Title: 'Leads',
            Module: 'Inbox',
            Layout: 'wide'
        },
        component: LeadsOverview
    },
    {
        path: '/narrowcasting',
        name: 'NarrowcastingScreen',
        meta: {
            Auth: false,
            Title: 'Narrowcasting',
            Module: 'Narrowcasting'
        },
        component: NarrowcastingScreen
    },
    {
        path: '/schermen',
        name: 'NarrowcastingOverview',
        meta: {
            Auth: true,
            Title: 'Schermen',
            Module: 'Narrowcasting',
            Layout: 'wide'
        },
        component: NarrowcastingOverview
    },
    {
        path: '/instellingen',
        name: 'SettingsOverview',
        meta: {
            Auth: true,
            Title: 'Instellingen',
            Layout: 'small'
        },
        component: SettingsOverview
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'ErrorPage',
        meta: {
            Auth: true,
            Title: '404',
            Layout: 'wide'
        },
        component: ErrorOverview
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if(localStorage.getItem('Modules') && to.meta.Auth && to.meta.Module && !JSON.parse(localStorage.getItem('Modules'))[to.meta.Module]) {
        router.push({ path: '/dashboard' })
    }

    // SET LOADER
    setTimeout(() => {
        document.querySelector('.bar-loader').classList.add('active')
    }, 250)

    setTimeout(() => {
        document.querySelector('.bar-loader .fill').classList.add('third')
    }, 500)

    // CHECK AUTH
    if(to.meta.Auth && !localStorage.getItem('WAT')) {
        router.push({ path: '/login' })
    } else {
        next()
    }

    // SET TITLE
    if(to.meta.Title) {
        document.title = to.meta.Title + ' | Wheels'
    } else {
        document.title = 'Wheels'
    }
})

router.afterEach(() => {
    setTimeout(() => {
        document.querySelector('.bar-loader .fill').classList.remove('third')
        document.querySelector('.bar-loader .fill').classList.add('full')
    }, 1000)

    setTimeout(() => {
        document.querySelector('.bar-loader').classList.remove('active')
    }, 1500)

    setTimeout(() => {
        document.querySelector('.bar-loader .fill').classList.remove('full')
    }, 2000)
})

export default router