<template>
    <div class="login" v-if="this.$root.Expert">
        <div class="left">
            <div class="block">
                <span class="title">{{ this.$root.Expert.login.title }}</span>
                <span class="subtitle">{{ this.$root.Expert.login.description }}</span>
            </div>
        </div>
        <div class="right" :style="{ background: this.$root.Expert.login.background }">
            <img class="logo" :src="this.$root.Expert.login.logo" alt="Login Logo">
            <div class="form" v-if="View == 'Login'">
                <div class="group">
                    <span class="title">E-mailadres</span>
                    <input type="text" v-model="Email">
                </div>
                <div class="group">
                    <span class="title">Wachtwoord</span>
                    <input type="password" v-model="Password" @keyup.enter="Login()">
                </div>
                <div class="group">
                    <button @click="Login" :class="{ disabled: (!Email || !Password) || Loader }" :style="{ background: this.$root.Expert.global.primary }">
                        <span v-if="!Loader">Inloggen</span>
                        <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path d="M12 3V6M12 18V21M6 12H3M21 12H18M5.63672 5.63672L7.75977 7.75977M16.2422 16.2422L18.3633 18.3633M18.3652 5.63477L16.2441 7.75586M7.75781 16.2422L5.63477 18.3652" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </button>
                </div>
                <span class="error" v-if="Error">Gegevens niet bekend</span>
            </div>
            <div class="form" v-if="View == 'Reset'">
                <div class="group">
                    <span class="title">E-mailadres</span>
                    <input type="text" v-model="Email">
                </div>
                <div class="group">
                    <button @click="Reset" :class="{ disabled: !Email || Loader }" :style="{ background: this.$root.Expert.global.primary }">
                        <span v-if="!Loader">Wachtwoord herstellen</span>
                        <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path d="M12 3V6M12 18V21M6 12H3M21 12H18M5.63672 5.63672L7.75977 7.75977M16.2422 16.2422L18.3633 18.3633M18.3652 5.63477L16.2441 7.75586M7.75781 16.2422L5.63477 18.3652" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </button>
                </div>
            </div>
            <span class="reset" v-if="View == 'Login'" @click="View = 'Reset'">Ik ben mijn wachtwoord vergeten</span>
            <span class="reset" v-if="View == 'Reset'" @click="View = 'Login'">Inloggen met e-mail en wachtwoord</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AuthenticateLogin',

    data () {
        return {
            View: 'Login',
            Loader: false,
            Error: false,
            Email: '',
            Password: ''
        }
    },

    mounted () {
        if(localStorage.getItem('WAT')) {
            location.href = '/'
        }
    },

    methods: {
        async Login () {
            this.Error = false
            this.Loader = true
            await this.$http.post('https://api.wheels.nl/auth/login', {
                Email: this.Email,
                Password: this.Password
            }).then((response) => {
                if(response.data.auth_token) {
                    localStorage.setItem('WAT', response.data.auth_token)
                    localStorage.setItem('WDT', response.data.device_token)

                    this.$root.SetModules(response.data.modules)

                    if(this.$route.query.redirectUrl) {
                        location.href = this.$route.query.redirectUrl
                    } else {
                        location.href = '/'
                    }
                } else {
                    this.Loader = false
                    this.Error = true
                    this.Password = ''
                }
            })
        },

        async Reset (Email) {
            await this.$http.post('https://api.wheels.nl/auth/reset', {
                Email
            })
        }
    }
}
</script>

<style scoped>
.login {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: unset;
}

.login .left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login .left .block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    width: 600px;
    max-width: 90%;
}

.login .left .block .title {
    font-size: 18px;
    font-weight: 300;
}

.login .left .block .subtitle {
    font-size: 72px;
    font-weight: 500;
    line-height: 80px;
}

.login .right {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    background: #222;
}

.login .right .logo {
    width: 200px;
}

.login .right .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    width: 500px;
    height: 400px;
    max-width: 90%;
    padding: 0 50px;
    background: #fff;
    border-radius: 5px;
}

.login .right .form .group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.login .right .form .group .title {
    font-size: 18px;
    font-weight: 500;
    padding: 0 0 0 15px;
}

.login .right .form .group input {
    height: 60px;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 300;
    background: #eaeaea;
    border: 0;
    border-radius: 5px;
    outline: 0;
}

.login .right .form .group button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 0 10px;
    background: #c7c7c7;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    outline: 0;
}

.login .right .form .group button.disabled {
    pointer-events: none;
    opacity: 0.75;
}

.login .right .form .group button span {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}

.login .right .form .group button svg {
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.login .right .form .error {
    margin: -15px 0 0 0;
    color: red;
    text-align: center;
}

.login .right .reset {
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    margin: -15px 0 0 0;
}





@media only screen and (max-width: 1000px) {
    .login .left {
        display: none;
    }

    .login .right {
        width: 100%;
        padding: 25px;
    }

    .login .right .form {
        width: 100%;
        max-width: unset;
        padding: 25px;
    }
}
</style>