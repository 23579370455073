<template>
    <div class="tasks">
        <span class="title">Taken</span>
        <div class="list" v-if="Tasks.length > 0">
            <div class="list-item" v-for="(Item, i) in Tasks" :key="i" :class="{ completed: Item.Completed }">
                <div class="checkbox" @click="Item.Completed = !Item.Completed, this.$parent.CompleteTask(Item)" :class="{ checked: Item.Completed }"></div>
                <router-link :to="'/voertuigen/' + Item.Vehicle" class="information">
                    <span class="text" v-if="Item.Date"><b>{{ Item.Text }}</b> {{ SetDate(Item.Date) }}</span>
                    <span class="text" v-else><b>{{ Item.Text }}</b></span>
                    <span class="dossier" v-if="Item.VIN">{{ Item.Brand + ' ' + Item.Model + ' • ' + Item.VIN }}</span>
                    <span class="dossier" v-else-if="Item.License">{{ Item.Brand + ' ' + Item.Model + ' • ' + $root.SetLicense(Item.License) }}</span>
                    <span class="dossier" v-else>{{ Item.Brand + ' ' + Item.Model }}</span>
                    <svg class="open" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><line id="secondary" x1="7" y1="12" x2="17" y2="12" style="stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></line><polyline points="14 15 17 12 14 9" style="stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></polyline><rect id="primary" x="3" y="3" width="18" height="18" rx="1" transform="translate(24) rotate(90)" style="stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></rect></g></svg>
                </router-link>
            </div>
        </div>
        <span class="empty" v-else>Er zijn geen openstaande taken</span>
    </div>
</template>

<script>
export default {
    name: 'DashboardTasks',

    props: ['Tasks'],

    methods: {
        SetDate (e) {
            let check = this.$moment(e).format('YYYY-MM-DD');
            if (this.$moment().diff(check, 'days') == 0) {
                return 'om ' + this.$moment(e).format('HH:mm')
            } else if(this.$moment(e).isSame(this.$moment().subtract(2, 'day'), "day")) {
                return 'eergisteren om ' + this.$moment(e).format('HH:mm')
            } else if(this.$moment(e).isSame(this.$moment().subtract(1, 'day'), "day")) {
                return 'gisteren om ' + this.$moment(e).format('HH:mm')
            } else if(this.$moment(e).isSame(this.$moment().add(1, 'day'), "day")) {
                return 'morgen om ' + this.$moment(e).format('HH:mm')
            } else if(this.$moment(e).isSame(this.$moment().add(2, 'day'), "day")) {
                return 'overmorgen om ' + this.$moment(e).format('HH:mm')
            } else {
                return 'op ' + this.$moment(e).format('DD-MM') + ' om ' + this.$moment(e).format('HH:mm')
            }
        }
    }
}
</script>

<style scoped>
.tasks {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px;
    background: #fff;
    border: 0;
    border-radius: 10px;
}

.tasks .title {
    font-size: 20px;
    font-weight: 600;
}

.tasks .list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.tasks .list .list-item {
    display: flex;
    align-items: center;
    gap: 25px;
}

.tasks .list .list-item.completed {
    opacity: 0.5;
}

.tasks .list .list-item .checkbox {
    min-width: 40px;
    min-height: 40px;
    background: #fff;
    outline: solid 5px var(--primary);
    border: solid 5px #ffffff;
    border-radius: 50%;
    cursor: pointer;
}

.tasks .list .list-item .checkbox:hover {
    background: var(--primary);
}

.tasks .list .list-item .checkbox.checked {
    background: var(--primary);
}

.tasks .list .list-item .information {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: #f7f8fc;
    text-decoration: none;
    border: solid 2px transparent;
    border-radius: 10px;
    transition: .25s;
    cursor: pointer;
}

.tasks .list .list-item .information:hover  {
    border: solid 2px var(--primary);
}

.tasks .list .list-item .information .text {
    font-size: 14px;
}

.tasks .list .list-item .information .text b {
    color: var(--primary);
    font-size: 14px;
    font-weight: 600;
}

.tasks .list .list-item .information .dossier {
    font-size: 14px;
}

.tasks .list .list-item .information .open {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    fill: transparent;
    stroke: var(--primary);
    pointer-events: none;
    transition: .25s;
    opacity: 0;
}

.tasks .list .list-item .information:hover .open {
    opacity: 1;
}
</style>