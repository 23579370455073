<template>
    <div class="latest">
        <span class="title">Laatste dossiers</span>
        <div class="items">
            <div class="item" v-for="(Item, i) in Latest" :key="i">
                <div class="image">
                    <img v-if="Item.Avatar" :src="Item.Avatar" :alt="Item.Text">
                    <img v-else :src="require('@/assets/placeholder/vehicle.png')">
                </div>
                <router-link :to="'/voertuigen/' + Item.Identifier" class="information">
                    <span class="source">{{ Item.ClientName + ' ' + SetDate(Item.Created) }}</span>
                    <span class="text">{{ Item.Text }}</span>
                    <span class="dossier" v-if="Item.VIN">{{ Item.Brand + ' ' + Item.Model + ' • ' + Item.VIN }}</span>
                    <span class="dossier" v-else-if="Item.License">{{ Item.Brand + ' ' + Item.Model + ' • ' + $root.SetLicense(Item.License) }}</span>
                    <span class="dossier" v-else>{{ Item.Brand + ' ' + Item.Model }}</span>
                    <svg class="open" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><line id="secondary" x1="7" y1="12" x2="17" y2="12" style="stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></line><polyline points="14 15 17 12 14 9" style="stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></polyline><rect id="primary" x="3" y="3" width="18" height="18" rx="1" transform="translate(24) rotate(90)" style="stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></rect></g></svg>
                </router-link>
            </div>
            <div class="placeholders" v-if="Latest && Latest.length == 0">
                <div class="placeholder" v-for="i in 5" :key="i">
                    <div class="image"></div>
                    <div class="information"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DashboardLatest',

    props: ['Latest'],

    data () {
        return {
            Items: []
        }
    },

    methods: {
        SetDate (e) {
            let check = this.$moment(e).format('YYYY-MM-DD');
            if (this.$moment().diff(check, 'days') == 0) {
                return 'om ' + this.$moment(e).format('HH:mm')
            }
            if (this.$moment().diff(check, 'days') > 180) {
                return 'op ' + this.$moment(e).format('DD-MM-YYYY')
            }
            if (this.$moment().diff(check, 'days') == 1) {
                return 'gisteren' + ' om ' + this.$moment(e).format('HH:mm')
            }
            if (this.$moment().diff(check, 'days') >= 2) {
                return 'op ' + this.$moment(e).format('DD-MM') + ' om ' + this.$moment(e).format('HH:mm')
            }
        }
    }
}
</script>

<style scoped>
.latest {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px;
    background: #fff;
    border: 0;
    border-radius: 10px;
}

.latest .title {
    font-size: 20px;
    font-weight: 600;
}

.latest .items {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.latest .items .item {
    display: flex;
    align-items: center;
    gap: 25px;
}

.latest .items .item .image {
    width: 50px;
}

.latest .items .item .image img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    background: linear-gradient(90deg, #ececec 8%, #cccccc 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    border-radius: 50%;
}

.latest .items .item .information {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: #f7f8fc;
    text-decoration: none;
    border: solid 2px transparent;
    border-radius: 10px;
    transition: .25s;
    cursor: pointer;
}

.latest .items .item .information:hover  {
    border: solid 2px var(--primary);
}

.latest .items .item .information .source {
    color: var(--primary);
    font-size: 14px;
    font-weight: 600;
}

.latest .items .item .information .text {
    font-weight: 500;
}

.latest .items .item .information .dossier {
    font-size: 14px;
}

.latest .items .item .information .open {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    fill: transparent;
    stroke: var(--primary);
    pointer-events: none;
    transition: .25s;
    opacity: 0;
}

.latest .items .item .information:hover .open {
    opacity: 1;
}



/* PLACEHOLDERS */
.latest .items .placeholders {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.latest .items .placeholders .placeholder {
    display: flex;
    align-items: center;
    gap: 25px;
}

.latest .items .placeholders .placeholder .image {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    background: linear-gradient(90deg, #ececec 8%, #cccccc 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    border-radius: 50%;
}

.latest .items .placeholders .placeholder .information {
    width: 100%;
    height: 73px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px;
    background: linear-gradient(90deg, #ececec 8%, #cccccc 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    border-radius: 10px;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
</style>