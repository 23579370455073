<template>
    <div class="task" :class="{active: Active.Fade}" @mousedown.self.prevent="Close">
        <div class="popup" :class="{active: Active.Popup}">
            <div class="header">
                <span class="title" v-if="Active.Method == 'Create'">Taak aanmaken</span>
                <span class="title" v-if="Active.Method == 'Edit'">Taak wijzigen</span>
                <span class="subtitle" v-if="Active.Method == 'Edit'">{{ Task.Brand }} {{ Task.Model }} {{ Task.VIN }}</span>
            </div>

            <div class="content">
                <div class="group">
                    <span class="subtitle">Tekst *</span>
                    <textarea v-model="Task.Text"></textarea>
                </div>
                <div class="group">
                    <span class="subtitle">Toewijzen</span>
                    <select v-model="Task.Actor">
                        <option value="">Kies een teamlid</option>
                        <option value="" disabled>- - - - -</option>
                        <option v-for="(User, i) in Team" :value="User.Identifier" :selected="User.Identifier == Task.Actor" :key="i">{{ User.FirstName }}</option>
                    </select>
                </div>
                <div class="group">
                    <span class="subtitle">Datum</span>
                    <input type="datetime-local" min="2020-01-01" max="2025-01-01" :value="$moment(Task.Date).format('YYYY-MM-DDTHH:mm')" @change="Task.Date = $event.target.value">
                </div>
                <div class="group check" v-if="Active.Method == 'Edit'">
                    <div class="checkbox" @click="Task.Completed = !Task.Completed">
                        <div class="checked" v-if="Task.Completed"></div>
                    </div>
                    Afgerond
                </div>
            </div>

            <div class="buttons">
                <span class="button" @click="Close">Sluiten</span>
                <span class="button primary" v-if="Active.Method == 'Edit'" @click="UpdateTask" :class="{ active: Task.Text, 'button-loader': Task.Loader }">Opslaan</span>
                <span class="button primary" v-if="Active.Method == 'Create'" @click="CreateTask" :class="{ active: Task.Text, 'button-loader': Task.Loader }">Aanmaken</span>
                <span class="button delete" v-if="Active.Method == 'Edit'" @click="DeleteTask" :class="{ 'button-loader': Task.Loader }">Verwijderen</span>
            </div>

            <div class="loader" v-if="Active.Loader">
                <svg fill="#333" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g><path d="M16 1.25c-0.414 0-0.75 0.336-0.75 0.75v0 8c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-8c-0-0.414-0.336-0.75-0.75-0.75v0zM16 21.25c-0.414 0-0.75 0.336-0.75 0.75v0 8c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-8c-0-0.414-0.336-0.75-0.75-0.75v0zM10.75 16c-0-0.414-0.336-0.75-0.75-0.75h-8c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h8c0.414-0 0.75-0.336 0.75-0.75v0zM30 15.25h-8c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h8c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM6.631 5.57c-0.135-0.131-0.319-0.212-0.522-0.212-0.414 0-0.75 0.336-0.75 0.75 0 0.203 0.081 0.387 0.212 0.522l5.657 5.657c0.135 0.13 0.318 0.209 0.52 0.209 0.414 0 0.75-0.336 0.75-0.75 0-0.202-0.080-0.385-0.21-0.52l0 0zM20.773 19.711c-0.136-0.136-0.324-0.22-0.531-0.22-0.415 0-0.751 0.336-0.751 0.751 0 0.207 0.084 0.395 0.22 0.531l5.658 5.658c0.136 0.136 0.324 0.22 0.531 0.22 0.415 0 0.751-0.336 0.751-0.751 0-0.207-0.084-0.395-0.22-0.531v0zM11.228 19.711l-5.657 5.658c-0.136 0.136-0.22 0.324-0.22 0.531 0 0.415 0.336 0.751 0.751 0.751 0.207 0 0.394-0.084 0.53-0.219v0l5.657-5.658c0.136-0.136 0.22-0.324 0.22-0.531 0-0.415-0.336-0.751-0.751-0.751-0.207 0-0.395 0.084-0.53 0.219v0zM20.242 12.508c0 0 0 0 0.001 0 0.207 0 0.395-0.084 0.531-0.22l5.658-5.657c0.136-0.136 0.219-0.323 0.219-0.53 0-0.415-0.336-0.751-0.751-0.751-0.208 0-0.395 0.084-0.531 0.22v0l-5.658 5.657c-0.136 0.136-0.22 0.323-0.22 0.53 0 0.414 0.336 0.75 0.75 0.75 0 0 0.001 0 0.001 0h-0z"></path> </g></svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Popup',

  data () {
        return {
            Active: {
                Fade: false,
                Popup: false,
                Loader: true,
                Method: false
            },
            Task: [],
            Team: [],
            Dossier: false
        }
  },

    methods: {
        Init (e, a) {
            this.Task = []
            this.Team = []

            this.Active.Fade = true

            if(e == 'Edit') {
                this.Active.Method = 'Edit'
                this.GetTask(a)
                this.GetTeam()
            } else {
                this.Active.Method = 'Create'
                this.Active.Loader = false
                this.Task.Date = ''
                this.Active.Dossier = a

                this.GetTeam()
            }

            var self = this
            setTimeout(function() {
                self.Active.Popup = true
            }, 125)
        },

        GetTeam () {
            this.$http.get(`https://api.wheels.nl/tasks/team`).then(response => {
                if(response.data.team) {
                    this.Team = response.data.team
                }
            })
        },

        GetTask (e) {
            this.$http.get(`https://api.wheels.nl/tasks/${e}`).then(response => {
                if(response.data.task) {
                    this.Task = response.data.task
                    this.Active.Loader = false
                } else {
                    this.Close()
                }
            })
        },

        CreateTask () {
            this.Task.Loader = true
            this.$http.post(`https://api.wheels.nl/tasks/${this.Active.Dossier}`, {
                Text: this.Task.Text,
                Actor: this.Task.Actor,
                Date: this.Task.Date
            }).then(() => {
                location.reload()
            })
        },

        UpdateTask () {
            this.Task.Loader = true
            this.$http.patch(`https://api.wheels.nl/tasks/${this.Task.Identifier}`, {
                Text: this.Task.Text,
                Actor: this.Task.Actor,
                Date: this.Task.Date,
                Completed: this.Task.Completed
            }).then(() => {
                location.reload()
            })
        },

        DeleteTask () {
            this.Task.Loader = true
            this.$http.delete(`https://api.wheels.nl/tasks/${this.Task.Identifier}`).then(() => {
                location.reload()
            })
        },

        Close () {
            this.Active.Popup = false
            var self = this
            setTimeout(function() {
                self.Active.Fade = false
            }, 200)
            setTimeout(function() {
                self.Task.Text = ''
                self.Task.Date = ''
                self.Task.Actor = ''
            }, 200)
        }
    }
}
</script>

<style scoped>
.task {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #00000080;
    transition: all .5s ease-in-out;
    pointer-events: none;
    opacity: 0;
    z-index: 9999;
    overflow: hidden;
}

.task.active {
    pointer-events: all;
    cursor: pointer;
    opacity: 1;
}

.task .popup {
    position: relative;
    width: 600px;
    max-height: 0;
    background: #fff;
    margin-bottom: -100%;
    border-radius: 5px;
    transition: all .35s ease-in-out;
    cursor: default;
    overflow: auto;
}

.task .popup.active {
    max-height: 90%;
    margin-bottom: 0; 
}

.task .popup .header {
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-bottom: solid 1px #e9e9e9;
}

.task .popup .header .title {
    font-size: 20px;
    font-weight: 700;
}

.task .popup .header .subtitle {
    font-weight: 300;
}

.task .popup .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 25px 25px 0 25px;
}

.task .popup .content .group {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 25px 0;
}

.task .popup .content .group.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.task .popup .content .group .subtitle {
    font-weight: 700;
    margin: 0 0 5px 0;
}

.task .popup .content .group select, .task .popup .content .group input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    font-weight: 300;
    background: #fff;
    border: solid 1px #e3e3e3;
    border-radius: 5px;
    outline: none;
}

.task .popup .content .group textarea {
    width: 100%;
    height: 100px;
    padding: 15px;
    font-weight: 300;
    background: #fff;
    border: solid 1px #e3e3e3;
    border-radius: 5px;
    outline: none;
    resize: none;
}

.task .popup .content .group.check {
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.task .popup .content .group .checkbox {
    position: relative;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border: solid 1px #ececec;
    border-radius: 5px;
    cursor: pointer;
}

.task .popup .content .group .checkbox .checked {
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: #0171d7;
    border-radius: 2.5px;
}

.task .popup .buttons {
    display: flex;
    justify-content: space-between;
    padding: 25px;
    gap: 15px;
    border-top: solid 1px #e9e9e9;
}

.task .popup .buttons .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-weight: 300;
    border: solid 1px #e9e9e9;
    cursor: pointer;
}

.task .popup .buttons .button.primary {
    color: #fff;
    background: var(--primary);
    font-weight: 500;
    opacity: 0.5;
    pointer-events: none;
}

.task .popup .buttons .button.primary.active {
    opacity: 1;
    pointer-events: all;
}

.task .popup .buttons .button.delete {
    color: #fff;
    background: indianred;
    font-weight: 500;
    opacity: 1;
    pointer-events: all;
}

.task .popup .buttons .button.button-loader {
    opacity: 0.5 !important;
    pointer-events: none;
}





.task .popup .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}

.task .popup .loader svg {
    width: 96px;
    height: 96px;
    animation: spin 5s linear infinite;
}





@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}





/* MOBILE */
@media screen and (max-width: 850px) {
    .task {
        align-items: flex-end
    }

    .task .popup {
        width: 100%;
        transition: all .75s ease-in-out;
        border-radius: 25px 25px 0 0;
        overflow: auto;
    }
}
</style>