<template>
    <div class="bar-loader">
        <div class="fill"></div>
    </div>

    <Bar class="bar" :class="{ small: $route.meta.Layout == 'wide' }" v-if="WAT && this.$route.meta.Title !== 'Narrowcasting'" />
    <Menu class="menu" :class="{ small: $route.meta.Layout == 'wide' }" v-if="WAT && this.$route.meta.Title !== 'Narrowcasting'" />
    <router-view class="router" :class="{ wide: $route.meta.Layout == 'wide' }" v-if="this.$route.meta.Title !== 'Narrowcasting'" />
    
    <Media ref="Media" class="media" v-if="WAT && Media && this.$route.meta.Title !== 'Narrowcasting'" :Focus="MediaFocus" :Type="MediaType" />
    <Page ref="Page" class="page" v-if="WAT && Page && this.$route.meta.Title !== 'Narrowcasting'" :PageIndex="PageIndex" />
    <CreatePage ref="CreatePage" class="page" v-if="WAT && CreatePage && this.$route.meta.Title !== 'Narrowcasting'" />
    <Task ref="Task" />
    
    <Narrowcasting class="narrowcasting" ref="narrowcasting" v-if="this.$route.meta.Title == 'Narrowcasting'" />
</template>

<script>
import Pusher from 'pusher-js'

import { v4 as uuidv4 } from 'uuid';

import Menu from '@/views/Layout/Menu.vue';
import Bar from '@/views/Layout/Bar.vue';
import Media from '@/views/Layout/Media.vue';
import Page from '@/views/Layout/Page.vue';
import CreatePage from '@/views/Layout/CreatePage.vue';
import Task from '@/views/Layout/Task.vue';
import Narrowcasting from '@/views/Narrowcasting/Screen.vue'

export default {
    name: 'App',

    components: {
        Menu,
        Bar,

        Media,
        Page,
        CreatePage,
        Task,

        Narrowcasting
    },

    mounted () {
        window.addEventListener('keydown', this.SearchBar)

        this.SetExpert()
        this.Session()
    },

    data () {
        return {
            WAT: localStorage.getItem('WAT'),
            WDT: localStorage.getItem('WDT'),
            
            Media: false,
            MediaType: false,
            MediaFocus: false,
            Page: false,
            PageIndex: false,
            CreatePage: false,
            PusherStream: false,

            Expert: false,

            User: false,
            Client: false,
            Placeholder: false,
            Scope: false,

            Modules: false
        }
    },
    
    methods: {
        async SetExpert () {
            await this.$http.get(`https://api.wheels.nl/auth/expert/${location.hostname}`).then((response) => {
                this.Expert = response.data

                if(response.data.login.module && this.$route.meta.Title == 'Dashboard') {
                    this.$router.push('/' + response.data.login.module)
                }

                document.documentElement.style.setProperty('--primary', response.data.global.primary)
            })
        },

        async Session () {
            await this.$http.get(`https://api.wheels.nl/auth/session/${location.hostname}`).then((response) => {
                if(response.data.settings) {
                    if(response.data.user.Name) { this.User = response.data.user.Name }

                    if(response.data.settings.Client) { this.Client = response.data.settings.Client }
                    if(response.data.settings.Placeholder) { this.Placeholder = response.data.settings.Placeholder }
                    if(response.data.settings.Scope) { this.Scope = response.data.settings.Scope }
    
                    if(response.data.client) {
                        this.SetPusher(response.data.client)
                    }
    
                    if(response.data.settings.Favicon) {
                        var link = document.querySelector("link[rel~='icon']")
    
                        if (!link) {
                            link = document.createElement('link')
                            link.rel = 'icon'
                            document.head.appendChild(link)
                        }
    
                        link.href = 'https://api.wheels.nl/vault/' + response.data.settings.Client + '/' + response.data.settings.Favicon + '.ico'
                    }
                }
            })
        },

        SetPusher (e) {
            this.PusherStream = new Pusher('cab193158f187399fe70', {
                cluster: 'eu'
            })

            var self = this
            self.PusherStream.subscribe(e)
            self.PusherStream.bind('inbox-new-message', function(data) {
                if(self.$route.meta.Title !== 'Leads' && data.Content) {
                    self.$toast.clear()
                    self.$toast.open({
                        message: data.Content,
                        position: 'top-right',
                        type: 'chat',
                        duration: 15000
                    })
                }
            })
        },

        OpenMedia (e, a) {
            var FocusID = uuidv4()
            e.Active = FocusID
            this.MediaFocus = FocusID

            this.Media = true
            this.MediaType = a
        },

        CloseMedia () {
            this.Media = false
        },

        OpenPage (i) {
            this.Page = true
            this.PageIndex = i
        },

        ClosePage () {
            this.Page = false
            this.PageIndex = false
        },

        OpenCreatePage () {
            this.CreatePage = true
        },

        CloseCreatePage () {
            this.CreatePage = false
            this.PageIndex = false
        },

        SearchBar (event) {
            if(event.metaKey && event.keyCode == 70) {
                event.preventDefault()
                document.querySelector('.search-input').focus()
            } else if(event.keyCode == 27 && (document.activeElement == document.querySelector('.search-input'))) {
                document.activeElement.blur()
                document.querySelector('.search-input').value = ''
            }
        },

        SetModules (e) {
            localStorage.setItem('Modules', JSON.stringify(e))
        },

        GetModules () {
            var Modules = localStorage.getItem('Modules')

            if(Modules) {
                return JSON.parse(localStorage.getItem('Modules'))
            } else {
                this.Logout()
            }
        },

        SetLicense (e) {
            const patterns = [
                { regex: /^(\d{2})([A-Z]{2})([A-Z]{2})$/, format: '$1-$2-$3' },
                { regex: /^(\d{2})([A-Z]{3})(\d{1})$/, format: '$1-$2-$3' },
                { regex: /^(\d{1})([A-Z]{3})(\d{2})$/, format: '$1-$2-$3' },
                { regex: /^([A-Z]{2})(\d{3})([A-Z]{1})$/, format: '$1-$2-$3' },
                { regex: /^([A-Z]{1})(\d{3})([A-Z]{2})$/, format: '$1-$2-$3' },
                { regex: /^([A-Z]{3})(\d{2})([A-Z]{1})$/, format: '$1-$2-$3' }
            ];
            
            for (const { regex, format } of patterns) {
                if (regex.test(e)) {
                    return e.replace(regex, format)
                }
            }
            
            return e
        },

        async Logout () {
            await this.$http.post('https://api.wheels.nl/auth/logout').then(() => {
                localStorage.clear()
                location.href = '/login'
            })
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Parkinsans');

:root {
    --primary: var(--primary);
}

* {
    font-family: 'Parkinsans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    margin: 0;
    padding: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #333;
    box-sizing: border-box;
}

html, body, #app {
    width: 100%;
    height: 100%;
    background: #f7f8fc;
}

#app {
    display: flex;
    flex-wrap: wrap;
}

#app.narrowcasting {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.bar {
    flex-basis: 100%;
    max-width: 100%;
    height: 80px;
    background: #2a2e3a;
    background: #222;
}

.menu {
    flex-basis: 300px;
    max-width: 300px;
    height: calc(100% - 80px);
    background: #fff;
}

.router {
    flex-basis: calc(100% - 301px);
    max-width: calc(100% - 301px);
    height: calc(100% - 80px);
}



/* SMALL MENU */
.menu.small {
    width: 100px;
    max-width: 100px;
}

.router.wide {
    flex-basis: calc(100% - 101px);
    max-width: calc(100% - 101px);
}



.bar-loader {
    position: fixed;
    top: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    background: #f7f8fc;
    transition: .25s;
}

.bar-loader.active {
    top: 0;
}

.bar-loader .fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 5px;
    background: var(--primary);
    transition: .25s;
}

.bar-loader .fill.third {
    width: 33%;
}

.bar-loader .fill.full {
    width: 100%;
}




.v-toast__item--chat {
    background: #fff;
    max-width: 400px;
}

.v-toast__item--chat .v-toast__text {
    padding: 25px !important;
}

.v-toast__item--chat .v-toast__text .title {
    font-weight: 600 !important;
}

.v-toast__item--chat .v-toast__icon {
    display: none !important;
}




.pagination-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.pagination-container .paginate-buttons {
    height: 40px;
    width: 40px;
    background: #fff;
    border: 1px solid #ededed;
    border-radius: 50%;
    transition: .25s;
    cursor: pointer;
}

.pagination-container .paginate-buttons:hover {
    background: #ededed;
}

.pagination-container .paginate-buttons.active-page {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: #fff;
}

.pagination-container .paginate-buttons.active-page:hover {
    background-color: #333;
}




::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 3px transparent;
    border-radius: 5px;
}

:hover::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #bbbbbb;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px #333;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 3px transparent;
}
</style>