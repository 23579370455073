<template>
    <div class="page" @click.self="this.$root.CloseCreatePage()">
        <div class="content">
            <span class="title">Pagina aanmaken</span>
            <div class="group">
                <span class="subtitle">Titel van de pagina</span>
                <input type="text" v-model="Title">
            </div>
            <div class="group">
                <span class="subtitle">Omschrijving van de pagina</span>
                <textarea v-model="Description"></textarea>
            </div>
            <div class="types">
                <span class="subtitle">Soort pagina</span>
                <div class="type" v-for="(Template, i) in Templates" :key="i" @click="Selected = Template.Value" :class="{ selected: Selected == Template.Value }">
                    <div class="image"></div>
                    <span class="name">{{ Template.Name }}</span>
                    <span class="description">{{ Template.Description }}</span>
                </div>
            </div>
            <span class="create" @click="CreatePage" :class="{ enabled: Title && Description && Selected }">Aanmaken</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreatePagePopup',

    data () {
        return {
            Active: false,
            Title: '',
            Description: '',
            Templates: [
                {
                    Name: 'Pagina',
                    Description: 'Een losse pagina',
                    Value: 'Page'
                },
                {
                    Name: 'Nieuws',
                    Description: 'Een nieuwspagina',
                    Value: 'Blog'
                },
                {
                    Name: 'Voorraad',
                    Description: 'Een pagina met artikelen op basis van filter(s)',
                    Value: 'Archive'
                }
            ],
            Selected: false
        }
    },

    methods: {
        CreatePage () {
            if(this.Title && this.Description && this.Selected) {
                this.$http.post('https://api.wheels.nl/pages', {
                    Title: this.Title,
                    Description: this.Description,
                    Template: this.Selected
                }).then((response) => {
                    if(response.data.identifier) {
                        this.$router.push('/website/pagina/' + response.data.identifier)
                        this.$root.CloseCreatePage()
                    }
                })
            }
        },
    }
}
</script>

<style scoped>
.page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #33333380;
    cursor: pointer;
    z-index: 888;
}

.page .content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 800px;
    max-width: 90%;
    min-height: 500px;
    max-height: 90%;
    padding: 50px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    cursor: default;
}

.page .content .title {
    font-size: 24px;
    font-weight: 600;
}

.page .content .group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.page .content .group .subtitle {
    font-weight: 500;
}

.page .content .group input {
    height: 50px;
    padding: 0 15px;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.page .content .group textarea {
    min-height: 150px;
    padding: 0 15px;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
    resize: none;
}

.page .content .types {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.page .content .types .subtitle {
    width: 100%;
    margin: 0 0 -20px 0;
    font-weight: 500;
}

.page .content .types .type {
    width: calc(33% - 14.5px);
    border: solid 1px #ededed;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}

.page .content .types .type.selected {
    border: solid 1px var(--primary);
}

.page .content .types .type .image {
    height: 120px;
    background: #f5f5f5;
}

.page .content .types .type .name {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 10px 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.page .content .types .type .description {
    display: block;
    width: 100%;
    padding: 0px 10px 10px 10px;
    font-size: 16px;
    font-weight: 300;
}

.page .content .create {
    padding: 7.5px 25px;
    margin: 0 0 0 auto;
    color: #fff;
    background: var(--primary);
    font-weight: 600;
    border-radius: 5px;
    pointer-events: none;
    opacity: 0.5;
    cursor: pointer;
}

.page .content .create.enabled {
    pointer-events: all;
    opacity: 1;
}
</style>