<template>
    <div class="website">
        <span class="title">Instellingen</span>
        <div class="block-trigger" @click="Active.Website = !Active.Website">
            Website
            <svg v-if="!Active.Website" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
            <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
        </div>
        <div class="block" v-if="Active.Website">
            <span class="title">Website</span>
            <div class="group">
                <span class="subtitle">URL('s)</span>
                <input type="text" disabled v-model="Settings.host">
            </div>
            <div class="group">
                <span class="subtitle">Bedrijfsnaam</span>
                <input type="text" v-model="Settings.Ecommerce_Name">
            </div>
            <div class="group">
                <span class="subtitle">E-mailadres</span>
                <input type="text" v-model="Settings.Ecommerce_email">
            </div>
            <div class="group">
                <span class="subtitle">Telefoonnummer</span>
                <input type="text" v-model="Settings.Ecommerce_Phone">
            </div>

            <span class="title">Hoofdmenu</span>
            <div class="group">
                <span class="subtitle">Achtergrond</span>
                <input type="text" v-model="Settings.menu.primary.background">
            </div>
            <div class="group">
                <span class="subtitle">Tekst</span>
                <input type="text" v-model="Settings.menu.primary.color">
            </div>

            <span class="title">Topmenu</span>
            <div class="group">
                <span class="subtitle">Achtergrond</span>
                <input type="text" v-model="Settings.menu.top.background">
            </div>
            <div class="group">
                <span class="subtitle">Tekst</span>
                <input type="text" v-model="Settings.menu.top.color">
            </div>
        </div>
        <div class="block-trigger" @click="Active.Colors = !Active.Colors">
            Kleuren
            <svg v-if="!Active.Colors" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
            <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
        </div>
        <div class="block" v-if="Active.Colors">
            <div class="group">
                <span class="subtitle">Primair</span>
                <input type="text" v-model="Settings.color.primary">
            </div>
            <div class="group">
                <span class="subtitle">Secundair</span>
                <input type="text" v-model="Settings.color.secondary">
            </div>
            <div class="group">
                <span class="subtitle">Highlights</span>
                <input type="text" v-model="Settings.color.highlight">
            </div>
            <div class="group">
                <span class="subtitle">Tekst</span>
                <input type="text" v-model="Settings.color.text">
            </div>
        </div>
        
        <div class="block-trigger" @click="Active.Logo = !Active.Logo">
            Logo's
            <svg v-if="!Active.Logo" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
            <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
        </div>
        <div class="block" v-if="Active.Logo">
            <div class="group">
                <span class="subtitle">Primaire logo (.png)</span>
                <img class="logo" v-if="Settings.logo.primary" :src="'https://api.wheels.nl/vault/' + Settings.identifier + '/' + Settings.logo.primary + '.png'" alt="Primary Logo">
                <div class="item dropzone" :class="{ drag: Dropzone.Active.PrimaryLogo }" @dragenter.prevent="Dropzone.Active.PrimaryLogo = true" @dragover.prevent="Dropzone.Active.PrimaryLogo = true" @dragleave.prevent="Dropzone.Active.PrimaryLogo = false" @drop.prevent="OnDrop($event, 'primarylogo')">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M22 20.8201C15.426 22.392 8.574 22.392 2 20.8201" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12.0508 16V2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M7.09961 6.21997L10.6096 2.60986C10.7895 2.42449 11.0048 2.27715 11.2427 2.17651C11.4806 2.07588 11.7363 2.02417 11.9946 2.02417C12.2529 2.02417 12.5086 2.07588 12.7465 2.17651C12.9844 2.27715 13.1997 2.42449 13.3796 2.60986L16.8996 6.21997" stroke-width="1.5" fill="transparent" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    <span class="dropzone-description" v-if="Dropzone.Active.PrimaryLogo">Laat het bestand los om te uploaden</span>
                    <span class="dropzone-description" v-else>Drag en drop hier je bestand</span>
                </div>
            </div>
            <div class="group">
                <span class="subtitle">Secundaire logo (zoekbalk - .png)</span>
                <img class="logo" v-if="Settings.logo.secondary" :src="'https://api.wheels.nl/vault/' + Settings.identifier + '/' + Settings.logo.secondary + '.png'" alt="Primary Logo">
                <div class="item dropzone" :class="{ drag: Dropzone.Active.SecondaryLogo }" @dragenter.prevent="Dropzone.Active.SecondaryLogo = true" @dragover.prevent="Dropzone.Active.SecondaryLogo = true" @dragleave.prevent="Dropzone.Active.SecondaryLogo = false" @drop.prevent="OnDrop($event, 'secondarylogo')">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M22 20.8201C15.426 22.392 8.574 22.392 2 20.8201" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12.0508 16V2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M7.09961 6.21997L10.6096 2.60986C10.7895 2.42449 11.0048 2.27715 11.2427 2.17651C11.4806 2.07588 11.7363 2.02417 11.9946 2.02417C12.2529 2.02417 12.5086 2.07588 12.7465 2.17651C12.9844 2.27715 13.1997 2.42449 13.3796 2.60986L16.8996 6.21997" stroke-width="1.5" fill="transparent" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    <span class="dropzone-description" v-if="Dropzone.Active.SecondaryLogo">Laat het bestand los om te uploaden</span>
                    <span class="dropzone-description" v-else>Drag en drop hier je bestand</span>
                </div>
            </div>
            <div class="group">
                <span class="subtitle">Factuur logo (.png)</span>
                <img class="logo" v-if="Settings.logo.invoice" :src="'https://api.wheels.nl/vault/' + Settings.identifier + '/' + Settings.logo.invoice + '.png'" alt="Primary Logo">
                <div class="item dropzone" :class="{ drag: Dropzone.Active.InvoiceLogo }" @dragenter.prevent="Dropzone.Active.InvoiceLogo = true" @dragover.prevent="Dropzone.Active.InvoiceLogo = true" @dragleave.prevent="Dropzone.Active.InvoiceLogo = false" @drop.prevent="OnDrop($event, 'invoicelogo')">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M22 20.8201C15.426 22.392 8.574 22.392 2 20.8201" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12.0508 16V2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M7.09961 6.21997L10.6096 2.60986C10.7895 2.42449 11.0048 2.27715 11.2427 2.17651C11.4806 2.07588 11.7363 2.02417 11.9946 2.02417C12.2529 2.02417 12.5086 2.07588 12.7465 2.17651C12.9844 2.27715 13.1997 2.42449 13.3796 2.60986L16.8996 6.21997" stroke-width="1.5" fill="transparent" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    <span class="dropzone-description" v-if="Dropzone.Active.InvoiceLogo">Laat het bestand los om te uploaden</span>
                    <span class="dropzone-description" v-else>Drag en drop hier je bestand</span>
                </div>
            </div>
            <div class="group">
                <span class="subtitle">Favicon (.ico)</span>
                <img class="favicon" v-if="Settings.favicon" :src="'https://api.wheels.nl/vault/' + Settings.identifier + '/' + Settings.favicon + '.ico'" alt="Favicon">
                <div class="item dropzone" :class="{ drag: Dropzone.Active.Favicon }" @dragenter.prevent="Dropzone.Active.Favicon = true" @dragover.prevent="Dropzone.Active.Favicon = true" @dragleave.prevent="Dropzone.Active.Favicon = false" @drop.prevent="OnDrop($event, 'favicon')">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M22 20.8201C15.426 22.392 8.574 22.392 2 20.8201" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12.0508 16V2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M7.09961 6.21997L10.6096 2.60986C10.7895 2.42449 11.0048 2.27715 11.2427 2.17651C11.4806 2.07588 11.7363 2.02417 11.9946 2.02417C12.2529 2.02417 12.5086 2.07588 12.7465 2.17651C12.9844 2.27715 13.1997 2.42449 13.3796 2.60986L16.8996 6.21997" stroke-width="1.5" fill="transparent" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    <span class="dropzone-description" v-if="Dropzone.Active.Favicon">Laat het bestand los om te uploaden</span>
                    <span class="dropzone-description" v-else>Drag en drop hier je bestand</span>
                </div>
            </div>
            <div class="group">
                <span class="subtitle">Placeholder (.png)</span>
                <img class="placeholder" v-if="Settings.placeholder" :src="'https://api.wheels.nl/vault/' + Settings.identifier + '/' + Settings.placeholder + '.png'" alt="Placeholder">
                <div class="item dropzone" :class="{ drag: Dropzone.Active.Placeholder }" @dragenter.prevent="Dropzone.Active.Placeholder = true" @dragover.prevent="Dropzone.Active.Placeholder = true" @dragleave.prevent="Dropzone.Active.Placeholder = false" @drop.prevent="OnDrop($event, 'placeholder')">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M22 20.8201C15.426 22.392 8.574 22.392 2 20.8201" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12.0508 16V2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M7.09961 6.21997L10.6096 2.60986C10.7895 2.42449 11.0048 2.27715 11.2427 2.17651C11.4806 2.07588 11.7363 2.02417 11.9946 2.02417C12.2529 2.02417 12.5086 2.07588 12.7465 2.17651C12.9844 2.27715 13.1997 2.42449 13.3796 2.60986L16.8996 6.21997" stroke-width="1.5" fill="transparent" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                    <span class="dropzone-description" v-if="Dropzone.Active.Placeholder">Laat het bestand los om te uploaden</span>
                    <span class="dropzone-description" v-else>Drag en drop hier je bestand</span>
                </div>
            </div>
        </div>

        <div class="block-trigger" @click="Active.Leads = !Active.Leads">
            Leads
            <svg v-if="!Active.Leads" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
            <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
        </div>
        <div class="block" v-if="Active.Leads">
            <div class="group">
                <span class="subtitle">Melding via E-mail</span>
                <select v-model="Settings.leads.email">
                    <option :value="0">Nee</option>
                    <option :value="1">Ja</option>
                </select>
            </div>
        </div>

        <div class="block-trigger" @click="Active.Shop = !Active.Shop">
            Webshop
            <svg v-if="!Active.Shop" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
            <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
        </div>
        <div class="block" v-if="Active.Shop">
            <div class="group">
                <span class="subtitle">Verzending</span>
                <select v-model="Settings.delivery.status">
                    <option :value="0">Nee</option>
                    <option :value="1">Ja</option>
                </select>
            </div>
            <div class="group">
                <span class="subtitle">Verzendkosten</span>
                <input type="text" v-model="Settings.delivery.cost">
            </div>
            <div class="group">
                <span class="subtitle">Gratis verzending vanaf (postcode)</span>
                <input type="text" v-model="Settings.delivery.from">
            </div>
            <div class="group">
                <span class="subtitle">Gratis verzending tot (postcode)</span>
                <input type="text" v-model="Settings.delivery.till">
            </div>
        </div>

        <div class="block-trigger" @click="Active.Email = !Active.Email">
            E-mail
            <svg v-if="!Active.Email" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
            <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
        </div>
        <div class="block" v-if="Active.Email">
            <span class="title">E-mail (IMAP)</span>
            <div class="group">
                <span class="subtitle">Server</span>
                <input type="text" v-model="Settings.imap.host">
            </div>
            <div class="group">
                <span class="subtitle">Gebruikersnaam</span>
                <input type="text" v-model="Settings.imap.username">
            </div>
            <div class="group">
                <span class="subtitle">Wachtwoord</span>
                <input type="password" v-model="Settings.imap.password">
            </div>
        </div>

        <div class="block-trigger" @click="Active.Connect = !Active.Connect">
            Koppelingen
            <svg v-if="!Active.Connect" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
            <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
        </div>
        <div class="block" v-if="Active.Connect">
            <span class="title">Analytics</span>
            <div class="group">
                <span class="subtitle">G-Code</span>
                <input type="text" v-model="Settings.analytics.token">
            </div>

            <span class="title">Chat (by Chatwoot)</span>
            <div class="group">
                <span class="subtitle">Chatwoot</span>
                <select v-model="Settings.chatwoot.status">
                    <option :value="0">Uit</option>
                    <option :value="1">Aan</option>
                </select>
            </div>
            <div class="group">
                <span class="subtitle">ID</span>
                <input type="text" v-model="Settings.chatwoot.id">
            </div>
            <div class="group">
                <span class="subtitle">Token</span>
                <input type="text" v-model="Settings.chatwoot.token">
            </div>

            <span class="title">Whatsapp</span>
            <div class="group">
                <span class="subtitle">API Key</span>
                <input type="text" v-model="Settings.whatsapp.token">
            </div>

            <span class="title">Channable</span>
            <div class="group">
                <span class="subtitle">Gebruikersnaam</span>
                <input type="text" disabled :value="Settings.channable.username">
            </div>
            <div class="group">
                <span class="subtitle">Wachtwoord</span>
                <input type="password" :value="Settings.channable.password">
            </div>

            <span class="title">CycleSoftware</span>
            <div class="group">
                <span class="subtitle">Gebruikersnaam</span>
                <input type="text" v-model="Settings.cyclesoftware.username">
            </div>
            <div class="group">
                <span class="subtitle">Wachtwoord</span>
                <input type="password" v-model="Settings.cyclesoftware.password">
            </div>

            <span class="title">Mollie</span>
            <div class="group">
                <span class="subtitle">API-Sleutel (Live)</span>
                <input type="text" v-model="Settings.mollie.token">
            </div>
        </div>

        <div class="block-trigger" @click="Active.CSS = !Active.CSS">
            CSS Editor
            <svg v-if="!Active.CSS" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
            <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
        </div>
        <div class="block" v-if="Active.CSS">
            <CodeEditor v-model="Settings.CSS" :languages="[['css']]" :tab-spaces="4" :header="false" width="100%" min-height="350px"></CodeEditor>
        </div>

        <div class="block-trigger" @click="Active.Status = !Active.Status">
            Statussen
            <svg v-if="!Active.Status" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M12 4V20" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
            <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12L18 12" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
        </div>
        <div class="block" v-if="Active.Status">
            <div class="block-status" v-for="(Settings, Si) in Settings.CustomStatus" :key="Si">
                <span class="block-status-title">{{ Si }}</span>
                <div class="block-status-item" v-for="(SettingsItem, SSi) in Settings" :key="SSi">
                    <div class="block-status-item-avatar">{{ SettingsItem.Code }}</div>
                    <div class="block-status-item-specifications">
                        <div class="block-status-item-specifications-group">
                            <span class="block-status-item-specifications-group-subtitle">Naam</span>
                            <input type="text" v-model="SettingsItem.Name">
                        </div>
                        <div class="block-status-item-specifications-group">
                            <span class="block-status-item-specifications-group-subtitle">Code</span>
                            <input type="text" v-model="SettingsItem.Code">
                        </div>
                        <div class="block-status-item-specifications-group">
                            <span class="block-status-item-specifications-group-subtitle">Opties</span>
                            <div class="block-status-item-specifications-option" v-for="(Option, SSSi) in SettingsItem.Options" :key="SSSi">
                                <div class="block-status-item-specifications-group avatar">
                                    <div class="block-status-item-avatar" :style="{ background: Option.Background, color: Option.Color }">{{ SettingsItem.Code }}</div>
                                </div>
                                <div class="block-status-item-specifications-group">
                                    <span class="block-status-item-specifications-group-subtitle">Text</span>
                                    <input type="text" v-model="Option.Text">
                                </div>
                                <div class="block-status-item-specifications-group">
                                    <span class="block-status-item-specifications-group-subtitle">Achtergrond</span>
                                    <input type="text" v-model="Option.Background">
                                </div>
                                <div class="block-status-item-specifications-group">
                                    <span class="block-status-item-specifications-group-subtitle">Kleur</span>
                                    <input type="text" v-model="Option.Color">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <span class="save" @click="SaveSettings()">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"></path></g></svg>
            Opslaan
        </span>
    </div>
</template>

<script>
import CodeEditor from 'simple-code-editor';

export default {
    name: 'WebsiteSettings',

    components: {
        CodeEditor
    },

    data () {
        return {
            Loader: false,
            Active: [],
            Settings: false,
            Dropzone: {
                Active: {
                    PrimaryLogo: false,
                    SecondaryLogo: false,
                    InvoiceLogo: false,
                    Favicon: false,
                    Placeholder: false
                },
                Files: []
            }
        }
    },

    mounted () {
        this.GetSettings()
    },

    methods: {
        GetSettings () {
            this.$http.get('https://api.wheels.nl/clients').then((response) => {
                if(response.data.settings) { this.Settings = response.data.settings }
            })
        },

        SaveSettings () {
            this.$http.patch('https://api.wheels.nl/clients', {
                Settings: this.Settings
            }).then(() => {
                this.$toast.open({
                    message: 'Instellingen opgeslagen',
                    position: 'bottom-right',
                    type: 'success'
                })
            })
        },

        SetDate (e) {
            if(this.$moment().isSame(e, 'day')) {
                return this.$moment(e).format('HH:mm')
            } else {
                return this.$moment(e).format('DD-MM')
            }
        },

        OnDrop (e, a) {
            var Files = [...e.dataTransfer.files]

            for(let i = 0; i < Files.length; i++ ){
                this.Dropzone.Files.push( Files[i] )
            }

            this.Dropzone.Active = false

            this.UploadFiles(a)
        },

        UploadFiles (a) {
            if(a && this.Dropzone.Files.length > 0) {
                var formData = new FormData()
                formData.append('media', this.Dropzone.Files[0])

                this.$http.post(`https://api.wheels.nl/clients/upload/${a}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                    if(response.data.error) {
                        this.$toast.open({
                            message: 'Kan bestand niet uploaden. Controleer bestandstype.',
                            position: 'top-right',
                            type: 'error'
                        })
                    } else {
                        if(a == 'primarylogo' || a =='secondarylogo') {
                            location.reload()
                        } else {
                            this.Settings[a] = response.data.identifier
                        }
                    }
                })
                
                this.Dropzone.Files = []
            }
        },

        SetStatusStyle (e) {
            if(e && e.Identifier) {
                return {
                    Background: e.Background,
                    Color: e.Color
                }
            } else {
                return {
                    Background: '',
                    Color: ''
                }
            }
        }
    }
}
</script>

<style scoped>
.website {
    display: flex;
    flex-direction: column;
    gap: 0 50px;
    width: 100%;
    padding: 50px;
    background: #f7f8fc;
    overflow: auto;
}

.website .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
    margin: 0 0 25px 0;
}

.website .block-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 400;
    background: #fff;
    border-bottom: solid 1px #eaeaea;
    cursor: pointer;
}

.website .block-trigger:last-of-type {
    border: 0;
}

.website .block-trigger svg {
    width: 24px;
    height: 24px;
}

.website .block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
    background: #fff;
}

.website .block .title {
    font-size: 20px;
    font-weight: 700;
    margin: 25px 0 0 0;
}

.website .block .group {
    display: flex;
    flex-direction: column;
}

.website .block .group input, .website .block .group select {
    width: 50%;
    padding: 5px;
    border: solid 1px #f7f8fc;
    border-radius: 5px;
}

.website .block .group .logo {
    width: 128px;
    height: 128px;
    margin: 5px 0 15px 0;
    object-fit: contain;
}

.website .block .group .favicon {
    width: 24px;
    height: 24px;
    margin: 5px 0 15px 0;
}

.website .block .group .placeholder {
    width: 128px;
    height: 128px;
    margin: 5px 0 15px 0;
    object-fit: contain;
}

.website .block .group .dropzone {
    position: relative;
    width: 50%;
    height: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    border: solid 1px #e5e5e5;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.website .block .group .dropzone svg {
    width: 50px;
    height: 50px;
    stroke: #333;
}

.website .block .group .dropzone span {
    text-align: center;
}

.website .save {
    width: fit-content;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 25px 0 0 0;
    padding: 0 15px;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.website .save svg {
    width: 20px;
    height: 20px;
    fill: #fff;
}





.website .block-status {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.website .block-status .block-status-title {
    font-size: 20px;
    font-weight: 700;
    margin: 25px 0 0 0;
}

.website .block-status .block-status-item {
    display: flex;
    gap: 15px;
}

.website .block-status .block-status-item-avatar {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f6f9;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
}

.website .block-status .block-status-item-specifications {
    width: calc(100% - 65px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0 0 25px 0;
}

.website .block-status .block-status-item-specifications .block-status-item-specifications-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.website .block-status .block-status-item-specifications .block-status-item-specifications-group:first-of-type {
    margin: 15px 0 0 0;
}

.website .block-status .block-status-item-specifications .block-status-item-specifications-group .block-status-item-specifications-group-subtitle {
    font-weight: 700;
    font-size: 16px;
}

.website .block-status .block-status-item-specifications .block-status-item-specifications-group input {
    width: 100%;
    padding: 5px;
    border: solid 1px #eaeaea;
    border-radius: 5px;
}

.website .block-status .block-status-item-specifications .block-status-item-specifications-option {
    display: flex;
    gap: 25px;
    padding: 15px;
    background: #eaeaea;
    border-radius: 5px;
}

.website .block-status .block-status-item-specifications .block-status-item-specifications-option .block-status-item-specifications-group {
    width: 100%;
    margin: 0;
}

.website .block-status .block-status-item-specifications .block-status-item-specifications-option .block-status-item-specifications-group.avatar {
    width: fit-content;
    margin: 5px 0 0 0;
}
</style>