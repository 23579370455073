<template>
    <div class="message">
        <svg class="logo" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M12 8V11M12 14H12.01M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
        <div class="information">
            <span class="title">{{ Notification.Title }}</span>
            <span class="text">{{ Notification.Text }}</span>
            <a v-if="Notification.Link" :href="Notification.Link" target="_blank" class="button">Lees meer</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DashboardMessage',

    props: ['Notification']
}
</script>

<style scoped>
.message {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 25px;
    padding: 25px;
    background: #ffffe0;
    border: 0;
    border-radius: 10px;
}

.message .logo {
    min-width: 50px;
    max-width: 50px;
    stroke: #ffa500;
}

.message .information {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.message .information .title {
    font-weight: 600;
}

.message .information .button {
    width: fit-content;
    padding: 5px 15px;
    font-weight: 600;
    text-decoration: none;
    border: solid 1px #333;
    border-radius: 5px;
}
</style>