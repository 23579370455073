<template>
    <div class="page" v-if="Page">
        <div class="page-bar">
            <div class="page-bar-left">
                <span class="page-bar-left-title">{{ Page.Title }}</span>
                <a v-if="Page.Status == 'O'" :href="'https://' + Client.Host + '/' + Page.Slug" target="_blank" class="page-bar-left-link">/{{ Page.Slug }}</a>
                <a v-else-if="Page.Status == 'C'" :href="'https://' + Client.Host + '/' + Page.Slug + '/' + Page.ConceptKey" target="_blank" class="page-bar-left-link">/{{ Page.Slug }}</a>
                <span v-else-if="Page.Status == 'A'" class="page-bar-left-link">/{{ Page.Slug }}</span>
            </div>
            <div class="page-bar-right">                
                <a :href="'https://' + Client.Host + '/' + Page.Slug" target=_blank class="page-bar-right-link" v-if="Page.Status == 'O'">
                    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#000000" fill="none"><g><path d="M55.4,32V53.58a1.81,1.81,0,0,1-1.82,1.82H10.42A1.81,1.81,0,0,1,8.6,53.58V10.42A1.81,1.81,0,0,1,10.42,8.6H32"></path><polyline points="40.32 8.6 55.4 8.6 55.4 24.18"></polyline><line x1="19.32" y1="45.72" x2="54.61" y2="8.91"></line></g></svg>
                    Pagina openen
                </a>
                <a :href="'https://' + Client.Host + '/' + Page.Slug + '/' + Page.ConceptKey" target=_blank class="page-bar-right-link" v-if="Page.Status == 'C'">
                    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#000000" fill="none"><g><path d="M55.4,32V53.58a1.81,1.81,0,0,1-1.82,1.82H10.42A1.81,1.81,0,0,1,8.6,53.58V10.42A1.81,1.81,0,0,1,10.42,8.6H32"></path><polyline points="40.32 8.6 55.4 8.6 55.4 24.18"></polyline><line x1="19.32" y1="45.72" x2="54.61" y2="8.91"></line></g></svg>
                    Conceptpagina openen
                </a>
                <span class="page-bar-right-save" @click="SavePage()">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"></path></g></svg>
                    Opslaan
                </span>
            </div>
        </div>
        <div class="page-elements">
            <div class="page-elements-search">
                <input v-model="Search" type="text" placeholder="Zoeken...">
            </div>
            <div v-for="(Category, i) in Categories" :key="i" class="page-elements-category">
                <span class="page-elements-category-title">{{ Category.Title }}</span>
                    <div class="page-elements-category-elements">
                        <div class="page-elements-category-elements-element" v-for="(Element, Si) in Category.Elements.filter(e => e.Templates.includes(Page.Template))" :key="Si" @click="AddElement(Element)" v-tooltip="{ value: Element.Title + ' toevoegen' }">
                            <div class="page-elements-category-elements-element-thumbnail">
                                <img v-if="Element.Placeholder" :src="require('@/assets/elements/' + Element.Placeholder)" alt="Placeholder">
                            </div>
                            <span class="page-elements-category-elements-element-title">{{ Element.Title }}</span>
                        </div>
                    </div>
                <div class="page-elements-divider"></div>
            </div>
        </div>
        <div class="page-content" @mousedown.self="Information.Menu = 'Page', Information.Active = false">
            <draggable v-model="Page.Elements" class="page-content-elements" handle=".handle" :sort="true" group="elements" item-key="Identifier" @click.self="Information.Menu = 'Page', Information.Active = false" @start="CheckCanJoin" @end="CanJoin = false">
                <template #item="{ element, index }">
                    <div class="page-content-elements-element" :class="{ half: element.Width == 'Half', active: element == Information.Active, split: element.Component == 'Split' }" :data-split="element.Split">
                        <div class="page-content-elements-element-header" @click.self="Information.Active = element, Information.Menu = 'Element'">
                            <svg class="handle" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g><path d="m 4.496094 0 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m 6 0 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m -6 6 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m 6 0 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m -6 6 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m 6 0 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m 0 0"></path> </g></svg>
                            <div class="title">
                                <span class="category">{{ element.Category }}</span>
                                <span class="subtitle">{{ element.Title }}</span>
                            </div>
                            <svg class="animated" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{ active: element.Animation }"><g><path d="M16.6582 9.28638C18.098 10.1862 18.8178 10.6361 19.0647 11.2122C19.2803 11.7152 19.2803 12.2847 19.0647 12.7878C18.8178 13.3638 18.098 13.8137 16.6582 14.7136L9.896 18.94C8.29805 19.9387 7.49907 20.4381 6.83973 20.385C6.26501 20.3388 5.73818 20.0469 5.3944 19.584C5 19.053 5 18.1108 5 16.2264V7.77357C5 5.88919 5 4.94701 5.3944 4.41598C5.73818 3.9531 6.26501 3.66111 6.83973 3.6149C7.49907 3.5619 8.29805 4.06126 9.896 5.05998L16.6582 9.28638Z" stroke-width="2" stroke-linejoin="round"></path></g></svg>
                            <svg class="margin" v-tooltip="{ value: 'Margin actief', disabled: !(element.Margin && (element.Margin.Computer.Top || element.Margin.Computer.Bottom || element.Margin.Computer.Left || element.Margin.Computer.Right || element.Margin.Mobile.Top || element.Margin.Mobile.Bottom || element.Margin.Mobile.Left || element.Margin.Mobile.Right)) }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve"><g><g> <g> <g> <path :class="{ active: element.Margin && (element.Margin.Computer.Top || element.Margin.Mobile.Top) }" d="M19,2H5C4.4,2,4,1.6,4,1s0.4-1,1-1h14c0.6,0,1,0.4,1,1S19.6,2,19,2z"></path> </g> </g> <g> <g> <path :class="{ active: element.Margin && (element.Margin.Computer.Bottom || element.Margin.Mobile.Bottom) }" d="M19,24H5c-0.6,0-1-0.4-1-1s0.4-1,1-1h14c0.6,0,1,0.4,1,1S19.6,24,19,24z"></path> </g> </g> <g> <g> <path :class="{ active: element.Margin && (element.Margin.Computer.Right || element.Margin.Mobile.Right) }" d="M23,20c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1s1,0.4,1,1v14C24,19.6,23.6,20,23,20z"></path> </g> </g> <g> <g> <path :class="{ active: element.Margin && (element.Margin.Computer.Left || element.Margin.Mobile.Left) }" d="M1,20c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1s1,0.4,1,1v14C2,19.6,1.6,20,1,20z"></path> </g> </g> <g> <g> <path d="M19,20H5c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1v14C20,19.6,19.6,20,19,20z M6,18h12V6H6V18z"></path> </g> </g> </g> </g></svg>
                            <svg class="padding" v-tooltip="{ value: 'Padding actief', disabled: !(element.Padding && (element.Padding.Computer.Top || element.Padding.Computer.Bottom || element.Padding.Computer.Left || element.Padding.Computer.Right || element.Padding.Mobile.Top || element.Padding.Mobile.Bottom || element.Padding.Mobile.Left || element.Padding.Mobile.Right)) }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve"><g><g> <g> <g> <g> <path :class="{ active: element.Padding && (element.Padding.Computer.Top || element.Padding.Mobile.Top) }" d="M16,6H8C7.4,6,7,5.6,7,5s0.4-1,1-1h8c0.6,0,1,0.4,1,1S16.6,6,16,6z"></path> </g> </g> <g> <g> <path :class="{ active: element.Padding && (element.Padding.Computer.Bottom || element.Padding.Mobile.Bottom) }" d="M16,20H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S16.6,20,16,20z"></path> </g> </g> <g> <g> <path :class="{ active: element.Padding && (element.Padding.Computer.Right || element.Padding.Mobile.Right) }" d="M19,17c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1s1,0.4,1,1v8C20,16.6,19.6,17,19,17z"></path> </g> </g> <g> <g> <path :class="{ active: element.Padding && (element.Padding.Computer.Left || element.Padding.Mobile.Left) }" d="M5,17c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1s1,0.4,1,1v8C6,16.6,5.6,17,5,17z"></path> </g> </g> </g> <g> <g> <path d="M23,24H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h22c0.6,0,1,0.4,1,1v22C24,23.6,23.6,24,23,24z M2,22h20V2H2V22z"></path> </g> </g> </g> </g></svg>
                            <SplitButton v-if="!element.Locked && element.Width == 'Full'" class="toggle" :model="[{ label: 'Maak 50%', command: () => { ChangeElement(element, index) } }, { label: 'Dupliceren', command: () => { DuplicateElement(element, index) } }, { label: 'Verwijderen', command: () => { DeleteElementIndex(index) } }]" />
                            <SplitButton v-else-if="!element.Locked && element.Width == 'Half'" class="toggle" :model="[{ label: 'Maak 100%', command: () => { ChangeElement(element, index) } }, { label: 'Dupliceren', command: () => { DuplicateElement(element, index) } }, { label: 'Verwijderen', command: () => { DeleteElementIndex(index) } }]" />
                            <SplitButton v-else-if="element.Locked" class="toggle" :model="[{ label: 'Dupliceren', command: () => { DuplicateElement(element, index) } }, { label: 'Verwijderen', command: () => { DeleteElementIndex(index) } }]" />
                            <div v-else class="toggle"></div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && element.Component == 'StockCars'">
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Toon filter</span>
                                <Select v-model="element.Filter" :options="[{ Label: 'Toon filter', Value: true }, { Label: 'Toon geen filter', Value: false }]" optionLabel="Label" optionValue="Value"></Select>
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Sorteren</span>
                                <Select v-model="element.Sort" :options="[{ Label: 'Datum nieuw > Oud', Value: 'DateDESC' }, { Label: 'Datum oud > Nieuw', Value: 'DateASC' }, { Label: 'Prijs laag > hoog', Value: 'PriceASC' }, { Label: 'Prijs hoog > laag', Value: 'PriceDESC' }]" optionLabel="Label" optionValue="Value"></Select>
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Aantal resultaten per pagina</span>
                                <InputNumber v-model="element.Limit" :min="1" :max="25"></InputNumber>
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && element.Component == 'StockRow'">
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Sorteren</span>
                                <Select v-model="element.Sort" :options="[{ Label: 'Nieuw > Oud', Value: 'DateDESC' }, { Label: 'Oud > Nieuw', Value: 'DateASC' }, { Label: 'Prijs laag > hoog', Value: 'PriceASC' }, { Label: 'Prijs hoog > laag', Value: 'PriceDESC' }]" optionLabel="Label" optionValue="Value"></Select>
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Aantal resultaten</span>
                                <InputNumber v-model="element.Limit" :min="1" :max="15"></InputNumber>
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && element.Component == 'StockCarsRow'">
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Sorteren</span>
                                <Select v-model="element.Sort" :options="[{ Label: 'Nieuw > Oud', Value: 'DateDESC' }, { Label: 'Oud > Nieuw', Value: 'DateASC' }, { Label: 'Prijs laag > hoog', Value: 'PriceASC' }, { Label: 'Prijs hoog > laag', Value: 'PriceDESC' }]" optionLabel="Label" optionValue="Value"></Select>
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Aantal resultaten</span>
                                <InputNumber v-model="element.Limit" :min="1" :max="6"></InputNumber>
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && element.Component == 'Text'">
                            <Editor v-model="element.Text" editorStyle="height: 320px">
                                <template v-slot:toolbar>
                                    <div id="toolbar-container">
                                        <span class="ql-formats">
                                            <select class="ql-size"></select>
                                        </span>
                                        <span class="ql-formats">
                                            <button class="ql-bold"></button>
                                            <button class="ql-italic"></button>
                                            <button class="ql-underline"></button>
                                            <button class="ql-strike"></button>
                                        </span>
                                        <span class="ql-formats">
                                            <select class="ql-color"></select>
                                            <select class="ql-background"></select>
                                        </span>
                                        <span class="ql-formats">
                                            <button class="ql-script" value="sub"></button>
                                            <button class="ql-script" value="super"></button>
                                        </span>
                                        <span class="ql-formats">
                                            <button class="ql-header" value="1"></button>
                                            <button class="ql-header" value="2"></button>
                                            <button class="ql-blockquote"></button>
                                        </span>
                                        <span class="ql-formats">
                                            <button class="ql-list" value="ordered"></button>
                                            <button class="ql-list" value="bullet"></button>
                                            <button class="ql-indent" value="-1"></button>
                                            <button class="ql-indent" value="+1"></button>
                                        </span>
                                        <span class="ql-formats">
                                            <button class="ql-direction" value="rtl"></button>
                                            <select class="ql-align"></select>
                                        </span>
                                        <span class="ql-formats">
                                            <button class="ql-link"></button>
                                            <button class="ql-button" @click="AddButton">
                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#333333"><g><path d="M20.5 17h-17A2.502 2.502 0 0 1 1 14.5v-4A2.502 2.502 0 0 1 3.5 8h17a2.502 2.502 0 0 1 2.5 2.5v4a2.502 2.502 0 0 1-2.5 2.5zm-17-8A1.502 1.502 0 0 0 2 10.5v4A1.502 1.502 0 0 0 3.5 16h17a1.502 1.502 0 0 0 1.5-1.5v-4A1.502 1.502 0 0 0 20.5 9zM17 12H7v1h10z"></path><path fill="none" d="M0 0h24v24H0z"></path></g></svg>
                                            </button>
                                        </span>
                                        <span class="ql-formats">
                                            <button class="ql-clean"></button>
                                        </span>
                                    </div>
                                </template>
                            </Editor>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && element.Component == 'Title'">
                            {{ element.Subtitle }}
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && element.Component == 'USP'">
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Blok 1</span>
                                <input type="text" v-model="element.Slides[0].Icon">
                                <input type="text" v-model="element.Slides[0].Title">
                                <input type="text" v-model="element.Slides[0].Text">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Blok 2</span>
                                <input type="text" v-model="element.Slides[1].Icon">
                                <input type="text" v-model="element.Slides[1].Title">
                                <input type="text" v-model="element.Slides[1].Text">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Blok 3</span>
                                <input type="text" v-model="element.Slides[2].Icon">
                                <input type="text" v-model="element.Slides[2].Title">
                                <input type="text" v-model="element.Slides[2].Text">
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && element.Component == 'Spacer'">
                            <span>Maak gebruik van Margin (Top en Bottom) in het menu rechts</span>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && (element.Component == 'Hero' || element.Component == 'Image')">
                            <div class="media" :style="{ background: '#fff url(' + element.ImageURL + ')' }" @click="this.$root.OpenMedia(element, 'Image')">
                                <span v-if="element.ImageURL" class="change">Wijzig afbeelding</span>
                                <span v-else class="select">Selecteer een afbeelding</span>
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Maximale breedte</span>
                                <input type="text" v-model="element.MaxWidth">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Maximale hoogte</span>
                                <input type="text" v-model="element.MaxHeight">
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && (element.Component == 'Button')">
                            <div class="page-content-elements-element-content-group full">
                                <span class="page-content-elements-element-content-group-subtitle">Tekst</span>
                                <input type="text" v-model="element.Text">
                            </div>

                            <div class="page-content-elements-element-content-group full">
                                <span class="page-content-elements-element-content-group-subtitle">Link</span>
                                <input type="text" v-model="element.Link">
                            </div>

                            <div class="page-content-elements-element-content-group full">
                                <span class="page-content-elements-element-content-group-subtitle">Extern openen</span>
                                <Select v-model="element.External" :options="[{ Label: 'Ja', Value: true }, { Label: 'Nee', Value: false }]" optionLabel="Label" optionValue="Value"></Select>
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && (element.Component == 'ThreeButtons')">
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Eerste blok</span>
                                <div class="media" :style="{ background: '#fff url(' + element.Slides[0].ImageURL + ')' }" @click="this.$root.OpenMedia(element.Slides[0], 'SlideWeb')">
                                    <span v-if="element.Slides[0].ImageURL" class="change">Wijzig afbeelding</span>
                                    <span v-else class="select">Selecteer een afbeelding</span>
                                </div>
                                <input type="text" v-model="element.Slides[0].Text" placeholder="Titel">
                                <input type="text" v-model="element.Slides[0].Link" placeholder="Link">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Tweede blok</span>
                                <div class="media" :style="{ background: '#fff url(' + element.Slides[1].ImageURL + ')' }" @click="this.$root.OpenMedia(element.Slides[1], 'SlideWeb')">
                                    <span v-if="element.Slides[1].ImageURL" class="change">Wijzig afbeelding</span>
                                    <span v-else class="select">Selecteer een afbeelding</span>
                                </div>
                                <input type="text" v-model="element.Slides[1].Text" placeholder="Titel">
                                <input type="text" v-model="element.Slides[1].Link" placeholder="Link">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Derde blok</span>
                                <div class="media" :style="{ background: '#fff url(' + element.Slides[2].ImageURL + ')' }" @click="this.$root.OpenMedia(element.Slides[2], 'SlideWeb')">
                                    <span v-if="element.Slides[2].ImageURL" class="change">Wijzig afbeelding</span>
                                    <span v-else class="select">Selecteer een afbeelding</span>
                                </div>
                                <input type="text" v-model="element.Slides[2].Text" placeholder="Titel">
                                <input type="text" v-model="element.Slides[2].Link" placeholder="Link">
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && (element.Component == 'FourButtons')">
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Eerste blok</span>
                                <div class="media" :style="{ background: '#fff url(' + element.Slides[0].ImageURL + ')' }" @click="this.$root.OpenMedia(element.Slides[0], 'SlideWeb')">
                                    <span v-if="element.Slides[0].ImageURL" class="change">Wijzig afbeelding</span>
                                    <span v-else class="select">Selecteer een afbeelding</span>
                                </div>
                                <input type="text" v-model="element.Slides[0].Text" placeholder="Titel">
                                <input type="text" v-model="element.Slides[0].Link" placeholder="Link">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Tweede blok</span>
                                <div class="media" :style="{ background: '#fff url(' + element.Slides[1].ImageURL + ')' }" @click="this.$root.OpenMedia(element.Slides[1], 'SlideWeb')">
                                    <span v-if="element.Slides[1].ImageURL" class="change">Wijzig afbeelding</span>
                                    <span v-else class="select">Selecteer een afbeelding</span>
                                </div>
                                <input type="text" v-model="element.Slides[1].Text" placeholder="Titel">
                                <input type="text" v-model="element.Slides[1].Link" placeholder="Link">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Derde blok</span>
                                <input type="text" v-model="element.Slides[2].Text" placeholder="Titel">
                                <input type="text" v-model="element.Slides[2].Subtext" placeholder="Subtext">
                                <input type="text" v-model="element.Slides[2].Link" placeholder="Link">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Vierde blok</span>
                                <input type="text" v-model="element.Slides[3].Text" placeholder="Titel">
                                <input type="text" v-model="element.Slides[3].Subtext" placeholder="Subtext">
                                <input type="text" v-model="element.Slides[3].Link" placeholder="Link">
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && (element.Component == 'Review')">
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Eerste blok</span>
                                <div class="media" :style="{ background: '#fff url(' + element.Slides[0].ImageURL + ')' }" @click="this.$root.OpenMedia(element.Slides[0], 'SlideWeb')">
                                    <span v-if="element.Slides[0].ImageURL" class="change">Wijzig afbeelding</span>
                                    <span v-else class="select">Selecteer een afbeelding</span>
                                </div>
                                <input type="text" v-model="element.Slides[0].Title" placeholder="Titel">
                                <input type="text" v-model="element.Slides[0].Text" placeholder="Text">
                                <input type="text" v-model="element.Slides[0].ButtonText" placeholder="Tekst van button">
                                <input type="text" v-model="element.Slides[0].ButtonLink" placeholder="Link van button">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Tweede blok</span>
                                <div class="media" :style="{ background: '#fff url(' + element.Slides[1].ImageURL + ')' }" @click="this.$root.OpenMedia(element.Slides[1], 'SlideWeb')">
                                    <span v-if="element.Slides[1].ImageURL" class="change">Wijzig afbeelding</span>
                                    <span v-else class="select">Selecteer een afbeelding</span>
                                </div>
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Derde blok</span>
                                <InputNumber v-model="element.Slides[2].Number" :min="1" :max="5" :minFractionDigits="1" :maxFractionDigits="1"></InputNumber>
                                <input type="text" v-model="element.Slides[2].Text" placeholder="Tekst">
                                <input type="text" v-model="element.Slides[2].Link" placeholder="Link naar beoordelingen">
                                <Select v-model="element.Slides[2].Google" :options="[{ Label: 'Via Google Reviews', Value: true }, { Label: 'Niet via Google Reviews', Value: false }]" optionLabel="Label" optionValue="Value"></Select>
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && (element.Component == 'BlogRow')">
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Aantal</span>
                                <Select v-model="element.Limit" :options="[{ Label: '1 artikelen', Value: '1' }, { Label: '2 artikelen', Value: '2' }, { Label: '3 artikelen', Value: '3' }, { Label: '4 artikelen', Value: '4' }, { Label: '5 artikelen', Value: '5' }, { Label: '6 artikelen', Value: '6' }, { Label: '7 artikelen', Value: '7' }, { Label: '8 artikelen', Value: '8' }, { Label: '9 artikelen', Value: '9' }, { Label: 'Alle artikelen', Value: '999' }]" optionLabel="Label" optionValue="Value"></Select>
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Tag</span>
                                <input type="text" v-model="element.Tag" placeholder="Bijvoorbeeld: Vacature">
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && (element.Component == 'ContactMethods')">
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">E-mail</span>
                                <Select v-model="element.Email.Active" :options="[{ Label: 'Actief', Value: true }, { Label: 'Niet actief', Value: false }]" optionLabel="Label" optionValue="Value"></Select>
                                <Editor v-model="element.Email.Text" editorStyle="height: 320px" />
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Telefoon</span>
                                <Select v-model="element.Phone.Active" :options="[{ Label: 'Actief', Value: true }, { Label: 'Niet actief', Value: false }]" optionLabel="Label" optionValue="Value"></Select>
                                <Editor v-model="element.Phone.Text" editorStyle="height: 320px" />
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Chat</span>
                                <Select v-model="element.Chat.Active" :options="[{ Label: 'Actief', Value: true }, { Label: 'Niet actief', Value: false }]" optionLabel="Label" optionValue="Value"></Select>
                                <Editor v-model="element.Chat.Text" editorStyle="height: 320px" />
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">WhatsApp</span>
                                <Select v-model="element.WhatsApp.Active" :options="[{ Label: 'Actief', Value: true }, { Label: 'Niet actief', Value: false }]" optionLabel="Label" optionValue="Value"></Select>
                                <Editor v-model="element.WhatsApp.Text" editorStyle="height: 320px" />
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && (element.Component == 'Stock')">
                            <div class="page-content-elements-element-content-group full query" v-for="(Item, i) in Page.Query" :key="i">
                                <select v-model="Item.Column" class="query-select">
                                    <option v-for="(Column, i) in Columns" :key="i" :value="Column.Value">{{ Column.Name }}</option>
                                </select>
                                <select v-if="Columns.find(item => item.Value == Item.Column) && !Columns.find(item => item.Value == Item.Column).Options" v-model="Item.Operator" class="query-select">
                                    <option v-for="(Operator, i) in Operators" :key="i" :value="Operator.Value">{{ Operator.Label }}</option>
                                </select>
                                <select v-if="Columns.find(item => item.Value == Item.Column) && Columns.find(item => item.Value == Item.Column).Options" v-model="Item.Value" class="query-select">
                                    <option v-for="(Option, i) in Columns.find(item => item.Value == Item.Column).Options" :key="i" :value="Option.Value">{{ Option.Name }}</option>
                                </select>
                                <input v-else v-model="Item.Value" type="text" class="value">
                                <span class="query-delete" @click="RemoveQueryRow(Item)">Verwijderen</span>
                            </div>
                            <div class="page-content-elements-element-content-group full">
                                <span @click="AddQueryRow" class="query-add">Regel toevoegen</span>
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && (element.Component == 'SlideShow')">
                            <div class="page-content-elements-element-content-group" v-for="(Slide, i) in element.Slides" :key="i">
                                <div class="slideshow-item">
                                    <span class="subtitle">Web</span>
                                    <div class="media" :style="{ background: '#fff url(' + Slide.ImageURL + ')' }" @click="this.$root.OpenMedia(Slide, 'SlideWeb')">
                                        <span class="select">Selecteer een afbeelding</span>
                                    </div>
                                </div>
                                <div class="slideshow-item">
                                    <span class="subtitle">Mobile</span>
                                    <div class="media" :style="{ background: '#fff url(' + Slide.MobileURL + ')' }" @click="this.$root.OpenMedia(Slide, 'SlideMobile')">
                                        <span class="select">Selecteer een afbeelding</span>
                                    </div>
                                </div>
                                <div class="slideshow-link">
                                    <span class="subtitle">Link</span>
                                    <input type="text" v-model="Slide.Link">
                                </div>
                                <span class="slideshow-remove" @click="RemoveSlide(element.Identifier, Slide.Identifier)">Verwijderen</span>
                            </div>
                            <div class="page-content-elements-element-content-group" @click="AddSlide(element.Identifier)">
                                <div class="slideshow-add">
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#333333"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                                </div>
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && (element.Component == 'USPPicture')">
                            <div class="media" :style="{ background: '#fff url(' + element.ImageURL + ')' }" @click="this.$root.OpenMedia(element, 'Element')">
                                <span class="select">Selecteer een afbeelding</span>
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <input type="text" v-model="element.Slides[0].Icon" placeholder="SVG">
                                <input type="text" v-model="element.Slides[0].Text" placeholder="Tekst">
                                <input type="text" v-model="element.Slides[0].Link" placeholder="Link">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <input type="text" v-model="element.Slides[1].Icon" placeholder="SVG">
                                <input type="text" v-model="element.Slides[1].Text" placeholder="Tekst">
                                <input type="text" v-model="element.Slides[1].Link" placeholder="Link">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <input type="text" v-model="element.Slides[2].Icon" placeholder="SVG">
                                <input type="text" v-model="element.Slides[2].Text" placeholder="Tekst">
                                <input type="text" v-model="element.Slides[2].Link" placeholder="Link">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <input type="text" v-model="element.Slides[3].Icon" placeholder="SVG">
                                <input type="text" v-model="element.Slides[3].Text" placeholder="Tekst">
                                <input type="text" v-model="element.Slides[3].Link" placeholder="Link">
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="element.Component == 'Split'">
                            <draggable v-model="element.Items" class="page-content-elements" handle=".handle" :sort="true" :group="{ name: 'elements', put: function (to) { return to.el.children.length < 2 && CanJoin } }" ghost-class="half" item-key="Identifier" @click.self="Information.Menu = 'Page', Information.Active = false">
                                <template #item="{ element }">
                                    <div class="page-content-elements-element" :class="{ active: element == Information.Active }" style="width: calc(50% - 12.5px)">
                                        <div class="page-content-elements-element-header" @click.self="Information.Active = element, Information.Menu = 'Element'">
                                            <svg class="handle" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g><path d="m 4.496094 0 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m 6 0 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m -6 6 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m 6 0 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m -6 6 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m 6 0 c -0.832032 0 -1.5 0.671875 -1.5 1.5 s 0.667968 1.5 1.5 1.5 c 0.828125 0 1.5 -0.671875 1.5 -1.5 s -0.671875 -1.5 -1.5 -1.5 z m 0 0"></path> </g></svg>
                                            <div class="title">
                                                <span class="category">{{ element.Category }}</span>
                                                <span class="subtitle">{{ element.Title }}</span>
                                            </div>
                                            <svg class="animated" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{ active: element.Animation }"><g><path d="M16.6582 9.28638C18.098 10.1862 18.8178 10.6361 19.0647 11.2122C19.2803 11.7152 19.2803 12.2847 19.0647 12.7878C18.8178 13.3638 18.098 13.8137 16.6582 14.7136L9.896 18.94C8.29805 19.9387 7.49907 20.4381 6.83973 20.385C6.26501 20.3388 5.73818 20.0469 5.3944 19.584C5 19.053 5 18.1108 5 16.2264V7.77357C5 5.88919 5 4.94701 5.3944 4.41598C5.73818 3.9531 6.26501 3.66111 6.83973 3.6149C7.49907 3.5619 8.29805 4.06126 9.896 5.05998L16.6582 9.28638Z" stroke-width="2" stroke-linejoin="round"></path></g></svg>
                                            <svg class="margin" v-tooltip="{ value: 'Margin actief', disabled: !(element.MarginTop || element.MarginBottom || element.MarginLeft || element.MarginRight) }" :class="{ active: element.MarginTop || element.MarginBottom || element.MarginLeft || element.MarginRight }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve"><g><g> <g> <g> <path :class="{ active: element.MarginTop }" d="M19,2H5C4.4,2,4,1.6,4,1s0.4-1,1-1h14c0.6,0,1,0.4,1,1S19.6,2,19,2z"></path> </g> </g> <g> <g> <path :class="{ active: element.MarginBottom }" d="M19,24H5c-0.6,0-1-0.4-1-1s0.4-1,1-1h14c0.6,0,1,0.4,1,1S19.6,24,19,24z"></path> </g> </g> <g> <g> <path :class="{ active: element.MarginRight }" d="M23,20c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1s1,0.4,1,1v14C24,19.6,23.6,20,23,20z"></path> </g> </g> <g> <g> <path :class="{ active: element.MarginLeft }" d="M1,20c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1s1,0.4,1,1v14C2,19.6,1.6,20,1,20z"></path> </g> </g> <g> <g> <path d="M19,20H5c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1v14C20,19.6,19.6,20,19,20z M6,18h12V6H6V18z"></path> </g> </g> </g> </g></svg>
                                            <svg class="padding" v-tooltip="{ value: 'Padding actief', disabled: !(element.PaddingTop || element.PaddingBottom || element.PaddingLeft || element.PaddingRight) }" :class="{ active: element.PaddingTop || element.PaddingBottom || element.PaddingLeft || element.PaddingRight }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve"><g><g> <g> <g> <g> <path :class="{ active: element.PaddingTop }" d="M16,6H8C7.4,6,7,5.6,7,5s0.4-1,1-1h8c0.6,0,1,0.4,1,1S16.6,6,16,6z"></path> </g> </g> <g> <g> <path :class="{ active: element.PaddingBottom }" d="M16,20H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S16.6,20,16,20z"></path> </g> </g> <g> <g> <path :class="{ active: element.PaddingRight }" d="M19,17c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1s1,0.4,1,1v8C20,16.6,19.6,17,19,17z"></path> </g> </g> <g> <g> <path :class="{ active: element.PaddingLeft }" d="M5,17c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1s1,0.4,1,1v8C6,16.6,5.6,17,5,17z"></path> </g> </g> </g> <g> <g> <path d="M23,24H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h22c0.6,0,1,0.4,1,1v22C24,23.6,23.6,24,23,24z M2,22h20V2H2V22z"></path> </g> </g> </g> </g></svg>
                                        </div>
                                        <div class="page-content-elements-element-content" v-if="Information.Active == element && element.Component == 'Text'">
                                            <Editor v-model="element.Text" editorStyle="height: 320px">
                                                <template v-slot:toolbar>
                                                    <div id="toolbar-container">
                                                        <span class="ql-formats">
                                                            <select class="ql-size"></select>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <button class="ql-bold"></button>
                                                            <button class="ql-italic"></button>
                                                            <button class="ql-underline"></button>
                                                            <button class="ql-strike"></button>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <select class="ql-color"></select>
                                                            <select class="ql-background"></select>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <button class="ql-script" value="sub"></button>
                                                            <button class="ql-script" value="super"></button>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <button class="ql-header" value="1"></button>
                                                            <button class="ql-header" value="2"></button>
                                                            <button class="ql-blockquote"></button>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <button class="ql-list" value="ordered"></button>
                                                            <button class="ql-list" value="bullet"></button>
                                                            <button class="ql-indent" value="-1"></button>
                                                            <button class="ql-indent" value="+1"></button>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <button class="ql-direction" value="rtl"></button>
                                                            <select class="ql-align"></select>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <button class="ql-link"></button>
                                                            <button class="ql-button" @click="AddButton">
                                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#333333"><g><path d="M20.5 17h-17A2.502 2.502 0 0 1 1 14.5v-4A2.502 2.502 0 0 1 3.5 8h17a2.502 2.502 0 0 1 2.5 2.5v4a2.502 2.502 0 0 1-2.5 2.5zm-17-8A1.502 1.502 0 0 0 2 10.5v4A1.502 1.502 0 0 0 3.5 16h17a1.502 1.502 0 0 0 1.5-1.5v-4A1.502 1.502 0 0 0 20.5 9zM17 12H7v1h10z"></path><path fill="none" d="M0 0h24v24H0z"></path></g></svg>
                                                            </button>
                                                        </span>
                                                        <span class="ql-formats">
                                                            <button class="ql-clean"></button>
                                                        </span>
                                                    </div>
                                                </template>
                                            </Editor>
                                        </div>
                                        <div class="page-content-elements-element-content" v-if="Information.Active == element && (element.Component == 'Hero' || element.Component == 'Image')">
                                            <div class="media" :style="{ background: '#fff url(' + element.ImageURL + ')' }" @click="this.$root.OpenMedia(element, 'Image')">
                                                <span v-if="element.ImageURL" class="change">Wijzig afbeelding</span>
                                                <span v-else class="select">Selecteer een afbeelding</span>
                                            </div>
                                            <div class="page-content-elements-element-content-group">
                                                <span class="page-content-elements-element-content-group-subtitle">Maximale breedte</span>
                                                <input type="text" v-model="element.MaxWidth">
                                            </div>
                                            <div class="page-content-elements-element-content-group">
                                                <span class="page-content-elements-element-content-group-subtitle">Maximale hoogte</span>
                                                <input type="text" v-model="element.MaxHeight">
                                            </div>
                                        </div>
                                        <div class="page-content-elements-element-content" v-if="Information.Active == element && element.Component == 'Maps'">
                                            <div class="page-content-elements-element-content-group full">
                                                <span class="page-content-elements-element-content-group-subtitle">Locatie</span>
                                                <input type="text" v-model="element.Search">
                                            </div>
                                            <div class="page-content-elements-element-content-group full">
                                                <span class="page-content-elements-element-content-group-subtitle">Soort kaart</span>
                                                <Select v-model="element.Mode" :options="[{ Label: 'Kaart', Value: 'roadmap' }, { Label: 'Sateliet', Value: 'satellite' }]" optionLabel="Label" optionValue="Value"></Select>
                                            </div>
                                            <div class="page-content-elements-element-content-group full">
                                                <span class="page-content-elements-element-content-group-subtitle">Zoom</span>
                                                <Select v-model="element.Zoom" :options="[{ Label: 'Dichtbij', Value: 18 }, { Label: 'Gemiddeld', Value: 10 }, { Label: 'Verweg', Value: 5 }]" optionLabel="Label" optionValue="Value"></Select>
                                            </div>
                                            <div class="page-content-elements-element-content-group full">
                                                <span class="page-content-elements-element-content-group-subtitle">Preview</span>
                                                <iframe v-if="element.URL" :src="element.URL" class="page-content-elements-element-content-group-iframe" width="100%" frameborder="0"></iframe>
                                                <span v-if="element.URL" class="page-content-elements-element-content-group-button" @click="element.URL = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBNXHffXjzIYolIpptjw94OURm4j-mKYDc&q=' + element.Search + '&zoom=' + element.Zoom + 'maptype=' + element.Mode + '&language=nl'">Opnieuw genereren</span>
                                                <span v-else class="page-content-elements-element-content-group-button" @click="element.URL = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBNXHffXjzIYolIpptjw94OURm4j-mKYDc&q=' + element.Search + '&zoom=' + element.Zoom + 'maptype=' + element.Mode + '&language=nl'">Genereren</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && element.Component == 'Maps'">
                            <div class="page-content-elements-element-content-group full">
                                <span class="page-content-elements-element-content-group-subtitle">Locatie</span>
                                <input type="text" v-model="element.Search">
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Soort kaart</span>
                                <Select v-model="element.Mode" :options="[{ Label: 'Kaart', Value: 'roadmap' }, { Label: 'Sateliet', Value: 'satellite' }]" optionLabel="Label" optionValue="Value"></Select>
                            </div>
                            <div class="page-content-elements-element-content-group">
                                <span class="page-content-elements-element-content-group-subtitle">Zoom</span>
                                <Select v-model="element.Zoom" :options="[{ Label: 'Dichtbij', Value: 18 }, { Label: 'Gemiddeld', Value: 10 }, { Label: 'Verweg', Value: 5 }]" optionLabel="Label" optionValue="Value"></Select>
                            </div>
                            <div class="page-content-elements-element-content-group full">
                                <span class="page-content-elements-element-content-group-subtitle">Preview</span>
                                <iframe v-if="element.URL" :src="element.URL" class="page-content-elements-element-content-group-iframe" width="100%" frameborder="0"></iframe>
                                <span v-if="element.URL" class="page-content-elements-element-content-group-button" @click="element.URL = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBNXHffXjzIYolIpptjw94OURm4j-mKYDc&q=' + element.Search + '&zoom=' + element.Zoom + 'maptype=' + element.Mode + '&language=nl'">Opnieuw genereren</span>
                                <span v-else class="page-content-elements-element-content-group-button" @click="element.URL = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBNXHffXjzIYolIpptjw94OURm4j-mKYDc&q=' + element.Search + '&zoom=' + element.Zoom + 'maptype=' + element.Mode + '&language=nl'">Genereren</span>
                            </div>
                        </div>
                        <div class="page-content-elements-element-content" v-if="Information.Active == element && element.Component == 'HTML'">
                            <div class="page-content-elements-element-content-group full">
                                <span class="page-content-elements-element-content-group-subtitle">Code</span>
                                <CodeEditor v-model="element.Code" :languages="[['html']]" :tab-spaces="4" :header="false" width="100%" min-height="350px"></CodeEditor>
                            </div>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
        <div class="page-styling">
            <div class="page-styling-menu">
                <span class="page-styling-menu-item border" @click="Information.Menu = 'Page'" :class="{ active: Information.Menu == 'Page' }">Pagina</span>
                <span class="page-styling-menu-item" @click="Information.Menu = 'Element'" :class="{ active: Information.Menu == 'Element', disabled: !Information.Active }">Blok</span>
            </div>
            <div class="page-styling-groups" v-if="Information.Menu == 'Page'">
                <div class="page-styling-group">
                    <span class="page-styling-group-title">Titel</span>
                    <input type="text" v-model="Page.Title">
                </div>
                <div class="page-styling-group">
                    <span class="page-styling-group-title">Slug</span>
                    <InputGroup class="left">
                        <InputGroupAddon>
                            /
                        </InputGroupAddon>
                        <input type="text" v-model="Page.Slug">
                    </InputGroup>
                </div>
                <div class="page-styling-group">
                    <span class="page-styling-group-title">Status</span>
                    <Select v-model="Page.Status" :options="Statusses" optionLabel="Label" optionValue="Value"></Select>
                </div>
                <div class="page-styling-divider" v-if="Page.Template == 'Blog'"></div>
                <div class="page-styling-group" v-if="Page.Template == 'Blog'">
                    <span class="page-styling-group-title">Auteur</span>
                    <Select v-model="Page.Author" :options="Authors" optionLabel="Name" optionValue="Identifier" placeholder="Selecteer een auteur"></Select>
                </div>
                <div class="page-styling-group" v-if="Page.Template == 'Blog'">
                    <span class="page-styling-group-title">Tags</span>
                    <tag-input class="page-styling-group-tags" v-model="Page.Tags" :autocomplete-items="['Vacature']" tagClass="page-styling-group-tags-tag" />
                </div>
                <div class="page-styling-divider"></div>
                <div class="page-styling-group">
                    <span class="page-styling-group-title">Breedte</span>
                    <InputGroup class="right">
                        <input type="number" v-model="Page.Width" min="600" max="1200">
                        <InputGroupAddon>
                            px
                        </InputGroupAddon>
                    </InputGroup>
                </div>
                <div class="page-styling-group" v-if="Page.Gap">
                    <span class="page-styling-group-title">Ruimte</span>
                    <SelectButton v-model="Device" :options="Devices" optionLabel="Label" optionValue="Value" :allowEmpty="false">
                        <template #option="slotProps">
                            <span>{{ slotProps.option.Label }}</span>
                        </template>
                    </SelectButton>
                    <InputGroup class="right" v-if="Device == 'Computer'">
                        <input type="number" v-model="Page.Gap.Computer" min="0" max="100">
                        <InputGroupAddon>
                            px
                        </InputGroupAddon>
                    </InputGroup>
                    <InputGroup class="right" v-if="Device == 'Mobile'">
                        <input type="number" v-model="Page.Gap.Mobile" min="0" max="100">
                        <InputGroupAddon>
                            px
                        </InputGroupAddon>
                    </InputGroup>
                </div>
                <div class="page-styling-group" v-if="Page.Margin">
                    <span class="page-styling-group-title">Margin</span>
                    <SelectButton v-model="Device" :options="Devices" optionLabel="Label" optionValue="Value" :allowEmpty="false">
                        <template #option="slotProps">
                            <span>{{ slotProps.option.Label }}</span>
                        </template>
                    </SelectButton>
                    <div class="page-styling-group-margin" v-if="Device == 'Computer'">
                        <input type="text" v-model="Page.Margin.Computer.Top" placeholder="Top" class="centered">
                        <input type="text" v-model="Page.Margin.Computer.Left" placeholder="Left" class="centered half">
                        <input type="text" v-model="Page.Margin.Computer.Right" placeholder="Right" class="centered half">
                        <input type="text" v-model="Page.Margin.Computer.Bottom" placeholder="Bottom" class="centered">
                    </div>
                    <div class="page-styling-group-margin" v-if="Device == 'Mobile'">
                        <input type="text" v-model="Page.Margin.Mobile.Top" placeholder="Top" class="centered">
                        <input type="text" v-model="Page.Margin.Mobile.Left" placeholder="Left" class="centered half">
                        <input type="text" v-model="Page.Margin.Mobile.Right" placeholder="Right" class="centered half">
                        <input type="text" v-model="Page.Margin.Mobile.Bottom" placeholder="Bottom" class="centered">
                    </div>
                </div>
                <div class="page-styling-divider"></div>
                <div class="page-styling-group">
                    <span class="page-styling-group-title">Achtergrondkleur</span>
                    <InputGroup class="left">
                        <InputGroupAddon>
                            <ColorPicker v-model="Page.Background" defaultColor="ffffff" />
                            #
                        </InputGroupAddon>
                        <input type="text" v-model="Page.Background">
                    </InputGroup>
                </div>
            </div>
            <div class="page-styling-groups" v-if="Information.Menu == 'Element'">
                <div class="page-styling-group">
                    <span class="page-styling-group-title">Soort</span>
                    <input type="text" :value="Information.Active.Title" disabled>
                </div>
                <div class="page-styling-group" v-if="Information.Active.Margin">
                    <span class="page-styling-group-title">Margin</span>
                    <SelectButton v-model="Device" :options="Devices" optionLabel="Label" optionValue="Value" :allowEmpty="false">
                        <template #option="slotProps">
                            <span>{{ slotProps.option.Label }}</span>
                        </template>
                    </SelectButton>
                    <div class="page-styling-group-margin" v-if="Device == 'Computer'">
                        <input type="text" v-model="Information.Active.Margin.Computer.Top" placeholder="Top" class="centered">
                        <input type="text" v-model="Information.Active.Margin.Computer.Left" placeholder="Left" class="centered half">
                        <input type="text" v-model="Information.Active.Margin.Computer.Right" placeholder="Right" class="centered half">
                        <input type="text" v-model="Information.Active.Margin.Computer.Bottom" placeholder="Bottom" class="centered">
                    </div>
                    <div class="page-styling-group-margin" v-if="Device == 'Mobile'">
                        <input type="text" v-model="Information.Active.Margin.Mobile.Top" placeholder="Top" class="centered">
                        <input type="text" v-model="Information.Active.Margin.Mobile.Left" placeholder="Left" class="centered half">
                        <input type="text" v-model="Information.Active.Margin.Mobile.Right" placeholder="Right" class="centered half">
                        <input type="text" v-model="Information.Active.Margin.Mobile.Bottom" placeholder="Bottom" class="centered">
                    </div>
                </div>
                <div class="page-styling-group" v-if="Information.Active.Padding">
                    <span class="page-styling-group-title">Padding</span>
                    <SelectButton v-model="Device" :options="Devices" optionLabel="Label" optionValue="Value" :allowEmpty="false">
                        <template #option="slotProps">
                            <span>{{ slotProps.option.Label }}</span>
                        </template>
                    </SelectButton>
                    <div class="page-styling-group-margin" v-if="Device == 'Computer'">
                        <input type="text" v-model="Information.Active.Padding.Computer.Top" placeholder="Top" class="centered">
                        <input type="text" v-model="Information.Active.Padding.Computer.Left" placeholder="Left" class="centered half">
                        <input type="text" v-model="Information.Active.Padding.Computer.Right" placeholder="Right" class="centered half">
                        <input type="text" v-model="Information.Active.Padding.Computer.Bottom" placeholder="Bottom" class="centered">
                    </div>
                    <div class="page-styling-group-margin" v-if="Device == 'Mobile'">
                        <input type="text" v-model="Information.Active.Padding.Mobile.Top" placeholder="Top" class="centered">
                        <input type="text" v-model="Information.Active.Padding.Mobile.Left" placeholder="Left" class="centered half">
                        <input type="text" v-model="Information.Active.Padding.Mobile.Right" placeholder="Right" class="centered half">
                        <input type="text" v-model="Information.Active.Padding.Mobile.Bottom" placeholder="Bottom" class="centered">
                    </div>
                </div>
                <div class="page-styling-group" v-if="Information.Active.Gap">
                    <span class="page-styling-group-title">Ruimte</span>
                    <input type="text" v-model="Information.Active.Gap">
                </div>
                <div class="page-styling-group">
                    <span class="page-styling-group-title">Class</span>
                    <input type="text" v-model="Information.Active.Class">
                </div>
                <div class="page-styling-group">
                    <span class="page-styling-group-title">Animatie</span>
                    <Select v-model="Information.Active.Animation" :options="Animations" optionLabel="Label" optionGroupLabel="Label" optionGroupChildren="Items" optionValue="Value" showClear>
                        <template #optiongroup="slotProps">
                            <span style="opacity: 0.5">{{ slotProps.option.Label }}</span>
                        </template>
                    </Select>
                </div>
                <div class="page-styling-group">
                    <span class="page-styling-group-title">Achtergrondkleur</span>
                    <InputGroup class="left">
                        <InputGroupAddon>
                            <ColorPicker v-model="Information.Active.Background" defaultColor="ffffff" />
                            #
                        </InputGroupAddon>
                        <input type="text" v-model="Information.Active.Background">
                    </InputGroup>
                </div>
                <div class="page-styling-group" v-if="!Information.Active.Disabled">
                    <span class="page-styling-group-delete" @click="DeleteElement(Information.Active)">Verwijderen</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputNumber from 'primevue/inputnumber';
import ColorPicker from 'primevue/colorpicker';
import Select from 'primevue/select';
import SplitButton from 'primevue/splitbutton';
import SelectButton from 'primevue/selectbutton';
import Editor from 'primevue/editor';

import CodeEditor from 'simple-code-editor';

import TagInput from '@mayank1513/vue-tag-input'
import '@mayank1513/vue-tag-input/style.css'

import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'WebsitePage',

    components: {
        draggable,
        Select,
        InputGroup,
        InputGroupAddon,
        InputNumber,
        ColorPicker,
        SplitButton,
        SelectButton,
        Editor,
        TagInput,
        CodeEditor
    },

    data () {
        return {
            Search: '',
            Categories: [
                {
                    Title: 'Inhoud',
                    Elements: [
                        {
                            Title: 'Tekst',
                            Category: 'Inhoud',
                            Component: 'Text',
                            Templates: ['Page', 'Archive', 'Blog'],
                            Text: '',
                            Placeholder: 'element-alinea.png',
                            Split: true,
                            Width: 'Full',
                            Locked: false
                        },
                        {
                            Title: 'Hero',
                            Category: 'Inhoud',
                            Component: 'Hero',
                            Templates: ['Page'],
                            Placeholder: 'element-header.png',
                            Width: 'Full',
                            Locked: false
                        },
                        {
                            Title: 'Afbeelding',
                            Category: 'Inhoud',
                            Component: 'Image',
                            Templates: ['Page', 'Archive', 'Blog'],
                            Placeholder: 'element-afbeelding.png',
                            Width: 'Full',
                            Split: true,
                            Locked: false,
                            MaxWidth: '',
                            MaxHeight: ''
                        },
                        {
                            Title: 'Diavoorstelling',
                            Category: 'Inhoud',
                            Component: 'SlideShow',
                            Templates: ['Page', 'Blog'],
                            Width: 'Full',
                            Locked: false,
                            Slides: [
                                {
                                    Identifier: '',
                                    ImageURL: '',
                                    MobileURL: '',
                                    Link: ''
                                }
                            ]
                        },
                        {
                            Title: 'Knop',
                            Category: 'Inhoud',
                            Component: 'Button',
                            Templates: ['Page', 'Blog'],
                            Placeholder: 'element-button.png',
                            Width: 'Full',
                            Locked: false,
                            External: false
                        },
                        {
                            Title: 'Blogs',
                            Category: 'Inhoud',
                            Component: 'BlogRow',
                            Templates: ['Page'],
                            Width: 'Full',
                            Locked: false,
                            Limit: 3,
                            Tags: ''
                        },
                        {
                            Title: 'Drie knoppen',
                            Category: 'Inhoud',
                            Component: 'ThreeButtons',
                            Templates: ['Page'],
                            Width: 'Full',
                            Locked: true,
                            Slides: [
                                {
                                    Identifier: '',
                                    Text: '',
                                    Link: ''
                                },
                                {
                                    Identifier: '',
                                    Text: '',
                                    Link: ''
                                },
                                {
                                    Identifier: '',
                                    Text: '',
                                    Link: ''
                                }
                            ]
                        },
                        {
                            Title: 'USP',
                            Category: 'Inhoud',
                            Component: 'USP',
                            Templates: ['Page'],
                            Width: 'Full',
                            Locked: true,
                            Slides: [
                                {
                                    Icon: '',
                                    Text: '',
                                    Link: ''
                                },
                                {
                                    Icon: '',
                                    Text: '',
                                    Link: ''
                                },
                                {
                                    Icon: '',
                                    Text: '',
                                    Link: ''
                                }
                            ]
                        },
                        {
                            Title: 'USP Afbeelding',
                            Category: 'Inhoud',
                            Component: 'USPPicture',
                            Templates: ['Page'],
                            Width: 'Full',
                            Locked: true,
                            ImageURL: '',
                            Slides: [
                                {
                                    Icon: '',
                                    Text: '',
                                    Link: ''
                                },
                                {
                                    Icon: '',
                                    Text: '',
                                    Link: ''
                                },
                                {
                                    Icon: '',
                                    Text: '',
                                    Link: ''
                                },
                                {
                                    Icon: '',
                                    Text: '',
                                    Link: ''
                                }
                            ]
                        },
                        {
                            Title: 'Vier knoppen',
                            Category: 'Inhoud',
                            Component: 'FourButtons',
                            Templates: ['Page'],
                            Width: 'Full',
                            Locked: true,
                            Slides: [
                                {
                                    Identifier: '',
                                    Text: '',
                                    Link: ''
                                },
                                {
                                    Identifier: '',
                                    Text: '',
                                    Link: ''
                                },
                                {
                                    Text: '',
                                    Subtext: '',
                                    Link: ''
                                },
                                {
                                    Text: '',
                                    Subtext: '',
                                    Link: ''
                                }
                            ]
                        },
                        {
                            Title: 'Review',
                            Category: 'Inhoud',
                            Component: 'Review',
                            Templates: ['Page'],
                            Width: 'Full',
                            Locked: false,
                            Slides: [
                                {
                                    ImageURL: '',
                                    Title: '',
                                    Text: '',
                                    ButtonText: '',
                                    ButtonLink: '',
                                },
                                {
                                    ImageURL: '',
                                },
                                {
                                    Number: '',
                                    Text: '',
                                    Link: '',
                                    Google: false
                                }
                            ]
                        },
                        {
                            Title: 'Contact',
                            Category: 'Inhoud',
                            Component: 'ContactMethods',
                            Templates: ['Page'],
                            Width: 'Full',
                            Locked: false,
                            Email: {
                                Active: false,
                                Text: ''
                            },
                            Phone: {
                                Active: false,
                                Text: ''
                            },
                            Chat: {
                                Active: false,
                                Text: ''
                            },
                            WhatsApp: {
                                Active: false,
                                Text: ''
                            }
                        },
                        {
                            Title: 'Maps',
                            Category: 'Inhoud',
                            Component: 'Maps',
                            Templates: ['Page', 'Blog'],
                            Width: 'Full',
                            Locked: false,
                            Search: '',
                            Mode: 'roadmap',
                            Zoom: 10
                        },
                        {
                            Title: 'HTML',
                            Category: 'Inhoud',
                            Component: 'HTML',
                            Templates: ['Page', 'Blog'],
                            Width: 'Full',
                            Locked: false,
                            Code: ''
                        }
                    ]
                },
                {
                    Title: 'Voorraad',
                    Elements: [
                        {
                            Title: 'Voorraad',
                            Category: 'Voorraad',
                            Component: 'Stock',
                            Templates: ['Page', 'Archive'],
                            Placeholder: 'element-voorraad.png',
                            Width: 'Full',
                            Locked: true
                        },
                        {
                            Title: 'Regel',
                            Category: 'Voorraad',
                            Component: 'StockRow',
                            Templates: ['Page', 'Blog'],
                            Placeholder: 'element-voorraad.png',
                            Width: 'Full',
                            Locked: true,
                            Limit: 15
                        },
                        {
                            Title: 'Regel',
                            Category: 'Voorraad',
                            Component: 'StockCarsRow',
                            Templates: ['Page'],
                            Placeholder: 'element-voorraad.png',
                            Width: 'Full',
                            Locked: true,
                            Limit: 3
                        },
                        {
                            Title: 'Pagina',
                            Category: 'Voorraad',
                            Component: 'StockCars',
                            Templates: ['Page'],
                            Placeholder: 'element-voorraad.png',
                            Width: 'Full',
                            Locked: true,
                            Filter: true,
                            Sort: 'DateDESC',
                            Limit: 25
                        }
                    ]
                }
            ],
            Client: [],
            Page: [],
            Information: {
                Menu: 'Page',
                Active: false
            },
            Statusses: [
                {
                    Label: 'Online',
                    Value: 'O'
                },
                {
                    Label: 'Concept',
                    Value: 'C'
                },
                {
                    Label: 'Archief',
                    Value: 'A'
                }
            ],
            Animations: [
                {
                    Label: 'Fade',
                    Items: [
                        {
                            Label: 'Fade',
                            Value: 'fade'
                        },
                        {
                            Label: 'Fade Up',
                            Value: 'fade-up'
                        },
                        {
                            Label: 'Fade Down',
                            Value: 'fade-down'
                        },
                        {
                            Label: 'Fade Left',
                            Value: 'fade-left'
                        },
                        {
                            Label: 'Fade Right',
                            Value: 'fade-right'
                        }
                    ]
                },
                {
                    Label: 'Flip',
                    Items: [
                        {
                            Label: 'Flip Up',
                            Value: 'flip-up'
                        },
                        {
                            Label: 'Flip Down',
                            Value: 'flip-down'
                        },
                        {
                            Label: 'Flip Left',
                            Value: 'flip-left'
                        },
                        {
                            Label: 'Flip Right',
                            Value: 'flip-right'
                        }
                    ]
                },
                {
                    Label: 'Slide',
                    Items: [
                        {
                            Label: 'Slide Up',
                            Value: 'slide-up'
                        },
                        {
                            Label: 'Slide Down',
                            Value: 'slide-down'
                        },
                        {
                            Label: 'Slide Left',
                            Value: 'slide-left'
                        },
                        {
                            Label: 'Slide Right',
                            Value: 'slide-right'
                        }
                    ]
                },
                {
                    Label: 'Zoom',
                    Items: [
                        {
                            Label: 'Zoom In',
                            Value: 'zoom-in'
                        },
                        {
                            Label: 'Zoom In Up',
                            Value: 'zoom-in-up'
                        },
                        {
                            Label: 'Zoom In Down',
                            Value: 'zoom-in-down'
                        },
                        {
                            Label: 'Zoom In Left',
                            Value: 'zoom-in-left'
                        },
                        {
                            Label: 'Zoom In Right',
                            Value: 'zoom-in-right'
                        },
                        {
                            Label: 'Zoom Out',
                            Value: 'zoom-out'
                        },
                        {
                            Label: 'Zoom Out Up',
                            Value: 'zoom-out-up'
                        },
                        {
                            Label: 'Zoom Out Down',
                            Value: 'zoom-out-down'
                        },
                        {
                            Label: 'Zoom Out Left',
                            Value: 'zoom-out-left'
                        },
                        {
                            Label: 'Zoom Out Right',
                            Value: 'zoom-out-right'
                        }
                    ]
                }
            ],
            Columns: [],
            Operators: [
                {
                    Label: 'is gelijk aan',
                    Value: 'IS'
                },
                {
                    Label: 'bevat',
                    Value: 'LIKE'
                },
                {
                    Label: 'is lager dan',
                    Value: '<'
                },
                {
                    Label: 'is gelijk aan of lager dan',
                    Value: '<='
                },
                {
                    Label: 'is gelijk aan of hoger dan',
                    Value: '>='
                },
                {
                    Label: 'is hoger dan',
                    Value: '>'
                }
            ],
            Device: 'Computer',
            Devices: [
                {
                    Label: 'Computer',
                    Value: 'Computer'
                },
                {
                    Label: 'Mobiel',
                    Value: 'Mobile'
                }
            ],
            Authors: [],
            CanJoin: false
        }
    },

    mounted () {
        this.GetPage()
        this.GetColumns()

        var self = this
        this.$mitt.on('ImageSelected', (e) => { self.ChangeMedia(e); })
    },

    methods: {
        GetPage () {
            this.$http.get(`https://api.wheels.nl/pages/${this.$route.params.id}`).then((response) => {                
                if(response.data.page) {
                    this.Page = response.data.page
                    this.Client = response.data.client

                    if(response.data.authors) { this.Authors = response.data.authors }

                    if(!response.data.page.Elements) {
                        this.Page.Elements = []
                    }

                    if(!response.data.page.Tags) {
                        this.Page.Tags = []
                    } else {
                        this.Page.Tags = response.data.page.Tags.split(',')
                    }
                } else {
                    this.$router.push('/website/pagina')
                    this.$toast.open({
                        message: 'Pagina niet gevonden',
                        position: 'bottom-right',
                        type: 'error'
                    })
                }
            })
        },

        SavePage () {
            this.$http.patch(`https://api.wheels.nl/pages/${this.$route.params.id}`, {
                Page: this.Page
            }).then(() => {                
                this.$toast.open({
                    message: 'Pagina geüpdate',
                    position: 'bottom-right',
                    type: 'success'
                })
            })
        },

        ToggleAdd (e) {
            this.$refs.add.toggle(e)
        },

        AddElement (e) {
            var Element = e
            
            Element.Identifier = uuidv4()
            Element.Margin = {"Computer": {"Top": '', "Left": '', "Right": '', "Bottom": ''}, "Mobile": {"Top": '', "Left": '', "Right": '', "Bottom": ''}}
            Element.Padding = {"Computer": {"Top": '', "Left": '', "Right": '', "Bottom": ''}, "Mobile": {"Top": '', "Left": '', "Right": '', "Bottom": ''}}

            this.Page.Elements.push({...Element})
        },

        ChangeElement (e, a) {
            this.Page.Elements.splice(a, 0, {
                Identifier: uuidv4(),
                Component: "Split",
                Category: "Layout",
                Title: "Twee kolommen",
                Width: "Full",
                Locked: true,
                Items: [e],
                Gap: 25,
                Margin: {"Computer": {"Top": '', "Left": '', "Right": '', "Bottom": ''}, "Mobile": {"Top": '', "Left": '', "Right": '', "Bottom": ''}},
                Padding: {"Computer": {"Top": '', "Left": '', "Right": '', "Bottom": ''}, "Mobile": {"Top": '', "Left": '', "Right": '', "Bottom": ''}}
            })

            this.Page.Elements = this.Page.Elements.filter(item => item !== e)
        },

        DuplicateElement (e, a) {
            var Element = e
            
            Element.Identifier = uuidv4()
            if(Element.Component == 'Split' && Element.Items.length > 0) {
                Element.Items.forEach(item => {
                    item.Identifier = uuidv4()
                })
            }

            this.Page.Elements.splice(a, 0, {...Element})
        },

        DeleteElement (a) {
            this.Page.Elements = this.Page.Elements.filter(e => e !== a)

            this.Information.Active = false
            this.Information.Menu = 'Page'
        },

        DeleteElementIndex (a) {
            this.Page.Elements.splice(a, 1)

            this.Information.Active = false
            this.Information.Menu = 'Page'
        },

        ChangeMediaSearch (e, a, z) {            
            for (const [key, value] of Object.entries(e)) {
                if(key == 'Active' && value == a) {
                    this.SetMedia(e, z)
                }

                if(Array.isArray(value)) {
                    this.ChangeMediaSearch(value, a, z)
                }

                if(typeof value === 'object' && value !== null) {
                    this.ChangeMediaSearch(value, a, z)
                }
            }
        },

        ChangeMedia (a) {
            if(a.Type == 'Thumbnail') {
                this.SetMedia('Thumbnail', a)
            } else {
                this.Page.Elements.forEach(e => {
                    this.ChangeMediaSearch(e, a.Focus, a)
                })
            }
        },

        SetMedia (e, a) {
            this.$http.get(`https://api.wheels.nl/pages/media/${a.Selected}`).then((response) => {
                if(a.Type == 'SlideWeb') {
                    if(response.data.Image) { e.ImageURL = response.data.Image }
                    if(response.data.Video) { e.VideoURL = response.data.Video } else { e.VideoURL = false }
                    e.Image = e.Selected,
                    e.Alt = response.data.Alt
                } else if(a.Type == 'SlideMobile') {
                    e.Mobile = e.Selected,
                    e.MobileURL = response.data.Image
                    e.Alt = response.data.Alt
                } else if (a.Type == 'Thumbnail') {
                    this.Page.Thumbnail = a.Selected
                    this.Page.ThumbnailSrc = response.data.Image
                } else {
                    e.Image = e.Selected,
                    e.ImageURL = response.data.Image
                    e.Alt = response.data.Alt
                }
            })
        },

        GetColumns () {
            this.$http.get('https://api.wheels.nl/pages/columns').then((response) => {
                if(response.data.columns) {
                    this.Columns = response.data.columns
                } else {
                    this.Columns = []
                }
            })
        },

        AddQueryRow () {
            if(!Array.isArray(this.Page.Query)) { this.Page.Query = [] }
            this.Page.Query.push({
                "Column": '',
                "Operator": '',
                "Value": ''
            })
        },

        RemoveQueryRow (e) {
            this.Page.Query = this.Page.Query.filter(item => item !== e)
        },

        AddSlide (e) {
            if(!this.Page.Elements.find(element => element.Identifier == e).Slides) {
                this.Page.Elements.find(element => element.Identifier == e).Slides = []
            }

            this.Page.Elements.find(element => element.Identifier == e).Slides.push({
                Identifier: uuidv4(),
                Image: '',
                ImageURL: '',
                Mobile: '',
                MobileURL: '',
                Alt: ''
            })
        },

        RemoveSlide (e, a) {
            this.Page.Elements.find(item => item.Identifier == e).Slides = this.Page.Elements.find(item => item.Identifier == e).Slides.filter(item => item.Identifier !== a)
        },

        CheckCanJoin (e) {
            if(e.item.dataset.split) {
                this.CanJoin = true
            } else {
                this.CanJoin = false
            }
        },

        AddButton () {
            let text = window.getSelection().baseNode.data
            let link = prompt("Geef de link op (beginnende met een /)")

            if (link != null) {
                var range;
                if (window.getSelection && window.getSelection().getRangeAt) {
                    range = window.getSelection().getRangeAt(0);
                    range.deleteContents();
                    var div = document.createElement("p");
                    div.innerHTML = '<a href="' + link + '" target="_self" class="secondary">' + text + '</a>';
                    var frag = document.createDocumentFragment(), child;
                    while ( (child = div.firstChild) ) {
                        frag.appendChild(child);
                    }
                    range.insertNode(frag);
                } else if (document.selection && document.selection.createRange) {
                    range = document.selection.createRange();
                    range.pasteHTML('<a href="' + link + '" target="_self" class="secondary">' + text + '</a>');
                }
            }
        }
    }
}
</script>

<style scoped>
.page {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}



.page .page-bar {
    width: 100%;
    height: 80px;
    display: flex;
    background: #fff;
    border-bottom: solid 1px #ededed;
}

.page .page-bar .page-bar-left {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding: 0 25px;
}

.page .page-bar .page-bar-left-title {
    font-size: 20px;
    font-weight: 700;
}

.page .page-bar .page-bar-left-link {
    color: unset;
    font-size: 14px;
    text-decoration: none;
    opacity: 0.5;
}

.page .page-bar .page-bar-right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    padding: 0 25px;
}

.page .page-bar .page-bar-right .page-bar-right-undo {
    width: 30px;
    height: 30px;
    transform: scaleX(-1);
    cursor: pointer;
}

.page .page-bar .page-bar-right .page-bar-right-redo {
    width: 30px;
    height: 30px;
    margin: 0 50px 0 0;
    cursor: pointer;
}

.page .page-bar .page-bar-right .page-bar-right-undo.inactive, .page .page-bar .page-bar-right .page-bar-right-redo.inactive {
    opacity: 0.25;
}

.page .page-bar .page-bar-right .page-bar-right-link {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.page .page-bar .page-bar-right .page-bar-right-link svg {
    width: 20px;
    height: 20px;
}

.page .page-bar .page-bar-right .page-bar-right-save {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.page .page-bar .page-bar-right .page-bar-right-save.disabled {
    opacity: 0.25;
}

.page .page-bar .page-bar-right .page-bar-right-save svg {
    width: 20px;
    height: 20px;
    fill: #fff;
}





.page .page-elements {
    width: 300px;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 25px 0;
    background: #fff;
    border-right: solid 1px #ededed;
    overflow: auto;
}

.page .page-elements .page-elements-search {
    display: none;
    padding: 0 25px;
}

.page .page-elements .page-elements-search input {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    background: #f7f8fc;
    outline: 0;
    border: 0;
    border-radius: 5px;
}

.page .page-elements .page-elements-category {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 25px;
}

.page .page-elements .page-elements-category .page-elements-category-title {
    font-weight: 700;
}

.page .page-elements .page-elements-category-elements {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.page .page-elements .page-elements-category-elements-element {
    width: calc(50% - 7.5px);
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.page .page-elements .page-elements-category-elements-element .page-elements-category-elements-element-thumbnail {
    width: 100%;
    height: 100px;
    background: #f7f8fc;
    border-radius: 5px;
}

.page .page-elements .page-elements-category-elements-element .page-elements-category-elements-element-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.page .page-elements .page-elements-category-elements-element.sortable-ghost .page-elements-category-elements-element-thumbnail {
    border: dashed 2px #d0d8df;
    opacity: 0.5;
}

.page .page-elements .page-elements-category-elements-element .page-elements-category-elements-element-title {
    font-size: 14px;
}

.page .page-elements .page-elements-category-elements-element.sortable-ghost .page-elements-category-elements-element-title {
    opacity: 0.5;
}

.page .page-elements .page-elements-category:last-of-type .page-elements-divider {
    display: none;
}

.page .page-elements .page-elements-divider {
    width: 100%;
    height: 2px;
    background: #ededed;
    margin: 35px auto 0 auto;
}

.page .page-elements .page-elements-empty {
    font-size: 14px;
    opacity: 0.5;
}

.page .page-elements::-webkit-scrollbar {
    width: 3px;
}

.page .page-elements::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
}

.page .page-elements:hover::-webkit-scrollbar-thumb {
    background: #b0b0b0;
}

.page .page-elements::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.page .page-elements::-webkit-scrollbar-track {
    background: transparent;
    margin: 0;
}





.page .page-content {
    width: calc(100% - 600px);
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    padding: 50px;
    overflow: auto;
}

.page .page-content .add-column {
    position: absolute;
    bottom: 25px;
    right: 25px;
    display: block;
    width: 60px;
    height: 60px;
    padding: 10px;
    background: var(--primary);
    border-radius: 50%;
    margin: auto;
    cursor: pointer;
}

.page .page-content .page-content-elements {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    gap: 25px;
    max-width: 1400px;
    margin: 0 auto;
    transition: .5s;
}

.page .page-content .page-content-elements .page-content-elements-element {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background: #fff;
    border: dashed 2px transparent;
    border-radius: 5px;
    cursor: pointer;
}

.page .page-content .page-content-elements .page-content-elements-element.split .page .page-content .page-content-elements .page-content-elements-element {
    width: calc(50% - 12.5px);
}

.page .page-content .page-content-elements .page-content-elements-element.half {
    width: calc(50% - 12.5px);
}

.page .page-content .page-content-elements .page-content-elements-element:hover {
    outline: 2px solid #ededed;
}

.page .page-content .page-content-elements .page-content-elements-element.active {
    outline: solid 2px var(--primary);
}

.page .page-content .page-content-elements .page-content-elements-element.sortable-chosen {
    border: dashed 2px #ededed;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 25px;
    user-select: none;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .handle {
    width: 24px;
    height: 24px;
    fill: #ededed;
    cursor: move;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .handle:hover {
    fill: var(--primary);
}

.page .page-content .page-content-elements .page-content-elements-element.locked .page-content-elements-element-header .handle {
    pointer-events: none;
    opacity: 0;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
    background: #f4f6f9;
    border-radius: 5px;
    padding: 5px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .title {
    display: flex;
    flex-direction: column;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .title .category {
    font-size: 12px;
    line-height: 12px;
    opacity: 0.5;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .title .subtitle {
    font-weight: 700;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .animated {
    width: 24px;
    height: 24px;
    stroke: #ededed;
    margin: 0 0 0 auto;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .animated.active {
    stroke: var(--primary);
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .margin {
    width: 24px;
    height: 24px;
    fill: #ededed;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .margin:deep(.active) {
    fill: var(--primary);
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .padding {
    width: 24px;
    height: 24px;
    fill: #ededed;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .padding:deep(.active) {
    fill: var(--primary);
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header:deep(.p-splitbutton) {
    width: 30px;
    height: 30px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header:deep(.p-splitbutton-button) {
    display: none;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header:deep(.p-splitbutton-dropdown) {
    background: #ededed;
    border-color: #f4f6f9;
    border-radius: 5px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header:deep(.p-splitbutton-dropdown:hover) {
    background: var(--primary);
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-header .toggle {
    width: 30px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding: 25px;
    background: #fafafa;
    cursor: default;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: calc(50% - 12.5px);
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group .page-content-elements-element-content-group-subtitle {
    font-weight: 600;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group.full {
    width: 100%;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group.query {
    flex-direction: row;
    gap: 15px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group input {
    height: 40px;
    padding: 0 15px;
    border: solid 1px #cbd5e1;
    border-radius: 5px;
    outline: none;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group .query-select {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    background: #fff;
    outline: 0;
    border: 0;
    border-radius: 5px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group .query-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 40px;
    padding: 0 15px;
    background: #cd5c5c;
    color: #fff;
    outline: 0;
    border: 0;
    border-radius: 5px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group .query-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    background: var(--primary);
    color: #fff;
    outline: 0;
    border: 0;
    border-radius: 5px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group .slideshow-link {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group .slideshow-remove {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 15px;
    margin: 15px 0 0 0;
    background: #cd5c5c;
    color: #fff;
    text-align: center;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group .slideshow-add {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    margin: 23px 0 0 0;
    background: #fff;
    background-position: 50% !important;
    background-size: cover !important;
    border: dashed 2px #ededed;
    border-radius: 10px;
    cursor: pointer;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group .slideshow-add svg {
    width: 48px;
    height: 48px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group .page-content-elements-element-content-group-button {
    width: fit-content;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
    margin: 15px 0 0 0;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .page-content-elements-element-content-group .page-content-elements-element-content-group-iframe {
    width: 100%;
    height: 400px;
    border-radius: 5px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .p-editor {
    width: 100%;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .p-editor:deep(h1) {
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .p-editor:deep(h2) {
    font-size: 24px;
    font-weight: 600;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .p-editor:deep(p) {
    font-size: 18px;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .p-editor:deep(strong) {
    font-size: 18px;
    font-weight: 600;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .p-editor:deep(ol li) {
    list-style: disc;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .media {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    background: #fff;
    background-position: 50% !important;
    background-size: cover !important;
    border: dashed 2px #ededed;
    border-radius: 10px;
    cursor: pointer;
}

.page .page-content .page-content-elements .page-content-elements-element .page-content-elements-element-content .media .change {
    color: #fff;
    text-shadow: 0 0 20px black;
}

.page .page-content .page-content-elements .sortable-ghost {
    background: #fff;
    border: dashed 2px #333;
    border-radius: 5px;
    opacity: 0.5;
}

.page .page-content .page-content-elements-add {
    width: 100%;
    height: fit-content;
    display: flex;
    gap: 25px;
    padding: 15px 25px;
    margin: 25px 0 0 0;
    background: transparent;
    border: dashed 2px #ededed;
    border-radius: 5px;
    transition: .5s;
    cursor: pointer;
}

.page .page-content .page-content-elements-add:hover {
    border: dashed 2px var(--primary);
}

.page .page-content .page-content-elements-add .column {
    width: 100%;
    height: 25px;
    background: #ededed;
    border-radius: 5px;
    transition: .25s;
}

.page .page-content .page-content-elements-add:hover .column {
    background: var(--primary);
}





.page .page-styling {
    width: 300px;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    background: #fff;
    border-left: solid 1px #ededed;
    overflow: auto;
}

.page .page-styling .page-styling-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 0 25px;
}

.page .page-styling .page-styling-group .page-styling-group-title {
    width: 100%;
    font-weight: 700;
}

.page .page-styling .page-styling-group .page-styling-group-margin {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.page .page-styling .page-styling-group input, .page .page-styling .page-styling-group:deep(input) {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    background: #f7f8fc;
    outline: 0;
    border: 0;
    border-radius: 5px;
}

.page .page-styling .page-styling-group:deep(.p-inputgroup.left input) {
    height: 100%;
    border-radius: 0 5px 5px 0;
}

.page .page-styling .page-styling-group:deep(.p-inputgroup.right input) {
    height: 100%;
    border-radius: 5px 0px 0px 5px;
}

.page .page-styling .page-styling-group:deep(.p-selectbutton), .page .page-styling .page-styling-group:deep(.p-togglebutton) {
    width: 100%;
}

.page .page-styling .page-styling-group input.half {
    width: calc(50% - 7.5px);
}

.page .page-styling .page-styling-group input.centered {
    text-align: center;
}

.page .page-styling .page-styling-group input::-webkit-outer-spin-button,
.page .page-styling .page-styling-group input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.page .page-styling .page-styling-group:deep(.p-select) {
    width: 100%;
    background: #f7f8fc;
    outline: 0;
    border: 0;
    border-radius: 5px;
}

.page .page-styling .page-styling-group:deep(.p-inputgroupaddon) {
    color: #333;
    background: #f4f6f9;
    border-color: #f4f6f9;
}

.page .page-styling .page-styling-group:deep(.p-colorpicker-preview) {
    width: 24px;
    height: 24px;
    border-radius: 5px !important;
    margin: 0 5px;
}

.page .page-styling .page-styling-group:deep(.tags) {
    gap: 10px;
    padding: 0;
    border: 0;
}

.page .page-styling .page-styling-group:deep(.tags .tag-input) {
    width: 210px;
}

.page .page-styling .page-styling-group:deep(.page-styling-group-tags-tag) {
    height: 35px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
}

.page .page-styling .page-styling-group:deep(.options) {
    top: 45px;
}

.page .page-styling .page-styling-group:deep(.options li) {
    height: 35px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    color: #fff;
    border-radius: 5px;
}

.page .page-styling .page-styling-menu {
    display: flex;
    border-bottom: solid 1px #ededed;
}

.page .page-styling .page-styling-menu .page-styling-menu-item {
    width: 50%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.page .page-styling .page-styling-menu .page-styling-menu-item.border {
    border-right: solid 1px #ededed;
}

.page .page-styling .page-styling-menu .page-styling-menu-item.active {
    font-weight: 700;
}

.page .page-styling .page-styling-menu .page-styling-menu-item.disabled {
    pointer-events: none;
    opacity: 0.25;
}

.page .page-styling .page-styling-groups {
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px 0;
}

.page .page-styling .page-styling-groups .page-styling-group-delete {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 15px 0 0 0;
    color: #cd5c5c;
    cursor: pointer;
}

.page .page-styling .page-styling-divider {
    width: calc(100% - 50px);
    height: 2px;
    border-bottom: dashed 1px #ededed;
    margin: 0 25px;
}
</style>