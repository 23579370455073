<template>
    <div class="articles">
        <div class="filters" :class="{ loader: Loader }">
            <span class="title">Filter</span>

            <router-link to="/inbox/aanmaken" class="create">Aanmaken</router-link>

            <span class="reset" v-if="Search.length > 0 || Filtered.length > 0 || Selected.length > 0" @click="ResetFilters">Reset</span>

            <div class="new-group" v-for="(Filter, i) in Filters" :key="i">
                <span class="group-title">{{ Filter.Title }}</span>
                <input class="group-search" v-if="Filter.Searchable" type="text" v-model="Filter.Search">
                <div class="selected" v-if="Filter.Checked.length > 0">
                    <span class="item" v-for="(Item, i) in Filter.Checked" :key="i" @click="SetDynamicFilter(Item, Filter, false)">{{ Item.Name }}</span>
                </div>
                <div class="divider" v-if="Filter.Checked.length > 0"></div>
                <div class="items" :class="{ 'max-extended': Filter.Items.filter(e => e.Active).length > 0 }">
                    <div class="item" v-for="(Item, i) in Filter.Items" :key="i" :class="{ hidden: Item.SubItems.filter(e => e.Name.toLowerCase().includes(Filter.Search.toLowerCase())).length == 0 && !Item.Name.toLowerCase().includes(Filter.Search.toLowerCase()) }">
                        <div class="brand">
                            <div class="checkbox" v-if="Filter.Checkable" @click="SetDynamicItemFilter(Filter, Item), Filter.Search = ''">
                                <div v-if="Item.SubItems.length == Filter.Checked.filter(e => e.Brand == Item.Identifier).length" class="checked"></div>
                                <div v-if="(Item.SubItems.length > Filter.Checked.filter(e => e.Brand == Item.Identifier).length) && Filter.Checked.filter(e => e.Brand == Item.Identifier).length > 0" class="sub-checked"></div>
                            </div>
                            <span class="name">{{ Item.Name }}</span>
                            <svg class="more" @click="Item.Active = !Item.Active" :class="{ active: Item.Active }" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#000000"></path></g></svg>
                        </div>
                        <div class="list" v-if="Item.Active || Filter.Search">
                            <div class="models" v-for="(SubItem, Si) in Item.SubItems.filter(e => e.Name.toLowerCase().includes(Filter.Search.toLowerCase()))" :key="Si">
                                <div class="model" @click="SetDynamicFilter(SubItem, Filter, Item)">
                                    <div class="checkbox">
                                        <div v-if="Filter.Checked.filter(item => item.Identifier == SubItem.Identifier).length > 0" class="checked"></div>
                                    </div>
                                    <span class="name">{{ SubItem.Name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="inbox-list">
                <router-link :to="'/inbox/' + Item.Identifier" class="list-item" v-for="Item in Inbox" :key="Item.Identifier" :class="{ active: $route.params.id == Item.Identifier, incoming: Item.Direction == 'incoming' }">
                    <div class="left">
                        <div class="avatar">{{ Item.Name[0] }}</div>
                    </div>
                    <div class="right">
                        <span class="name">{{ Item.Name }}</span>
                        <span class="message">{{ Item.Message }}</span>
                    </div>
                    <span class="date">{{ SetDate(Item.Changed) }}</span>
                </router-link>
            </div>
        </div>
        
        <div class="chat" v-if="this.$route.params.id">
            <div class="chat-information">
                <span class="name">{{ Chat.Name }}</span>
                <span class="phone">{{ Chat.Phone }}</span>
                <span class="email">{{ Chat.Email }}</span>
                <div class="chat-information-fade"></div>
            </div>
            <div class="chat-items">
                <div class="chat-items-item" v-for="(Item, i) in Chat.Items" :key="i" :class="Item.Direction">
                    <span class="today" v-if="CheckToday(i)">{{ this.$moment(Item.Created).format('DD-MM-Y') }}</span>
                    <div class="chat-items-item-baloon">
                        <span class="chat-items-item-baloon-text">{{ Item.Content }}</span>
                        <span class="chat-items-item-baloon-date">{{ SetDate(Item.Created) }}</span>
                    </div>
                </div>
            </div>
            <div class="chat-message" v-if="!Chat.Expired">
                <div class="content">
                    <div type="text" contenteditable="true" class="input chatInput" @keyup="SetMessage" :class="{ disabled: Message.Loader }"></div>
                    <div class="button" @click="SendChat" :class="{ disabled: Message.Loader || Message.Content.length == 0 }">
                        <svg v-if="Message.Loader" class="loader" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path d="M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path> <path d="M19.7942 7.5C19.8905 7.66673 19.9813 7.83651 20.0667 8.00907" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path> </g></svg>
                        <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path d="M10.3009 13.6949L20.102 3.89742M10.5795 14.1355L12.8019 18.5804C13.339 19.6545 13.6075 20.1916 13.9458 20.3356C14.2394 20.4606 14.575 20.4379 14.8492 20.2747C15.1651 20.0866 15.3591 19.5183 15.7472 18.3818L19.9463 6.08434C20.2845 5.09409 20.4535 4.59896 20.3378 4.27142C20.2371 3.98648 20.013 3.76234 19.7281 3.66167C19.4005 3.54595 18.9054 3.71502 17.9151 4.05315L5.61763 8.2523C4.48114 8.64037 3.91289 8.83441 3.72478 9.15032C3.56153 9.42447 3.53891 9.76007 3.66389 10.0536C3.80791 10.3919 4.34498 10.6605 5.41912 11.1975L9.86397 13.42C10.041 13.5085 10.1295 13.5527 10.2061 13.6118C10.2742 13.6643 10.3352 13.7253 10.3876 13.7933C10.4468 13.87 10.491 13.9585 10.5795 14.1355Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </div>
                    <svg class="ai" :class="{ disabled: Message.Loader }" @click="GPTAnswer" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><g><path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z"></path></g></svg>
                </div>
            </div>
            <div class="chat-expired" v-else>
                <div class="content">
                    <span class="send" :class="{ disabled: Message.Loader }" @click="SendTemplate()">Reageren op een WhatsApp is alleen mogelijk binnen 24 uur na het laatste bericht van de klant. Klik hier om een reminder naar de klant te sturen.</span>
                </div>
            </div>
        </div>
        <div class="chat empty" v-else>
            <span class="chat-title">Kies een chat</span>
            <span class="chat-subtitle">en begin een gesprek</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticlesOverview',

    data () {
        return {
            Loader: false,
            Inbox: [],
            Chat: {
                Items: []
            },
            Search: '',
            Filters: [
                {
                    Title: '',
                    Search: '',
                    Searchable: false,
                    Checkable: true,
                    Checked: [],
                    Items: [
                        {
                            Identifier: 'c75b95b2-b982-4298-8eba-a7b3ccddca63',
                            Name: 'Bron',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: '18c42123-f953-4a4e-91fb-0e24cb8c690b',
                                    Name: 'Website',
                                    Value: 1111
                                },
                                {
                                    Identifier: '1963d634-1be7-458e-88a0-6e6609acd46f',
                                    Name: 'WhatsApp',
                                    Value: 2222
                                },
                                {
                                    Identifier: '31af88a1-3ad1-4d98-b85d-00eb11eafebf',
                                    Name: 'Chat',
                                    Value: 3333
                                }
                            ]
                        },
                        {
                            Identifier: '22ba16a9-64ed-44b2-ad50-67c96d1a6731',
                            Name: 'Status',
                            Active: false,
                            SubItems: [
                                {
                                    Identifier: 'e3ace2ce-2d81-4efe-b6a0-5a7dc6d96e67',
                                    Name: 'Actief',
                                    Value: 1111
                                },
                                {
                                    Identifier: '4cff503f-32f8-435f-a518-72792d80480d',
                                    Name: 'Archief',
                                    Value: 3333
                                }
                            ]
                        }
                    ]
                }
            ],
            Filtered: [],
            Selected: [],
            Count: 0,
            Page: 1,
            PageLimit: 25,
            Message: {
                Content: ''
            }
        }
    },

    mounted () {
        this.GetInbox()

        if(this.$route.params.id) {
            this.GetChat()
        }
    },

    methods: {
        GetInbox () {
            this.$http.get('https://api.wheels.nl/inbox').then((response) => {
                if(response.data.chats) { this.Inbox = response.data.chats } else { this.Inbox = [] }
            })
        },

        GetChat (chatOnly, noLoader) {
            if(!noLoader) {
                this.Loader = true
            }

            if(!chatOnly) {
                this.Chat = []

                // this.Client.Search = []
                // this.Client.Content = false
                // this.Client.Objects = false
                // this.Client.Services = false
            }

            this.$http.get(`https://api.wheels.nl/inbox/${this.$route.params.id}`).then((response) => {
                if(chatOnly) {
                    if(response.data.chat) { this.Chat = response.data.chat }
                    if(response.data.expired) { this.Chat.Expired = true } else { this.Chat.Expired = false }
                } else {
                    if(response.data.chat) { this.Chat = response.data.chat }
                    // if(response.data.expired) { this.Chat.Expired = true } else { this.Chat.Expired = false }
                    // if(response.data.client) { this.Client.Content = response.data.client }
                    // if(response.data.clientSearch) { this.Client.Search = response.data.clientSearch }
                    // if(response.data.objects) { this.Client.Objects = response.data.objects }
                    // if(response.data.services) { this.Client.Services = response.data.services }
                    // if(response.data.actors) { this.Actors = response.data.actors }
                }

                this.Loader = false
                this.Message.Content = ''
                if(document.querySelector('.chatInput')) {
                    document.querySelector('.chatInput').innerText = ''
                }
            })
        },

        ResetRead (e) {
            if(this.Inbox.filter(item => item.Identifier == e).length > 0) {
                this.Inbox.filter(item => item.Identifier == e)[0].Unread = false
            }
        },

        SetDate (e) {
            if(this.$moment().isSame(e, 'day')) {
                return this.$moment(e).format('HH:mm')
            } else {
                return this.$moment(e).format('DD-MM')
            }
        },

        SetFilter (e, a) {
            if(a.Checked.filter(item => item.ModelIdentifier == e.ModelIdentifier).length > 0) {
                a.Checked = a.Checked.filter(item => item.ModelIdentifier !== e.ModelIdentifier)
            } else {
                a.Checked.push({
                    ModelIdentifier: e.ModelIdentifier,
                    Brand: e.BrandIdentifier,
                    Name: e.ModelName
                })
            }
        },

        SetDynamicFilter (e, a, z) {
            if(a.Checked.filter(item => item.Identifier == e.Identifier).length > 0) {
                a.Checked = a.Checked.filter(item => item.Identifier !== e.Identifier)
            } else {
                a.Checked.push({
                    Identifier: e.Identifier,
                    Brand: z.Identifier,
                    Name: e.Name
                })
            }
        },

        SetItemFilter (e, a) {
            if(e.length == a.Checked.filter(z => z.Brand == e[0].BrandIdentifier).length) {
                a.Checked = a.Checked.filter(z => z.Brand !== e[0].BrandIdentifier)
            } else {
                e.forEach(z => {
                    if(a.Checked.filter(item => item.ModelIdentifier == z.ModelIdentifier).length == 0) {
                        a.Checked.push({
                            ModelIdentifier: z.ModelIdentifier,
                            Brand: z.BrandIdentifier,
                            Name: z.ModelName
                        })
                    }
                })
            }
        },

        SetDynamicItemFilter (e, a) {
            if(a.SubItems.length == e.Checked.filter(e => e.Brand == a.Identifier).length) {
                a.SubItems.forEach(z => {
                    e.Checked = e.Checked.filter(item => item.Identifier !== z.Identifier)
                })

                a.Active = false
            } else {
                a.SubItems.forEach(z => {
                    if(e.Checked.filter(item => item.Identifier == z.Identifier).length == 0) {
                        e.Checked.push({
                            Identifier: z.Identifier,
                            Brand: a.Identifier,
                            Name: a.Name + ' - ' + z.Name
                        })
                    }
                })

                a.Active = true
            }
        },

        SetSelection (e) {
            if(this.Selected.filter(item => item.Identifier == e.Identifier).length > 0) {
                this.Selected = this.Selected.filter(item => item.Identifier !== e.Identifier)
            } else {
                this.Selected.push(e)
            }

            localStorage.setItem('ArticleTime', Date.now())
            localStorage.setItem('ArticleSelection', JSON.stringify(this.Selected))
        },

        ResetFilters () {
            this.Page = 0
            this.Limit = 100

            localStorage.removeItem('ArticleTime')

            this.Search = ''
            localStorage.removeItem('ArticleSearch')

            this.Filtered = []
            localStorage.removeItem('ArticleFilter')

            this.Group = false
            localStorage.removeItem('ArticleGroup')

            this.Selected = []
            localStorage.removeItem('ArticleSelection')

            this.GetArticles()
        },

        SetMessage () {
            this.Message.Content = document.querySelector('.chatInput').innerText
        },

        SendChat () {
            if(this.Message.Content && !this.Message.Loader) {
                this.Message.Loader = true
                this.$http.post(`https://api.wheels.nl/inbox/send`, {
                    Conversation: this.$route.params.id,
                    Message: this.Message.Content
                }).then((response) => {
                    if(response.data.error && response.data.expired) {
                        this.$toast.error('Het sturen van een los bericht is niet meer mogelijk. (24-uur verlopen)')
                        this.Message.Loader = false
                        this.Message.Expired = true
                    } else if(response.data.error) {
                        this.$toast.error('Er is een fout opgetreden')
                        this.Message.Loader = false
                    } else {
                        this.Message.Content = ''

                        var self = this
                        setTimeout(function() {
                            self.GetChat(true, true)
                            self.Message.Loader = false
                        }, 250)
                    }
                })
            }
        },

        SendTemplate () {
            if(!this.Message.Loader) {
                this.Message.Loader = true
                this.$http.post(`https://api.wheels.nl/inbox/template`, {
                    Conversation: this.$route.params.id
                }).then((response) => {
                    if(response.data.error) {
                        this.$toast.error('Er is een fout opgetreden')
                        this.Message.Loader = false
                    } else {
                        this.Message.Content = ''

                        var self = this
                        setTimeout(function() {
                            self.GetChat(true, true)
                            self.Message.Loader = false
                        }, 250)
                    }
                })
            }
        },

        CheckToday (e) {
            // var Days = [new Set(this.Chat.Items.map(item => this.$moment(item.Created, 'YYYY-MM-DD').format('YYYY-MM-DD')))][0].size

            // if(this.Chat.Items[e - 1]) {
            //     var DateTomorrow = this.$moment(this.Chat.Items[e - 1]['Created'], 'YYYY-MM-DD')
            //     var Tomorrow = this.$moment().diff(DateTomorrow, 'days')
            // } else {
            //     Tomorrow = 99
            // }
            
            // var DateToday = this.$moment(this.Chat.Items[e]['Created'], 'YYYY-MM-DD')
            // var Today = this.$moment().diff(DateToday, 'days')
            
            // if(this.Chat.Items[e + 1]) {
            //     var DateYesterday = this.$moment(this.Chat.Items[e + 1]['Created'], 'YYYY-MM-DD')
            //     var Yesterday = this.$moment().diff(DateYesterday, 'days')
            // } else {
            //     Yesterday = 99
            // }

            // if(Today == 0 && Tomorrow == 0 && Yesterday > 1 && Days > 1) {
            //     return true
            // } else {
            //     return false
            // }

            if(this.Chat.Items[e + 1]) {
                if(this.$moment(this.Chat.Items[e + 1]['Created']).dayOfYear() !== this.$moment(this.Chat.Items[e]['Created']).dayOfYear()) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    },

    watch: {
        "$route.params.id" (e, a) {
            if(a) { this.ResetRead(a) }

            this.Message.Content = ''
            this.GetChat()
        }
    }
}
</script>

<style scoped>
.articles {
    position: relative;
    display: flex;
    gap: 0 50px;
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 0 0 50px;
    background: #f7f8fc;
    overflow: hidden;
}

.articles .filters {
    position: relative;
    min-width: 350px;
    max-width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    padding: 50px 0;
    transition: .5s;
    overflow-y: auto;
}

.articles .filters.loader {
    pointer-events: none;
    opacity: 0.25;
}

.articles .filters::-webkit-scrollbar {
    width: 0px;
}

.articles .filters .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.articles .filters .create {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    font-weight: 600;
    text-decoration: none;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.articles .filters .menu .list {
    width: 32px;
    height: 32px;
    fill: #000;
    cursor: pointer;
}

.articles .filters .menu .blocks {
    width: 24px;
    height: 24px;
    fill: #000;
    cursor: pointer;
}





.articles .filters .group .search {
    display: block;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.articles .filters .group .search.active {
    outline: solid 1px var(--primary);
    border: solid 1px var(--primary);
}





.articles .filters .radio {
    display: flex;
    width: 100%;
    min-height: 50px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
    overflow: hidden;
}

.articles .filters .radio .item {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.articles .filters .radio .item.active {
    color: #fff;
    font-weight: 600;
    background: var(--primary);
}





.articles .filters .reset {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    background: var(--primary);
    font-weight: 500;
    color: #fff;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}





.articles .filters .new-group {
    padding: 0 25px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.articles .filters .new-group.active {
    outline: solid 1px var(--primary);
    border: solid 1px var(--primary);
}

.articles .filters .new-group .group-title {
    display: block;
    font-weight: 700;
    margin: 20px 0 10px 0;
}

.articles .filters .new-group .group-search {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    margin: 0 0 15px 0;
    background: #f7f8fc;
    border: 0;
    border-radius: 5px;
    outline: 0;
}

.articles .filters .new-group .prices {
    display: flex;
    gap: 15px;
    margin: 0 0 25px 0;
}

.articles .filters .new-group .prices input {
    width: 100%;
    height: 50px;
    padding: 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.articles .filters .new-group .selected {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.articles .filters .new-group .selected .item {
    display: flex;
    align-items: center;
    padding: 2.5px 10px;
    font-size: 14px;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.articles .filters .new-group .divider {
    border-bottom: solid 1px #f7f8fc;
    padding: 15px 0 0 0;
    margin: 0 0 15px 0;
}

.articles .filters .new-group .search {
    width: 100%;
    background: #f7f8fc;
    padding: 2.5px 10px;
    margin: 0 0 10px 0;
    border: 0;
    border-radius: 5px;
    outline: none;
}

.articles .filters .new-group .items {
    display: flex;
    flex-direction: column;
    max-height: 250px;
    padding: 0 0 25px 0;
    overflow: auto;
    transition: .5s;
}

.articles .filters .new-group .items.extended {
    max-height: 500px;
}

.articles .filters .new-group .items.max-extended {
    max-height: unset;
}

.articles .filters .new-group .items::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.articles .filters .new-group .items .item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.articles .filters .new-group .items .item.hidden {
    display: none;
}

.articles .filters .new-group .items .item .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.articles .filters .new-group .items .item .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}

.articles .filters .new-group .items .item .checkbox .sub-checked {
    width: 14px;
    height: 14px;
    background: #ededed;
    border-radius: 2.5px;
    opacity: 0.5;
}

.articles .filters .new-group .items .item .count {
    margin: 0 0 0 auto;
    font-size: 12px;
    opacity: 0.25;
}

.articles .filters .new-group .items .item .brand {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.articles .filters .new-group .items .item .brand .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.articles .filters .new-group .items .item .brand span {
    cursor: pointer;
}

.articles .filters .new-group .items .item .brand .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}

.articles .filters .new-group .items .item .brand .more {
    height: 40px;
    padding: 10px 0;
    margin: 0 0 0 auto;
    transition: .25s;
}

.articles .filters .new-group .items .item .brand .more.active {
    transform: rotate(180deg);
}

.articles .filters .new-group .items .item .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 0 10px 0;
}

.articles .filters .new-group .items .item .list .models {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 0 0 0 30px;
    cursor: pointer;
}

.articles .filters .new-group .items .item .list .models .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
}

.articles .filters .new-group .items .item .list .models .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}

.articles .filters .new-group .items .item .list .models .model {
    display: flex;
    gap: 10px;
}





.articles .inbox-list {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.articles .inbox-list .list-item {
    position: relative;
    display: flex;
    gap: 10px;
    text-decoration: none;
}

.articles .inbox-list .list-item .left .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    margin: -2.5px 0 0 0;
    background: #ededed;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 50%;
}

.articles .inbox-list .list-item.incoming .left .avatar {
    background: #333;
}

.articles .inbox-list .list-item .right {
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.articles .inbox-list .list-item .right .name {
    width: 100%;
    font-weight: 600;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.articles .inbox-list .list-item .right .message {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.articles .inbox-list .list-item.incoming .right .message {
    font-weight: 600;
}

.articles .inbox-list .list-item .date {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
}





.articles .catalog {
    position: relative;
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 50px 0;
    overflow: auto;
}

.articles .catalog .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.articles .catalog .items {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.articles .catalog .items .item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding: 15px;
    background: #fff;
    text-decoration: none;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.articles .catalog .items .item .avatar {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f8fc;
    font-weight: 700;
    border-radius: 5px;
}

.articles .catalog .items .item .avatar.unread {
    background: var(--primary);
    color: #fff;
}

.articles .catalog .items .item .information {
    width: calc(100% - 210px);
    display: flex;
    flex-direction: column;
}

.articles .catalog .items .item .information .information-name {
    font-weight: 700;
}

.articles .catalog .items .item .information .information-message {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.articles .catalog .items .item .state {
    height: 100%;
    width: 150px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 5px;
}

.articles .catalog .items .item .state .state-source {
    min-width: 80px;
    padding: 5px;
    margin: auto 0 0 0;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    background: #f4f6f9;
    border-radius: 5px;
}

.articles .catalog .items .item .state .state-source.Website {
    background: var(--primary);
    color: #fff;
}

.articles .catalog .items .item .state .state-source.Email {
    background: #ffa500;
    color: #fff;
}

.articles .catalog .items .item .state .state-source.WhatsApp {
    background: #075e54;
    color: #fff;
}

.articles .catalog .items .item .state .state-source.Chat {
    background: #2284f0;
    color: #fff;
}




.chat {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
}

.chat .chat-information {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    height: 300px;
    padding: 50px 25px 0 25px;
    margin: auto;
}

.chat .chat-information .name {
    font-size: 30px;
    font-weight: 300;
}

.chat .chat-information .chat-information-fade {
    position: absolute;
    bottom: -150px;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(180deg, #ffffff, transparent);
    z-index: 99;
}

.chat .chat-items {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
    height: calc(100% - 100px);
    width: 100%;
    max-width: 800px;
    padding: 40px 25px;
    margin: 0 auto;
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: unset;
}

.chat .chat-items .chat-items-item {
    position: relative;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 auto;
}

.chat .chat-items .chat-items-item .today {
    width: 100%;
    text-align: center;
    margin: 75px 0;
}

.chat .chat-items .chat-items-item .today::before {
    content: '';
    position: absolute;
    top: 77px;
    right: 0;
    display: block;
    width: calc(50% - 50px);
    height: 10px;
    border-bottom: dashed 1px #b2b2b2;
}

.chat .chat-items .chat-items-item .today::after {
    content: '';
    position: absolute;
    top: 77px;
    left: 0;
    display: block;
    width: calc(50% - 50px);
    height: 10px;
    border-bottom: dashed 1px #b2b2b2;
}

.chat .chat-items .chat-items-item:first-of-type {
    margin-bottom: 25px;
}

.chat .chat-items .chat-items-item.incoming {
    justify-content: flex-start;
    margin: 0 auto 0 0;
}

.chat .chat-items .chat-items-item .chat-items-item-baloon {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-width: 75%;
    background: var(--primary);
    padding: 15px 20px;
    border: 1px solid #eee;
    border-radius: 5px;
}

.chat .chat-items .chat-items-item.incoming .chat-items-item-baloon {
    background: #eee;
    border: 0;
}

.chat .chat-items .chat-items-item .chat-items-item-baloon .chat-items-item-baloon-text {
    color: #fff;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.chat .chat-items .chat-items-item.incoming .chat-items-item-baloon .chat-items-item-baloon-text {
    color: #333;
}

.chat .chat-items .chat-items-item .chat-items-item-baloon .chat-items-item-baloon-date {
    position: absolute;
    bottom: -25px;
    right: 10px;
    width: 300px;
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
    text-align: right;
}

.chat .chat-items .chat-items-item.incoming .chat-items-item-baloon .chat-items-item-baloon-date {
    left: 10px;
    text-align: left;
}





.chat .chat-message {
    display: flex;
    width: 100%;
    padding: 20px 25px;
    border-top: solid 1px #f7f8fc;
}

.chat .chat-message .content {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    max-width: 750px;
    margin: auto;
}

.chat .chat-message .content .input {
    width: 100%;
    min-height: 50px;
    background: #f7f8fc;
    padding: 12.5px 45px 12.5px 15px;
    border: solid 1px transparent;
    border-radius: 5px;
    overflow: auto;
    outline: none;
}

.chat .chat-message .content .input:focus {
    border: solid 1px #ededed;
}

.chat .chat-message .content .input.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.chat .chat-message .content .button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    height: 50px;
    background: #8fbc8f;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 0 auto 0;
}

.chat .chat-message .content .button.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.chat .chat-message .content .button svg {
    min-width: 30px;
    height: 30px;
}

.chat .chat-message .content .button .loader {
    animation: spin 2s linear infinite;
}

.chat .chat-message .content .ai {
    display: none;
    position: absolute;
    top: 15px;
    right: 80px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: .25s;
    opacity: 0.5;
}

.chat .chat-message .content .ai:hover {
    opacity: 1;
}

.chat .chat-message .content .ai.disabled {
    pointer-events: none;
    opacity: 0.5;
}




.chat .chat-expired {
    display: flex;
    width: 100%;
    padding: 20px 25px;
    border-top: solid 1px #f7f8fc;
}

.chat .chat-expired .content {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.chat .chat-expired .content .send {
    text-align: center;
    background: #f4f4f4;
    border: dashed orange 2px;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.chat .chat-expired .content .send.disabled {
    pointer-events: none;
    opacity: 0.5;
}





.chat.empty {
    align-items: center;
    justify-content: center;
}

.chat.empty .chat-title {
    font-size: 30px;
    font-weight: 700;
}

.chat.empty .chat-subtitle {
    font-size: 20px;
}





@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>