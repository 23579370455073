<template>
    <div class="counters">
        <router-link to="/voertuigen/lijst?goedkeuren=1" class="item sign">
            <span class="counter">{{ Counters.Approve }}</span>
            <span class="title">BPM aangiftes goedkeuren</span>
        </router-link>
        <router-link to="/voertuigen/lijst" class="item">
            <span class="counter blue" v-if="Counters">{{ Counters.All.toLocaleString('nl-NL') }}</span>
            <span class="counter loader" v-else></span>
            <span class="title">Openstaande dossiers</span>
        </router-link>
        <router-link to="/voertuigen/lijst?papieren=01" class="item" v-if="Counters.Papers">
            <span class="counter orange" v-if="Counters">{{ Counters.Papers }}</span>
            <span class="counter loader" v-else></span>
            <span class="title">Dossiers zonder papieren</span>
        </router-link>
        <router-link to="/voertuigen/lijst?opname=01" class="item" v-if="Counters.Pictures">
            <span class="counter pink" v-if="Counters">{{ Counters.Pictures }}</span>
            <span class="counter loader" v-else></span>
            <span class="title">Dossiers zonder foto's</span>
        </router-link>
        <router-link to="/voertuigen/lijst?papieren=11&opname=11&rdw=01" class="item" v-if="Counters.RDW">
            <span class="counter green" v-if="Counters">{{ Counters.RDW }}</span>
            <span class="counter loader" v-else></span>
            <span class="title">Wachtend op RDW aanmelding</span>
        </router-link>
        <router-link to="/voertuigen/lijst?rdw=44" class="item" v-if="Counters.RDWFiles">
            <span class="counter petrol" v-if="Counters">{{ Counters.RDWFiles }}</span>
            <span class="counter loader" v-else></span>
            <span class="title">Verzoek om goede bestanden</span>
        </router-link>
        <router-link to="/voertuigen/lijst?rdw=22" class="item" v-if="Counters.RDWCheck">
            <span class="counter purple" v-if="Counters">{{ Counters.RDWCheck }}</span>
            <span class="counter loader" v-else></span>
            <span class="title">RDW fysieke afspraak maken</span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'DashboardCounters',

    props: ['Counters']
}
</script>

<style scoped>
.counters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.counters .item {
    width: calc((100% / 3) - 17px);
    height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    background: #fff;
    text-decoration: none;
    border: solid 2px transparent;
    border-radius: 10px;
    transition: .25s;
    cursor: pointer;
}

.counters .item.sign {
    background: var(--primary);
}

.counters .item:hover {
    border: solid 2px var(--primary);
}

.counters .item .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: #969696;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    border: 0;
    border-radius: 10px;
    transition: .25s;
}

.counters .item.sign .counter {
    background: #fff;
    color: var(--primary);
}

.counters .item:hover .counter {
    transform: scale(1.1);
}

.counters .item .counter:hover {
    transform: scale(1.25);
}

.counters .item .counter:active {
    transform: scale(1.1);
}

.counters .item .counter.blue {
    background: var(--primary);
}

.counters .item .counter.orange {
    background: #ffa500;
}

.counters .item .counter.pink {
    background: #ffc0cb;
}

.counters .item .counter.green {
    background: #008000;
}

.counters .item .counter.petrol {
    background: #437347;
}

.counters .item .counter.purple {
    background: #800080;
}

.counters .item .counter.loader {
    background: linear-gradient(90deg, #ececec 8%, #cccccc 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.counters .item .title {
    max-width: 90%;
    height: 50px;
    margin: 0 auto;
    text-align: center;
    font-weight: 500;
}

.counters .item.sign .title {
    height: unset;
    max-width: unset;
    margin: unset;
    font-weight: 700;
    color: #fff;
}
</style>