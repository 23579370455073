<template>
    <div class="pages">
        <span class="pages-title">Pagina's</span>
        <div class="pages-menu">
            <input v-model="Filter.Search" type="text" placeholder="Zoek een pagina..." class="pages-menu-input">
            <Button unstyled class="pages-menu-add" type="button" label="+" @click="ToggleAdd" />
            <Popover ref="add">
                <div class="popover add">
                    <span class="popover-title">Titel</span>
                    <input type="text" class="popover-input" v-model="Create.Title">
                    <button @click="CreatePage()" class="popover-button" :class="{ disabled: !Create.Title }">Aanmaken</button>
                </div>
            </Popover>
            <Button unstyled class="pages-menu-toggle" type="button" :label="SetStatus(Filter.Status).Label" :class="SetStatus(Filter.Status).Class" @click="ToggleStatus" />
            <Popover ref="status">
                <div class="popover">
                    <span class="popover-item" @click="Filter.Status = 'O'" :class="{ active: Filter.Status == 'O' }">Online</span>
                    <span class="popover-item" @click="Filter.Status = 'C'" :class="{ active: Filter.Status == 'C' }">Concept</span>
                    <span class="popover-item" @click="Filter.Status = 'A'" :class="{ active: Filter.Status == 'A' }">Archief</span>
                    <div v-if="Filter.Status" class="popover-divider"></div>
                    <span v-if="Filter.Status" class="popover-item" @click="Filter.Status = false">Wissen</span>
                </div>
            </Popover>
            <Button unstyled class="pages-menu-toggle" type="button" :label="SetSort(Filter.Sort).Label" :class="SetSort(Filter.Sort).Class" @click="ToggleSort" />
            <Popover ref="sort">
                <div class="popover">
                    <span class="popover-item" @click="Filter.Sort = 'AZ'" :class="{ active: Filter.Sort == 'AZ' }">A - Z</span>
                    <span class="popover-item" @click="Filter.Sort = 'ZA'" :class="{ active: Filter.Sort == 'ZA' }">Z - A</span>
                    <span class="popover-item" @click="Filter.Sort = 'NO'" :class="{ active: Filter.Sort == 'NO' }">Nieuw - Oud</span>
                    <span class="popover-item" @click="Filter.Sort = 'ON'" :class="{ active: Filter.Sort == 'ON' }">Oud - Nieuw</span>
                    <div v-if="Filter.Sort" class="popover-divider"></div>
                    <span v-if="Filter.Sort" class="popover-item" @click="Filter.Sort = false">Wissen</span>
                </div>
            </Popover>
            <span class="pages-menu-reset" v-if="Filter.Search || Filter.Sort || Filter.Status" @click="Reset">Filters verwijderen</span>
        </div>
        <div class="pages-list" v-if="FilteredPages.length > 0">
            <div class="pages-list-item" v-for="Page in FilteredPages" :key="Page.Identifier">
                <div class="pages-list-item-select">
                    <div class="checkbox" @click="Page.Selected = !Page.Selected">
                        <div v-if="Page.Selected" class="checked"></div>
                    </div>
                </div>
                <div class="pages-list-item-status">
                    <div v-if="Page.Status == 'O'" class="pill online">Online</div>
                    <div v-if="Page.Status == 'C'" class="pill concept">Concept</div>
                    <div v-if="Page.Status == 'A'" class="pill archive">Archief</div>
                </div>
                <div class="pages-list-item-information">
                    <span class="pages-list-item-information-title">{{ Page.Title }}</span>
                    <div class="pages-list-item-information-options">
                        <a v-if="Page.Status == 'O'" :href="'https://' + Client.Host + '/' + Page.Slug" target="_blank" class="page-bar-left-link">Bekijken</a>
                        <a v-else-if="Page.Status == 'C'" :href="'https://' + Client.Host + '/' + Page.Slug + '/' + Page.ConceptKey" target="_blank" class="page-bar-left-link">Bekijken</a>
                        <span v-else-if="Page.Status == 'A'" class="page-bar-left-link">Bekijken</span>
                        <router-link :to="'/website/pagina/' + Page.Identifier">Bewerken</router-link>
                        <span @click="DuplicatePage(Page)">Dupliceren</span>
                        <span @click="DeletePage(Page)">Verwijderen</span>
                    </div>
                </div>
                <div class="pages-list-item-date">
                    <span>{{ SetDate(Page.Created) }}</span>
                </div>
            </div>
        </div>
        <span class="empty" v-else>Geen pagina's gevonden</span>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Popover from 'primevue/popover';

export default {
    name: 'WebsitePages',

    components: {
        Popover,
        Button
    },

    data () {
        return {
            Pages: [],
            Client: [],
            Create: {
                Title: '',
                Template: 'Page'
            },
            Filter: {
                Search: '',
                Status: false,
                Sort: false
            }
        }
    },

    mounted () {
        this.GetPages()
    },

    methods: {
        GetPages () {
            this.$http.get('https://api.wheels.nl/pages?e=page').then((response) => {
                if(response.data.pages) { this.Pages = response.data.pages }
                if(response.data.client) { this.Client = response.data.client }
            })
        },

        CreatePage () {
            if(this.Create.Title && this.Create.Template) {
                this.$http.post('https://api.wheels.nl/pages', {
                    Title: this.Create.Title,
                    Template: this.Create.Template
                }).then((response) => {
                    if(response.data.identifier) {
                        this.$router.push('/website/pagina/' + response.data.identifier)
                    }
                })
            }
        },

        DuplicatePage (e) {
            if (confirm('Weet je zeker dat je de pagina wilt dupliceren?')) {
                this.$http.get(`https://api.wheels.nl/pages/duplicate/${e.Identifier}`).then((response) => {
                    if(response.data.identifier) {
                        this.$router.push('/website/pagina/' + response.data.identifier)
                    }
                })
            }
        },

        DeletePage (e) {
            if (confirm('Weet je zeker dat je de pagina wilt verwijderen?')) {
                this.$http.delete(`https://api.wheels.nl/pages/${e.Identifier}`).then(() => {
                    this.Pages = this.Pages.filter(a => a.Identifier !== e.Identifier)
                })
            }
        },

        SetDate (e) {
            if(this.$moment().isSame(e, 'day')) {
                return this.$moment(e).format('HH:mm')
            } else {
                return this.$moment(e).format('DD-MM')
            }
        },

        ToggleAdd (e) {
            this.$refs.add.toggle(e)
        },

        ToggleStatus (e) {
            this.$refs.status.toggle(e)
        },

        SetStatus (e) {
            if(e == 'O') {
                return {
                    Label: 'Online',
                    Class: 'active'
                }
            } else if (e == 'C') {
                return {
                    Label: 'Concept',
                    Class: 'active'
                }
            } else if (e == 'A') {
                return {
                    Label: 'Archive',
                    Class: 'active'
                }
            } else {
                return {
                    Label: 'Status',
                    Class: false
                }
            }
        },

        ToggleSort (e) {
            this.$refs.sort.toggle(e)
        },

        SetSort (e) {
            if(e == 'AZ') {
                return {
                    Label: 'A - Z',
                    Class: 'active'
                }
            } else if (e == 'ZA') {
                return {
                    Label: 'Z - A',
                    Class: 'active'
                }
            } else if (e == 'NO') {
                return {
                    Label: 'Nieuw - Oud',
                    Class: 'active'
                }
            } else if (e == 'ON') {
                return {
                    Label: 'Oud - Nieuw',
                    Class: 'active'
                }
            } else {
                return {
                    Label: 'Sorteren',
                    Class: false
                }
            }
        },

        Reset () {
            this.Filter.Search = ''
            this.Filter.Status = false
            this.Filter.Sort = false
        }
    },

    computed: {
        FilteredPages () {
            var Pages = this.Pages

            if(this.Filter.Status) {
                Pages = this.Pages.filter(item => item.Status == this.Filter.Status)
            }
            
            if(this.Filter.Search) {
                Pages = this.Pages.filter(item => item.Title.toString().toLowerCase().includes(this.Filter.Search.toString().toLowerCase()))
            }

            if(this.Filter.Sort == 'AZ') {
                return Pages.sort((a,b) => a.Title.localeCompare(b.Title))
            } else if(this.Filter.Sort == 'ZA') {
                return Pages.sort((a,b) => b.Title.localeCompare(a.Title))
            } else if(this.Filter.Sort == 'NO') {
                return Pages.sort((a,b) => new Date(a.Created) - new Date(b.Created))
            } else if(this.Filter.Sort == 'ON') {
                return Pages.sort((a,b) => new Date(b.Created) - new Date(a.Created))
            } else {
                return Pages.sort((a,b) => a.Title.localeCompare(b.Title))
            }
        }
    }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 50px;
    overflow: auto;
}

.pages-title {
    font-size: 30px;
}





.pages-menu {
    display: flex;
    gap: 15px;
}

.pages-menu .pages-menu-input {
    height: 40px;
    min-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    background: #ededed;
    outline: 0;
    border: 0;
    border-radius: 5px;
}

.pages-menu .pages-menu-add {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    background: #222222;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}

.pages-menu .pages-menu-add:deep(span) {
    color: #fff;
    font-weight: 600;
    font-size: 24px;
}

.pages-menu .pages-menu-toggle {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    background: #222222;
    font-weight: 600;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}

.pages-menu .pages-menu-toggle.active {
    padding: 0 25px 0 40px;
    background: #20b2aa;
}

.pages-menu .pages-menu-toggle:deep(span) {
    color: #fff;
}

.pages-menu .pages-menu-toggle.active:deep(span) {
    font-weight: 700;
}

.pages-menu .pages-menu-toggle::before {
    position: absolute;
    top: calc(50% - 7.5px);
    left: 15px;
    width: 15px;
    height: 15px;
    background: #088c85;
    border-radius: 50%;
}

.pages-menu .pages-menu-toggle.active::before {
    content: '';
}

.pages-menu .pages-menu-reset {
    display: flex;
    align-items: center;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
}





.pages-list {
    display: flex;
    flex-direction: column;
    border: 1px solid #ededed;
}

.pages-list-item {
    display: flex;
    padding: 15px 0;
    background: #fff;
    border-bottom: 1px solid #ededed;
}

.pages-list-item:last-of-type {
    border: 0;
}

.pages-list-item-select {
    display: flex;
    justify-content: center;
    min-width: 60px;
    max-width: 60px;
}

.pages-list-item-select .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: #eee;
    border-radius: 5px;
    cursor: pointer;
}

.pages-list-item-select .checkbox .checked {
    width: 20px;
    height: 20px;
    background: var(--primary);
    border-radius: 2.5px;
    pointer-events: none;
    opacity: 0;
}

.pages-list-item-select .checkbox .checked {
    opacity: 1;
}

.pages-list-item-status {
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
}

.pages-list-item-status .pill {
    height: 30px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 600;
    background: #eee;
    border-radius: 5px;
}

.pages-list-item-status .pill.online {
    color: #fff;
    background: #00cb7d;
}

.pages-list-item-status .pill.concept {
    background: #ffcc8d;
}

.pages-list-item-status .pill.archive {
    color: #fff;
    background: #cd5c5c;
}

.pages-list-item-information {
    width: calc(100% - 260px);
}

.pages-list-item-information-title {
    height: 30px;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.pages-list-item-information-options {
    display: flex;
    align-items: center;
    gap: 15px;
    opacity: 0;
}

.pages-list-item-information-options a {
    text-decoration: none;
}

.pages-list-item-information-options a:hover, .pages-list-item-information-options span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.pages-list-item-information:hover .pages-list-item-information-options {
    opacity: 1;
}

.pages-list-item-date {
    width: 100px;
    display: flex;
    justify-content: flex-end;
    padding: 0 25px 0 0;
}





.popover {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 0;
}

.popover.add {
    gap: 10px;
}

.popover .popover-title {
    font-weight: 700;
}

.popover .popover-input {
    height: 40px;
    min-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    outline: 0;
    border: solid 1px #333;
    border-radius: 5px;
}

.popover .popover-textarea {
    height: 40px;
    min-width: 350px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    outline: 0;
    border: solid 1px #333;
    border-radius: 5px;
    resize: none;
}

.popover .popover-button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    background: #222222;
    color: #fff;
    font-weight: 600;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}

.popover .popover-button.disabled {
    pointer-events: none;
    opacity: 0.25;
}

.popover .popover-item {
    padding: 0 15px;
    cursor: pointer;
}

.popover .popover-item.active {
    font-weight: 700;
}

.popover .popover-divider {
    width: 100%;
    height: 1px;
    background: #e2e8f0;
}
</style>