<template>
    <div class="overview">
        <div class="filters" :class="{ loader: Loader }">
            <span class="title">Filter</span>

            <span @click="CreateInvoice()" class="create">Aanmaken</span>

            <div class="group search">
                <input v-model="Search" @change="GetInvoices(true)" class="search" :class="{ active: Search }" type="text" placeholder="Zoek op nummer of klant">
            </div>

            <span class="reset" v-if="Search.length > 0 || Filtered.length > 0 || Selected.length > 0" @click="ResetFilters">Reset</span>

            <div class="group" v-for="(Filter, i) in Filters" :key="i">
                <span class="group-title">{{ Filter.Title }}</span>
                <input class="group-search" v-if="Filter.Searchable" type="text" v-model="Filter.Search">
                <div class="selected" v-if="Filter.Checked.length > 0">
                    <span class="item" v-for="(Item, i) in Filter.Checked" :key="i" @click="SetDynamicFilter(Item, Filter, false)">{{ Item.Name }}</span>
                </div>
                <div class="divider" v-if="Filter.Checked.length > 0"></div>
                <div class="items" :class="{ 'max-extended': Filter.Items.filter(e => e.Active).length > 0 }">
                    <div class="item" v-for="(Item, i) in Filter.Items" :key="i" :class="{ hidden: Item.SubItems.filter(e => e.Name.toLowerCase().includes(Filter.Search.toLowerCase())).length == 0 && !Item.Name.toLowerCase().includes(Filter.Search.toLowerCase()) }">
                        <div class="brand">
                            <div class="checkbox" v-if="Filter.Checkable" @click="SetDynamicItemFilter(Filter, Item), Filter.Search = ''">
                                <div v-if="Item.SubItems.length == Filter.Checked.filter(e => e.Brand == Item.Identifier).length" class="checked"></div>
                                <div v-if="(Item.SubItems.length > Filter.Checked.filter(e => e.Brand == Item.Identifier).length) && Filter.Checked.filter(e => e.Brand == Item.Identifier).length > 0" class="sub-checked"></div>
                            </div>
                            <span class="name">{{ Item.Name }}</span>
                            <svg class="more" @click="Item.Active = !Item.Active" :class="{ active: Item.Active }" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#000000"></path></g></svg>
                        </div>
                        <div class="list" v-if="Item.Active || Filter.Search">
                            <div class="models" v-for="(SubItem, Si) in Item.SubItems.filter(e => e.Name.toLowerCase().includes(Filter.Search.toLowerCase()))" :key="Si">
                                <div class="model" @click="SetDynamicFilter(SubItem, Filter, Item)">
                                    <div class="checkbox">
                                        <div v-if="Filter.Checked.filter(item => item.Identifier == SubItem.Identifier).length > 0" class="checked"></div>
                                    </div>
                                    <span class="name">{{ SubItem.Name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="catalog">
            <span class="title" v-if="Count > 1">{{ Count }} facturen</span>
            <span class="title" v-else-if="Count == 1">{{ Count }} factuur</span>
            <span class="title" v-else>Geen facturen gevonden</span>
            
            <div class="catalog-list" v-if="Count > 0">
                <div class="catalog-list-item" v-for="Invoice in Invoices" :key="Invoice.Identifier">
                    <div class="catalog-list-item-select">
                        <div class="checkbox" @click="Invoice.Selected = !Invoice.Selected">
                            <div v-if="Invoice.Selected" class="checked"></div>
                        </div>
                    </div>
                    <div class="catalog-list-item-status">
                        <div v-if="Invoice.Status == 'O'" class="pill open">Open</div>
                        <div v-if="Invoice.Status == 'S'" class="pill send">Verstuurd</div>
                        <div v-if="Invoice.Status == 'P'" class="pill payd">Betaald</div>
                    </div>
                    <div class="catalog-list-item-information">
                        <span class="catalog-list-item-information-title" v-if="Invoice.Title">{{ Invoice.Number }} - {{ Invoice.Title }}</span>
                        <span class="catalog-list-item-information-title" v-else>{{ Invoice.Number }}</span>
                        <span class="catalog-list-item-information-client">{{ Invoice.Customer }}</span>
                        <div class="catalog-list-item-information-options">
                            <router-link :to="'/facturatie/' + Invoice.Identifier" v-if="Invoice.Status == 'O'">Bewerken</router-link>
                            <router-link :to="'/facturatie/' + Invoice.Identifier" v-else>Bekijken</router-link>
                            <span @click="DownloadInvoice(Invoice)">Downloaden</span>
                            <span @click="DuplicateInvoice(Invoice)">Dupliceren</span>
                            <span @click="DeleteInvoice(Invoice)" v-if="Invoice.Status == 'O'">Verwijderen</span>
                        </div>
                    </div>
                    <div class="catalog-list-item-date" :class="SetDate(Invoice.Created).Class">{{ SetDate(Invoice.Created).Text }}</div>
                </div>
            </div>

            <vue-awesome-paginate v-if="Count > PageLimit" :total-items="Count" :items-per-page="PageLimit" :max-pages-shown="5" v-model="Page" @click="SetPage" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BillingOverview',

    data () {
        return {
            View: 'List',
            Loader: false,
            Latest: false,
            Invoices: [],
            Search: '',
            Filters: [
                {
                    Title: '',
                    Search: '',
                    Searchable: false,
                    Checkable: true,
                    Checked: [],
                    Items: [
                        {
                            Identifier: '22ba16a9-64ed-44b2-ad50-67c96d1a6731',
                            Name: 'Status',
                            Active: true,
                            SubItems: [
                                {
                                    Identifier: 'e3ace2ce-2d81-4efe-b6a0-5a7dc6d96e67',
                                    Name: 'Open',
                                    Value: 1111
                                },
                                {
                                    Identifier: '8853b96c-b3b5-4701-a698-c911e9455669',
                                    Name: 'Betaald',
                                    Value: 2222
                                },
                                {
                                    Identifier: '4cff503f-32f8-435f-a518-72792d80480d',
                                    Name: 'Verzonden',
                                    Value: 3333
                                }
                            ]
                        }
                    ]
                }
            ],
            Filtered: [],
            Selected: [],
            Models: {
                Active: false,
                Search: '',
                Items: '',
                Checked: []
            },
            Count: 0,
            Page: 1,
            PageLimit: 25,
            Query: false,
            SearchQuery: false
        }
    },

    mounted () {
        this.GetInvoices()
    },

    methods: {
        GetInvoices () {
            this.$http.get('https://api.wheels.nl/invoices', {
                params: {
                    page: this.Page,
                    limit: this.PageLimit
                }
            }).then((response) => {
                if(response.data.invoices) {
                    this.Invoices = response.data.invoices
                    this.Count = response.data.count
                } else {
                    this.Invoices = []
                    this.Count = 0
                }

                document.querySelector(".overview .catalog").scrollTo({top: 0, behavior: 'smooth'})
            })
        },

        CreateInvoice () {
            this.$http.post('https://api.wheels.nl/invoices').then((response) => {                
                if(response.data.identifier) {
                    this.$router.push('/facturatie/' + response.data.identifier)
                }
            })
        },

        DownloadInvoice (e) {
            window.open('https://api.wheels.nl/invoices/' + e.Identifier + '/file?M=View&WAT=' + this.$root.WAT + '&WDT=' + this.$root.WDT)
        },

        DuplicateInvoice (e) {
            if(confirm('Weet je zeker dat je deze factuur wilt dupliceren?')) {
                this.$http.get(`https://api.wheels.nl/invoices/${e.Identifier}/duplicate`).then((response) => {
                    if(response.data.identifier) {
                        this.$router.push('/facturatie/' + response.data.identifier)
                    }

                    this.$toast.open({
                        message: 'Factuur gedupliceerd',
                        position: 'bottom-right',
                        type: 'success',
                        duration: 1500
                    })
                })
            }
        },

        DeleteInvoice (e) {
            if(confirm('Weet je zeker dat je deze factuur wilt verwijderen?')) {
                this.$http.delete(`https://api.wheels.nl/invoices/${e.Identifier}`).then((response) => {
                    if(response.data.error) {
                        this.$toast.open({
                            message: 'Factuur kan niet verwijderd worden',
                            position: 'bottom-right',
                            type: 'error',
                            duration: 1500
                        })
                    } else {
                        this.GetInvoices()
                        this.$toast.open({
                            message: 'Factuur verwijderd',
                            position: 'bottom-right',
                            type: 'success',
                            duration: 1500
                        })
                    }
                })
            }
        },

        SetDate (e) {
            if(this.$moment().isSame(e, 'day')) {
                return {
                    Text: this.$moment(e).format('HH:mm'),
                    Class: 'today'
                }
            } else {
                return {
                    Text: this.$moment(e).format('DD-MM'),
                    Class: false
                }
            }
        },

        SetFilter (e, a) {
            if(a.Checked.filter(item => item.ModelIdentifier == e.ModelIdentifier).length > 0) {
                a.Checked = a.Checked.filter(item => item.ModelIdentifier !== e.ModelIdentifier)
            } else {
                a.Checked.push({
                    ModelIdentifier: e.ModelIdentifier,
                    Brand: e.BrandIdentifier,
                    Name: e.ModelName
                })
            }
        },

        SetDynamicFilter (e, a, z) {
            if(a.Checked.filter(item => item.Identifier == e.Identifier).length > 0) {
                a.Checked = a.Checked.filter(item => item.Identifier !== e.Identifier)
            } else {
                a.Checked.push({
                    Identifier: e.Identifier,
                    Brand: z.Identifier,
                    Name: e.Name
                })
            }
        },

        SetItemFilter (e, a) {
            if(e.length == a.Checked.filter(z => z.Brand == e[0].BrandIdentifier).length) {
                a.Checked = a.Checked.filter(z => z.Brand !== e[0].BrandIdentifier)
            } else {
                e.forEach(z => {
                    if(a.Checked.filter(item => item.ModelIdentifier == z.ModelIdentifier).length == 0) {
                        a.Checked.push({
                            ModelIdentifier: z.ModelIdentifier,
                            Brand: z.BrandIdentifier,
                            Name: z.ModelName
                        })
                    }
                })
            }
        },

        SetDynamicItemFilter (e, a) {
            if(a.SubItems.length == e.Checked.filter(e => e.Brand == a.Identifier).length) {
                a.SubItems.forEach(z => {
                    e.Checked = e.Checked.filter(item => item.Identifier !== z.Identifier)
                })

                a.Active = false
            } else {
                a.SubItems.forEach(z => {
                    if(e.Checked.filter(item => item.Identifier == z.Identifier).length == 0) {
                        e.Checked.push({
                            Identifier: z.Identifier,
                            Brand: a.Identifier,
                            Name: a.Name + ' - ' + z.Name
                        })
                    }
                })

                a.Active = true
            }
        },

        SetSelection (e) {
            if(this.Selected.filter(item => item.Identifier == e.Identifier).length > 0) {
                this.Selected = this.Selected.filter(item => item.Identifier !== e.Identifier)
            } else {
                this.Selected.push(e)
            }

            localStorage.setItem('ArticleTime', Date.now())
            localStorage.setItem('ArticleSelection', JSON.stringify(this.Selected))
        },

        SetPage (e) {
            this.Page = e

            this.GetInvoices()
        },

        ResetFilters () {
            this.Search = ''
            localStorage.removeItem('BillingSearch')

            this.Page = 1
            this.Limit = 25

            this.GetInvoices()
        }
    }
}
</script>

<style scoped>
.overview {
    position: relative;
    display: flex;
    gap: 0 50px;
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 50px;
    background: #f7f8fc;
    overflow: hidden;
}

.overview .filters {
    position: relative;
    min-width: 350px;
    max-width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    padding: 50px 0;
    transition: .5s;
    overflow-y: auto;
}

.overview .filters.loader {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.overview .filters::-webkit-scrollbar {
    width: 0px;
}

.overview .filters .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.overview .filters .create {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    font-weight: 600;
    text-decoration: none;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.overview .filters .menu .list {
    width: 32px;
    height: 32px;
    fill: #000;
    cursor: pointer;
}

.overview .filters .menu .blocks {
    width: 24px;
    height: 24px;
    fill: #000;
    cursor: pointer;
}





.overview .filters .radio {
    display: flex;
    width: 100%;
    min-height: 50px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
    overflow: hidden;
}

.overview .filters .radio .item {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.overview .filters .radio .item.active {
    color: #fff;
    font-weight: 600;
    background: var(--primary);
}





.overview .filters .reset {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    background: var(--primary);
    font-weight: 500;
    color: #fff;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}





.overview .filters .group {
    padding: 0 25px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.overview .filters .group.search {
    padding: 0;
}

.overview .filters .group.active {
    outline: solid 1px var(--primary);
    border: solid 1px var(--primary);
}

.overview .filters .group .group-title {
    display: block;
    font-weight: 700;
    margin: 20px 0 10px 0;
}

.overview .filters .group .group-search {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    margin: 0 0 15px 0;
    background: #f7f8fc;
    border: 0;
    border-radius: 5px;
    outline: 0;
}

.overview .filters .group .prices {
    display: flex;
    gap: 15px;
    margin: 0 0 25px 0;
}

.overview .filters .group .prices input {
    width: 100%;
    height: 50px;
    padding: 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #ededed;
    border-radius: 5px;
}

.overview .filters .group .selected {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.overview .filters .group .selected .item {
    display: flex;
    align-items: center;
    padding: 2.5px 10px;
    font-size: 14px;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.overview .filters .group .divider {
    border-bottom: solid 1px #f7f8fc;
    padding: 15px 0 0 0;
    margin: 0 0 15px 0;
}

.overview .filters .group .search {
    width: 100%;
    background: #fff;
    padding: 15px 25px;
    border: 0;
    border-radius: 5px;
    outline: none;
}

.overview .filters .group .items {
    display: flex;
    flex-direction: column;
    max-height: 250px;
    padding: 0 0 25px 0;
    overflow: auto;
    transition: .5s;
}

.overview .filters .group .items.extended {
    max-height: 500px;
}

.overview .filters .group .items.max-extended {
    max-height: unset;
}

.overview .filters .group .items::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.overview .filters .group .items .item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.overview .filters .group .items .item.hidden {
    display: none;
}

.overview .filters .group .items .item .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.overview .filters .group .items .item .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}

.overview .filters .group .items .item .checkbox .sub-checked {
    width: 14px;
    height: 14px;
    background: #ededed;
    border-radius: 2.5px;
    opacity: 0.5;
}

.overview .filters .group .items .item .count {
    margin: 0 0 0 auto;
    font-size: 12px;
    opacity: 0.25;
}

.overview .filters .group .items .item .brand {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.overview .filters .group .items .item .brand .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
    cursor: pointer;
}

.overview .filters .group .items .item .brand span {
    cursor: pointer;
}

.overview .filters .group .items .item .brand .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}

.overview .filters .group .items .item .brand .more {
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 0 0 0 auto;
    transition: .25s;
}

.overview .filters .group .items .item .brand .more.active {
    transform: rotate(180deg);
}

.overview .filters .group .items .item .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 0 10px 0;
}

.overview .filters .group .items .item .list .models {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 0 0 0 30px;
    cursor: pointer;
}

.overview .filters .group .items .item .list .models .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #f7f8fc;
    border-radius: 5px;
}

.overview .filters .group .items .item .list .models .checkbox .checked {
    width: 14px;
    height: 14px;
    background: var(--primary);
    border-radius: 2.5px;
}

.overview .filters .group .items .item .list .models .model {
    display: flex;
    gap: 10px;
}





.overview .catalog {
    position: relative;
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 50px 0;
    overflow: auto;
}

.overview .catalog .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.overview .catalog .catalog-list {
    display: flex;
    flex-direction: column;
    border: 1px solid #ededed;
    border-radius: 5px;
}

.overview .catalog .catalog-list-item {
    display: flex;
    padding: 15px 0;
    background: #fff;
    border-bottom: 1px solid #ededed;
}

.overview .catalog .catalog-list-item:first-of-type {
    border-radius: 5px 5px 0 0;
}

.overview .catalog .catalog-list-item:last-of-type {
    border: 0;
    border-radius: 0 0 5px 5px;
}

.overview .catalog .catalog-list-item-select {
    display: flex;
    justify-content: center;
    min-width: 60px;
    max-width: 60px;
}

.overview .catalog .catalog-list-item-select .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: #eee;
    border-radius: 5px;
    cursor: pointer;
}

.overview .catalog .catalog-list-item-select .checkbox .checked {
    width: 20px;
    height: 20px;
    background: var(--primary);
    border-radius: 2.5px;
    pointer-events: none;
    opacity: 0;
}

.overview .catalog .catalog-list-item-select .checkbox .checked {
    opacity: 1;
}

.overview .catalog .catalog-list-item-status {
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
}

.overview .catalog .catalog-list-item-status .pill {
    height: 30px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 600;
    background: #eee;
    border-radius: 5px;
}

.overview .catalog .catalog-list-item-status .pill.open {
    color: #fff;
    background: #ffcc8d;
}

.overview .catalog .catalog-list-item-status .pill.send {
    background: var(--primary);
}

.overview .catalog .catalog-list-item-status .pill.payd {
    color: #fff;
    background: #00cb7d;
}

.overview .catalog .catalog-list-item-information {
    width: calc(100% - 260px);
}

.overview .catalog .catalog-list-item-information-title {
    height: 30px;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.overview .catalog .catalog-list-item-information-options {
    display: flex;
    align-items: center;
    gap: 15px;
    opacity: 0;
}

.overview .catalog .catalog-list-item-information-options a {
    text-decoration: none;
}

.overview .catalog .catalog-list-item-information-options a:hover, .overview .catalog .catalog-list-item-information-options span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.overview .catalog .catalog-list-item-information:hover .catalog-list-item-information-options {
    opacity: 1;
}

.overview .catalog .catalog-list-item-date {
    width: 100px;
    display: flex;
    justify-content: flex-end;
    padding: 0 25px 0 0;
}

.overview .catalog .catalog-list-item-date.today {
    font-weight: 700;
}
</style>