<template>
    <div class="item">
        <div class="item-block full notice" v-if="Client.Status == 'A'">
            <span class="item-block-title">📌 Klant is gearchiveerd</span>
            <span>Deze klant is gearchiveerd en kan niet worden bewerkt of gebruikt in nieuwe transacties. Om de klant weer actief te maken, dien je deze eerst te de-archiveren. Daarna kun je de gegevens aanpassen en de klant opnieuw gebruiken.</span>
        </div>
        <div class="item-block full notice" v-if="Create">
            <span class="item-block-title">📌 Nieuwe klant aanmaken</span>
            <span>Voer hier de gegevens in om een nieuwe klant aan te maken, inclusief adres-, factuur-, contact- en bedrijfsgegevens. Voeg indien nodig notities toe voor specifieke wensen of afspraken. Sla de klant op om deze toe te voegen aan het systeem.</span>
        </div>
        <div class="item-block buttons">
            <div class="item-block-button blue" @click="Patch">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" fill="#ffffff"></path></g></svg>
                Opslaan
            </div>
            <div class="item-block-spacer" v-if="!Create && Client.Status == 'O'"></div>
            <div class="item-block-button red" v-if="!Create && Client.Status == 'O'" @click="Delete">
                <svg fill="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"></path></g></svg>
                Archiveren
            </div>
        </div>
        <div class="item-column">
            <div class="item-block">
                <span class="item-block-title">Adresgegevens</span>
                <div class="item-block-group full">
                    <span class="item-block-group-subtitle">Soort</span>
                    <SelectButton v-model="Client.Type" :options="[{ Label: 'Bedrijf', Value: 'B' }, { Label: 'Particulier', Value: 'P' }]" optionLabel="Label" optionValue="Value" :allowEmpty="false" />
                </div>
                <div class="item-block-group full" v-if="Client.Type == 'B'">
                    <span class="item-block-group-subtitle">Bedrijfsnaam</span>
                    <input v-model="Client.Name" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group third" v-if="Client.Type == 'P'">
                    <span class="item-block-group-subtitle">Voornaam</span>
                    <input v-model="Client.FirstName" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group third" v-if="Client.Type == 'P'">
                    <span class="item-block-group-subtitle">Tussenvoegsels</span>
                    <input v-model="Client.MiddleName" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group third" v-if="Client.Type == 'P'">
                    <span class="item-block-group-subtitle">Achternaam</span>
                    <input v-model="Client.LastName" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group">
                    <span class="item-block-group-subtitle">Straat</span>
                    <input v-model="Client.Street" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group">
                    <span class="item-block-group-subtitle">Huisnummer</span>
                    <input v-model="Client.Number" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group">
                    <span class="item-block-group-subtitle">Postcode</span>
                    <input v-model="Client.Postcode" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group">
                    <span class="item-block-group-subtitle">Stad</span>
                    <input v-model="Client.City" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group full">
                    <span class="item-block-group-subtitle">Land</span>
                    <Select v-model="Client.Country" :options="Countries" optionLabel="native" optionValue="native" :editable="true" :autoOptionFocus="true">
                        <template #option="slotProps">
                            <div class="flex items-center">                                 
                                <div>{{ CountryFlag(slotProps.option) }} {{ slotProps.option.native }}</div>
                            </div>
                        </template>
                    </Select>
                </div>
                <div class="item-block-group">
                    <span class="item-block-group-subtitle">Aangemaakt</span>
                    <input :value="this.$moment(Client.Created).format('DD-MM-YYYY HH:mm')" type="text" class="item-block-group-input" disabled>
                </div>
                <div class="item-block-group">
                    <span class="item-block-group-subtitle">Gewijzigd</span>
                    <input v-if="Client.Changed" :value="this.$moment(Client.Changed).format('DD-MM-YYYY HH:mm')" type="text" class="item-block-group-input" disabled>
                    <input v-else type="text" class="item-block-group-input" disabled>
                </div>
            </div>
            <div class="item-block">
                <span class="item-block-title">Factuurgegevens</span>
                <div class="item-block-group full">
                    <span class="item-block-group-subtitle">Soort</span>
                    <SelectButton v-model="Client.Alt" :options="[{ Label: 'Zelfde als adres', Value: '0' }, { Label: 'Niet hetzelfde als adres', Value: '1' }]" optionLabel="Label" optionValue="Value" :allowEmpty="false" />
                </div>
                <div class="item-block-group full" v-if="Client.Alt == '1'">
                    <span class="item-block-group-subtitle">Bedrijfsnaam</span>
                    <input v-model="Client.AltName" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group" v-if="Client.Alt == '1'">
                    <span class="item-block-group-subtitle">Straat</span>
                    <input v-model="Client.AltStreet" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group" v-if="Client.Alt == '1'">
                    <span class="item-block-group-subtitle">Huisnummer</span>
                    <input v-model="Client.AltNumber" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group" v-if="Client.Alt == '1'">
                    <span class="item-block-group-subtitle">Postcode</span>
                    <input v-model="Client.AltPostcode" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group" v-if="Client.Alt == '1'">
                    <span class="item-block-group-subtitle">Stad</span>
                    <input v-model="Client.AltCity" type="text" class="item-block-group-input">
                </div>
                <div class="item-block-group full" v-if="Client.Alt == '1'">
                    <span class="item-block-group-subtitle">Land</span>
                    <Select v-model="Client.AltCountry" :options="Countries" optionLabel="native" optionValue="native" :editable="true" :autoOptionFocus="true">
                        <template #option="slotProps">
                            <div class="flex items-center">                                 
                                <div>{{ CountryFlag(slotProps.option) }} {{ slotProps.option.native }}</div>
                            </div>
                        </template>
                    </Select>
                </div>
            </div>
        </div>
        <div class="item-column">
            <div class="item-block">
                <span class="item-block-title">Contactgegevens</span>
                <div class="item-block-group">
                    <span class="item-block-group-subtitle">Telefoonnummer</span>
                    <input v-model="Client.Phone" type="phone" class="item-block-group-input">
                </div>
                <div class="item-block-group">
                    <span class="item-block-group-subtitle">E-mailadres</span>
                    <input v-model="Client.Email" type="email" class="item-block-group-input">
                </div>
            </div>
            <div class="item-block">
                <span class="item-block-title">Bedrijfsgegevens</span>
                <div class="item-block-group">
                    <span class="item-block-group-subtitle">Kamer van Koophandel</span>
                    <input v-model="Client.TradeNumber" type="email" class="item-block-group-input">
                </div>
                <div class="item-block-group">
                    <span class="item-block-group-subtitle">BTW Nummer</span>
                    <input v-model="Client.VatNumber" type="phone" class="item-block-group-input">
                </div>
            </div>
            <div class="item-block">
                <span class="item-block-title">Notities</span>
                <div class="item-block-group full">
                    <textarea v-model="Client.Notes" class="item-block-group-textarea"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCountryDataList, getEmojiFlag } from 'countries-list'

import Select from 'primevue/select';
import SelectButton from 'primevue/selectbutton';

export default {
    name: 'ClientsItem',

    components: {
        Select,
        SelectButton
    },

    data () {
        return {
            Create: false,
            Client: [],
            ClientStart: [],
            Status: 'O',
            Countries: getCountryDataList()
        }
    },

    mounted () {
        if(this.$route.query && this.$route.query.aanmaken == 'true') {
            this.Create = true
            this.Client.Type = 'B'
            this.Client.Alt = '0'
        } else {
            this.GetClient()
        }
    },

    methods: {
        GetClient () {
            this.$http.get(`https://api.wheels.nl/clients/${this.$route.params.id}`).then((response) => {                
                if(response.data.client) {
                    this.Client = response.data.client
                    this.ClientStart = JSON.stringify(response.data.client)
                } else {
                    this.$router.push('/klanten')
                    this.$toast.open({
                        message: 'Klant niet gevonden',
                        position: 'bottom-right',
                        type: 'error',
                        duration: 1500
                    })
                }
            })
        },

        Patch () {
            this.$http.patch(`https://api.wheels.nl/clients/${this.$route.params.id}`, {
                Create: this.Create,
                Client: Object.assign({}, this.Client)
            }).then(() => {
                this.Create = false
                this.ClientStart = JSON.stringify(this.Client)

                this.$router.replace({'query': null})

                this.$toast.open({
                    message: 'Klant opgeslagen',
                    position: 'bottom-right',
                    type: 'success',
                    duration: 1500
                })
            })
        },

        Delete () {
            if(confirm('Weet je zeker dat je deze klant wilt verwijderen?')) {
                this.$http.delete(`https://api.wheels.nl/clients/${this.$route.params.id}`).then((response) => {
                    if(response.data.error) {
                        this.$toast.open({
                            message: 'Klant kan niet verwijderd worden',
                            position: 'bottom-right',
                            type: 'error',
                            duration: 1500
                        })
                    } else {
                        this.$router.push('/klanten')
                        this.$toast.open({
                            message: 'Klant verwijderd',
                            position: 'bottom-right',
                            type: 'success',
                            duration: 1500
                        })
                    }
                })
            }
        },

        CountryFlag (e) {
            return getEmojiFlag(e.iso2)
        }
    },

    beforeRouteLeave (to, from , next) {
        if((JSON.stringify(this.Client) !== this.ClientStart) && this.$route.query.aanmaken !== 'true') {
            const answer = window.confirm('Weet je zeker dat je de klant wilt sluiten? Wijzigingen worden niet opgeslagen.')
            if (answer) {
                next()
            } else {
                next(false)
            }
        } else {
            next()
        }
    }
}
</script>

<style scoped>
.item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: 25px;
    max-width: 1200px;
    margin: auto;
    padding: 50px;
    overflow: auto;
}

.item .item-column {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: calc(50% - 12.5px);
}

.item .item-block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 15px 25px;
    width: 100%;
    padding: 25px;
    background: #fff;
    border-radius: 5px;
}

.item .item-block.full {
    width: 100%;
}

.item .item-block.notice {
    background: #ffffe0;
}

.item .item-block.summery {
    background: unset;
    padding: 0 25px;
}

.item .item-block.buttons {
    width: 100%;
    gap: 15px;
}

.item .item-block .item-block-title {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
}

.item .item-block .item-block-group {
    width: calc(50% - 12.5px);
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.item .item-block .item-block-group.full {
    width: 100%;
}

.item .item-block .item-block-group.third {
    width: calc(33% - 15.5px);
}

.item .item-block .item-block-group .item-block-group-input {
    height: 40px;
    padding: 0 10px;
    outline: 0;
    border: solid 1px #cbd5e1;
    border-radius: 5px;
}

.item .item-block .item-block-group .item-block-group-input:disabled {
    opacity: 0.5;
}

.item .item-block .item-block-group .item-block-group-input.date {
    padding: 0;
}

.item .item-block .item-block-group .item-block-group-input.date:deep(.p-inputtext) {
    background: unset;
    border: unset;
}

.item .item-block .item-block-group .item-block-group-textarea {
    width: 100%;
    min-height: 150px;
    padding: 10px;
    resize: none;
    outline: 0;
    border: solid 1px #cbd5e1;
    border-radius: 5px;
}

.item .item-block .item-block-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    background: #333333;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.item .item-block .item-block-button.blue {
    background: var(--primary);
}

.item .item-block .item-block-button.green {
    background: #8fbc8f;
}

.item .item-block .item-block-button.red {
    background: #cd5c5c;
}

.item .item-block .item-block-button svg {
    width: 18px;
    height: 18px;
}

.item .item-block .item-block-spacer {
    width: 8px;
    height: 8px;
    margin: auto 8px;
    background: var(--primary);
    border-radius: 50%;
}

.item .item-block .item-block-header {
    width: 100%;
    display: flex;
    gap: 15px;
}

.item .item-block .item-block-header-item.article {
    width: calc(60% - 90px);
}

.item .item-block .item-block-header-item.quantity {
    width: 10%;
}

.item .item-block .item-block-header-item.btw {
    width: 10%;
}

.item .item-block .item-block-header-item.price {
    min-width: 20%;
    max-width: 20%;
}

.item .item-block .item-block-header-item.more {
    min-width: 30px;
    max-width: 30px;
}

.item .item-block .item-block-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.item .item-block .item-block-row .item-block-row-item.article {
    width: calc(60% - 90px);
}

.item .item-block .item-block-row .item-block-row-item.article.min {
    padding: 0;
    border: 0;
}

.item .item-block .item-block-row .item-block-row-item.bpm {
    width: calc(80% - 60px);
}

.item .item-block .item-block-row .item-block-row-item.quantity {
    width: 10%;
}

.item .item-block .item-block-row .item-block-row-item.btw {
    width: 10%;
}

.item .item-block .item-block-row .item-block-row-item.price {
    min-width: 20%;
    max-width: 20%;
    padding: 0;
    border: 0;
}

.item .item-block .item-block-row .item-block-row-item {
    width: 60%;
    height: 40px;
    padding: 0 10px;
    outline: 0;
    border: solid 1px #cbd5e1;
    border-radius: 6px;
}

.item .item-block .item-block-row .item-block-row-more {
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    margin: auto;
    cursor: pointer;
}

.item .item-block .item-block-row .item-block-row-shortcuts {
    display: flex;
    gap: 5px;
    margin: 0 45px 0 auto;
}

.item .item-block .item-block-row .item-block-row-shortcuts-shortcut {
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    padding: 5px;
    border: solid 1px #cbd5e1;
    border-radius: 5px;
    cursor: pointer;
}

.item .item-block .item-block-row .item-block-row-shortcuts-shortcut.disabled {
    pointer-events: none;
    opacity: 0.25;
}

.item .item-block .item-block-row textarea {
    width: calc(100% - 45px);
    min-height: 120px;
    padding: 10px;
    outline: 0;
    border: solid 1px #cbd5e1;
    border-radius: 5px;
    resize: none;
}

.item .item-block .item-block-add {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.item .item-block .item-block-add.margin {
    margin: 25px 0 0 0;
}

.item .item-block .item-block-add .item-block-add-item {
    display: flex;
    padding: 10px 15px;
    background: #f7f8fc;
    cursor: pointer;
    border-radius: 5px;
}

.item .item-block .item-block-summery {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 0 0 auto;
}

.item .item-block .item-block-summery .item-block-summery-group {
    display: flex;
    align-items: center;
}

.item .item-block .item-block-summery .item-block-summery-group .item-block-summery-group-subtitle {
    width: 100px;
}

.item .item-block .item-block-summery .item-block-summery-group .item-block-summery-group-input {
    width: 200px;
}

.item .item-block .item-block-summery .item-block-summery-group .item-block-summery-group-input.disabled {
    pointer-events: none;
}





/* PAYD */
.input-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.element-disabled {
    pointer-events: none;
    opacity: 0.5;
}
</style>